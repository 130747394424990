import React from 'react'
import UserDatabase from '../components/UserDatabase'
import Layout from '../components/Layout'

export default function Database() {
  return (
    <Layout>
        <div>
            <UserDatabase />
        </div>
    </Layout> 
  )
}
