import React, { useState } from 'react'
import Layout from '../components/Layout'
import { isArguments } from 'lodash';
import Appointment from '../components/Appointment';
import GeneralNotification from '../components/GeneralNotification';
import Button from '../components/Button';
import Announcement from '../assets/Announcement';

export default function Notifications() {
    const [appointmentsActive, setAppointmentsActive] = useState(true);
    const [generalActive, setGeneralActive] = useState(false);

    const handleAppointmentsClick = () => {
        setAppointmentsActive(true);
        setGeneralActive(false);
    };

    const handleGeneralClick = () => {
        setAppointmentsActive(false);
        setGeneralActive(true);
    };
  return (
    <Layout>
        <div>
            <h2 className='p-2 text-black text-2xl font-bold'>Notifications</h2>
            <div className='flex flex-col md:flex-row lg:min-h-[67px]'>
                <div className='flex justify-center md:justify-start border-b w-full'>
                    <button className={`py-3 px-4 border-b text-neutral-200 hover:text-primary-100 hover:border-b-primary-100 bg-transparent ${appointmentsActive ? 'border-b-black text-black font-semibold' : ''}`} onClick={handleAppointmentsClick}>Appointments</button>
                    <button className={`py-3 px-4 border-b text-neutral-200 hover:text-primary-100 hover:border-b-primary-100 bg-transparent ${generalActive ? 'border-b-black text-black font-semibold' : ''}`} onClick={handleGeneralClick}>General</button>
                </div>
                {generalActive &&
                <div className='relative  md:border-b pt-4 md:pt-0 pb-3 md:w-1/3'>
                    <Announcement className='absolute h-6 w-6 my-2 md:my-4 ml-8 md:ml-2 xl:ml-8 stroke-light' />
                    <Button className='w-full py-1' name={ 'New Announcement'}/>
                </div>
                }
           </div>

            {appointmentsActive && 
                <div>
                    <Appointment isConfirmed={false} />
                    <Appointment isConfirmed={true} />
                </div>
            }
            
            {generalActive &&
                <div>
                    <GeneralNotification />
                    <GeneralNotification />
                    <GeneralNotification />
                </div>
            }
        </div>
    </Layout>
  )
}
