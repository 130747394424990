import React from 'react'

export default function Hexagon({className}) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M40 45.833C38.6333 45.833 37.5 44.6997 37.5 43.333V25.833C37.5 24.4663 38.6333 23.333 40 23.333C41.3667 23.333 42.5 24.4663 42.5 25.833V43.333C42.5 44.6997 41.3667 45.833 40 45.833Z" />
    <path d="M40.0001 56.6001C39.3378 56.6001 38.7425 56.3612 38.2863 55.9135C38.0662 55.6654 37.8848 55.3855 37.72 55.0604C37.6165 54.8003 37.5667 54.4991 37.5667 54.1667C37.5667 53.5494 37.831 52.9046 38.2782 52.428C38.7002 52.0112 39.329 51.775 40.0001 51.775C40.6711 51.775 41.3 52.0112 41.722 52.428C42.1692 52.9046 42.4334 53.5494 42.4334 54.1667C42.4334 54.4635 42.3643 54.7793 42.2359 55.0873C42.1125 55.3834 41.9402 55.6582 41.7139 55.9135C41.2577 56.3612 40.6623 56.6001 40.0001 56.6001Z" strokeWidth="1.8"/>
    <path d="M40.0005 75.8337C37.7672 75.8337 35.5005 75.2669 33.5005 74.1003L13.7005 62.667C9.70048 60.3336 7.20044 56.0336 7.20044 51.4003V28.6004C7.20044 23.967 9.70048 19.667 13.7005 17.3337L33.5005 5.90039C37.5005 3.56706 42.4672 3.56706 46.5006 5.90039L66.3006 17.3337C70.3006 19.667 72.8006 23.967 72.8006 28.6004V51.4003C72.8006 56.0336 70.3006 60.3336 66.3006 62.667L46.5006 74.1003C44.5006 75.2669 42.2339 75.8337 40.0005 75.8337ZM40.0005 9.16699C38.6339 9.16699 37.2338 9.53368 36.0005 10.2337L16.2005 21.667C13.7338 23.1003 12.2004 25.7337 12.2004 28.6004V51.4003C12.2004 54.2336 13.7338 56.9003 16.2005 58.3337L36.0005 69.767C38.4672 71.2003 41.5339 71.2003 43.9672 69.767L63.7672 58.3337C66.2339 56.9003 67.7672 54.2669 67.7672 51.4003V28.6004C67.7672 25.767 66.2339 23.1003 63.7672 21.667L43.9672 10.2337C42.7672 9.53368 41.3672 9.16699 40.0005 9.16699Z" />
    </svg>

  )
}
