import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { createModule } from '../api/mutations';

const CreateModuleForm = ({ onSubmit }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const mutation = useMutation({
    mutationFn: createModule,
        onSuccess: (data) => {
            toast.success('Module created successfully!');
        },
    });
    const onFormSubmit = (data) => {
        mutation.mutate(data)
        console.log(data)
    }

    return (
        <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-4">
            <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Module Name</label>
                <input
                    id="name"
                    type="text"
                    {...register('name', { required: 'Module name is required' })}
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
            </div>

            <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                    id="description"
                    {...register('description', { required: 'Description is required' })}

                />
                {errors.description && <p className="text-red-500 text-sm">{errors.description.message}</p>}
            </div>


            <button
                type="submit"
            >
                Create Module
            </button>
        </form>
    );
};

export default CreateModuleForm;
