import React from 'react'
import ForumPost from './ForumPost'

export default function ForumComponent() {
  return (
    <div className='py-6'>
        <ForumPost />
    </div>
  )
}
