import api, { fileApi } from "../../api/axios";



export const geModules = async () => {
  return await api.get('/modules')
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

export const getTopicsByModuleId = async ({ queryKey }) => {
  const [, module_id] = queryKey
  return await api.get(`/topics?module_id=${module_id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

export const getLessonsByTopic = async ({ queryKey }) => {
  const [, module_id] = queryKey
  return await api.get(`/lessons?topic_id=${module_id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

export const getQuizByTopic = async ({ queryKey }) => {
  const [, module_id] = queryKey
  return await api.get(`/quizzes?topic_id=${module_id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}
export const getQuizByQuizId = async ({ queryKey }) => {
  const [, quiz_id] = queryKey
  return await api.get(`/quizzes/${quiz_id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}



export const createCourse = async (course) => {
  try {
    const response = await api.post('/topics', course);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await api.post('/v1/users/signin', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createLesson = async (lessons) => {
  try {
    const response = await api.post('/lessons', lessons);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createQuiz = async (questions) => {
  try {
    const response = await api.post('/questions/createQuestion', questions);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadVideo = async (lessons) => {
  try {
    const response = await fileApi.post('/lessons', lessons);
    return response.data;
  } catch (error) {
    throw error;
  }
};