import React from 'react'

export default function SmartLab({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_1344_6654)">
    <path d="M19.5 8.70977L14.167 4.56177C13.699 4.19768 13.123 4 12.53 4C11.9371 4 11.361 4.19768 10.893 4.56177L5.55903 8.70977C5.23847 8.95905 4.97912 9.2783 4.80078 9.64312C4.62245 10.0079 4.52984 10.4087 4.53003 10.8148V18.0148C4.53003 18.5452 4.74074 19.0539 5.11582 19.429C5.49089 19.8041 5.9996 20.0148 6.53003 20.0148H18.53C19.0605 20.0148 19.5692 19.8041 19.9442 19.429C20.3193 19.0539 20.53 18.5452 20.53 18.0148V10.8148C20.53 9.99177 20.15 9.21477 19.5 8.70977Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.5 15C14.29 16.333 10.708 16.333 8.5 15"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1344_6654">
    <rect width="100%" height="100%" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
    </svg>

  )
}
