import React from 'react'
import { ChevronLeft } from '../assets'
import Button from './Button'
import { useCreateLesson, useUploadVideo } from '../pages/data/api'
import useQueryParams from '../utils/useQueryParams'

export default function UploadNav({
  module,
  type,
  handleBackToModule,
  handleModalToggle,
  topicNotes,
  uploadedVideo,
}) {
  const { mutate } = useCreateLesson()
  const { mutate: mutateVideo } = useUploadVideo()
  const { getParam } = useQueryParams()
  const topic_id = getParam('course_id')
  const handleSubmit = () => {
    if (type === 'Video') {
      mutateVideo({
        type: 'video', video_url: 'https://www.youtube.com/watch?v=v4NaKWkwGUY&list=PLvPg5tzuFZe9JXOF7jQ2YOzQIdVSL_RQp&index=18', topic_id,
      })
    }
    if (type === 'Notes') {
      mutate({ notes: topicNotes, type: 'text', topic_id })
    }
  }
  return (
    <div className='flex flex-col md:flex-row justify-between py-5'>
      <div className='relative px-2'>
        <ChevronLeft className='absolute h-5 w-5 my-3 m-2 stroke-light' />
        <Button className='w-full px-8 md:py-2 font-normal' name={'Back to Modules'} onClick={handleBackToModule} />
      </div>
      <div className='flex justify-evenly'>
        <button className='p-2 border-none text-alert hover:text-alert/55 underline bg-transparent rounded' onClick={() => handleModalToggle('delete', `Delete ${type}`, module)}>Delete {type}</button>
        <p className='p-2'>/</p>
        <button className='p-2 border-none text-primary-100  hover:text-primary-100/55 underline bg-transparent'
          onClick={handleSubmit}>Save {type}</button>
      </div>
    </div>
  )
}
