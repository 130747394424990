// import api from "./axios";

// export const deleteModule = async (moduleId) => {
//     try {
//         await api.delete(`/modules/${moduleId}`);
//     } catch (error) {
//         console.error('Error deleting module:', error);
//         throw new Error('Error deleting module');
//     }
// };


import api from "./axios";

export const deleteModule = async (moduleId) => {
    const response = await api.delete(`/modules/${moduleId}`);
    return response.data;
};