import React, { useMemo, useRef, useState } from 'react'
import Button from '../Button'
import Back from '../../assets/Back'
import JoditEditor from 'jodit-react'
import { useForm } from 'react-hook-form'
import useQueryParams from '../../utils/useQueryParams'
import { useCreateQuiz, useGetQuizzesById } from '../../pages/data/api'

export default function AddQuestion() {
    const [question, setQuestion] = useState()
    const [checked, setChecked] = useState(null)
    const { getParam } = useQueryParams()
    const quiz_id = getParam('quiz_id')
    const editor = useRef(null)
    const config = useMemo(() => ({
        readonly: false,
        width: '100%',
        showTooltip: true,
        uploader: {
            insertImageAsBase64URI: true
        }
    }), [])
    const { data: quizData, isLoading } = useGetQuizzesById(quiz_id)

    const { register, handleSubmit, reset } = useForm()
    const { mutate } = useCreateQuiz()

    const onSubmit = (data) => {
        const arrayOfChoices = Object.keys(data).map((key, index) => ({
            choice: data[key],
            is_correct: data[key] === data[checked],
            explanation: 'default',
            question_id: quiz_id,

        }))
        const formData = {
            question,
            quiz_id: parseInt(quiz_id),
            question_type: quizData?.data?.quiz_type,
            explanation: "string",
            answer: quizData?.data?.quiz_type === 'open_ended' ? data?.answer : data[checked],
            choices: arrayOfChoices
        }
        mutate(formData, {
            onSuccess: () => {
                reset()
                setChecked(null)
            }
        })
    }
    if (isLoading) return <p>Loading please wait...</p>
    return (
        <div className=' py-5 flex flex-col justify-between w-full'>
            <div className='pb-4'>
                <div className='p-5  rounded-lg bg-light'>
                    <JoditEditor
                        ref={editor}
                        value={question}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setQuestion(newContent)} // preferred to use only this option to update the content for performance reasons
                    />
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    quizData?.data?.quiz_type === 'open_ended' ?
                        <textarea
                            id="description"
                            placeholder='Type answer'
                            className="block w-full px-3 py-2 border border-gray-300 
                        rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500
                         focus:border-primary-500 sm:text-sm"
                            {...register('answer', { required: 'Answer is required' })}
                        />
                        : <>
                            <AngazaFormInput checked={checked} name={'first'} label={'A'} setChecked={setChecked} register={register} />
                            <AngazaFormInput checked={checked} name={'second'} label={'B'} setChecked={setChecked} register={register} />
                            <AngazaFormInput checked={checked} name={'third'} label={'C'} setChecked={setChecked} register={register} />
                            <AngazaFormInput checked={checked} name={'fourth'} label={'D'} setChecked={setChecked} register={register} />

                        </>
                }
                <div className='flex justify-center md:justify-end md:w-full'>
                    <div className='relative p-2 w-1/6 group'>
                        <Back className='absolute m-4  h-6 w-6 stroke-neutral-200 group-hover:stroke-white' />
                        <Button className='w-full bg-transparent border-2 border-neutral-200 text-neutral-200 hover:text-light hover:border-primary-100' name={'Back'} />
                    </div>
                    <div className='flex  p-2   '>
                        <Button type='submit' className='w-full p-4' name={'Save & Next'} />
                        <Back className='absolute my-4 ml-24 h-6 w-6 rotate-180 stroke-white' />
                    </div>
                </div>
            </form>
        </div>
    )
}


const AngazaFormInput = ({ checked, name, label, setChecked, register }) => {
    return (<div className='flex flex-row p-4 '>
        <label className='text-xl font-bold mr-5' htmlFor="">{label}.</label>
        <div className='w-full'>
            <textarea
                id="description"
                placeholder='Type answer'
                className="block w-full px-3 py-2 border border-gray-300 
                        rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500
                         focus:border-primary-500 sm:text-sm"
                {...register(name, { required: 'Description is required' })}
            />
            <div className="flex items-center">
                <input
                    type="checkbox"
                    className="h-5 w-5 border-neutral-600 cursor-pointer"
                    onChange={() => setChecked(name)}
                    checked={checked === name}
                />
                <label className='text-sm  mt-2 ml-2 text-primary-100 font-semibold' htmlFor="">Mark as correct answer</label>
            </div>
        </div>
    </div>)
}