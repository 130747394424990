export default function Export({ className }) {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M10.8333 9.16658L17.6666 2.33325"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 5.66675V1.66675H14.3333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  