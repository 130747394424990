import React from 'react'

export default function LoginPolygon({className}) {
  return (
    <svg width="647" height="679" viewBox="0 0 647 679" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M101.988 24.3798C114.256 8.00834 134.129 -0.839672 154.504 0.998301L469.277 29.3931C490.581 31.3148 509.175 44.6163 517.875 64.157L641.715 342.305C650.415 361.846 647.858 384.565 635.031 401.682L445.508 654.604C433.241 670.975 413.368 679.824 392.992 677.986L78.2197 649.591C56.9162 647.669 38.3213 634.368 29.6212 614.827L-94.2183 336.679C-102.918 317.138 -100.361 294.419 -87.5342 277.301L101.988 24.3798Z" fill="#38BCA7"/>
    <path d="M54.9913 36.0598C65.4805 18.4956 84.3197 7.61881 104.775 7.31693L420.792 2.65344C442.179 2.3378 462.063 13.6227 472.758 32.147L624.993 295.827C635.688 314.351 635.52 337.213 624.553 355.577L462.506 626.924C452.016 644.488 433.177 655.365 412.721 655.667L96.7051 660.33C75.3174 660.646 55.434 649.361 44.739 630.837L-107.496 367.157C-118.191 348.633 -118.023 325.771 -107.056 307.406L54.9913 36.0598Z" fill="#5B2A98"/>
    </svg>

  )
}
