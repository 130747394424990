import React from 'react'
import { ChevronLeft } from '../assets'

export default function ModuleListItem({
    module,
    isEditing,
    moduleTitle,
    handleModalToggle,
    handleContentUpload,
    handleViewContent
}) {
  return (
    <div className='flex justify-between my-5 p-5 bg-light shadow-sm'>
        <div className='text-black font-bold text-xl px-5'>
            {moduleTitle}
        </div>
        <div className='flex'>
            <div className='px-8'>
                <button className='text-success text-lg font-semibold mr-2' onClick={() => handleModalToggle('edit','Edit Module', module)}>Edit</button>
                /
                <button className='text-alert text-lg font-semibold ml-2' onClick={() => handleModalToggle('delete', 'Delete Module', module)}>Delete</button>
            </div>
            <ChevronLeft className='h-6 w-6 -rotate-90 mx-5 stroke-neutral-200 cursor-pointer' onClick={handleContentUpload}/>
        </div>
    </div>
  )
}
