import React from 'react'
import { avatar, Reply, Comment, Like } from '../../assets'


export default function ForumPost() {
  return (
    <div id='forum' className='flex flex-col justify-start py-4 px-8 rounded-lg shadow-lg w-full bg-light'>
        <div className='flex p-2'>
            <div className='pr-3'>
                <img src={avatar} alt="avatar" title="Profile" className="rounded-full" />
            </div>
            <div>
                <h3 className='text-black font-bold text-xl'>Kate Doe</h3>
                <p className='text-neutral-200'>Posted on: September 10, 2023</p>
            </div>
        </div>
        <div className='px-2 py-4'>
            <p>What is IoT?</p>
        </div>
        <div className='flex'>
            <div className='flex p-1'>
                <Reply className='h-6 w-6 mr-1 stroke-neutral-200' />
                <p className=' text-neutral-200'>Reply</p>
            </div>
            <div className='flex p-1'>
                <Comment className='h-6 w-6 mr-1 stroke-neutral-200' />
                <p className=' text-neutral-200'>Comment (30)</p>
            </div>
            <div className='flex p-1'>
                <Like className='h-6 w-6 mr-1 stroke-neutral-200' />
                <p className=' text-neutral-200'>Like (100)</p>
            </div>
        </div>
    </div>
  )
}
