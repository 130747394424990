import React from 'react'

export default function List({className}) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_1385_1289)">
    <path d="M3.33333 4.99967C3.33333 4.55765 3.50893 4.13372 3.82149 3.82116C4.13405 3.5086 4.55797 3.33301 5 3.33301H15C15.442 3.33301 15.8659 3.5086 16.1785 3.82116C16.4911 4.13372 16.6667 4.55765 16.6667 4.99967V6.66634C16.6667 7.10837 16.4911 7.53229 16.1785 7.84485C15.8659 8.15741 15.442 8.33301 15 8.33301H5C4.55797 8.33301 4.13405 8.15741 3.82149 7.84485C3.50893 7.53229 3.33333 7.10837 3.33333 6.66634V4.99967Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.33333 13.3337C3.33333 12.8916 3.50893 12.4677 3.82149 12.1551C4.13405 11.8426 4.55797 11.667 5 11.667H15C15.442 11.667 15.8659 11.8426 16.1785 12.1551C16.4911 12.4677 16.6667 12.8916 16.6667 13.3337V15.0003C16.6667 15.4424 16.4911 15.8663 16.1785 16.1788C15.8659 16.4914 15.442 16.667 15 16.667H5C4.55797 16.667 4.13405 16.4914 3.82149 16.1788C3.50893 15.8663 3.33333 15.4424 3.33333 15.0003V13.3337Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1385_1289">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}
