import React from 'react'

export default function Warning({className}) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M40 45.833C38.6333 45.833 37.5 44.6997 37.5 43.333V25.833C37.5 24.4663 38.6333 23.333 40 23.333C41.3667 23.333 42.5 24.4663 42.5 25.833V43.333C42.5 44.6997 41.3667 45.833 40 45.833Z" fill="#CC3333"/>
    <path d="M40 56.6001C39.3377 56.6001 38.7423 56.3612 38.2861 55.9135C38.0661 55.6654 37.8847 55.3855 37.7198 55.0604C37.6164 54.8003 37.5666 54.4991 37.5666 54.1667C37.5666 53.5494 37.8309 52.9046 38.2781 52.428C38.7001 52.0112 39.3289 51.775 40 51.775C40.671 51.775 41.2998 52.0112 41.7219 52.428C42.169 52.9046 42.4333 53.5494 42.4333 54.1667C42.4333 54.4635 42.3641 54.7793 42.2358 55.0873C42.1124 55.3834 41.9401 55.6582 41.7138 55.9135C41.2576 56.3612 40.6622 56.6001 40 56.6001Z" fill="#CC3333" stroke="#CC3333" strokeWidth="1.8"/>
    <path d="M40.0006 75.8337C37.7673 75.8337 35.5006 75.2669 33.5006 74.1003L13.7006 62.667C9.7006 60.3336 7.20056 56.0336 7.20056 51.4003V28.6004C7.20056 23.967 9.7006 19.667 13.7006 17.3337L33.5006 5.90039C37.5006 3.56706 42.4674 3.56706 46.5007 5.90039L66.3007 17.3337C70.3007 19.667 72.8007 23.967 72.8007 28.6004V51.4003C72.8007 56.0336 70.3007 60.3336 66.3007 62.667L46.5007 74.1003C44.5007 75.2669 42.234 75.8337 40.0006 75.8337ZM40.0006 9.16699C38.634 9.16699 37.2339 9.53368 36.0006 10.2337L16.2006 21.667C13.7339 23.1003 12.2006 25.7337 12.2006 28.6004V51.4003C12.2006 54.2336 13.7339 56.9003 16.2006 58.3337L36.0006 69.767C38.4673 71.2003 41.534 71.2003 43.9673 69.767L63.7673 58.3337C66.234 56.9003 67.7674 54.2669 67.7674 51.4003V28.6004C67.7674 25.767 66.234 23.1003 63.7673 21.667L43.9673 10.2337C42.7673 9.53368 41.3673 9.16699 40.0006 9.16699Z" fill="#CC3333"/>
    </svg>

  )
}
