import { Dashboard, Database, Upload, SmartLab, Modules, Appointments } from "../assets";

export default () => [
   {
    name: "Dashboard",
    url: "/dashboard",
    inner: false,
    component: Dashboard,
  },
  {
    name: "User Database",
    url: "/database",
    inner: false,
    component:Database,
  },
  {
    name: "Upload",
    url: "/upload",
    inner: false,
    component: Upload,
  },
  {
    name: "SmartLab",
    url: "/smartlab",
    inner: false,
    component: SmartLab,
   },  
  {
    name: "Modules",
    url: "/modules",
    inner: false,
    component: Modules,
   },  
  {
    name: "Appointments",
    url: "/notifications",
    inner: false,
    component: Appointments,
   },  
   
];
