import React from 'react'

export default function Copy({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6 2H15.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 5C7 3.34 8.34 2 10 2H12.62" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
