import React, { useState } from 'react';
import UserForm from './UserForm';
import UserTable from './UserTable';
import UserDatabaseNavbar from './UserDatabaseNavbar';
import TutorDatabase from './TutorDatabase';
import NewUser from './NewUser';
import CreateStudent from './CreateStudent';
import CreateTutor from './CreateTutor';
import SuccessPage from './SuccessPage';
import CreateModule from './CreateModule';
import Lessons from './LessonsComponents';
import Questions from './Questions';
import QuestionComponent from './QuestionComponent';




export default function UserDatabase() {
  const initialUsers = [
    { id: 1, name: 'John Doe', email: 'john@example.com', phoneNumber: '+1234567890' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', phoneNumber: '+0987654321' },
    { id: 3, name: 'Mike Johnson', email: 'mike@example.com', phoneNumber: '+1122334455' },
  ];

  const [users, setUsers] = useState(initialUsers);
  const [showForm, setShowForm] = useState(false);

  const addUser = (newUser) => {
    const nextId = users.length ? users[users.length - 1].id + 1 : 1;
    const userToAdd = { id: nextId, ...newUser };
    setUsers((prevUsers) => [...prevUsers, userToAdd]);
    setShowForm(false);
  };
  const handleModuleSubmit = (data) => {
   
    console.log(data);
  };

  const handleAddUserClick = () => {
    setShowForm(true);
  };

  return (
    <div className="container mx-auto mt-10">
      <UserDatabaseNavbar onAddUser={handleAddUserClick} />
      {/* <NewUser /> */}
      {/* <CreateStudent/> */}
      {/* <CreateTutor/> */}
      {/* <SuccessPage/> */}
      {showForm && <UserForm addUser={addUser} />}
      <UserTable users={users} />
      <CreateModule onSubmit={handleModuleSubmit} />
      {/* <Lessons/> */}
      {/* <Questions/> */}
      <QuestionComponent/>
    </div>
  );
}