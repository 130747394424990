import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createModule, createQuiz, updateModule } from '../api/mutations';
import { FaUserTag } from 'react-icons/fa';
import useQueryParams from '../utils/useQueryParams';

const CreateModuleComponent = ({ modalTitle, actionType = 'create', action = 'Create', handleModalToggle, selectedModule }) => {
    const { getParam } = useQueryParams()

    const topic_id = getParam('course_id')
    const queryClient = useQueryClient()
    const [formVisible, setFormVisible] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: actionType === 'edit' ? {
            name: selectedModule.name,
            description: selectedModule.description
        } : null
    });

    const mutation = useMutation({
        mutationFn: createModule,
        onSuccess: (data) => {
            toast.success('Module created successfully!');
            queryClient.invalidateQueries('modules')
            setFormVisible(false);
        },
    });

    const { mutate, isPending } = useMutation({
        mutationFn: updateModule,
        onSuccess: (data) => {
            toast.success('Module updated successfully!');
            queryClient.invalidateQueries('modules')
            setFormVisible(false);
        },
    });

    const { mutate: mutateQuiz, isPending: quizIsPending } = useMutation({
        mutationFn: createQuiz,
        onSuccess: (data) => {
            toast.success('Module updated successfully!');
            queryClient.invalidateQueries('modules')
            setFormVisible(false);
        },
    });

    const onFormSubmit = (data) => {
        if (actionType === 'edit') {
            mutate({ ...data, id: selectedModule.id })
        } else {
            mutation.mutate(data);
        }
    };

    const handleQuizCreation = (data) => {
        mutateQuiz({ ...data, topic_id })
    }

    if (modalTitle === 'Add New Quiz') {
        return (
            <div className="space-y-6 p-6 bg-white rounded-lg shadow-md">
                {formVisible ?
                    <form onSubmit={handleSubmit(handleQuizCreation)} className="space-y-6">
                        <div className="space-y-2">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Quiz Name</label>
                            <input
                                id="name"
                                type="text"
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                {...register('name', { required: 'Module name is required' })}
                            />
                            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
                        </div>
                        <div className="relative">
                            <label htmlFor="userType" className="block text-sm font-medium text-gray-700">Quiz Type</label>
                            <div className="relative">
                                <FaUserTag className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                                <select
                                    id="quiz_type"
                                    {...register('quiz_type', { required: 'Module name is required' })}
                                    className={`mt-1 block w-full border rounded-md shadow-sm pl-10 ${errors.quiz_type ? 'border-red-500' : 'border-gray-300'}`}
                                >
                                    <option value="open_ended">Open ended</option>
                                    <option value="multiple">Multiple</option>
                                </select>
                            </div>
                            {errors.quiz_type && <p className="text-red-500 text-xs">{errors.quiz_type}</p>}
                        </div>
                        <div className="flex space-x-4">
                            <button type="submit"
                                isPending={quizIsPending}
                                disabled={quizIsPending}
                                className="bg-primary-100 text-white hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium">
                                Create quiz
                            </button>
                            <button type="button" className="ring-1 ring-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium" onClick={handleModalToggle}>
                                Cancel
                            </button>
                        </div>
                    </form> :
                    <div className="text-center">
                        <p className="text-green-600 font-semibold"> Quiz created successfully!</p>
                        <button className="mt-4 bg-primary-100 text-white hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium" onClick={handleModalToggle}>
                            Close
                        </button>
                    </div>}
            </div>
        )

    }

    return (
        <div className="space-y-6 p-6 bg-white rounded-lg shadow-md">
            {formVisible ? (
                <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-6">
                    <div className="space-y-2">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Module Name</label>
                        <input
                            id="name"
                            type="text"
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            {...register('name', { required: 'Module name is required' })}
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
                    </div>

                    <div className="space-y-2">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            id="description"
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            {...register('description', { required: 'Description is required' })}
                        />
                        {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>}
                    </div>

                    <div className="flex space-x-4">
                        <button type="submit"
                            isPending={isPending}
                            disabled={isPending}
                            className="bg-primary-100 text-white hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium">
                            {actionType === 'edit' ? 'Edit' : ' Create'} module
                        </button>
                        <button type="button" className="ring-1 ring-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium" onClick={handleModalToggle}>
                            Cancel
                        </button>
                    </div>
                </form>
            ) : (
                <div className="text-center">
                    <p className="text-green-600 font-semibold">Module {actionType === 'edit' ? 'updated' : ' created'} successfully!</p>
                    <button className="mt-4 bg-primary-100 text-white hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium" onClick={handleModalToggle}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default CreateModuleComponent;



