import React from 'react'

export default function Card({className}) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_1385_1311)">
    <path d="M3.33337 4.99967C3.33337 4.55765 3.50897 4.13372 3.82153 3.82116C4.13409 3.5086 4.55801 3.33301 5.00004 3.33301H6.66671C7.10873 3.33301 7.53266 3.5086 7.84522 3.82116C8.15778 4.13372 8.33337 4.55765 8.33337 4.99967V14.9997C8.33337 15.4417 8.15778 15.8656 7.84522 16.1782C7.53266 16.4907 7.10873 16.6663 6.66671 16.6663H5.00004C4.55801 16.6663 4.13409 16.4907 3.82153 16.1782C3.50897 15.8656 3.33337 15.4417 3.33337 14.9997V4.99967Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6666 4.99967C11.6666 4.55765 11.8422 4.13372 12.1548 3.82116C12.4673 3.5086 12.8913 3.33301 13.3333 3.33301H15C15.442 3.33301 15.8659 3.5086 16.1785 3.82116C16.491 4.13372 16.6666 4.55765 16.6666 4.99967V9.99967C16.6666 10.4417 16.491 10.8656 16.1785 11.1782C15.8659 11.4907 15.442 11.6663 15 11.6663H13.3333C12.8913 11.6663 12.4673 11.4907 12.1548 11.1782C11.8422 10.8656 11.6666 10.4417 11.6666 9.99967V4.99967Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1385_1311">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}
