import React from 'react'

export default function Timer({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M17.2917 11.8522C17.2917 15.8772 14.025 19.1439 9.99999 19.1439C5.97499 19.1439 2.70833 15.8772 2.70833 11.8522C2.70833 7.82721 5.97499 4.56055 9.99999 4.56055C14.025 4.56055 17.2917 7.82721 17.2917 11.8522Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 7.47656V11.6432" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 2.47656H12.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
