import React from 'react'

export default function LoginPolygonResponsive({className}) {
  return (
    <svg width="740" height="464" viewBox="0 0 740 464" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M122.913 -282.296C135.18 -298.667 155.053 -307.515 175.428 -305.677L541.604 -272.646C562.907 -270.724 581.502 -257.422 590.202 -237.882L734.495 86.2055C743.195 105.746 740.638 128.465 727.811 145.583L507.339 439.807C495.071 456.178 475.198 465.026 454.823 463.188L88.6481 430.156C67.3446 428.235 48.7498 414.933 40.0497 395.393L-104.243 71.3053C-112.943 51.7646 -110.386 29.0455 -97.5592 11.928L122.913 -282.296Z" fill="#38BCA7"/>
    <path d="M69.2075 -269.302C79.6967 -286.866 98.536 -297.743 118.992 -298.045L486.614 -303.47C508.001 -303.785 527.885 -292.5 538.58 -273.976L715.958 33.253C726.653 51.7773 726.485 74.6392 715.518 93.0037L527.008 408.661C516.519 426.225 497.68 437.102 477.224 437.404L109.602 442.829C88.2145 443.145 68.3312 431.86 57.6362 413.336L-119.743 106.107C-130.438 87.5822 -130.269 64.7202 -119.302 46.3558L69.2075 -269.302Z" fill="#5B2A98"/>
    </svg>

  )
}
