import React from 'react'
import { SmartFarm, SmartHome, Timer } from '../assets'
import Button from '../components/Button'
import Layout from '../components/Layout'

export default function SmartLab() {
  
  return (
    <Layout>
        <div className='flex md:grid md:grid-cols-2 gap-32'>
            <div className='flex flex-col md:col-span-1 items-center justify-center rounded-2xl p-10 bg-light'>
                <div className='p-5'>
                    <SmartHome />
                </div>
                <div className='flex flex-col text-left p-5'>
                    <h2 className='text-black font-bold text-2xl py-2'>Smart Home</h2>
                    <p className='text-neutral-200'>In this exciting project, you'll dive into the world of IoT by prototyping your very own smart home. Using sensors, actuators, and your coding skills, you'll bring everyday objects to life.</p>
                </div>
                <div className='p-5 w-full'>
                    <Button className='w-full px-6 py-[10px]' name={'Continue to project'} />
                </div>
            </div>
            <div className='flex flex-col items-center justify-center rounded-2xl p-10 bg-light'>
                <div>
                    <SmartFarm />
                </div>
                <div className='flex flex-col md:col-span-1 text-left p-5'>
                    <h2 className='text-black font-bold text-2xl py-2'>Smart Farm</h2>
                    <p className='text-neutral-200'>Dive into the world of smart agriculture with this exciting IoT project. Think self-watering systems, temperature monitors, and even smart pest control – all designed and controlled by you! </p>
                </div>
                <div className='relative p-5 w-full'>
                    <Timer className='absolute h-5 w-5 stroke-white my-4 ml-44'/>
                    <Button type='SECONDARY' className='w-full px-8 py-[10px] text-white bg-neutral-200 cursor-not-allowed hover:bg-neutral-200 border-neutral-200 ring-0' name={'Coming Soon'} />
                </div>
            </div>
        </div>
    </Layout>
  )
}
