import React, { useEffect, useState } from 'react'
import Notification from './Notification';
import { toast } from "react-toastify";
import { ChevronLeft } from '../assets';
import TopicMarker from '../assets/TopicMarker';
import Plus from '../assets/Plus';
import Modal from './Modal';
import CreateCourse from './CreateCourse';
import { useGetLessonsByTopic, useGetQuizzesByTopic, useGetTopicsByModuleId } from '../pages/data/api';
import useQueryParams from '../utils/useQueryParams';

export default function ContentSidePanel({ setCreateTopic, setTopicNotes, setUploadQuiz, selectedModule, setModalType, setContent, isUploading = false, handleModalToggle }) {

  const [currentQuiz, setCurrentQuiz] = useState({ id: '' });
  const [currentTopic, setCurrentTopic] = useState({ id: '', title: '', notes: '' });

  const { getParam, setParam } = useQueryParams()
  const module_id = getParam('module')
  const selected_quiz_id = getParam('quiz_id')
  const quiz_id = parseInt(selected_quiz_id)
  const selectedCourse = getParam('course_id')
  const course_id = parseInt(selectedCourse)

  const handleTopicClick = async (id, title, notes, event) => {
    event?.stopPropagation();
    if (currentTopic.id === id && event !== null) return;

    setCurrentTopic({ id, title, notes });
    try {
      setContent({ id, title, notes });
    } catch (error) {
      console.log(error);
      toast(<Notification type="danger" message="An error occured." />);
    }
  };

  const { data, isLoading } = useGetTopicsByModuleId(module_id)
  const { data: lessons, isLoading: isLoadingLessons } = useGetLessonsByTopic(course_id)
  const { data: quizzes, isLoading: isLoadingQuizzes } = useGetQuizzesByTopic(course_id)


  const handleQuizClick = async (id, event) => {
    event?.stopPropagation();
    if (currentQuiz.id === id && event !== null) return;

    setCurrentQuiz({ id });
    try {
      setContent();
    } catch (error) {
      console.log(error);
      toast(<Notification type="danger" message="An error occured." />);
    }
  };


  if (isLoading) return <p>Loading...</p>

  return (
    <div className="col-span-full lg:col-span-3 mt-16 md:mt-0 hidden lg:block">
      <div className="bg-light rounded-md border border-neutral-300/20 max-h-[82vh] min-h-fit overflow-scroll">
        {/* concepts */}
        <button >
          <h3 className="p-2 pr-0 text-center text-black font-semibold  border-b border-neutral-300/30 flex-1">
            Courses
          </h3>
        </button>
        {data?.data?.map((c) => (
          <div
            className="flex flex-col select-none cursor-pointer"
            key={c.id}
            onClick={() => {
              if (course_id !== c.id) {
                setParam('course_id', c.id)
                // setParam('quiz_id')
              }
            }}
          >
            <div className="flex items-center gap-1 px-1 ">
              <h3 className="p-2 pr-0 text-primary-100 font-semibold  border-b border-neutral-300/30 flex-1">
                {c.name}
              </h3>
              {course_id === c.id ? (
                <ChevronLeft className="h-6 w-6 rotate-90 stroke-primary-100" />
              ) : (
                <ChevronLeft className="h-6 w-6 -rotate-90 stroke-primary-100" />
              )}
            </div>

            {c.id === course_id && lessons?.data?.map((topic) => (isLoadingLessons ? <p>Loading...</p> :
              <div key={topic.id} className="divide-y divide-neutral-300/30">
                <label
                  className={`flex items-center justify-between cursor-pointer group py-3 px-4 ${currentTopic.id === topic.id
                    && !quiz_id
                    ? " bg-secondary-500 text-black "
                    : " text-dark/80 "
                    }`}
                  onClick={(e) => {
                    handleTopicClick(topic.id, topic.title, topic.notes, e)
                    setTopicNotes(topic.notes)
                    setParam('quiz_id')
                  }}
                >
                  <MyComponent notes={topic.notes} />
                  {isUploading && <TopicMarker className={`h-6 w-6  ${currentTopic.id === topic.id
                    ? " stroke-black "
                    : " stroke-neutral-200 "
                    }`} />}
                </label>
              </div>
            ))}

            {c.id === course_id && quizzes?.data?.data?.map((quiz) => (isLoadingQuizzes ? <p>Loading...</p> :
              <div key={quiz.id} className="divide-y divide-neutral-300/30"
                onClick={() => setParam('quiz_id', quiz.id)}>
                <label
                  className={`flex items-center justify-between cursor-pointer group py-3 px-4 ${quiz_id === quiz.id
                    ? " bg-secondary-500 text-black "
                    : " text-dark/80 "
                    }`}
                >
                  <p>Quiz ({quiz?.name})</p>
                </label>
              </div>
            ))}

            {/* {c.id === course_id &&
              <div className="divide-y divide-neutral-300/30">
                <label
                  className={`flex items-center justify-between cursor-pointer group py-3 px-4 ${course_id == c.id && c.quiz.id === currentQuiz.id
                    ? " bg-secondary-500 text-black "
                    : " text-dark/80 "
                    }`}
                  onClick={(e) => handleQuizClick(c.quiz.id, e)}
                >
                  <p className="">{c.title} Quiz</p>
                  {isUploading && <TopicMarker className={`h-6 w-6 stroke-neutral-200`} />}
                </label>
                {isUploading &&
                  <div>
                    <label
                      className='flex items-center text-center justify-center cursor-pointer group py-3 px-4 bg-[#E9EBEC] text-primary-100 hover:bg-primary-100 hover:text-light'
                    >
                      <p className=' underline cursor-pointer m-1 pr-2 hover:font-semibold' onClick={() => handleModalToggle('create', 'topic', 'Add New Topic', c)}>Add Topic</p>
                      /
                      <p className=' underline cursor-pointer m-1 pl-2 hover:font-semibold' onClick={() => handleModalToggle('create', 'quiz', 'Add New Quiz', c)}>Add Quiz</p>
                    </label>
                  </div>
                }
              </div>
            } */}

            {c.id == course_id ? <div>
              <label
                onClick={() => handleModalToggle('create', 'quiz', 'Add New Quiz', c)}
                className='flex items-center text-center justify-center cursor-pointer group py-3 px-4 bg-[#E9EBEC] text-primary-100 hover:bg-primary-100 hover:text-light'
              >
                <p className=' underline cursor-pointer m-1 pr-2 hover:font-semibold' >Add Quiz</p>
              </label>
            </div> : null}
          </div>
        ))}
        {<div>
          <label
            onClick={() => setCreateTopic(true)}
            className='flex items-center text-center justify-center cursor-pointer group py-3 px-4 bg-[#E9EBEC] text-primary-100 hover:bg-primary-100 hover:text-light'
          >
            <p className=' underline cursor-pointer m-1 pr-2 hover:font-semibold' >Add Course</p>
          </label>
        </div>}
      </div>
    </div>
  )
}

const MyComponent = ({ notes }) => {
  const getFirstTwoWords = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    const firstTwoWords = textContent.split(" ").slice(0, 3).join(" ");

    return firstTwoWords;
  };

  return <p>{getFirstTwoWords(notes)}</p>;
};
