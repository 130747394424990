import React from 'react'

export default function Video({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15Z"  strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.52002 7.11011H21.48"  strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.52002 2.11011V6.97011"  strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.48 2.11011V6.52011"  strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.75 14.4501V13.2501C9.75 11.7101 10.84 11.0801 12.17 11.8501L13.21 12.4501L14.25 13.0501C15.58 13.8201 15.58 15.0801 14.25 15.8501L13.21 16.4501L12.17 17.0501C10.84 17.8201 9.75 17.1901 9.75 15.6501V14.4501V14.4501Z"  strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
