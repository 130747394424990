import AddModule from "../components/AddModule";
import Layout from "../components/Layout";
import React, { useEffect, useState } from "react";
import Module from "../components/Module";
import Modal from "../components/Modal";
import DeleteModule from "../components/DeleteModule";
import CreateModuleComponent from "../components/CreateModuleComponent";
import Content from "../components/Content";

//Test Data
import testData from "../data/test.json"
import Button from "../components/Button";
import { Card, List } from "../assets";
import Add from "../assets/Add";
import ModuleListItem from "../components/ModuleListItem";
import UploadNav from "../components/UploadNav";
import { useGetModules } from "./data/api";
import useQueryParams from "../utils/useQueryParams";

export default function Upload({
  type = 'module'
}) {
  const [actionType, setActionType] = useState('create');
  const [contentType, setContentType] = useState('Notes');
  const [uploadContent, setUploadContent] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [listToggle, setListToggle] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedModule, setSelectedModule] = useState(null)

  const { setParam } = useQueryParams()
  const { data, isLoading } = useGetModules()
  const [topicNotes, setTopicNotes] = useState('');
  const [uploadedVideo, setUploadedVideo] = useState()

  if (isLoading) return <div>Loading...</div>

  const handleModalToggle = (type = 'create', title = 'Create New Module', module = null) => {

    setActionType(type);
    setEnableModal(!enableModal)
    setModalTitle(title)
    if (handleModalToggle) {
      setSelectedModule(module)
    }
  }

  const handleDeleteModule = async () => {
    handleModalToggle('delete')
  }

  const handleCreateModule = (details) => {
    handleModalToggle('create')
  }

  const handleEditModule = (details) => {
    handleModalToggle('edit')
  }

  const handleContentUpload = (module) => {
    setSelectedModule(module);
    setUploadContent(true);
  }
  const handleBackToModule = () => {
    setUploadContent(false);
    setParam('module')
    setParam('course_id')
  }
  const handleVideo = () => {
    console.log('handleVideo handleVideo handleVideo handleVideo')
  }

  return (
    <Layout>
      {!uploadContent &&
        <div>
          <div className="flex flex-col md:flex-row justify-between pb-10">
            <h2 className="text-3xl">Content Upload</h2>
            <div className="flex justify-around">
              <div className="relative px-2 group">
                {listToggle ? <Card className='absolute my-3 m-2 stroke-primary-100 group-hover:stroke-light' /> : <List className='absolute my-3 m-2 stroke-primary-100 group-hover:stroke-light' />}
                <Button type="SECONDARY" className='w-full px-8 bg-transparent text-primary-100 font-normal md:py-2' name={listToggle ? 'View Modal Cards' : 'View Modal List'} onClick={() => setListToggle(!listToggle)} />
              </div>
              <div className="relative px-2">
                <Add className='absolute h-5 w-5 my-3 m-2 stroke-light' />
                <Button className='w-full px-8 md:py-2 font-normal' name={'New Module'} onClick={handleCreateModule} />
              </div>
            </div>
          </div>
          {listToggle ?
            <div>
              {data?.data?.map((module) => (
                <ModuleListItem
                  module={module}
                  key={module.id}
                  moduleTitle={module.name}
                  handleModalToggle={handleModalToggle}
                  handleContentUpload={() => handleContentUpload(module)}
                />
              ))}
            </div>
            :
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-10">
              {data?.data?.map((module) => (
                <Module
                  module={module}
                  key={module.id}
                  moduleTitle={module.name}
                  moduleDescription={module.description}
                  isEditing={true}
                  handleModalToggle={handleModalToggle}
                  handleContentUpload={() => handleContentUpload(module)}
                />
              ))}
            </div>
          }

        </div>
      }
      {uploadContent &&
        <div>
          <UploadNav handleModalToggle={handleModalToggle} type={contentType} handleBackToModule={handleBackToModule}
            topicNotes={topicNotes}
            uploadedVideo={uploadedVideo} />
          <Content setContentType={setContentType} isUploading={true} modules={data?.data} selectedModule={selectedModule}
            setTopicNotes={setTopicNotes}
            topicNotes={topicNotes}
            setUploadedVideo={setUploadedVideo}
          />
        </div>
      }
      <Modal
        isOpen={enableModal}
        title={modalTitle}
        onClose={handleModalToggle}
      >
        {
          actionType === 'delete' ? (
            <DeleteModule
              type={type}
              handleModalToggle={handleModalToggle}
              selectedModule={selectedModule}
              handleDeleteModule={handleDeleteModule}
            />
          ) :
            (
              <CreateModuleComponent
                handleCreateModule={handleCreateModule}
                actionType={actionType}
                type={type}
                handleModalToggle={handleModalToggle}
                selectedModule={selectedModule}
                editModule={handleEditModule}
              />
            )
        }
      </Modal>
    </Layout>
  )
}
