import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Export } from '../assets'

export default function GeneralNotification() {
  return (
    <div className='flex flex-col md:flex-row justify-between items-center my-8 min-h-[85px] bg-light rounded-3xl shadow'>
        <div className='h-[85px] w-full md:w-40 rounded-t-3xl md:rounded-tr-none md:rounded-tl-3xl md:rounded-bl-3xl bg-[#DBF0E3] z-10'>
        </div>
        <div className='p-5'>
            <h3 className='font-medium text-black'>New mention in IoT architecture</h3>
        </div>
        <div className='p-5'>
            <Link to='#forum' className ='flex text-primary-100 font-medium'>
                Go to Forum
                <Export className='h-5 w-5 ml-2 stroke-primary-100' />
            </Link>
        </div>
    </div>
  )
}
