import { useEffect } from "react";
import { useState } from "react";
import { HiddenEyes, VisibleEyes, Lock, Email } from "../assets";

export default function Input({
  label = "",
  name,
  errors,
  value,
  rules,
  type = "text",
  onChange,
  placeholder,
  register,
  labelBackground,
  ...others
}) {
  const [visible, setVisible] = useState(false);
  const [_type, setType] = useState("");
  placeholder = placeholder ? placeholder : label.toLowerCase();

  const makeVisible = () => {
    setVisible(true);
    setType("text");
  };
  const makeInvisible = () => {
    setVisible(false);
    setType("password");
  };

  useEffect(() => {
    setType(type);
  }, [type]);

  return (
    <div>
      <label
        htmlFor={label.toLowerCase().trim().split(" ").join("")}
        className={`scale-90 top-2.5 left-3 duration-300 origin-0 md:text-base text-neutral-100 font-medium md:px-1 select-none ${labelBackground ? labelBackground : "bg-[#FBFBFB]"
          }`}
      >
        {label}
      </label>
      <div className="border border-neutral-700 rounded-md focus-within:border-primary-100 relative outline-none my-1.5 md:my-0.5 focus-within:bg-light">
        <input
          className={`block w-full appearance-none focus:outline-none bg-transparent p-2 pl-8 md:p-3 md:pl-8 text-lg md:text-base focus:text-left focus:ring-0 border-none ${others.disabled ? "cursor-not-allowed " : ""
            }`}
          {...register(name, { ...rules })}
          onFocus={(e) =>
            _type === "password" &&
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          placeholder={placeholder}
          type={_type}
        // value={value}
        // {...others}
        />
        {type === "email" && (
          <Email className="absolute left-2 top-1/2 transform -translate-y-1/2 h-5 w-5 stroke-neutral-100" />
        )}
        {type === "password" && (
          <Lock className="absolute left-2 top-1/2 transform -translate-y-1/2 h-5 w-5 stroke-neutral-100" />
        )}
        {type === "password" && (
          <div className="absolute right-2 top-4 cursor-pointer">
            {visible ? (
              <HiddenEyes className="stroke-neutral-400" onClick={() => makeInvisible()} />
            ) : (
              <VisibleEyes className="stroke-neutral-400" onClick={() => makeVisible()} />
            )}
          </div>
        )}
      </div>
      {errors && <p className="text-red-500 text-sm mt-1">{errors?.message}</p>}
    </div>
  );
}
