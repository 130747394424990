import React, { useState } from 'react'
export default function DropdownButton() {
  // Define the range of years (e.g., from 2020 to 2030)
  const startYear = 2018;
  const endYear = 2023;
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  const [selectedYear, setSelectedYear] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setIsDropdownOpen(false); // Close the dropdown after selecting a year
    // Handle the selected year as needed (e.g., pass it to a parent component)
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 bg-transparent px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          onClick={toggleDropdown} // Toggle dropdown visibility on button click
        >
          {selectedYear ? 'Year '+ selectedYear : "Select Year"}
          {/* Down arrow icon */}
          <svg className="mt-1 ml-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M10 12l-8-8 1.5-1.5L10 9.014l6.5-6.514L18 4z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      {/* Dropdown panel */}
      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-28 items-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {years.map((year) => (
              <button
                key={year}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                onClick={() => handleYearSelect(year)}
              >
                Year {year}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};