import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getQuestionById } from '../api/getQuestionById';

const QuestionComponent = ({ questionId }) => {
    const { data, error, isLoading } = useQuery({
        queryKey: ['question', questionId], 
        queryFn: () => getQuestionById(questionId), 
        onSuccess: (data) => {
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });
    console.log(data)
    return (
        <div>
            <h1>Question Details</h1>
            {isLoading && <div>Loading...</div>}
            {error && <div>Error fetching question: {error.message}</div>}
            {data && (
                <div>
                    <p><strong>Question:</strong> {data.question}</p>
                    <p><strong>Answer:</strong> {data.answer}</p>
                </div>
            )}
        </div>
    );
};

export default QuestionComponent;
