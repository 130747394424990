import React from 'react';
import Edit from '../assets/Edit';
import Trash from '../assets/Trash';
import Button from './Button';
import useQueryParams from '../utils/useQueryParams';

export default function Module({
    module,
    isEditing,
    moduleTitle,
    moduleDescription,
    handleModalToggle,
    handleContentUpload,
    handleViewContent
}) {
    const { setParam } = useQueryParams()

    const viewContent = () => {
        setParam('module', module.id)
        handleContentUpload()
    }
    return (
        <div className='flex flex-col justify-between p-0 rounded-3xl w-full shadow bg-white min-h-[20px] cursor-pointer transition-transform transform hover:scale-105'>
            {isEditing &&
                <div className='flex justify-end items-center space-x-2'>
                    <Edit className='h-6 w-6 stroke-primary-100 cursor-pointer' onClick={() => handleModalToggle('edit', 'Edit Module', module)} />
                    <Trash className='h-6 w-6 stroke-error cursor-pointer' onClick={() => handleModalToggle('delete', 'Delete Module', module)} />
                </div>
            }
            <div className='p-5'>
                <h3 className='font-bold text-x sm:text-2xs pb-2'>{moduleTitle}</h3>
                <p className='text-2px sm:text-2xs lg:text-xs '>{moduleDescription}</p>
            </div>
            <div className='flex justify-center items-center px-5 pb-4'>
                <Button className='w-full' onClick={isEditing ?  viewContent : handleViewContent} name={isEditing ? 'Upload content' : 'View content'} />
            </div>
        </div>
    );
}
