import React from 'react'
import { CloudUpload } from '../../assets'

export default function UploadResources({handleFileUpload}) {
  return (
    <div className=' flex flex-col justify-center items-center w-full p-20 my-2 mx-10 gap-10 border-2 border-dashed border-secondary-300 rounded-lg bg-[#E9F7F4]'>
        <CloudUpload className='stroke-secondary-200' />
        <p className='text-neutral-200 font-medium'>.PDF .PPT .DOC .DOCX</p>
        <div className='flex flex-col justify-center items-center text-center'>
            <p className='pb-2 text-base'>Drag and drop to upload your file</p>
            <p className='text-neutral-200'>or</p>
            <label className='bg-primary-100 hover:bg-transparent text-light hover:text-primary-100 border-2 hover:border-primary-100 px-8 py-3 m-2 rounded-lg cursor-pointer'>
                <input className='hidden' type="file" id='uploadVideo' name='uploadVideo' onChange={handleFileUpload} />
                Browse Files
            </label>
           
        </div>
        
    </div>
  )
}
