export default function ChevronLeft({ className,onClick }) {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
      >
        <path d="M15 6L9 12L15 18" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
  