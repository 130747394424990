import React from 'react'
import { CloudUpload } from '../../assets'
import { useForm } from 'react-hook-form'

export default function UploadVideo({ setUploadedVideo }) {
  const { register, handleSubmit } = useForm({ mode: 'onChange' })



  return (
      <div className=' flex flex-col justify-center items-center p-20 my-2 gap-10 border-2 border-dashed border-secondary-300 rounded-lg bg-[#E9F7F4]'>
        <CloudUpload className='stroke-secondary-200' />
        <p className='text-neutral-200 font-medium'>.MP4 max 24MB</p>
        <div className='flex flex-col justify-center items-center text-center'>
          <p className='pb-2 text-base'>Drag and drop to upload your video</p>
          <p className='text-neutral-200'>or</p>
          <label className='bg-primary-100 hover:bg-transparent text-light hover:text-primary-100 border-2 hover:border-primary-100 px-8 py-3 m-2 rounded-lg cursor-pointer'>
            <input className='hidden' type="file" id='uploadVideo' name='uploadVideo'
            onChange={e =>  setUploadedVideo(e.target.files[0],'e.target.files[0] e.target.files[0]')}
              // accept=".mp4" 
              />
            Browse Files
          </label>
        </div>
        <button type='submit' className='bg-primary-100'>Submit</button>
      </div>
  )
}
