export default function Hamburger({ className }) {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g clipPath="url(#clip0_219_3973)">
          <path
            d="M6.66666 10H33.3333"
            stroke="#717A84"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66666 20H33.3333"
            stroke="#717A84"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66666 30H33.3333"
            stroke="#717A84"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_219_3973">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  