import React, { useState } from "react";
import Sidebar from "./Sidebar";



export default function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
      <div className="min-h-screen bg-neutral-900">
        <div className="flex min-h-screen">
          <div
            className={`bg-light transition-all ease-in-out duration-300 h-full w-full mx-auto lg:w-[280px] absolute lg:relative  ${!showSidebar ? "lg:-ml-[280px]" : "lg:ml-0"
              }`}
          >
            <Sidebar onHide={() => setShowSidebar(false)} />
          </div>
          <div className="mt-16 lg:mt-0 p-3 md:p-10 xl:px-32 w-full">{children}</div>
        </div>
      </div>
   
  );
}
