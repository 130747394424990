import React from 'react'
import { useForm } from 'react-hook-form'
import { useCreateCourse } from '../pages/data/api'
import useQueryParams from '../utils/useQueryParams'

function CreateCourse({ setCreateTopic }) {
  const{ handleSubmit, formState: {errors}, register} = useForm()
  const {getParam} = useQueryParams()
  const module_id = getParam('module')
  const { isPending,mutate } = useCreateCourse()

  const onFormSubmit =(data)=>{
    mutate({ ...data, module_id },{
      onSuccess: () => setCreateTopic(false)
    })
  }

  return (
    <div className="space-y-6 p-6 bg-white rounded-lg shadow-md">
        <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Course Name</label>
            <input
              id="name"
              type="text"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              {...register('name', { required: 'Module name is required' })}
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
          </div>


          <div className="flex space-x-4">
            <button type="submit"
              isPending={isPending}
              disabled={isPending}
              className="bg-primary-100 text-white hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium">
             Create Course
            </button>
          <button type="button" className="ring-1 ring-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-md px-4 py-2 text-sm font-medium" 
          onClick={() => setCreateTopic(false)}>
              Cancel
            </button>
          </div>
        </form>
    </div>
  )
}

export default CreateCourse