import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaUser, FaUserTag, FaUserAlt, FaChevronRight } from 'react-icons/fa';
import Button from './Button';

const NewUser = ({ onSubmit }) => {
    const history = useHistory();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        userType: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.username) newErrors.username = 'Username is required';
        if (!formData.userType) newErrors.userType = 'User type is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            await onSubmit(formData);
            toast.success('User created successfully!');
            setFormData({ firstName: '', lastName: '', username: '', userType: '' });


            if (formData.userType === 'student') {
                history.push('/student');
            }

        } catch (error) {
            toast.error('Error creating user.');
        }
    };

    return (
        <div className="p-4 max-w-lg mx-auto bg-white rounded-lg shadow-md">
            <div className="flex items-center justify-center mb-6">
                <h2 className="text-2xl font-bold text-center">Create New User</h2>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="grid gap-4 md:grid-cols-2">
                    <div className="relative">
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                        <div className="relative">
                            <FaUser className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                            <input
                                id="firstName"
                                name="firstName"
                                placeholder="Type first name"
                                type="text"
                                value={formData.firstName}
                                onChange={handleChange}
                                className={`mt-1 block w-full border rounded-md shadow-sm pl-10 ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`}
                            />
                        </div>
                        {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}
                    </div>
                    <div className="relative">
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                        <div className="relative">
                            <FaUser className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                placeholder="Type last name"
                                value={formData.lastName}
                                onChange={handleChange}
                                className={`mt-1 block w-full border rounded-md shadow-sm pl-10 ${errors.lastName ? 'border-red-500' : 'border-gray-300'}`}
                            />
                        </div>
                        {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}
                    </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2 mt-10">
                    <div className="relative">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                        <div className="relative">
                            <FaUserAlt className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                            <input
                                id="username"
                                name="username"
                                type="text"
                                placeholder="Type username"
                                value={formData.username}
                                onChange={handleChange}
                                className={`mt-1 block w-full border rounded-md shadow-sm pl-10 ${errors.username ? 'border-red-500' : 'border-gray-300'}`}
                            />
                        </div>
                        {errors.username && <p className="text-red-500 text-xs">{errors.username}</p>}
                    </div>
                    <div className="relative">
                        <label htmlFor="userType" className="block text-sm font-medium text-gray-700">User Type</label>
                        <div className="relative">
                            <FaUserTag className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                            <select
                                id="userType"
                                name="userType"
                                value={formData.userType}
                                onChange={handleChange}
                                className={`mt-1 block w-full border rounded-md shadow-sm pl-10 ${errors.userType ? 'border-red-500' : 'border-gray-300'}`}
                            >
                                <option value="">Select type</option>
                                <option value="student">Student</option>
                                <option value="tutor">Tutor</option>
                            </select>
                        </div>
                        {errors.userType && <p className="text-red-500 text-xs">{errors.userType}</p>}
                    </div>
                </div>
                <div className="flex justify-end items-center w-full relative mt-10">
                    <Button className="w-full md:w-auto px-8 py-2 font-normal" name="Continue" />
                    <FaChevronRight className="absolute ml-2 text-white h-5 w-5" />
                </div>
            </form>
        </div>
    );
};

export default NewUser;
