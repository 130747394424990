import api from "./axios";


export const createModule = async (moduleData) => {
    try {
        const response = await api.post('/modules', moduleData);
        return response.data;
    } catch (error) {

        console.error('Error creating module:', error);
        throw error;
    }
};


export const updateModule = async (moduleData) => {
    try {
        const response = await api.put(`/modules/${moduleData?.id}`, moduleData);
        return response.data;
    } catch (error) {
        console.error('Error creating module:', error);
        throw error;
    }
};

export const createQuiz = async (data) => {
    try {
        const response = await api.post(`/quizzes/`, data);
        return response.data;
    } catch (error) {
        console.error('Error creating module:', error);
        throw error;
    }
};
