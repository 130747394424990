import React from 'react'
import useQueryParams from '../utils/useQueryParams'

export default function ViewNav({ isUploading, notesActive, videoActive, forumActive, quizStatsActive,
  handleNotesClick, handleForumClick, handleVideoClick,
  resourcesActive, handleResourcesClick, handleQuizStatsClick }) {
  const { getParam } = useQueryParams()
  const quiz_id = getParam('quiz_id')
  if (quiz_id) {
    return (<div className='flex justify-start gap-8 w-full my-5 p-3 bg-light rounded-2xl'>
      <button className={`py-3 px-6 text-neutral-200 hover:text-primary-100 bg-transparent rounded-lg
       ${!notesActive ? 'bg-primary-500 text-primary-100 font-semibold' : ''}`} onClick={() => { }}>
        View Quizzes
      </button>
    </div>)
  }
  return (
    <div className='flex justify-start gap-8 w-full my-5 p-3 bg-light rounded-2xl'>
      <button className={`py-3 px-6 text-neutral-200 hover:text-primary-100 bg-transparent rounded-lg ${notesActive ? 'bg-primary-500 text-primary-100 font-semibold' : ''}`} onClick={handleNotesClick}>Learn</button>
      <button className={`py-3 px-6 text-neutral-200 hover:text-primary-100 bg-transparent rounded-lg ${videoActive ? 'bg-primary-500 text-primary-100 font-semibold' : ''}`} onClick={handleVideoClick}>Video</button>
      <button className={`py-3 px-6 text-neutral-200 hover:text-primary-100 bg-transparent rounded-lg ${resourcesActive ? 'bg-primary-500 text-primary-100 font-semibold' : ''}`} onClick={handleResourcesClick}>Resources</button>
      <button className={`py-3 px-6 text-neutral-200 hover:text-primary-100 bg-transparent rounded-lg ${forumActive ? 'bg-primary-500 text-primary-100 font-semibold' : ''}`} onClick={handleForumClick}>Forum</button>
      {!isUploading && <button className={`py-3 px-6 text-neutral-200 hover:text-primary-100 bg-transparent rounded-lg ${quizStatsActive ? 'bg-primary-500 text-primary-100 font-semibold' : ''}`} onClick={handleQuizStatsClick}>Quiz stats</button>}
    </div>
  )
}
