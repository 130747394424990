import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LogIn from './components/LogIn'
import Dashboard from './pages/dashboard';
import Upload from './pages/upload';
import Modules from './pages/modules';
import Notifications from './pages/notifications';
import SmartLab from './pages/smartlab';
import Database from './pages/database';

export default function App() {
  return (
    <div>
      <Router>
      <Switch>
        <Route path="/database" component={Database} />
        <Route path="/smartlab" component={SmartLab} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/modules" component={Modules} />
        <Route path="/upload" component={Upload} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/" component={LogIn} />
      </Switch>
    </Router>
    </div>
  )
}
