import { useLocation, useHistory } from 'react-router-dom';

function useQueryParams() {
  const location = useLocation();
  const history = useHistory();

  // Parse query params from location.search
  const getParam = (key) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
  };

  // Set query params
  const setParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);

    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    history.push({
      search: searchParams.toString(),
    });
  };

  return { getParam, setParam };
}

export default useQueryParams;
