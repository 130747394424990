import React, { useEffect, useMemo, useRef, useState } from 'react';
import NotesComponent from './content/NotesComponent';
import VideoComponent from './content/VideoComponent';
import ForumComponent from './content/ForumComponent';
import QuizStatsComponent from './content/QuizStatsComponent';
import Modal from './Modal';
import DeleteModule from './DeleteModule';
import CreateModuleComponent from './CreateModuleComponent';
import ContentSidePanel from './ContentSidePanel';
import ViewNav from './ViewNav';
import AddQuestion from './content/AddQuestion';
import UploadVideo from './content/UploadVideo';
import UploadResources from './content/UploadResources';
import Resource from './content/Resource';
import CreateCourse from './CreateCourse';
import useQueryParams from '../utils/useQueryParams';
import JoditEditor from 'jodit-react';


export default function Content({
    modules,
    selectedModule,
    isUploading = false,
    setContentType,
    topicNotes,
    setTopicNotes,
    setUploadedVideo
}) {
    const [actionType, setActionType] = useState('create');
    const [modalType, setModalType] = useState('topic');
    const [enableModal, setEnableModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [uploadQuiz, setUploadQuiz] = useState(false);
    const [topicContent, setTopicContent] = useState([]);
    const [notesActive, setNotesActive] = useState(true);
    const [videoActive, setVideoActive] = useState(false);
    const [resourcesActive, setResourcesActive] = useState(false);
    const [forumActive, setForumActive] = useState(false);
    const [quizStatsActive, setQuizStatsActive] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);


    const [createTopic, setCreateTopic] = useState(false)

    const { setParams, getParam } = useQueryParams()
    const isQuizView = getParam('quiz_id')

    const handleModalToggle = (type = 'create', modalType = 'topic', title = '', module = null) => {
        setActionType(type);
        setModalType(modalType);
        setEnableModal(!enableModal);
        setModalTitle(title)
    }

    const handleDeleteModule = async () => {
        handleModalToggle('delete')
    }

    const handleCreateModule = (details) => {
        handleModalToggle('create')
    }

    const handleNotesClick = () => {
        setNotesActive(true);
        setVideoActive(false);
        setForumActive(false);
        setResourcesActive(false);
        setQuizStatsActive(false);
        if (isUploading) {
            setContentType('Notes');
        }
    };

    const handleVideoClick = () => {
        setNotesActive(false);
        setVideoActive(true);
        setForumActive(false);
        setResourcesActive(false);
        setQuizStatsActive(false);
        if (isUploading) {
            setContentType('Video');
        }
    };

    const handleForumClick = () => {
        setNotesActive(false);
        setVideoActive(false);
        setForumActive(true);
        setResourcesActive(false);
        setQuizStatsActive(false);
        if (isUploading) {
            setContentType('Forum');
        }
    };

    const handleResourcesClick = () => {
        setNotesActive(false);
        setVideoActive(false);
        setForumActive(false);
        setResourcesActive(true);
        setQuizStatsActive(false);
        if (isUploading) {
            setContentType('Resource');
        }
    };

    const handleQuizStatsClick = () => {
        setNotesActive(false);
        setVideoActive(false);
        setForumActive(false);
        setResourcesActive(false);
        setQuizStatsActive(true);
        if (isUploading) {
            setContentType('Quiz Stats');
        }
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const newFiles = Array.from(files).map((file) => ({
            name: file.name,
            fileType: file.type,
        }));
        setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);;
    };

    const deleteFile = (index) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const editor = useRef(null)
    const config = useMemo(() => ({
        readonly: false,
        placeholder: 'start typing....',
        width: '100%',
        showTooltip: true,
        uploader: {
            insertImageAsBase64URI: true
        }
    }), [])

    return (
        <div className='flex justify-center'>
            <div className='md:w-3/4 px-2'>
                    <ViewNav
                        isUploading={isUploading}
                        notesActive={notesActive}
                        videoActive={videoActive}
                        forumActive={forumActive}
                        resourcesActive={resourcesActive}
                        quizStatsActive={quizStatsActive}
                        handleForumClick={handleForumClick}
                        handleResourcesClick={handleResourcesClick}
                        handleNotesClick={handleNotesClick}
                        handleVideoClick={handleVideoClick}
                        handleQuizStatsClick={handleQuizStatsClick}
                    />
    
                {isUploading ? (
                    isQuizView ? (
                        <AddQuestion />
                    ) : (
                        <div>
                            {notesActive &&
                                <div className='p-5 min-h-[500px] rounded-lg bg-light'>
                                    <JoditEditor
                                        ref={editor}
                                        value={topicNotes}
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newContent => setTopicNotes(newContent)} // preferred to use only this option to update the content for performance reasons
                                    // onChange={newContent => { }}
                                    />
                                </div>
                            }
                            {videoActive && <UploadVideo setUploadedVideo={setUploadedVideo} />}
                            {resourcesActive &&
                                <div className="flex flex-col justify-center items-center gap-10">
                                    {uploadedFiles.map((file, index) => (
                                        <Resource key={index} name={file.name} fileType={file.fileType} onDelete={() => deleteFile(index)} />
                                    ))}
                                    <UploadResources handleFileUpload={handleFileUpload} />
                                </div>
                            }

                        </div>
                    )
                ) : (
                    <div>
                        {notesActive && <NotesComponent title={topicContent.title} notes={topicContent.notes} />}
                        {videoActive && <VideoComponent />}
                        {forumActive && <ForumComponent />}
                        {quizStatsActive && <QuizStatsComponent />}
                    </div>
                )}

                <Modal
                    isOpen={enableModal}
                    title={modalTitle}
                    onClose={handleModalToggle}
                >
                    {
                        actionType === 'delete' ? (
                            <DeleteModule
                                type={modalType}
                                handleModalToggle={handleModalToggle}
                                selectedModule={selectedModule}
                                handleDeleteModule={handleDeleteModule}
                            ></DeleteModule>
                        ) :
                            (
                                <CreateModuleComponent
                                    handleCreateModule={handleCreateModule}
                                    actionType={actionType}
                                    type={modalType}
                                    handleModalToggle={handleModalToggle}
                                    modalTitle={modalTitle}
                                />

                            )
                    }
                </Modal>
                <Modal
                    isOpen={createTopic}
                    title={'Create course'}
                    onClose={() => setCreateTopic(false)}
                >
                    <CreateCourse setCreateTopic={setCreateTopic} />
                </Modal>

            </div>
            <div className='md:w-1/4 md:px-3'>
                <ContentSidePanel setCreateTopic={setCreateTopic}
                    modules={modules}
                    selectedModule={selectedModule}
                    isUploading={true}
                    setTopicNotes={setTopicNotes}
                    setContent={setTopicContent}
                    handleModalToggle={handleModalToggle}
                    setModalType={setModalType} />
            </div>
        </div>
    );
}