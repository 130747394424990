import React from 'react'

export default function SmartFarm({className}) {
  return (
    <svg width="300" height="263" viewBox="0 0 300 263" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
<rect width="300" height="260.81" transform="translate(0 1)" fill="white"/>
<path d="M284.217 53.7137C283.227 52.166 282.187 50.6376 281.098 49.1286C266.647 29.1918 241.767 15.5582 217.293 9.39434C206.555 6.73626 195.524 5.23862 184.418 4.93105C111.682 2.63847 82.7106 56.0063 58.6731 69.7444C34.6356 83.4825 -2.37494 107.029 0.119869 156.358C2.61468 205.686 40.7978 252.124 114.763 255.026C188.728 257.928 193.661 226.929 234.956 215.437C262.287 207.845 281.734 182.203 291.214 158.505C304.175 126.212 303.564 84.2661 284.217 53.7137Z" fill="#38BCA7"/>
<path opacity="0.7" d="M284.217 53.7137C283.227 52.166 282.187 50.6376 281.098 49.1286C266.647 29.1918 241.767 15.5582 217.293 9.39434C206.555 6.73626 195.524 5.23862 184.418 4.93105C111.682 2.63847 82.7106 56.0063 58.6731 69.7444C34.6356 83.4825 -2.37494 107.029 0.119869 156.358C2.61468 205.686 40.7978 252.124 114.763 255.026C188.728 257.928 193.661 226.929 234.956 215.437C262.287 207.845 281.734 182.203 291.214 158.505C304.175 126.212 303.564 84.2661 284.217 53.7137Z" fill="white"/>
<path d="M183.557 26.4984C181.1 28.2396 179.54 30.0736 178.168 31.0313C175.674 32.7725 171.264 29.8705 168.12 29.8705C164.977 29.8705 159.333 36.2955 159.333 36.2955H277.393C277.393 36.2955 272.372 31.6233 267.345 29.8705C262.318 28.1177 259.181 30.4509 255.414 31.6117C254.679 27.9837 253.567 24.4305 252.096 21.002" fill="#38BCA7"/>
<path d="M183.557 26.4984C181.1 28.2396 179.54 30.0736 178.168 31.0313C175.674 32.7725 171.264 29.8705 168.12 29.8705C164.977 29.8705 159.333 36.2955 159.333 36.2955H277.393C277.393 36.2955 272.372 31.6233 267.345 29.8705C262.318 28.1177 259.181 30.4509 255.414 31.6117C254.679 27.9837 253.567 24.4305 252.096 21.002" stroke="#263238"strokeMiterlimit="10"/>
<path d="M250.418 17.5136C247.692 12.3596 243.657 7.19988 237.832 4.74478C224.029 -1.11146 208.312 10.5778 203.921 20.5143C199.53 30.4507 200.148 21.0947 191.983 22.8359C190.414 23.1682 188.888 23.6566 187.43 24.2927" fill="#38BCA7"/>
<path d="M250.418 17.5136C247.692 12.3596 243.657 7.19988 237.832 4.74478C224.029 -1.11146 208.312 10.5778 203.921 20.5143C199.53 30.4507 200.148 21.0947 191.983 22.8359C190.414 23.1682 188.888 23.6566 187.43 24.2927" stroke="#263238"strokeMiterlimit="10"/>
<path d="M189.644 5.1344C188.568 3.68073 187.107 2.50943 185.403 1.73325C178.917 -1.00624 171.545 4.47855 169.48 9.14497C167.416 13.8114 167.709 9.41776 163.867 10.2419C160.025 11.0661 158.559 13.26 157.38 14.0842C156.202 14.9084 154.137 13.5038 152.659 13.5038C151.181 13.5038 148.53 16.5219 148.53 16.5219H203.965C203.965 16.5219 201.607 14.328 199.243 13.5038C196.879 12.6796 195.414 13.7766 193.63 14.328C193.124 11.8052 192.229 9.36356 190.973 7.07874" fill="#38BCA7"/>
<path d="M189.644 5.1344C188.568 3.68073 187.107 2.50943 185.403 1.73325C178.917 -1.00624 171.545 4.47855 169.48 9.14497C167.416 13.8114 167.709 9.41776 163.867 10.2419C160.025 11.0661 158.559 13.26 157.38 14.0842C156.202 14.9084 154.137 13.5038 152.659 13.5038C151.181 13.5038 148.53 16.5219 148.53 16.5219H203.965C203.965 16.5219 201.607 14.328 199.243 13.5038C196.879 12.6796 195.414 13.7766 193.63 14.328C193.124 11.8052 192.229 9.36356 190.973 7.07874" stroke="#263238"strokeMiterlimit="10"/>
<path d="M48.0452 34.5838H74.1035C74.1035 34.5838 71.2345 31.9197 68.3654 30.9156C65.4964 29.9115 63.7376 31.2523 61.5546 31.9139C61.5546 31.9139 59.4028 19.8996 51.5192 16.5681C43.6356 13.2366 34.6793 19.8996 32.1844 25.5759C29.6896 31.2523 30.0327 25.9068 25.3736 26.9051C20.7145 27.9034 18.8933 30.5848 17.4588 31.5831C16.0243 32.5814 13.517 30.9156 11.727 30.9156C9.93696 30.9156 6.73737 34.5838 6.73737 34.5838H43.2365" fill="#38BCA7"/>
<path d="M48.0452 34.5838H74.1035C74.1035 34.5838 71.2345 31.9197 68.3654 30.9156C65.4964 29.9115 63.7376 31.2523 61.5546 31.9139C61.5546 31.9139 59.4028 19.8996 51.5192 16.5681C43.6356 13.2366 34.6793 19.8996 32.1844 25.5759C29.6896 31.2523 30.0327 25.9068 25.3736 26.9051C20.7145 27.9034 18.8933 30.5848 17.4588 31.5831C16.0243 32.5814 13.517 30.9156 11.727 30.9156C9.93696 30.9156 6.73737 34.5838 6.73737 34.5838H43.2365" stroke="#263238"strokeMiterlimit="10"/>
<path d="M45.0265 160.351C45.0265 160.351 42.145 165.848 42.4319 168.39C42.7188 170.932 43.5857 181.525 43.5857 181.525C43.5857 181.525 41.4277 193.179 41.7146 202.158C42.0015 211.137 42.8622 235.571 42.8622 235.571H115.973C115.973 235.571 117.407 209.97 115.393 203.649C113.378 197.329 102.919 177.079 102.919 174.397C102.919 171.716 102.345 163.41 102.345 162.876C102.345 162.342 91.9726 166.626 83.0474 166.092C74.1222 165.558 49.2178 158.343 47.9205 158.21C46.6232 158.076 45.6502 159.022 45.0265 160.351Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M63.0764 77.3702C63.0764 77.3702 53.2656 82.1179 47.4839 86.1343C41.7021 90.1506 38.3279 90.0288 35.9765 101.352C33.6252 112.676 34.2988 121.295 36.2946 134.099C38.2905 146.902 41.3965 154.059 41.7894 157.228C42.1824 160.397 42.7001 160.878 47.1471 161.871C51.5941 162.863 65.9891 169.904 71.7459 170.635C77.5027 171.366 80.7709 171.854 86.9206 170.635C93.0703 169.416 101.047 166.984 104.971 162.846C108.894 158.708 108.37 155.301 108.37 155.301C108.511 153.349 108.424 151.39 108.108 149.456C107.584 146.293 103.923 129.008 103.923 126.571C103.944 125.434 103.901 124.296 103.792 123.164C103.792 123.164 117.919 125.485 122.759 125.602C127.598 125.718 129.301 124.87 129.825 124.383C130.349 123.895 132.831 112.937 133.093 108.677C133.355 104.417 133.879 103.807 132.962 102.832C132.045 101.857 120.014 97.3592 115.043 95.6528C110.072 93.9465 106.935 92.7508 105.756 92.0021C104.578 91.2534 99.7377 87.4982 96.8624 86.1575C95.4558 85.4804 94.0132 84.8702 92.5401 84.3292C92.5401 84.3292 81.6877 81.4272 77.6274 79.5815C73.5671 77.7359 69.3509 74.5669 63.0764 77.3702Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M48.5005 161.959C48.5005 161.959 60.881 163.567 61.3176 163.7L48.5005 161.959Z" fill="white"/>
<path d="M48.5005 161.959C48.5005 161.959 60.881 163.567 61.3176 163.7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.761 123.142L105.725 107.07" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.546 120.954L108.993 106.346" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.249 117.785L110.958 109.752" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.875 108.654L112.917 107.436" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M92.1285 117.471C92.1285 117.471 96.0204 120.419 96.4508 120.553C96.8811 120.686 96.5942 117.471 96.5942 117.471" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M84.9435 114.912C84.9435 114.912 94.299 127.24 96.6067 127.24C98.9144 127.24 99.9123 123.618 99.9123 123.618" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M85.2179 89.4609C85.2179 89.4609 92.4217 101.928 94.4362 106.078" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M141.307 105.857C141.307 105.857 149.547 109.264 155.827 111.58C162.108 113.896 186.046 125.696 187.742 126.671C189.034 127.359 190.393 127.931 191.802 128.377C191.802 128.377 193.106 128.743 197.297 130.327C201.489 131.912 202.006 133.003 202.137 133.856C202.28 134.991 202.236 136.14 202.006 137.263C201.744 138.969 200.565 139.695 199.511 141.036C198.457 142.376 197.678 142.62 197.017 142.498C196.355 142.376 193.48 141.523 193.48 141.523C193.48 141.523 190.362 141.036 187.985 140.426C185.609 139.817 182.235 138.238 180.532 135.435C179.579 133.829 178.391 132.354 177.002 131.053C177.002 131.053 165.881 128.377 156.201 126.792C146.522 125.208 134.996 123.507 134.996 123.507C134.996 123.507 141.077 109.63 141.307 105.857Z" fill="white" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.541 134.588C191.541 134.588 195.595 136.654 195.988 137.264C196.38 137.873 196.611 139.817 198.077 140.427C199.543 141.036 201.482 141.402 201.195 142.377C200.909 143.352 198.701 143.839 197.534 143.596C196.368 143.352 193.474 141.524 193.474 141.524C193.474 141.524 189.682 141.889 187.979 140.427C187 139.594 186.083 138.7 185.235 137.751" fill="white"/>
<path d="M191.541 134.588C191.541 134.588 195.595 136.654 195.988 137.264C196.38 137.873 196.611 139.817 198.077 140.427C199.543 141.036 201.482 141.402 201.195 142.377C200.909 143.352 198.701 143.839 197.534 143.596C196.368 143.352 193.474 141.524 193.474 141.524C193.474 141.524 189.682 141.889 187.979 140.427C187 139.594 186.083 138.7 185.235 137.751" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M198.601 136.896L195.988 137.262" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M132.931 102.81C132.931 102.81 133.586 101.231 134.765 102.229C135.511 102.831 136.353 103.322 137.26 103.686C137.26 103.686 140.79 103.199 141.314 105.88C141.838 108.562 137.777 120.489 135.95 123.292C134.122 126.095 133.854 126.821 132.675 126.699C132.347 126.674 132.031 126.571 131.757 126.4C131.483 126.23 131.26 125.997 131.11 125.724C131.11 125.724 129.669 126.304 129.145 125.968L128.621 125.608C128.621 125.608 130.717 116.96 131.503 112.334C132.289 107.708 132.931 102.81 132.931 102.81Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M58.4985 72.7451C58.4985 72.7451 58.1055 74.9332 60.856 75.2988C63.6066 75.6645 65.3031 74.0858 65.565 72.5013C65.827 70.9168 61.8976 67.7536 61.249 67.5099C60.6003 67.2661 58.4985 72.7451 58.4985 72.7451Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M101.278 50.1038C101.278 50.1038 104.154 60.0867 103.892 61.9092C103.63 63.7316 101.802 65.0724 101.671 66.4131C101.54 67.7538 102.713 74.086 102.713 75.299C102.713 76.5121 100.218 77.0054 99.8375 77.1273C99.457 77.2492 96.5693 83.8193 95.5215 85.4038C94.4736 86.9883 93.1639 89.1764 90.8125 89.4202C88.4611 89.664 84.6628 88.0853 83.8769 87.8415C83.0911 87.5977 84.4008 93.9241 84.4008 94.2898C84.4008 94.6554 91.4674 114.743 91.4674 116.566C91.4674 118.388 76.8353 101.353 69.7501 91.2485C62.6648 81.1437 61.5109 79.06 61.7729 78.4796C62.0348 77.8992 64.3862 73.0006 64.3862 71.7818C64.3862 70.563 59.9392 67.0341 59.9392 67.0341L57.7126 64.1321C57.7126 64.1321 77.459 50.4869 85.4487 48.2756C93.4383 46.0642 94.8666 46.0875 96.3073 46.3254C97.7481 46.5634 100.754 46.9348 101.278 50.1038Z" fill="white" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.1348 46.203C98.1348 46.203 92.5214 57.6485 85.3177 63.4931C78.1139 69.3378 76.9476 70.6727 74.9829 70.458C73.9473 70.3774 72.953 70.043 72.1014 69.4887C73.4882 69.4291 74.8378 69.0524 76.0307 68.3917C77.6269 67.389 79.116 66.2462 80.4777 64.979C79.0603 65.5066 77.5447 65.768 76.0183 65.7481C74.4918 65.7282 72.9847 65.4273 71.5837 64.8629C66.9995 62.9127 62.2906 58.8964 62.2906 58.8964C62.2906 58.8964 74.459 49.8885 84.1201 47.5727C93.7813 45.2569 98.1348 46.203 98.1348 46.203Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M92.7709 62.7614C92.7709 62.7614 98.6587 63.9803 99.3136 63.6146C99.9684 63.249 100.492 62.3958 98.1348 61.6645C95.7772 60.9332 93.9497 61.2988 92.7709 62.7614Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.2179 67.8985C97.2179 67.8985 94.8666 69.483 91.9913 67.2891" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M96.0454 80.899C94.8283 81.2547 93.5201 81.2337 92.317 80.8392C91.1138 80.4447 90.0804 79.698 89.3717 78.7109" fill="#38BCA7"/>
<path d="M96.0454 80.899C94.8283 81.2547 93.5201 81.2337 92.317 80.8392C91.1138 80.4447 90.0804 79.698 89.3717 78.7109" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.8375 77.1283C99.8375 77.1283 97.3427 77.2502 95.7834 76.397C95.3474 76.1926 94.9815 75.8789 94.7269 75.4912C94.4723 75.1036 94.3393 74.6574 94.3427 74.2031" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M83.8769 87.8421C83.8769 87.8421 77.9892 85.0388 76.2927 83.1989C75.4032 82.2759 74.5702 81.307 73.7979 80.2969C73.7979 80.2969 75.8935 84.9401 77.459 86.9947C79.0245 89.0493 84.3946 94.2961 84.3946 94.2961C84.3946 94.2961 83.615 88.6895 83.8769 87.8421Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M64.3613 71.7695C65.2904 72.3283 66.3735 72.6246 67.4798 72.6227C68.3213 72.5695 69.1562 72.4472 69.9746 72.2571C69.9746 72.2571 66.8561 74.4452 65.6586 74.5787C64.9068 74.634 64.1598 74.4271 63.5629 73.9983L64.3613 71.7695Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M52.2178 70.918C52.2178 70.918 48.8186 71.6493 47.2281 72.8681C45.6377 74.087 42.1263 80.2914 42.1263 80.2914C42.1263 80.2914 47.7396 80.6571 49.4548 78.7128C51.1699 76.7684 53.3779 71.0399 53.3779 71.0399L52.2178 70.918Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M58.4984 72.7453C58.4984 72.7453 69.3571 59.9765 78.906 54.8516C88.4549 49.7266 97.0869 47.8868 98.7896 47.7881C99.2372 47.7294 99.6927 47.8194 100.076 48.0423C100.459 48.2652 100.746 48.6068 100.885 49.0069L102.064 52.7796C103.153 51.8618 103.849 50.6084 104.023 49.2507C104.153 47.1787 104.153 45.8438 96.8312 40.4867C89.5089 35.1296 82.6981 33.4232 77.459 31.6007C72.2199 29.7783 66.0827 31.4788 59.671 33.1794C53.2593 34.88 49.5982 35.501 48.8123 36.8301C48.0265 38.1593 36.7749 62.8843 36.6501 65.0724C36.5254 67.2606 36.3882 68.3633 36.3882 68.3633C36.3882 68.3633 43.0556 66.9007 45.8061 66.9007C47.6106 66.9449 49.3884 67.3175 51.039 67.9977C51.039 67.9977 52.0805 71.6484 53.6523 72.1359L55.224 72.6235C55.224 72.6235 56.802 74.324 58.4984 72.7453Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M60.2074 48.6234C60.6752 47.5264 61.0432 46.6791 61.249 46.2031" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M55.2302 72.6233C55.2302 72.6233 54.7063 71.2826 54.7063 66.1693C54.7063 62.6869 57.2822 55.7221 59.2407 50.9512" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M51.1762 43.1621C51.1762 43.1621 50.7832 63.1279 51.4381 65.9254" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M36.3944 68.3634C36.3944 68.3634 36.9121 68.3634 38.0909 69.2166C39.2697 70.0698 41.2094 71.2828 41.2094 71.2828L41.989 67.1445L36.3944 68.3634Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M87.6254 176.498L92.3343 201.061C92.3343 208.42 101.359 214.41 112.492 214.41C123.626 214.41 132.657 208.443 132.657 201.061L135.775 177.322L87.6254 176.498Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.6649 192.895C100.969 193.875 103.442 194.464 105.968 194.636L98.6649 192.895Z" fill="#38BCA7"/>
<path d="M98.6649 192.895C100.969 193.875 103.442 194.464 105.968 194.636" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.6649 194.49C100.968 195.472 103.442 196.062 105.968 196.231L98.6649 194.49Z" fill="#38BCA7"/>
<path d="M98.6649 194.49C100.968 195.472 103.442 196.062 105.968 196.231" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.6649 196.092C100.969 197.072 103.442 197.662 105.968 197.833L98.6649 196.092Z" fill="#38BCA7"/>
<path d="M98.6649 196.092C100.969 197.072 103.442 197.662 105.968 197.833" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.802 194.09V198.756" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.804 194.357V199.024" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.956 194.09V198.756" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.9206 204.357C101.224 205.338 103.698 205.927 106.224 206.099" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.9206 205.953C101.224 206.935 103.697 207.525 106.224 207.694" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.9206 207.555C101.224 208.535 103.698 209.125 106.224 209.296" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.089 205.553V210.219" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.091 205.82V210.487" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.243 205.553V210.219" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.089 200.357C110.493 200.833 112.973 200.871 115.393 200.468L108.089 200.357Z" fill="#38BCA7"/>
<path d="M108.089 200.357C110.493 200.833 112.973 200.871 115.393 200.468" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.089 201.971C110.493 202.443 112.973 202.481 115.393 202.081L108.089 201.971Z" fill="#38BCA7"/>
<path d="M108.089 201.971C110.493 202.443 112.973 202.481 115.393 202.081" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.089 203.557C110.493 204.033 112.973 204.072 115.393 203.673L108.089 203.557Z" fill="#38BCA7"/>
<path d="M108.089 203.557C110.493 204.033 112.973 204.072 115.393 203.673" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M117.257 199.521V204.188" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.26 199.348V204.014" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.411 198.605V203.272" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.006 192.378C126.001 192.378 137.347 184.878 137.347 175.627C137.347 166.376 126.001 158.877 112.006 158.877C98.0104 158.877 86.6649 166.376 86.6649 175.627C86.6649 184.878 98.0104 192.378 112.006 192.378Z" fill="#B0B0B0" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.006 190.073C124.482 190.073 134.596 183.397 134.596 175.162C134.596 166.928 124.482 160.252 112.006 160.252C99.5295 160.252 89.4154 166.928 89.4154 175.162C89.4154 183.397 99.5295 190.073 112.006 190.073Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.892 188.733C109.795 188.414 109.803 188.076 109.914 187.761C110.024 187.446 110.233 187.169 110.513 186.965C110.793 186.761 111.132 186.639 111.487 186.614C111.842 186.589 112.197 186.663 112.506 186.827C112.816 186.99 113.067 187.235 113.226 187.531C113.386 187.827 113.448 188.16 113.403 188.489C113.359 188.817 113.211 189.126 112.978 189.376C112.744 189.627 112.437 189.807 112.093 189.894C111.636 190.01 111.148 189.953 110.735 189.735C110.323 189.518 110.02 189.158 109.892 188.733Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M101.721 188.448C101.625 188.129 101.633 187.791 101.743 187.476C101.854 187.161 102.063 186.884 102.343 186.68C102.623 186.476 102.962 186.354 103.317 186.329C103.672 186.304 104.026 186.378 104.336 186.541C104.646 186.705 104.896 186.95 105.056 187.246C105.215 187.542 105.277 187.875 105.233 188.204C105.188 188.532 105.04 188.841 104.807 189.091C104.574 189.341 104.266 189.521 103.923 189.609C103.465 189.724 102.977 189.668 102.565 189.45C102.153 189.233 101.849 188.872 101.721 188.448Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.956 182.819C108.86 182.501 108.868 182.163 108.979 181.848C109.09 181.534 109.298 181.257 109.578 181.053C109.858 180.849 110.196 180.727 110.551 180.702C110.905 180.677 111.26 180.751 111.569 180.913C111.879 181.076 112.13 181.321 112.29 181.616C112.45 181.912 112.512 182.245 112.469 182.573C112.425 182.901 112.278 183.21 112.046 183.461C111.814 183.711 111.507 183.892 111.164 183.98C110.706 184.098 110.216 184.042 109.802 183.824C109.388 183.607 109.084 183.245 108.956 182.819Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.112 187.588C115.016 187.27 115.023 186.931 115.134 186.617C115.245 186.302 115.453 186.025 115.734 185.821C116.014 185.616 116.353 185.494 116.708 185.469C117.062 185.445 117.417 185.519 117.727 185.682C118.036 185.845 118.287 186.09 118.447 186.386C118.606 186.682 118.668 187.016 118.624 187.344C118.579 187.673 118.431 187.982 118.198 188.232C117.965 188.482 117.657 188.662 117.314 188.749C116.856 188.865 116.368 188.808 115.956 188.591C115.543 188.373 115.24 188.013 115.112 187.588Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.808 185.895C110.712 185.576 110.72 185.238 110.831 184.923C110.941 184.608 111.15 184.331 111.43 184.127C111.71 183.923 112.049 183.801 112.404 183.776C112.759 183.751 113.114 183.825 113.423 183.989C113.733 184.152 113.983 184.397 114.143 184.693C114.303 184.989 114.364 185.322 114.32 185.651C114.276 185.98 114.128 186.288 113.894 186.539C113.661 186.789 113.353 186.969 113.01 187.056C112.553 187.172 112.065 187.115 111.652 186.897C111.24 186.68 110.937 186.32 110.808 185.895Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M105.837 188.304C105.742 187.985 105.75 187.647 105.86 187.333C105.971 187.018 106.18 186.742 106.459 186.537C106.739 186.333 107.078 186.211 107.432 186.186C107.787 186.162 108.141 186.235 108.451 186.398C108.76 186.561 109.011 186.805 109.171 187.101C109.331 187.396 109.394 187.729 109.35 188.057C109.307 188.386 109.16 188.695 108.927 188.945C108.695 189.196 108.388 189.376 108.045 189.464C107.587 189.581 107.098 189.524 106.685 189.306C106.272 189.089 105.967 188.729 105.837 188.304Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.312 185.227C106.216 184.909 106.224 184.571 106.334 184.257C106.445 183.942 106.654 183.665 106.933 183.461C107.213 183.257 107.552 183.135 107.906 183.11C108.261 183.085 108.615 183.159 108.925 183.322C109.234 183.484 109.485 183.729 109.645 184.024C109.805 184.32 109.868 184.653 109.824 184.981C109.781 185.31 109.634 185.619 109.401 185.869C109.169 186.12 108.862 186.3 108.519 186.388C108.293 186.447 108.055 186.464 107.821 186.438C107.588 186.411 107.362 186.342 107.156 186.234C106.951 186.127 106.771 185.982 106.626 185.809C106.481 185.637 106.374 185.439 106.312 185.227Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.68 185.366C118.583 185.047 118.591 184.709 118.702 184.394C118.812 184.079 119.021 183.802 119.301 183.598C119.581 183.394 119.92 183.271 120.275 183.247C120.63 183.222 120.985 183.296 121.294 183.459C121.604 183.623 121.855 183.868 122.014 184.164C122.174 184.46 122.235 184.793 122.191 185.122C122.147 185.45 121.999 185.759 121.766 186.009C121.532 186.259 121.225 186.439 120.881 186.526C120.424 186.644 119.935 186.588 119.522 186.37C119.109 186.153 118.806 185.791 118.68 185.366Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.451 185.227C114.355 184.909 114.363 184.571 114.474 184.257C114.584 183.942 114.793 183.665 115.073 183.461C115.353 183.257 115.691 183.135 116.046 183.11C116.4 183.085 116.755 183.159 117.064 183.322C117.374 183.484 117.624 183.729 117.785 184.024C117.945 184.32 118.007 184.653 117.963 184.981C117.92 185.31 117.773 185.619 117.541 185.869C117.308 186.12 117.001 186.3 116.659 186.388C116.201 186.506 115.711 186.45 115.297 186.232C114.883 186.015 114.579 185.653 114.451 185.227Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M105.226 186.811C105.13 186.493 105.138 186.155 105.249 185.841C105.36 185.526 105.568 185.249 105.848 185.045C106.128 184.841 106.467 184.719 106.821 184.694C107.176 184.669 107.53 184.743 107.84 184.906C108.149 185.068 108.4 185.313 108.56 185.608C108.72 185.904 108.782 186.237 108.739 186.565C108.695 186.894 108.548 187.203 108.316 187.453C108.084 187.703 107.777 187.884 107.434 187.972C106.976 188.09 106.486 188.034 106.073 187.816C105.659 187.599 105.354 187.237 105.226 186.811Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.0869 186.528C96.9907 186.209 96.9985 185.871 97.1091 185.556C97.2198 185.241 97.4284 184.964 97.7085 184.76C97.9887 184.556 98.3277 184.434 98.6826 184.409C99.0374 184.384 99.3922 184.458 99.7018 184.621C100.011 184.785 100.262 185.03 100.422 185.326C100.581 185.622 100.643 185.955 100.599 186.284C100.554 186.612 100.406 186.921 100.173 187.171C99.9398 187.421 99.632 187.602 99.2886 187.689C98.8312 187.806 98.3424 187.75 97.9296 187.532C97.5167 187.315 97.2137 186.953 97.0869 186.528Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.322 180.879C104.226 180.561 104.233 180.222 104.344 179.908C104.455 179.593 104.663 179.316 104.944 179.112C105.224 178.907 105.563 178.785 105.918 178.761C106.272 178.736 106.627 178.81 106.937 178.973C107.246 179.136 107.497 179.381 107.657 179.677C107.816 179.973 107.878 180.307 107.834 180.635C107.789 180.964 107.641 181.273 107.408 181.523C107.175 181.773 106.867 181.953 106.524 182.04C106.066 182.156 105.578 182.099 105.166 181.882C104.753 181.664 104.45 181.304 104.322 180.879Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.478 185.668C110.382 185.35 110.389 185.011 110.5 184.697C110.611 184.382 110.819 184.105 111.099 183.901C111.38 183.696 111.719 183.574 112.073 183.55C112.428 183.525 112.783 183.599 113.093 183.762C113.402 183.925 113.653 184.171 113.813 184.466C113.972 184.762 114.034 185.096 113.99 185.424C113.945 185.753 113.797 186.062 113.564 186.312C113.331 186.562 113.023 186.742 112.68 186.829C112.222 186.947 111.733 186.891 111.32 186.673C110.908 186.455 110.605 186.094 110.478 185.668Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.149 183.979C106.054 183.661 106.061 183.323 106.172 183.009C106.283 182.694 106.491 182.417 106.771 182.213C107.051 182.009 107.39 181.887 107.744 181.862C108.099 181.837 108.453 181.911 108.763 182.074C109.072 182.236 109.323 182.481 109.483 182.776C109.643 183.072 109.705 183.405 109.662 183.733C109.619 184.061 109.471 184.37 109.239 184.621C109.007 184.871 108.7 185.052 108.357 185.14C107.899 185.258 107.409 185.202 106.996 184.984C106.582 184.767 106.277 184.405 106.149 183.979Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M101.203 186.381C101.107 186.062 101.115 185.724 101.226 185.41C101.336 185.095 101.545 184.818 101.825 184.614C102.105 184.409 102.444 184.287 102.799 184.262C103.154 184.238 103.509 184.312 103.818 184.475C104.128 184.638 104.378 184.883 104.538 185.179C104.698 185.475 104.759 185.809 104.715 186.137C104.671 186.466 104.523 186.775 104.289 187.025C104.056 187.275 103.748 187.455 103.405 187.542C102.948 187.658 102.46 187.601 102.047 187.384C101.635 187.166 101.332 186.806 101.203 186.381Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M101.677 183.311C101.581 182.992 101.589 182.654 101.7 182.339C101.81 182.024 102.019 181.747 102.299 181.543C102.579 181.339 102.918 181.217 103.273 181.192C103.628 181.167 103.983 181.241 104.292 181.405C104.602 181.568 104.852 181.813 105.012 182.109C105.172 182.405 105.233 182.738 105.189 183.067C105.145 183.396 104.997 183.704 104.763 183.955C104.53 184.205 104.222 184.385 103.879 184.472C103.422 184.588 102.934 184.531 102.521 184.313C102.109 184.096 101.806 183.736 101.677 183.311Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.039 183.45C113.943 183.132 113.951 182.794 114.062 182.479C114.173 182.165 114.381 181.888 114.661 181.684C114.941 181.48 115.279 181.358 115.634 181.333C115.989 181.308 116.343 181.382 116.653 181.544C116.962 181.707 117.213 181.952 117.373 182.247C117.533 182.542 117.595 182.875 117.552 183.204C117.508 183.532 117.361 183.841 117.129 184.092C116.897 184.342 116.59 184.523 116.247 184.611C115.789 184.727 115.3 184.67 114.887 184.453C114.473 184.236 114.169 183.875 114.039 183.45Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.817 183.305C109.721 182.986 109.728 182.648 109.839 182.333C109.95 182.019 110.158 181.742 110.438 181.537C110.718 181.333 111.057 181.211 111.412 181.186C111.767 181.162 112.122 181.236 112.432 181.399C112.741 181.562 112.992 181.807 113.151 182.103C113.311 182.399 113.373 182.733 113.328 183.061C113.284 183.39 113.136 183.699 112.903 183.949C112.67 184.199 112.362 184.379 112.018 184.466C111.561 184.582 111.073 184.525 110.661 184.308C110.248 184.09 109.945 183.73 109.817 183.305Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.9456 175.883C98.8494 175.564 98.8571 175.226 98.9678 174.911C99.0784 174.597 99.2871 174.32 99.5672 174.115C99.8473 173.911 100.186 173.789 100.541 173.764C100.896 173.74 101.251 173.814 101.56 173.977C101.87 174.14 102.121 174.385 102.28 174.681C102.44 174.977 102.502 175.311 102.457 175.639C102.413 175.968 102.265 176.277 102.032 176.527C101.798 176.777 101.491 176.957 101.147 177.044C100.69 177.162 100.201 177.105 99.7882 176.888C99.3754 176.67 99.0723 176.309 98.9456 175.883Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M90.8 175.598C90.7038 175.279 90.7115 174.941 90.8222 174.626C90.9329 174.312 91.1415 174.035 91.4216 173.83C91.7018 173.626 92.0408 173.504 92.3956 173.479C92.7505 173.455 93.1052 173.529 93.4149 173.692C93.7245 173.855 93.975 174.1 94.1347 174.396C94.2944 174.692 94.356 175.025 94.3117 175.354C94.2674 175.683 94.1193 175.992 93.8861 176.242C93.6528 176.492 93.345 176.672 93.0017 176.759C92.5444 176.875 92.0563 176.818 91.6439 176.601C91.2315 176.383 90.9282 176.023 90.8 175.598Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.0412 169.95C97.9454 169.632 97.9533 169.294 98.064 168.979C98.1747 168.665 98.3832 168.388 98.663 168.184C98.9429 167.98 99.2815 167.858 99.636 167.833C99.9906 167.808 100.345 167.882 100.655 168.044C100.964 168.207 101.215 168.452 101.375 168.747C101.535 169.042 101.597 169.375 101.554 169.704C101.51 170.032 101.363 170.341 101.131 170.592C100.899 170.842 100.592 171.023 100.249 171.111C99.791 171.227 99.3021 171.17 98.8887 170.953C98.4753 170.736 98.1707 170.375 98.0412 169.95Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.191 174.739C104.095 174.42 104.102 174.082 104.213 173.767C104.324 173.452 104.532 173.175 104.813 172.971C105.093 172.767 105.432 172.645 105.787 172.62C106.141 172.595 106.496 172.669 106.806 172.832C107.115 172.996 107.366 173.241 107.526 173.537C107.685 173.833 107.747 174.166 107.703 174.495C107.658 174.823 107.51 175.132 107.277 175.382C107.044 175.632 106.736 175.812 106.393 175.9C105.935 176.016 105.447 175.959 105.035 175.741C104.622 175.524 104.319 175.163 104.191 174.739Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.9123 173.045C99.8161 172.727 99.8238 172.388 99.9345 172.074C100.045 171.759 100.254 171.482 100.534 171.278C100.814 171.073 101.153 170.951 101.508 170.927C101.863 170.902 102.218 170.976 102.527 171.139C102.837 171.302 103.087 171.547 103.247 171.843C103.407 172.139 103.468 172.473 103.424 172.801C103.38 173.13 103.232 173.439 102.998 173.689C102.765 173.939 102.457 174.119 102.114 174.206C101.657 174.324 101.168 174.268 100.755 174.05C100.342 173.832 100.039 173.471 99.9123 173.045Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.9227 175.454C94.8265 175.135 94.8342 174.797 94.9449 174.482C95.0555 174.167 95.2642 173.89 95.5443 173.686C95.8244 173.482 96.1634 173.359 96.5183 173.335C96.8732 173.31 97.2279 173.384 97.5375 173.547C97.8472 173.711 98.0977 173.956 98.2574 174.252C98.417 174.548 98.4786 174.881 98.4344 175.21C98.3901 175.538 98.242 175.847 98.0087 176.097C97.7755 176.347 97.4677 176.527 97.1244 176.614C96.667 176.732 96.1782 176.676 95.7653 176.458C95.3525 176.24 95.0494 175.879 94.9227 175.454Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M95.3905 172.372C95.2946 172.053 95.3026 171.716 95.4133 171.401C95.524 171.087 95.7325 170.81 96.0123 170.606C96.2921 170.402 96.6308 170.28 96.9853 170.255C97.3398 170.23 97.6943 170.303 98.0038 170.466C98.3134 170.629 98.5641 170.874 98.7242 171.169C98.8842 171.464 98.9465 171.797 98.9031 172.126C98.8597 172.454 98.7125 172.763 98.4803 173.014C98.248 173.264 97.9411 173.445 97.5984 173.533C97.1402 173.651 96.6505 173.594 96.2367 173.377C95.8229 173.159 95.5186 172.798 95.3905 172.372Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.759 172.522C107.662 172.203 107.67 171.865 107.781 171.55C107.891 171.235 108.1 170.958 108.38 170.754C108.66 170.55 108.999 170.428 109.354 170.403C109.709 170.378 110.064 170.452 110.373 170.616C110.683 170.779 110.933 171.024 111.093 171.32C111.253 171.616 111.314 171.949 111.27 172.278C111.226 172.606 111.078 172.915 110.845 173.166C110.611 173.416 110.304 173.596 109.96 173.683C109.503 173.799 109.015 173.742 108.602 173.524C108.19 173.307 107.887 172.947 107.759 172.522Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.53 172.372C103.434 172.053 103.442 171.716 103.553 171.401C103.663 171.087 103.872 170.81 104.152 170.606C104.431 170.402 104.77 170.28 105.125 170.255C105.479 170.23 105.834 170.303 106.143 170.466C106.453 170.629 106.703 170.874 106.863 171.169C107.024 171.464 107.086 171.797 107.042 172.126C106.999 172.454 106.852 172.763 106.62 173.014C106.387 173.264 106.08 173.445 105.738 173.533C105.28 173.651 104.79 173.594 104.376 173.377C103.962 173.159 103.658 172.798 103.53 172.372Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.3281 172.243C98.2319 171.924 98.2396 171.586 98.3503 171.271C98.461 170.956 98.6696 170.679 98.9497 170.475C99.2298 170.271 99.5689 170.148 99.9237 170.124C100.279 170.099 100.633 170.173 100.943 170.336C101.253 170.5 101.503 170.745 101.663 171.041C101.822 171.337 101.884 171.67 101.84 171.999C101.796 172.327 101.647 172.636 101.414 172.886C101.181 173.136 100.873 173.316 100.53 173.403C100.072 173.519 99.5844 173.462 99.172 173.245C98.7596 173.028 98.4563 172.667 98.3281 172.243Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M90.1826 171.959C90.0863 171.641 90.0941 171.302 90.2047 170.988C90.3154 170.673 90.524 170.396 90.8042 170.192C91.0843 169.987 91.4233 169.865 91.7782 169.841C92.133 169.816 92.4878 169.89 92.7974 170.053C93.107 170.216 93.3576 170.462 93.5172 170.757C93.6769 171.053 93.7385 171.387 93.6942 171.715C93.65 172.044 93.5018 172.353 93.2686 172.603C93.0354 172.853 92.7276 173.033 92.3842 173.12C91.9269 173.236 91.4389 173.179 91.0265 172.962C90.614 172.744 90.3107 172.384 90.1826 171.959Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.4175 166.306C97.3217 165.987 97.3296 165.649 97.4403 165.335C97.551 165.02 97.7595 164.744 98.0393 164.539C98.3192 164.335 98.6578 164.213 99.0123 164.188C99.3669 164.163 99.7213 164.237 100.031 164.4C100.34 164.563 100.591 164.807 100.751 165.103C100.911 165.398 100.974 165.731 100.93 166.059C100.887 166.388 100.74 166.697 100.507 166.947C100.275 167.198 99.9681 167.378 99.6254 167.466C99.1672 167.584 98.6776 167.528 98.2637 167.31C97.8499 167.093 97.5456 166.732 97.4175 166.306Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.573 171.1C103.477 170.781 103.485 170.443 103.596 170.128C103.706 169.814 103.915 169.537 104.195 169.332C104.475 169.128 104.814 169.006 105.169 168.981C105.524 168.957 105.879 169.031 106.188 169.194C106.498 169.357 106.748 169.602 106.908 169.898C107.068 170.194 107.129 170.527 107.085 170.856C107.041 171.185 106.893 171.494 106.66 171.744C106.426 171.994 106.118 172.174 105.775 172.261C105.318 172.377 104.83 172.32 104.417 172.102C104.005 171.885 103.702 171.525 103.573 171.1Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.2886 169.399C99.1924 169.08 99.2001 168.742 99.3108 168.427C99.4215 168.112 99.6301 167.835 99.9102 167.631C100.19 167.427 100.529 167.305 100.884 167.28C101.239 167.255 101.594 167.329 101.903 167.493C102.213 167.656 102.464 167.901 102.623 168.197C102.783 168.493 102.845 168.826 102.8 169.155C102.756 169.483 102.608 169.792 102.375 170.042C102.141 170.293 101.834 170.473 101.49 170.56C101.033 170.677 100.544 170.621 100.131 170.403C99.7184 170.186 99.4153 169.824 99.2886 169.399Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.299 171.79C94.2032 171.471 94.2111 171.133 94.3218 170.819C94.4325 170.505 94.641 170.228 94.9208 170.024C95.2007 169.82 95.5393 169.698 95.8938 169.673C96.2484 169.648 96.6028 169.721 96.9124 169.884C97.2219 170.047 97.4726 170.291 97.6327 170.587C97.7928 170.882 97.855 171.215 97.8116 171.544C97.7682 171.872 97.6211 172.181 97.3888 172.432C97.1565 172.682 96.8496 172.863 96.5069 172.951C96.0488 173.067 95.5599 173.01 95.1465 172.793C94.7331 172.575 94.4285 172.215 94.299 171.79Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.773 168.737C94.6772 168.419 94.6852 168.081 94.7958 167.766C94.9065 167.452 95.115 167.175 95.3949 166.971C95.6747 166.767 96.0133 166.645 96.3679 166.62C96.7224 166.595 97.0769 166.669 97.3864 166.831C97.6959 166.994 97.9466 167.239 98.1067 167.534C98.2668 167.83 98.3291 168.163 98.2856 168.491C98.2422 168.819 98.0951 169.128 97.8628 169.379C97.6305 169.629 97.3236 169.81 96.9809 169.898C96.754 169.957 96.5169 169.974 96.283 169.948C96.0491 169.921 95.8231 169.852 95.618 169.744C95.4129 169.636 95.2326 169.492 95.0876 169.319C94.9426 169.146 94.8357 168.949 94.773 168.737Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.141 168.889C107.045 168.57 107.053 168.232 107.163 167.917C107.274 167.603 107.483 167.326 107.763 167.121C108.043 166.917 108.382 166.795 108.737 166.77C109.092 166.746 109.446 166.82 109.756 166.983C110.066 167.146 110.316 167.391 110.476 167.687C110.635 167.983 110.697 168.317 110.653 168.645C110.608 168.974 110.46 169.283 110.227 169.533C109.994 169.783 109.686 169.963 109.343 170.05C108.885 170.167 108.397 170.111 107.984 169.894C107.571 169.676 107.268 169.315 107.141 168.889Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.912 168.737C102.817 168.419 102.824 168.081 102.935 167.766C103.046 167.452 103.254 167.175 103.534 166.971C103.814 166.767 104.153 166.645 104.507 166.62C104.862 166.595 105.216 166.669 105.526 166.831C105.835 166.994 106.086 167.239 106.246 167.534C106.406 167.83 106.468 168.163 106.425 168.491C106.382 168.819 106.234 169.128 106.002 169.379C105.77 169.629 105.463 169.81 105.12 169.898C104.662 170.016 104.172 169.96 103.759 169.742C103.345 169.525 103.04 169.163 102.912 168.737Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M113.309 180.12C113.213 179.801 113.221 179.463 113.332 179.148C113.442 178.833 113.651 178.556 113.931 178.352C114.211 178.148 114.55 178.025 114.905 178.001C115.26 177.976 115.615 178.05 115.924 178.213C116.234 178.377 116.484 178.622 116.644 178.918C116.804 179.214 116.865 179.547 116.821 179.876C116.777 180.204 116.629 180.513 116.395 180.763C116.162 181.013 115.854 181.193 115.511 181.28C115.054 181.398 114.565 181.342 114.152 181.124C113.739 180.906 113.436 180.545 113.309 180.12Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M105.164 179.834C105.068 179.516 105.075 179.177 105.186 178.863C105.297 178.548 105.505 178.271 105.786 178.067C106.066 177.862 106.405 177.74 106.76 177.716C107.114 177.691 107.469 177.765 107.779 177.928C108.088 178.091 108.339 178.337 108.499 178.632C108.658 178.928 108.72 179.262 108.676 179.59C108.631 179.919 108.483 180.228 108.25 180.478C108.017 180.728 107.709 180.908 107.366 180.995C106.908 181.111 106.42 181.054 106.008 180.837C105.595 180.619 105.292 180.259 105.164 179.834Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.386 174.188C112.291 173.87 112.299 173.532 112.409 173.218C112.52 172.903 112.728 172.626 113.008 172.422C113.288 172.218 113.627 172.096 113.981 172.071C114.336 172.046 114.69 172.12 115 172.283C115.309 172.445 115.56 172.69 115.72 172.985C115.88 173.281 115.942 173.614 115.899 173.942C115.856 174.27 115.708 174.579 115.476 174.83C115.244 175.08 114.937 175.261 114.594 175.349C114.367 175.408 114.13 175.425 113.896 175.399C113.662 175.372 113.436 175.303 113.231 175.195C113.026 175.088 112.846 174.943 112.701 174.77C112.556 174.597 112.449 174.4 112.386 174.188Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.555 178.977C118.459 178.658 118.466 178.32 118.577 178.005C118.688 177.691 118.896 177.413 119.176 177.209C119.457 177.005 119.796 176.883 120.15 176.858C120.505 176.833 120.86 176.907 121.17 177.071C121.479 177.234 121.73 177.479 121.889 177.775C122.049 178.071 122.111 178.404 122.066 178.733C122.022 179.062 121.874 179.371 121.641 179.621C121.408 179.871 121.1 180.051 120.756 180.138C120.299 180.254 119.811 180.197 119.399 179.979C118.986 179.762 118.683 179.402 118.555 178.977Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.257 177.282C114.161 176.963 114.169 176.625 114.28 176.31C114.39 175.995 114.599 175.718 114.879 175.514C115.159 175.31 115.498 175.188 115.853 175.163C116.208 175.138 116.563 175.212 116.872 175.375C117.182 175.539 117.432 175.784 117.592 176.08C117.752 176.376 117.813 176.709 117.769 177.038C117.725 177.366 117.577 177.675 117.344 177.925C117.11 178.175 116.803 178.355 116.459 178.442C116.002 178.56 115.513 178.504 115.1 178.286C114.687 178.069 114.384 177.707 114.257 177.282Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.268 179.69C109.172 179.372 109.18 179.034 109.291 178.719C109.401 178.405 109.61 178.128 109.89 177.924C110.17 177.72 110.508 177.598 110.863 177.573C111.217 177.548 111.572 177.622 111.881 177.785C112.191 177.947 112.441 178.192 112.602 178.487C112.762 178.783 112.824 179.116 112.78 179.444C112.737 179.772 112.59 180.081 112.358 180.332C112.125 180.582 111.818 180.763 111.476 180.851C111.018 180.969 110.528 180.913 110.114 180.695C109.7 180.478 109.396 180.116 109.268 179.69Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.754 176.614C109.659 176.296 109.666 175.958 109.777 175.643C109.888 175.329 110.096 175.052 110.376 174.848C110.656 174.644 110.995 174.522 111.349 174.497C111.704 174.472 112.058 174.546 112.368 174.708C112.677 174.871 112.928 175.116 113.088 175.411C113.248 175.707 113.31 176.04 113.267 176.368C113.224 176.696 113.076 177.005 112.844 177.256C112.612 177.506 112.305 177.687 111.962 177.775C111.504 177.893 111.014 177.837 110.601 177.619C110.187 177.402 109.882 177.04 109.754 176.614Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.122 176.752C122.026 176.434 122.034 176.095 122.145 175.781C122.255 175.466 122.464 175.189 122.744 174.985C123.024 174.78 123.363 174.658 123.718 174.634C124.073 174.609 124.428 174.683 124.737 174.846C125.047 175.009 125.297 175.255 125.457 175.55C125.617 175.846 125.678 176.18 125.634 176.508C125.59 176.837 125.442 177.146 125.208 177.396C124.975 177.646 124.667 177.826 124.324 177.913C123.867 178.031 123.378 177.975 122.965 177.757C122.552 177.539 122.249 177.178 122.122 176.752Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M117.894 176.614C117.798 176.296 117.806 175.958 117.916 175.643C118.027 175.329 118.236 175.052 118.516 174.848C118.795 174.644 119.134 174.522 119.488 174.497C119.843 174.472 120.198 174.546 120.507 174.708C120.817 174.871 121.067 175.116 121.227 175.411C121.387 175.707 121.45 176.04 121.406 176.368C121.363 176.696 121.216 177.005 120.983 177.256C120.751 177.506 120.444 177.687 120.102 177.775C119.643 177.893 119.154 177.837 118.74 177.619C118.326 177.402 118.022 177.04 117.894 176.614Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M95.0973 190.945C95.0973 190.945 84.4944 184.363 84.5817 185.547C84.669 186.731 92.8395 195.704 93.2325 195.797" fill="#B0B0B0"/>
<path d="M95.0973 190.945C95.0973 190.945 84.4944 184.363 84.5817 185.547C84.669 186.731 92.8395 195.704 93.2325 195.797" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M44.0722 161.129L86.5464 188.025L87.0391 186.289L46.4298 161.309L44.0722 161.129Z" fill="#B0B0B0" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.235 161.267C110.325 161.588 110.311 161.925 110.194 162.238C110.078 162.551 109.864 162.825 109.581 163.024C109.297 163.224 108.956 163.341 108.601 163.36C108.246 163.379 107.892 163.3 107.586 163.132C107.279 162.964 107.033 162.715 106.879 162.417C106.724 162.119 106.669 161.784 106.718 161.457C106.768 161.129 106.922 160.822 107.159 160.576C107.396 160.329 107.707 160.154 108.052 160.072C108.511 159.962 108.999 160.027 109.408 160.251C109.817 160.475 110.114 160.84 110.235 161.267Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.374 161.657C118.463 161.977 118.448 162.315 118.33 162.627C118.213 162.939 117.999 163.212 117.714 163.411C117.43 163.61 117.089 163.726 116.734 163.744C116.379 163.762 116.026 163.682 115.72 163.514C115.414 163.345 115.169 163.096 115.015 162.798C114.861 162.5 114.806 162.166 114.857 161.838C114.907 161.511 115.061 161.205 115.298 160.958C115.536 160.712 115.846 160.537 116.191 160.455C116.419 160.401 116.657 160.388 116.89 160.419C117.123 160.449 117.348 160.523 117.551 160.634C117.753 160.746 117.93 160.894 118.072 161.069C118.213 161.245 118.316 161.444 118.374 161.657Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.064 167.218C111.153 167.538 111.137 167.875 111.02 168.187C110.903 168.499 110.688 168.771 110.405 168.97C110.121 169.169 109.78 169.285 109.425 169.303C109.071 169.322 108.718 169.242 108.412 169.074C108.106 168.906 107.861 168.658 107.706 168.36C107.552 168.062 107.496 167.729 107.546 167.401C107.595 167.074 107.748 166.768 107.985 166.521C108.221 166.275 108.531 166.099 108.875 166.016C109.103 165.961 109.341 165.949 109.575 165.979C109.809 166.01 110.034 166.083 110.237 166.195C110.44 166.306 110.618 166.454 110.76 166.63C110.902 166.805 111.005 167.005 111.064 167.218Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.971 162.354C105.06 162.674 105.044 163.012 104.927 163.324C104.81 163.637 104.595 163.909 104.311 164.108C104.027 164.307 103.686 164.423 103.331 164.441C102.976 164.46 102.623 164.38 102.317 164.211C102.011 164.043 101.766 163.794 101.612 163.495C101.458 163.197 101.403 162.863 101.453 162.536C101.504 162.208 101.657 161.902 101.895 161.656C102.132 161.409 102.443 161.234 102.788 161.153C103.016 161.098 103.253 161.085 103.487 161.116C103.72 161.147 103.944 161.22 104.147 161.332C104.35 161.443 104.527 161.591 104.668 161.766C104.81 161.942 104.912 162.142 104.971 162.354Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.268 164.1C109.356 164.42 109.341 164.757 109.224 165.069C109.106 165.381 108.892 165.654 108.608 165.852C108.324 166.051 107.984 166.167 107.629 166.186C107.274 166.204 106.922 166.124 106.616 165.956C106.31 165.789 106.064 165.54 105.91 165.242C105.756 164.944 105.7 164.611 105.75 164.283C105.799 163.956 105.952 163.65 106.188 163.403C106.425 163.157 106.735 162.981 107.079 162.898C107.307 162.842 107.545 162.829 107.779 162.86C108.013 162.89 108.239 162.963 108.442 163.075C108.646 163.186 108.823 163.334 108.965 163.51C109.107 163.686 109.21 163.887 109.268 164.1Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.257 161.75C114.346 162.07 114.331 162.407 114.213 162.719C114.096 163.031 113.882 163.303 113.598 163.502C113.314 163.701 112.973 163.817 112.619 163.835C112.264 163.854 111.911 163.774 111.606 163.606C111.3 163.438 111.054 163.19 110.9 162.892C110.746 162.594 110.69 162.261 110.739 161.933C110.789 161.606 110.941 161.3 111.178 161.053C111.415 160.807 111.724 160.631 112.068 160.548C112.53 160.439 113.019 160.504 113.429 160.729C113.839 160.954 114.137 161.321 114.257 161.75Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M113.74 164.826C113.828 165.146 113.813 165.484 113.696 165.796C113.578 166.108 113.364 166.38 113.08 166.579C112.796 166.778 112.456 166.894 112.101 166.912C111.746 166.931 111.394 166.851 111.088 166.683C110.782 166.515 110.536 166.267 110.382 165.969C110.228 165.671 110.172 165.337 110.222 165.01C110.271 164.683 110.424 164.376 110.66 164.13C110.897 163.883 111.207 163.708 111.551 163.625C111.779 163.569 112.017 163.556 112.251 163.586C112.485 163.616 112.711 163.69 112.914 163.801C113.118 163.913 113.295 164.061 113.437 164.237C113.579 164.413 113.682 164.613 113.74 164.826Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M101.378 164.53C101.467 164.85 101.451 165.187 101.334 165.499C101.216 165.811 101.002 166.084 100.718 166.283C100.435 166.481 100.094 166.597 99.7392 166.616C99.3846 166.634 99.032 166.555 98.726 166.387C98.4201 166.219 98.1745 165.97 98.0203 165.673C97.8661 165.375 97.8103 165.041 97.8598 164.714C97.9093 164.387 98.062 164.08 98.2986 163.834C98.5351 163.587 98.8449 163.411 99.1888 163.329C99.4173 163.274 99.6553 163.261 99.889 163.292C100.123 163.322 100.348 163.396 100.551 163.507C100.754 163.619 100.932 163.766 101.074 163.942C101.216 164.118 101.319 164.317 101.378 164.53Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M105.601 164.722C105.69 165.042 105.676 165.379 105.56 165.692C105.443 166.004 105.23 166.278 104.946 166.477C104.663 166.677 104.322 166.794 103.968 166.813C103.613 166.832 103.26 166.753 102.953 166.586C102.647 166.418 102.401 166.17 102.246 165.873C102.091 165.575 102.034 165.241 102.083 164.914C102.132 164.586 102.285 164.279 102.521 164.032C102.758 163.785 103.067 163.609 103.411 163.526C103.872 163.415 104.361 163.478 104.772 163.703C105.182 163.927 105.48 164.293 105.601 164.722Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.592 180.636C115.681 180.956 115.666 181.294 115.549 181.606C115.431 181.919 115.217 182.191 114.933 182.39C114.649 182.589 114.307 182.705 113.952 182.723C113.598 182.742 113.245 182.662 112.939 182.493C112.633 182.325 112.387 182.076 112.234 181.777C112.08 181.479 112.025 181.145 112.075 180.817C112.125 180.49 112.279 180.184 112.517 179.938C112.754 179.691 113.065 179.516 113.409 179.435C113.87 179.325 114.358 179.39 114.768 179.616C115.177 179.841 115.473 180.208 115.592 180.636Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.732 181.019C123.821 181.339 123.807 181.676 123.691 181.989C123.574 182.301 123.361 182.575 123.077 182.774C122.794 182.974 122.453 183.09 122.099 183.11C121.744 183.129 121.391 183.05 121.084 182.883C120.778 182.715 120.532 182.467 120.377 182.169C120.222 181.872 120.165 181.538 120.214 181.21C120.263 180.883 120.416 180.576 120.652 180.329C120.889 180.082 121.198 179.906 121.542 179.823C122.003 179.712 122.492 179.775 122.903 179.999C123.313 180.224 123.611 180.59 123.732 181.019Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M116.415 186.58C116.506 186.9 116.492 187.238 116.375 187.551C116.259 187.864 116.045 188.137 115.761 188.337C115.478 188.536 115.137 188.653 114.782 188.672C114.427 188.691 114.073 188.612 113.767 188.444C113.46 188.276 113.214 188.027 113.06 187.729C112.905 187.431 112.85 187.097 112.899 186.769C112.949 186.441 113.103 186.135 113.34 185.888C113.577 185.642 113.888 185.466 114.233 185.384C114.692 185.275 115.18 185.339 115.589 185.563C115.998 185.787 116.295 186.153 116.415 186.58Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.328 181.717C110.418 182.037 110.404 182.375 110.288 182.687C110.171 183 109.958 183.274 109.674 183.473C109.39 183.673 109.049 183.79 108.694 183.809C108.339 183.828 107.986 183.749 107.679 183.581C107.373 183.413 107.127 183.164 106.972 182.866C106.818 182.568 106.762 182.234 106.812 181.906C106.862 181.578 107.015 181.272 107.253 181.025C107.49 180.778 107.801 180.603 108.145 180.521C108.605 180.411 109.093 180.476 109.502 180.7C109.911 180.924 110.208 181.289 110.328 181.717Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.613 183.462C114.703 183.782 114.689 184.12 114.572 184.432C114.456 184.745 114.242 185.018 113.959 185.217C113.675 185.417 113.335 185.534 112.98 185.553C112.625 185.572 112.272 185.493 111.966 185.326C111.659 185.159 111.413 184.91 111.258 184.613C111.103 184.315 111.047 183.981 111.096 183.654C111.145 183.326 111.297 183.02 111.534 182.773C111.77 182.526 112.08 182.35 112.424 182.266C112.884 182.155 113.374 182.219 113.784 182.443C114.195 182.667 114.493 183.034 114.613 183.462Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.609 181.118C119.698 181.438 119.683 181.776 119.565 182.088C119.448 182.4 119.234 182.673 118.949 182.872C118.665 183.071 118.324 183.187 117.969 183.205C117.614 183.223 117.261 183.143 116.955 182.975C116.649 182.806 116.404 182.557 116.25 182.259C116.096 181.961 116.041 181.627 116.092 181.299C116.142 180.972 116.296 180.665 116.533 180.419C116.771 180.173 117.081 179.998 117.426 179.916C117.654 179.861 117.892 179.849 118.125 179.88C118.358 179.91 118.583 179.984 118.785 180.095C118.988 180.207 119.165 180.355 119.307 180.53C119.448 180.706 119.551 180.905 119.609 181.118Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.097 184.183C119.187 184.503 119.173 184.84 119.057 185.153C118.94 185.465 118.727 185.739 118.443 185.938C118.16 186.138 117.819 186.255 117.464 186.274C117.11 186.293 116.757 186.214 116.45 186.047C116.144 185.879 115.897 185.631 115.743 185.333C115.588 185.036 115.531 184.702 115.58 184.375C115.629 184.047 115.782 183.74 116.018 183.493C116.254 183.246 116.564 183.07 116.908 182.987C117.369 182.876 117.858 182.939 118.269 183.164C118.679 183.388 118.977 183.754 119.097 184.183Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.736 183.899C106.824 184.219 106.809 184.556 106.691 184.868C106.574 185.18 106.36 185.452 106.076 185.651C105.792 185.85 105.451 185.966 105.097 185.984C104.742 186.003 104.39 185.923 104.084 185.755C103.778 185.587 103.532 185.339 103.378 185.041C103.224 184.743 103.168 184.41 103.217 184.082C103.267 183.755 103.42 183.449 103.656 183.202C103.893 182.956 104.203 182.78 104.546 182.697C104.775 182.641 105.013 182.628 105.247 182.658C105.481 182.689 105.706 182.762 105.91 182.874C106.113 182.985 106.291 183.133 106.433 183.309C106.575 183.485 106.678 183.685 106.736 183.899Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.958 184.09C111.047 184.41 111.031 184.747 110.914 185.059C110.797 185.371 110.582 185.644 110.299 185.843C110.015 186.041 109.674 186.157 109.319 186.176C108.965 186.194 108.612 186.115 108.306 185.947C108 185.779 107.755 185.53 107.6 185.232C107.446 184.935 107.39 184.601 107.44 184.274C107.489 183.946 107.642 183.64 107.879 183.394C108.115 183.147 108.425 182.971 108.769 182.888C108.997 182.833 109.236 182.82 109.47 182.85C109.704 182.88 109.929 182.953 110.132 183.065C110.336 183.177 110.514 183.325 110.655 183.501C110.797 183.677 110.9 183.877 110.958 184.09Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.32 174.112C123.408 174.432 123.393 174.77 123.276 175.081C123.158 175.393 122.944 175.666 122.66 175.865C122.376 176.063 122.036 176.179 121.681 176.198C121.326 176.216 120.974 176.137 120.668 175.969C120.362 175.801 120.116 175.552 119.962 175.255C119.808 174.957 119.752 174.623 119.802 174.296C119.851 173.969 120.004 173.662 120.24 173.416C120.477 173.169 120.787 172.993 121.131 172.911C121.359 172.856 121.597 172.843 121.831 172.874C122.065 172.904 122.29 172.978 122.493 173.089C122.696 173.201 122.874 173.349 123.016 173.524C123.158 173.7 123.261 173.899 123.32 174.112Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M131.459 174.501C131.548 174.821 131.532 175.158 131.415 175.47C131.298 175.782 131.083 176.055 130.8 176.253C130.516 176.452 130.175 176.568 129.82 176.587C129.466 176.605 129.113 176.525 128.807 176.357C128.501 176.19 128.256 175.941 128.101 175.643C127.947 175.345 127.891 175.012 127.941 174.685C127.991 174.357 128.143 174.051 128.38 173.804C128.616 173.558 128.926 173.382 129.27 173.299C129.499 173.244 129.736 173.232 129.97 173.262C130.204 173.293 130.429 173.366 130.632 173.478C130.835 173.589 131.013 173.737 131.155 173.913C131.297 174.088 131.4 174.288 131.459 174.501Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M124.143 180.062C124.232 180.382 124.217 180.72 124.1 181.032C123.982 181.344 123.768 181.617 123.484 181.816C123.2 182.015 122.858 182.131 122.503 182.149C122.148 182.167 121.796 182.087 121.49 181.919C121.184 181.75 120.938 181.501 120.784 181.203C120.631 180.905 120.576 180.571 120.626 180.243C120.676 179.916 120.83 179.61 121.068 179.363C121.305 179.117 121.616 178.942 121.96 178.86C122.421 178.751 122.909 178.816 123.318 179.041C123.728 179.267 124.024 179.634 124.143 180.062Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.056 175.199C118.145 175.519 118.13 175.857 118.012 176.169C117.895 176.481 117.68 176.754 117.396 176.953C117.112 177.152 116.771 177.268 116.416 177.286C116.061 177.304 115.708 177.224 115.402 177.056C115.096 176.887 114.851 176.638 114.697 176.34C114.543 176.042 114.488 175.707 114.539 175.38C114.589 175.052 114.743 174.746 114.98 174.5C115.218 174.254 115.528 174.079 115.873 173.997C116.334 173.888 116.822 173.953 117.231 174.178C117.64 174.403 117.937 174.77 118.056 175.199Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.366 176.945C122.455 177.265 122.441 177.602 122.325 177.915C122.208 178.227 121.995 178.5 121.711 178.7C121.428 178.899 121.087 179.016 120.733 179.036C120.378 179.055 120.025 178.976 119.718 178.808C119.412 178.641 119.166 178.393 119.011 178.095C118.856 177.798 118.8 177.464 118.849 177.136C118.898 176.809 119.05 176.502 119.286 176.255C119.523 176.008 119.832 175.832 120.176 175.749C120.637 175.638 121.126 175.701 121.537 175.925C121.947 176.149 122.245 176.516 122.366 176.945Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.355 174.594C127.445 174.914 127.431 175.252 127.315 175.564C127.199 175.877 126.985 176.151 126.701 176.35C126.418 176.55 126.077 176.667 125.721 176.686C125.366 176.705 125.013 176.626 124.707 176.458C124.4 176.29 124.154 176.041 123.999 175.743C123.845 175.445 123.789 175.111 123.839 174.783C123.889 174.455 124.042 174.149 124.28 173.902C124.517 173.655 124.828 173.48 125.172 173.398C125.632 173.288 126.12 173.353 126.529 173.577C126.938 173.801 127.235 174.166 127.355 174.594Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M126.825 177.665C126.914 177.985 126.898 178.322 126.781 178.634C126.664 178.946 126.449 179.219 126.166 179.417C125.882 179.616 125.541 179.732 125.186 179.751C124.832 179.769 124.479 179.689 124.173 179.522C123.867 179.354 123.622 179.105 123.467 178.807C123.313 178.51 123.257 178.176 123.307 177.849C123.356 177.521 123.509 177.215 123.746 176.968C123.982 176.722 124.292 176.546 124.636 176.463C124.864 176.408 125.102 176.396 125.336 176.426C125.57 176.457 125.795 176.53 125.998 176.642C126.201 176.753 126.379 176.901 126.521 177.077C126.663 177.252 126.766 177.452 126.825 177.665Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.463 177.375C114.553 177.695 114.539 178.032 114.422 178.345C114.306 178.657 114.093 178.931 113.809 179.13C113.526 179.33 113.185 179.447 112.83 179.466C112.476 179.485 112.123 179.406 111.816 179.239C111.51 179.071 111.263 178.823 111.109 178.526C110.954 178.228 110.897 177.894 110.946 177.567C110.995 177.239 111.148 176.932 111.384 176.685C111.62 176.439 111.93 176.262 112.274 176.179C112.735 176.07 113.223 176.134 113.633 176.358C114.043 176.582 114.342 176.947 114.463 177.375Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.686 177.595C118.776 177.915 118.761 178.252 118.645 178.565C118.528 178.877 118.315 179.151 118.032 179.35C117.748 179.55 117.408 179.667 117.053 179.686C116.698 179.705 116.345 179.626 116.039 179.459C115.732 179.291 115.486 179.043 115.331 178.746C115.176 178.448 115.12 178.114 115.169 177.787C115.218 177.459 115.37 177.152 115.606 176.905C115.843 176.659 116.153 176.482 116.497 176.399C116.957 176.288 117.447 176.351 117.857 176.576C118.267 176.8 118.566 177.166 118.686 177.595Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M100.461 180.497C100.27 180.774 99.9945 180.992 99.67 181.125C99.3455 181.257 98.9862 181.297 98.6374 181.24C98.2885 181.182 97.9657 181.03 97.7094 180.803C97.4532 180.575 97.2749 180.282 97.1972 179.96C97.1194 179.639 97.1456 179.303 97.2724 178.995C97.3992 178.687 97.621 178.421 97.9098 178.23C98.1987 178.04 98.5417 177.933 98.8958 177.923C99.2499 177.913 99.5992 178.001 99.8998 178.175C100.101 178.293 100.275 178.446 100.413 178.627C100.55 178.807 100.647 179.011 100.7 179.227C100.752 179.443 100.757 179.666 100.717 179.884C100.676 180.102 100.589 180.31 100.461 180.497Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M105.744 186.301C105.553 186.579 105.277 186.797 104.953 186.929C104.628 187.062 104.269 187.102 103.92 187.044C103.571 186.987 103.248 186.835 102.992 186.607C102.736 186.38 102.558 186.087 102.48 185.765C102.402 185.443 102.428 185.108 102.555 184.8C102.682 184.492 102.904 184.226 103.193 184.035C103.481 183.844 103.824 183.737 104.179 183.727C104.533 183.717 104.882 183.805 105.183 183.98C105.384 184.097 105.558 184.251 105.695 184.431C105.833 184.612 105.93 184.816 105.982 185.032C106.034 185.248 106.04 185.471 105.999 185.689C105.958 185.906 105.872 186.115 105.744 186.301Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M96.3759 185.139C96.1845 185.416 95.9093 185.635 95.5848 185.767C95.2603 185.899 94.901 185.939 94.5521 185.882C94.2033 185.825 93.8804 185.673 93.6242 185.445C93.3679 185.218 93.1897 184.925 93.1119 184.603C93.0342 184.281 93.0603 183.945 93.1871 183.638C93.3139 183.33 93.5357 183.064 93.8246 182.873C94.1134 182.682 94.4565 182.575 94.8106 182.565C95.1647 182.555 95.514 182.643 95.8146 182.817C96.0165 182.935 96.1914 183.088 96.3291 183.268C96.4668 183.449 96.5646 183.653 96.6168 183.869C96.6691 184.085 96.6747 184.309 96.6333 184.527C96.5919 184.745 96.5045 184.953 96.3759 185.139Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M96.0079 177.67C95.8166 177.948 95.5413 178.166 95.2168 178.298C94.8923 178.431 94.533 178.471 94.1841 178.413C93.8353 178.356 93.5124 178.204 93.2562 177.976C92.9999 177.749 92.8217 177.456 92.7439 177.134C92.6662 176.813 92.6923 176.477 92.8191 176.169C92.946 175.861 93.1677 175.595 93.4566 175.404C93.7454 175.213 94.0885 175.106 94.4426 175.096C94.7967 175.087 95.146 175.174 95.4466 175.349C95.6485 175.466 95.8234 175.619 95.9611 175.8C96.0988 175.98 96.1966 176.184 96.2489 176.4C96.3011 176.616 96.3067 176.84 96.2653 177.058C96.224 177.276 96.1365 177.484 96.0079 177.67Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.5797 181.767C97.3889 182.045 97.1141 182.264 96.79 182.397C96.4658 182.53 96.1067 182.57 95.7578 182.514C95.4089 182.457 95.0858 182.306 94.829 182.079C94.5723 181.852 94.3934 181.559 94.3148 181.238C94.2363 180.916 94.2615 180.58 94.3875 180.272C94.5134 179.964 94.7344 179.698 95.0226 179.506C95.3109 179.315 95.6535 179.207 96.0075 179.196C96.3615 179.185 96.711 179.272 97.0121 179.446C97.2151 179.562 97.3912 179.715 97.53 179.895C97.6688 180.075 97.7676 180.279 97.8204 180.496C97.8733 180.712 97.8792 180.936 97.8379 181.154C97.7965 181.372 97.7088 181.581 97.5797 181.767Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.844 183.543C102.652 183.821 102.377 184.039 102.053 184.172C101.728 184.304 101.369 184.344 101.02 184.286C100.671 184.229 100.348 184.077 100.092 183.849C99.8357 183.622 99.6575 183.329 99.5797 183.007C99.502 182.686 99.5281 182.35 99.6549 182.042C99.7817 181.734 100.004 181.468 100.292 181.277C100.581 181.086 100.924 180.979 101.278 180.97C101.632 180.96 101.982 181.047 102.282 181.222C102.485 181.338 102.66 181.491 102.799 181.672C102.937 181.852 103.035 182.057 103.087 182.273C103.14 182.489 103.145 182.713 103.103 182.931C103.061 183.149 102.973 183.357 102.844 183.543Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M100.087 185.297C99.8956 185.575 99.6203 185.793 99.2958 185.925C98.9713 186.058 98.612 186.098 98.2632 186.04C97.9143 185.983 97.5915 185.831 97.3352 185.603C97.0789 185.376 96.9007 185.083 96.823 184.761C96.7452 184.44 96.7714 184.104 96.8982 183.796C97.025 183.488 97.2467 183.222 97.5356 183.031C97.8245 182.84 98.1675 182.733 98.5216 182.723C98.8757 182.714 99.225 182.801 99.5256 182.976C99.7282 183.092 99.9037 183.245 100.042 183.426C100.18 183.606 100.278 183.811 100.33 184.027C100.383 184.243 100.388 184.467 100.346 184.685C100.305 184.903 100.216 185.111 100.087 185.297Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M91.8354 176.731C91.644 177.008 91.3687 177.227 91.0442 177.359C90.7197 177.491 90.3604 177.531 90.0116 177.474C89.6627 177.417 89.3399 177.265 89.0836 177.037C88.8273 176.809 88.6491 176.516 88.5714 176.195C88.4936 175.873 88.5198 175.537 88.6466 175.229C88.7734 174.922 88.9951 174.656 89.284 174.465C89.5729 174.274 89.9159 174.167 90.27 174.157C90.6241 174.147 90.9734 174.235 91.274 174.409C91.4753 174.527 91.6495 174.68 91.7868 174.861C91.924 175.042 92.0215 175.246 92.0737 175.461C92.1259 175.677 92.1317 175.9 92.0908 176.118C92.0499 176.336 91.9631 176.544 91.8354 176.731Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.5859 179.719C94.3951 179.996 94.1204 180.215 93.7962 180.348C93.472 180.481 93.1129 180.521 92.764 180.465C92.4151 180.408 92.092 180.257 91.8353 180.03C91.5785 179.803 91.3996 179.51 91.3211 179.189C91.2425 178.867 91.2678 178.532 91.3937 178.224C91.5197 177.916 91.7406 177.649 92.0289 177.458C92.3171 177.266 92.6598 177.158 93.0137 177.147C93.3677 177.137 93.7172 177.223 94.0183 177.397C94.2214 177.513 94.3975 177.666 94.5363 177.846C94.6751 178.026 94.7738 178.23 94.8267 178.447C94.8795 178.663 94.8855 178.887 94.8441 179.105C94.8028 179.324 94.715 179.532 94.5859 179.719Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.364 170.05C111.453 170.37 111.439 170.708 111.323 171.02C111.206 171.332 110.993 171.606 110.709 171.805C110.426 172.005 110.085 172.122 109.731 172.141C109.376 172.16 109.023 172.081 108.716 171.914C108.41 171.747 108.164 171.498 108.009 171.201C107.854 170.903 107.797 170.569 107.846 170.242C107.895 169.914 108.048 169.608 108.284 169.361C108.521 169.114 108.83 168.937 109.174 168.854C109.635 168.743 110.124 168.807 110.535 169.031C110.945 169.255 111.243 169.621 111.364 170.05Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.503 170.415C119.591 170.735 119.576 171.072 119.459 171.384C119.341 171.696 119.127 171.969 118.843 172.167C118.559 172.366 118.219 172.482 117.864 172.501C117.509 172.519 117.157 172.439 116.851 172.272C116.545 172.104 116.299 171.855 116.145 171.557C115.991 171.26 115.935 170.926 115.985 170.599C116.034 170.271 116.187 169.965 116.423 169.718C116.66 169.472 116.97 169.296 117.314 169.213C117.542 169.158 117.78 169.146 118.014 169.176C118.248 169.207 118.472 169.28 118.676 169.392C118.879 169.503 119.057 169.651 119.199 169.827C119.341 170.002 119.444 170.202 119.503 170.415Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.187 175.975C112.275 176.295 112.26 176.633 112.143 176.945C112.025 177.257 111.811 177.529 111.527 177.728C111.243 177.927 110.903 178.043 110.548 178.061C110.193 178.08 109.841 178 109.535 177.832C109.229 177.664 108.983 177.416 108.829 177.118C108.675 176.82 108.619 176.486 108.669 176.159C108.718 175.832 108.871 175.526 109.107 175.279C109.344 175.032 109.654 174.857 109.998 174.774C110.226 174.719 110.464 174.706 110.698 174.737C110.932 174.768 111.156 174.841 111.36 174.952C111.563 175.064 111.741 175.212 111.883 175.387C112.025 175.563 112.128 175.763 112.187 175.975Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.099 171.111C106.188 171.431 106.173 171.769 106.055 172.081C105.938 172.393 105.724 172.665 105.44 172.864C105.156 173.063 104.815 173.179 104.461 173.197C104.106 173.216 103.753 173.136 103.447 172.968C103.142 172.8 102.896 172.552 102.742 172.254C102.588 171.956 102.532 171.623 102.581 171.295C102.631 170.968 102.783 170.662 103.02 170.415C103.257 170.168 103.566 169.993 103.91 169.91C104.139 169.854 104.377 169.841 104.611 169.871C104.845 169.902 105.07 169.975 105.274 170.086C105.477 170.198 105.655 170.346 105.797 170.522C105.938 170.698 106.041 170.898 106.099 171.111Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.384 172.859C110.473 173.18 110.458 173.517 110.34 173.829C110.223 174.141 110.009 174.413 109.725 174.612C109.441 174.811 109.1 174.927 108.745 174.945C108.391 174.964 108.038 174.884 107.732 174.716C107.426 174.548 107.181 174.3 107.027 174.002C106.872 173.704 106.817 173.371 106.866 173.043C106.916 172.716 107.068 172.41 107.305 172.163C107.541 171.917 107.851 171.741 108.195 171.658C108.424 171.602 108.662 171.589 108.896 171.619C109.13 171.65 109.355 171.723 109.559 171.834C109.762 171.946 109.94 172.094 110.082 172.27C110.223 172.446 110.326 172.646 110.384 172.859Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.38 170.509C115.469 170.829 115.453 171.166 115.336 171.478C115.219 171.79 115.004 172.062 114.721 172.261C114.437 172.46 114.096 172.576 113.741 172.594C113.387 172.613 113.034 172.533 112.728 172.365C112.422 172.197 112.177 171.949 112.022 171.651C111.868 171.353 111.812 171.02 111.862 170.692C111.911 170.365 112.064 170.059 112.301 169.812C112.537 169.566 112.847 169.39 113.191 169.307C113.419 169.252 113.657 169.24 113.891 169.27C114.125 169.301 114.35 169.374 114.553 169.486C114.756 169.597 114.934 169.745 115.076 169.921C115.218 170.096 115.321 170.296 115.38 170.509Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.881 173.578C114.97 173.898 114.955 174.236 114.838 174.548C114.72 174.861 114.506 175.133 114.222 175.332C113.938 175.531 113.596 175.647 113.241 175.665C112.886 175.684 112.534 175.604 112.228 175.435C111.922 175.267 111.676 175.018 111.522 174.719C111.369 174.421 111.314 174.087 111.364 173.759C111.414 173.432 111.568 173.126 111.806 172.88C112.043 172.633 112.354 172.458 112.698 172.377C112.926 172.321 113.164 172.308 113.398 172.338C113.631 172.369 113.856 172.442 114.059 172.553C114.262 172.665 114.439 172.813 114.58 172.989C114.722 173.165 114.824 173.365 114.881 173.578Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.501 173.288C102.59 173.609 102.574 173.946 102.457 174.259C102.34 174.571 102.125 174.844 101.841 175.043C101.557 175.241 101.216 175.357 100.861 175.376C100.506 175.394 100.153 175.314 99.8472 175.145C99.5412 174.977 99.2957 174.728 99.142 174.43C98.9882 174.131 98.933 173.797 98.9834 173.47C99.0338 173.142 99.1875 172.836 99.425 172.59C99.6626 172.344 99.9732 172.169 100.318 172.087C100.778 171.978 101.267 172.043 101.676 172.268C102.085 172.493 102.382 172.86 102.501 173.288Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.723 173.48C106.813 173.8 106.799 174.138 106.683 174.451C106.566 174.764 106.353 175.037 106.069 175.237C105.785 175.437 105.444 175.554 105.089 175.573C104.734 175.592 104.381 175.512 104.074 175.345C103.768 175.177 103.522 174.928 103.367 174.63C103.213 174.331 103.157 173.997 103.207 173.67C103.257 173.342 103.41 173.035 103.648 172.789C103.885 172.542 104.196 172.367 104.54 172.285C105 172.175 105.488 172.239 105.897 172.463C106.306 172.687 106.603 173.053 106.723 173.48Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.655 174.872C103.744 175.193 103.728 175.53 103.611 175.843C103.494 176.155 103.279 176.428 102.995 176.627C102.711 176.825 102.37 176.941 102.015 176.96C101.66 176.978 101.307 176.898 101.001 176.729C100.695 176.561 100.45 176.312 100.296 176.014C100.142 175.715 100.087 175.381 100.137 175.054C100.188 174.726 100.341 174.42 100.579 174.174C100.816 173.928 101.127 173.753 101.472 173.671C101.932 173.562 102.421 173.627 102.83 173.852C103.239 174.077 103.536 174.444 103.655 174.872Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.819 175.274C111.907 175.594 111.892 175.932 111.775 176.244C111.657 176.556 111.443 176.828 111.159 177.027C110.875 177.226 110.535 177.341 110.18 177.36C109.825 177.379 109.473 177.299 109.167 177.131C108.861 176.963 108.615 176.714 108.461 176.417C108.307 176.119 108.251 175.785 108.301 175.458C108.35 175.131 108.503 174.824 108.739 174.578C108.976 174.331 109.286 174.156 109.63 174.073C109.858 174.018 110.096 174.005 110.33 174.036C110.563 174.066 110.788 174.14 110.992 174.251C111.195 174.363 111.373 174.511 111.515 174.686C111.657 174.862 111.76 175.061 111.819 175.274Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.503 180.816C104.592 181.136 104.577 181.474 104.459 181.786C104.342 182.098 104.127 182.371 103.843 182.57C103.559 182.769 103.218 182.885 102.863 182.903C102.508 182.921 102.155 182.841 101.849 182.673C101.543 182.504 101.298 182.255 101.144 181.957C100.99 181.659 100.935 181.325 100.986 180.997C101.036 180.67 101.19 180.363 101.427 180.117C101.665 179.871 101.975 179.696 102.32 179.614C102.78 179.505 103.269 179.57 103.678 179.795C104.087 180.02 104.384 180.387 104.503 180.816Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.4154 175.952C98.5044 176.273 98.4892 176.61 98.3718 176.923C98.2544 177.235 98.0401 177.508 97.756 177.707C97.4718 177.906 97.1307 178.021 96.7757 178.04C96.4208 178.058 96.0679 177.978 95.7619 177.81C95.4559 177.641 95.2105 177.392 95.0567 177.094C94.903 176.795 94.8478 176.461 94.8982 176.134C94.9486 175.806 95.1023 175.5 95.3398 175.254C95.5773 175.008 95.888 174.833 96.2325 174.751C96.6931 174.642 97.1816 174.707 97.5907 174.932C97.9999 175.157 98.2965 175.524 98.4154 175.952Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.7 177.698C102.79 178.018 102.776 178.356 102.659 178.668C102.543 178.981 102.33 179.254 102.046 179.454C101.763 179.653 101.422 179.77 101.067 179.789C100.713 179.809 100.36 179.73 100.053 179.562C99.7466 179.395 99.5003 179.147 99.3455 178.849C99.1907 178.551 99.1342 178.218 99.1832 177.89C99.2322 177.563 99.3846 177.256 99.6209 177.009C99.8573 176.762 100.167 176.586 100.511 176.503C100.972 176.392 101.461 176.455 101.871 176.679C102.282 176.903 102.58 177.27 102.7 177.698Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.696 175.349C107.786 175.67 107.772 176.007 107.656 176.32C107.539 176.633 107.326 176.907 107.042 177.106C106.758 177.306 106.417 177.423 106.062 177.442C105.707 177.461 105.354 177.382 105.047 177.214C104.741 177.046 104.495 176.797 104.34 176.499C104.186 176.201 104.13 175.866 104.18 175.539C104.23 175.211 104.383 174.904 104.621 174.658C104.858 174.411 105.169 174.236 105.513 174.154C105.973 174.044 106.461 174.109 106.87 174.333C107.279 174.557 107.576 174.922 107.696 175.349Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.185 178.419C107.273 178.739 107.258 179.076 107.14 179.388C107.023 179.7 106.809 179.973 106.525 180.171C106.241 180.37 105.901 180.486 105.546 180.505C105.191 180.523 104.839 180.443 104.533 180.275C104.227 180.108 103.981 179.859 103.827 179.561C103.673 179.263 103.617 178.93 103.666 178.603C103.716 178.275 103.869 177.969 104.105 177.722C104.342 177.476 104.652 177.3 104.995 177.217C105.224 177.162 105.462 177.15 105.696 177.18C105.929 177.211 106.154 177.284 106.358 177.396C106.561 177.507 106.739 177.655 106.881 177.831C107.022 178.006 107.126 178.206 107.185 178.419Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.8229 178.128C94.9127 178.448 94.8985 178.786 94.782 179.098C94.6656 179.411 94.4522 179.684 94.1688 179.883C93.8854 180.083 93.5447 180.2 93.19 180.219C92.8352 180.239 92.4822 180.159 92.1757 179.992C91.8692 179.825 91.623 179.576 91.4681 179.279C91.3133 178.981 91.2568 178.647 91.3058 178.32C91.3549 177.992 91.5072 177.686 91.7436 177.439C91.9799 177.192 92.2897 177.016 92.6337 176.933C93.0944 176.821 93.5837 176.885 93.9941 177.109C94.4046 177.333 94.7027 177.7 94.8229 178.128Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.0454 178.32C99.1352 178.64 99.1209 178.977 99.0045 179.29C98.8881 179.602 98.6746 179.875 98.3912 180.075C98.1078 180.274 97.7672 180.391 97.4124 180.411C97.0577 180.43 96.7047 180.351 96.3982 180.183C96.0917 180.016 95.8454 179.768 95.6906 179.47C95.5358 179.173 95.4793 178.839 95.5283 178.511C95.5773 178.184 95.7297 177.877 95.966 177.63C96.2024 177.383 96.5122 177.207 96.8562 177.124C97.3168 177.013 97.8061 177.076 98.2166 177.3C98.6271 177.524 98.9251 177.891 99.0454 178.32Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M116.902 175.075C116.806 174.757 116.814 174.419 116.925 174.104C117.036 173.79 117.244 173.513 117.524 173.309C117.804 173.105 118.142 172.983 118.497 172.958C118.851 172.933 119.206 173.007 119.515 173.169C119.825 173.332 120.076 173.577 120.236 173.872C120.396 174.167 120.458 174.5 120.415 174.829C120.371 175.157 120.224 175.466 119.992 175.717C119.76 175.967 119.453 176.148 119.11 176.236C118.652 176.352 118.163 176.295 117.749 176.078C117.336 175.861 117.032 175.5 116.902 175.075Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.763 174.791C108.666 174.473 108.674 174.134 108.785 173.82C108.896 173.505 109.104 173.228 109.384 173.024C109.664 172.819 110.003 172.697 110.358 172.673C110.713 172.648 111.068 172.722 111.377 172.885C111.687 173.048 111.938 173.294 112.097 173.589C112.257 173.885 112.319 174.219 112.274 174.547C112.23 174.876 112.082 175.185 111.849 175.435C111.615 175.685 111.308 175.865 110.964 175.952C110.507 176.068 110.019 176.011 109.607 175.794C109.194 175.576 108.891 175.216 108.763 174.791Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.998 169.139C115.901 168.82 115.909 168.482 116.02 168.167C116.13 167.853 116.339 167.576 116.619 167.371C116.899 167.167 117.238 167.045 117.593 167.02C117.948 166.996 118.303 167.07 118.612 167.233C118.922 167.396 119.173 167.641 119.332 167.937C119.492 168.233 119.554 168.567 119.509 168.895C119.465 169.224 119.317 169.533 119.084 169.783C118.85 170.033 118.543 170.213 118.199 170.3C117.742 170.416 117.254 170.359 116.842 170.142C116.429 169.924 116.126 169.564 115.998 169.139Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.154 173.932C122.057 173.613 122.065 173.275 122.176 172.96C122.286 172.646 122.495 172.369 122.775 172.164C123.055 171.96 123.394 171.838 123.749 171.813C124.104 171.789 124.459 171.863 124.768 172.026C125.078 172.189 125.329 172.434 125.488 172.73C125.648 173.026 125.71 173.359 125.665 173.688C125.621 174.017 125.473 174.326 125.24 174.576C125.006 174.826 124.699 175.006 124.355 175.093C123.898 175.209 123.41 175.152 122.997 174.934C122.585 174.717 122.282 174.357 122.154 173.932Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M117.844 172.231C117.748 171.913 117.756 171.575 117.867 171.26C117.977 170.946 118.186 170.669 118.466 170.465C118.745 170.261 119.084 170.139 119.439 170.114C119.793 170.089 120.148 170.163 120.457 170.326C120.767 170.488 121.017 170.733 121.177 171.028C121.338 171.324 121.4 171.657 121.356 171.985C121.313 172.313 121.166 172.622 120.934 172.873C120.701 173.123 120.394 173.304 120.052 173.392C119.594 173.508 119.105 173.452 118.691 173.234C118.278 173.017 117.973 172.656 117.844 172.231Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.879 174.645C112.783 174.327 112.791 173.989 112.902 173.675C113.013 173.36 113.221 173.083 113.501 172.879C113.781 172.675 114.119 172.553 114.474 172.528C114.828 172.503 115.183 172.577 115.492 172.74C115.802 172.902 116.053 173.147 116.213 173.442C116.373 173.738 116.435 174.071 116.392 174.399C116.348 174.727 116.201 175.036 115.969 175.287C115.737 175.537 115.43 175.718 115.087 175.806C114.629 175.924 114.139 175.868 113.725 175.65C113.311 175.433 113.007 175.071 112.879 174.645Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M113.353 171.571C113.257 171.252 113.265 170.914 113.375 170.599C113.486 170.284 113.695 170.007 113.975 169.803C114.255 169.599 114.594 169.477 114.949 169.452C115.304 169.427 115.658 169.501 115.968 169.664C116.278 169.828 116.528 170.073 116.688 170.369C116.847 170.665 116.909 170.998 116.865 171.327C116.821 171.655 116.672 171.964 116.439 172.214C116.206 172.464 115.898 172.644 115.555 172.732C115.097 172.849 114.609 172.793 114.196 172.575C113.783 172.358 113.48 171.996 113.353 171.571Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.721 171.709C125.625 171.391 125.633 171.052 125.743 170.738C125.854 170.423 126.063 170.146 126.343 169.942C126.623 169.737 126.962 169.615 127.317 169.591C127.672 169.566 128.026 169.64 128.336 169.803C128.646 169.966 128.896 170.212 129.056 170.507C129.215 170.803 129.277 171.137 129.233 171.465C129.189 171.794 129.04 172.103 128.807 172.353C128.574 172.603 128.266 172.783 127.923 172.87C127.465 172.988 126.977 172.932 126.564 172.714C126.151 172.496 125.848 172.135 125.721 171.709Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.492 171.571C121.396 171.252 121.404 170.914 121.515 170.599C121.625 170.284 121.834 170.007 122.114 169.803C122.394 169.599 122.733 169.477 123.088 169.452C123.443 169.427 123.798 169.501 124.107 169.664C124.417 169.828 124.667 170.073 124.827 170.369C124.987 170.665 125.048 170.998 125.004 171.327C124.96 171.655 124.812 171.964 124.578 172.214C124.345 172.464 124.037 172.644 123.694 172.732C123.237 172.848 122.749 172.791 122.336 172.573C121.924 172.356 121.621 171.995 121.492 171.571Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M120.495 170.909C120.398 170.59 120.406 170.252 120.517 169.937C120.627 169.622 120.836 169.345 121.116 169.141C121.396 168.937 121.735 168.814 122.09 168.79C122.445 168.765 122.8 168.839 123.109 169.002C123.419 169.166 123.67 169.411 123.829 169.707C123.989 170.003 124.05 170.336 124.006 170.665C123.962 170.993 123.814 171.302 123.581 171.552C123.347 171.802 123.04 171.982 122.696 172.069C122.239 172.185 121.751 172.128 121.338 171.911C120.926 171.694 120.623 171.333 120.495 170.909Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.386 170.629C112.29 170.311 112.298 169.972 112.409 169.658C112.519 169.343 112.728 169.066 113.008 168.862C113.288 168.657 113.627 168.535 113.982 168.511C114.337 168.486 114.692 168.56 115.001 168.723C115.311 168.886 115.561 169.131 115.721 169.427C115.881 169.723 115.942 170.057 115.898 170.385C115.854 170.714 115.706 171.023 115.472 171.273C115.239 171.523 114.931 171.703 114.588 171.79C114.131 171.906 113.643 171.849 113.23 171.632C112.818 171.414 112.515 171.054 112.386 170.629Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.603 164.971C119.506 164.652 119.514 164.314 119.625 163.999C119.735 163.685 119.944 163.408 120.224 163.203C120.504 162.999 120.843 162.877 121.198 162.852C121.553 162.828 121.908 162.902 122.217 163.065C122.527 163.228 122.778 163.473 122.937 163.769C123.097 164.065 123.159 164.399 123.114 164.727C123.07 165.056 122.922 165.365 122.689 165.615C122.455 165.865 122.148 166.045 121.804 166.132C121.347 166.25 120.858 166.193 120.445 165.976C120.032 165.758 119.729 165.397 119.603 164.971Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.759 169.766C125.662 169.447 125.67 169.109 125.781 168.794C125.891 168.48 126.1 168.203 126.38 167.998C126.66 167.794 126.999 167.672 127.354 167.647C127.709 167.623 128.064 167.697 128.373 167.86C128.683 168.023 128.934 168.268 129.093 168.564C129.253 168.86 129.315 169.193 129.27 169.522C129.226 169.851 129.078 170.16 128.845 170.41C128.611 170.66 128.304 170.84 127.96 170.927C127.503 171.043 127.015 170.986 126.602 170.768C126.19 170.551 125.887 170.191 125.759 169.766Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.449 168.065C121.353 167.746 121.36 167.408 121.471 167.093C121.582 166.778 121.79 166.501 122.07 166.297C122.351 166.093 122.69 165.971 123.044 165.946C123.399 165.921 123.754 165.995 124.064 166.159C124.373 166.322 124.624 166.567 124.783 166.863C124.943 167.159 125.005 167.492 124.96 167.821C124.916 168.149 124.768 168.458 124.535 168.709C124.302 168.959 123.994 169.139 123.65 169.226C123.193 169.342 122.705 169.285 122.293 169.067C121.88 168.85 121.577 168.49 121.449 168.065Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M116.484 170.473C116.388 170.154 116.396 169.816 116.506 169.501C116.617 169.187 116.826 168.91 117.106 168.705C117.386 168.501 117.725 168.379 118.08 168.354C118.435 168.33 118.789 168.404 119.099 168.567C119.409 168.73 119.659 168.975 119.819 169.271C119.978 169.567 120.04 169.901 119.996 170.229C119.952 170.558 119.803 170.867 119.57 171.117C119.337 171.367 119.029 171.547 118.686 171.634C118.228 171.75 117.74 171.693 117.328 171.476C116.916 171.258 116.612 170.898 116.484 170.473Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M116.958 167.403C116.862 167.084 116.87 166.746 116.98 166.431C117.091 166.116 117.3 165.839 117.58 165.635C117.86 165.431 118.199 165.309 118.554 165.284C118.909 165.259 119.263 165.333 119.573 165.497C119.883 165.66 120.133 165.905 120.293 166.201C120.452 166.497 120.514 166.83 120.47 167.159C120.426 167.487 120.277 167.796 120.044 168.046C119.811 168.297 119.503 168.477 119.16 168.564C118.702 168.681 118.214 168.625 117.801 168.407C117.388 168.19 117.085 167.828 116.958 167.403Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M129.32 167.542C129.224 167.223 129.232 166.885 129.343 166.571C129.453 166.257 129.662 165.98 129.942 165.776C130.222 165.572 130.56 165.45 130.915 165.425C131.269 165.4 131.624 165.473 131.933 165.636C132.243 165.799 132.494 166.043 132.654 166.339C132.814 166.634 132.876 166.967 132.833 167.296C132.789 167.624 132.642 167.933 132.41 168.183C132.177 168.434 131.871 168.615 131.528 168.703C131.07 168.82 130.58 168.764 130.166 168.547C129.752 168.329 129.448 167.968 129.32 167.542Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.097 167.403C125.001 167.084 125.009 166.746 125.12 166.431C125.23 166.116 125.439 165.839 125.719 165.635C125.999 165.431 126.338 165.309 126.693 165.284C127.048 165.259 127.403 165.333 127.712 165.497C128.022 165.66 128.272 165.905 128.432 166.201C128.592 166.497 128.653 166.83 128.609 167.159C128.565 167.487 128.417 167.796 128.183 168.046C127.95 168.297 127.642 168.477 127.299 168.564C126.842 168.68 126.354 168.623 125.941 168.405C125.529 168.188 125.226 167.827 125.097 167.403Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.409 180.235C125.313 179.917 125.321 179.579 125.432 179.264C125.543 178.95 125.751 178.673 126.031 178.469C126.311 178.265 126.65 178.143 127.004 178.118C127.359 178.093 127.713 178.167 128.023 178.329C128.332 178.492 128.583 178.737 128.743 179.032C128.903 179.328 128.965 179.661 128.922 179.989C128.878 180.317 128.731 180.626 128.499 180.877C128.267 181.127 127.96 181.308 127.617 181.396C127.159 181.512 126.67 181.455 126.257 181.238C125.843 181.021 125.539 180.66 125.409 180.235Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M117.27 179.946C117.174 179.627 117.181 179.289 117.292 178.974C117.403 178.659 117.611 178.382 117.892 178.178C118.172 177.974 118.511 177.852 118.866 177.827C119.22 177.802 119.575 177.876 119.885 178.039C120.194 178.203 120.445 178.448 120.605 178.744C120.764 179.04 120.826 179.373 120.782 179.702C120.737 180.03 120.589 180.339 120.356 180.589C120.123 180.839 119.815 181.019 119.472 181.107C119.014 181.224 118.525 181.168 118.113 180.95C117.7 180.733 117.397 180.371 117.27 179.946Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M124.505 174.297C124.409 173.979 124.416 173.64 124.527 173.326C124.638 173.011 124.846 172.734 125.127 172.53C125.407 172.325 125.746 172.203 126.101 172.178C126.455 172.154 126.81 172.228 127.12 172.391C127.429 172.554 127.68 172.799 127.84 173.095C127.999 173.391 128.061 173.725 128.017 174.053C127.972 174.382 127.824 174.691 127.591 174.941C127.358 175.191 127.05 175.371 126.707 175.458C126.249 175.574 125.761 175.517 125.349 175.3C124.936 175.082 124.633 174.722 124.505 174.297Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M130.661 179.092C130.565 178.773 130.572 178.435 130.683 178.12C130.794 177.806 131.002 177.529 131.282 177.324C131.563 177.12 131.902 176.998 132.256 176.973C132.611 176.949 132.966 177.023 133.276 177.186C133.585 177.349 133.836 177.594 133.996 177.89C134.155 178.186 134.217 178.52 134.173 178.848C134.128 179.177 133.98 179.486 133.747 179.736C133.514 179.986 133.206 180.166 132.863 180.253C132.405 180.369 131.917 180.312 131.505 180.095C131.092 179.877 130.789 179.517 130.661 179.092Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M126.351 177.392C126.255 177.073 126.263 176.735 126.374 176.421C126.485 176.106 126.693 175.829 126.973 175.625C127.253 175.421 127.591 175.299 127.946 175.274C128.3 175.249 128.655 175.323 128.964 175.486C129.274 175.648 129.525 175.893 129.685 176.188C129.845 176.484 129.907 176.817 129.864 177.145C129.82 177.474 129.673 177.783 129.441 178.033C129.209 178.284 128.902 178.464 128.559 178.552C128.101 178.669 127.612 178.612 127.199 178.394C126.785 178.177 126.481 177.817 126.351 177.392Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.386 179.8C121.291 179.481 121.299 179.143 121.409 178.829C121.52 178.514 121.728 178.238 122.008 178.034C122.288 177.83 122.627 177.707 122.981 177.682C123.336 177.658 123.69 177.731 124 177.894C124.309 178.057 124.56 178.301 124.72 178.597C124.88 178.892 124.942 179.225 124.899 179.553C124.856 179.882 124.708 180.191 124.476 180.441C124.244 180.692 123.937 180.872 123.594 180.961C123.136 181.078 122.646 181.022 122.233 180.805C121.819 180.587 121.514 180.226 121.386 179.8Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.86 176.731C121.764 176.412 121.772 176.074 121.883 175.759C121.993 175.444 122.202 175.167 122.482 174.963C122.762 174.759 123.101 174.637 123.456 174.612C123.811 174.587 124.166 174.661 124.475 174.825C124.785 174.988 125.035 175.233 125.195 175.529C125.355 175.825 125.416 176.158 125.372 176.487C125.328 176.815 125.18 177.124 124.946 177.375C124.713 177.625 124.405 177.805 124.062 177.892C123.605 178.008 123.117 177.951 122.704 177.733C122.292 177.516 121.989 177.156 121.86 176.731Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M134.216 176.87C134.12 176.551 134.127 176.213 134.238 175.898C134.349 175.583 134.557 175.306 134.838 175.102C135.118 174.898 135.457 174.775 135.812 174.751C136.166 174.726 136.521 174.8 136.831 174.963C137.14 175.127 137.391 175.372 137.551 175.668C137.71 175.964 137.772 176.297 137.728 176.626C137.683 176.954 137.535 177.263 137.302 177.513C137.069 177.763 136.761 177.943 136.418 178.03C135.96 178.146 135.472 178.089 135.06 177.872C134.647 177.655 134.344 177.294 134.216 176.87Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M130 176.725C129.904 176.406 129.911 176.068 130.022 175.753C130.133 175.439 130.341 175.162 130.621 174.957C130.901 174.753 131.24 174.631 131.595 174.606C131.95 174.582 132.305 174.656 132.615 174.819C132.924 174.982 133.175 175.227 133.334 175.523C133.494 175.819 133.556 176.152 133.511 176.481C133.467 176.81 133.319 177.119 133.086 177.369C132.853 177.619 132.545 177.799 132.201 177.886C131.744 178.002 131.256 177.945 130.844 177.727C130.431 177.51 130.128 177.15 130 176.725Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.366 176.231C122.269 175.912 122.277 175.574 122.388 175.259C122.498 174.944 122.707 174.667 122.987 174.463C123.267 174.259 123.606 174.137 123.961 174.112C124.316 174.087 124.671 174.161 124.98 174.325C125.29 174.488 125.541 174.733 125.7 175.029C125.86 175.325 125.922 175.658 125.877 175.987C125.833 176.315 125.685 176.624 125.452 176.875C125.218 177.125 124.911 177.305 124.567 177.392C124.11 177.509 123.621 177.453 123.208 177.235C122.795 177.018 122.492 176.656 122.366 176.231Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.257 175.946C114.161 175.627 114.169 175.289 114.28 174.974C114.39 174.659 114.599 174.382 114.879 174.178C115.159 173.974 115.498 173.852 115.853 173.827C116.208 173.802 116.563 173.876 116.872 174.039C117.182 174.203 117.432 174.448 117.592 174.744C117.752 175.04 117.813 175.373 117.769 175.702C117.725 176.03 117.577 176.339 117.344 176.589C117.11 176.839 116.803 177.019 116.459 177.107C116.002 177.223 115.514 177.166 115.101 176.948C114.689 176.731 114.386 176.37 114.257 175.946Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.461 170.3C121.365 169.981 121.373 169.643 121.484 169.329C121.595 169.014 121.803 168.738 122.083 168.534C122.363 168.33 122.702 168.207 123.056 168.182C123.411 168.158 123.765 168.231 124.075 168.394C124.384 168.557 124.635 168.801 124.795 169.097C124.955 169.392 125.017 169.725 124.974 170.053C124.93 170.382 124.783 170.691 124.551 170.941C124.319 171.192 124.012 171.372 123.669 171.461C123.211 171.578 122.721 171.522 122.307 171.305C121.894 171.087 121.589 170.726 121.461 170.3Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.617 175.088C127.521 174.77 127.529 174.431 127.639 174.117C127.75 173.802 127.959 173.525 128.239 173.321C128.519 173.116 128.858 172.994 129.213 172.969C129.568 172.945 129.922 173.019 130.232 173.182C130.542 173.345 130.792 173.59 130.952 173.886C131.112 174.182 131.173 174.516 131.129 174.844C131.085 175.173 130.936 175.482 130.703 175.732C130.47 175.982 130.162 176.162 129.819 176.249C129.361 176.367 128.873 176.31 128.46 176.093C128.047 175.875 127.744 175.514 127.617 175.088Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.314 173.393C123.217 173.074 123.225 172.736 123.336 172.421C123.446 172.107 123.655 171.829 123.935 171.625C124.215 171.421 124.554 171.299 124.909 171.274C125.264 171.25 125.619 171.323 125.928 171.487C126.238 171.65 126.489 171.895 126.648 172.191C126.808 172.487 126.87 172.82 126.825 173.149C126.781 173.478 126.633 173.787 126.4 174.037C126.166 174.287 125.859 174.467 125.515 174.554C125.058 174.67 124.57 174.613 124.158 174.395C123.745 174.178 123.442 173.818 123.314 173.393Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.343 175.802C118.247 175.483 118.255 175.145 118.366 174.831C118.476 174.516 118.685 174.24 118.965 174.036C119.244 173.831 119.583 173.709 119.938 173.684C120.292 173.66 120.647 173.733 120.956 173.896C121.266 174.059 121.516 174.303 121.676 174.599C121.837 174.894 121.899 175.227 121.855 175.555C121.812 175.884 121.665 176.193 121.433 176.443C121.2 176.694 120.893 176.874 120.551 176.963C120.093 177.079 119.604 177.022 119.19 176.805C118.777 176.587 118.472 176.227 118.343 175.802Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.817 172.725C118.721 172.406 118.728 172.068 118.839 171.753C118.95 171.439 119.158 171.162 119.438 170.957C119.718 170.753 120.057 170.631 120.412 170.606C120.767 170.582 121.122 170.656 121.432 170.819C121.741 170.982 121.992 171.227 122.151 171.523C122.311 171.819 122.373 172.152 122.328 172.481C122.284 172.81 122.136 173.119 121.903 173.369C121.67 173.619 121.362 173.799 121.018 173.886C120.561 174.002 120.073 173.945 119.661 173.727C119.248 173.51 118.945 173.15 118.817 172.725Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M131.185 172.864C131.089 172.545 131.096 172.207 131.207 171.892C131.318 171.577 131.526 171.3 131.806 171.096C132.087 170.892 132.426 170.77 132.78 170.745C133.135 170.72 133.49 170.794 133.8 170.957C134.109 171.121 134.36 171.366 134.519 171.662C134.679 171.958 134.741 172.291 134.696 172.62C134.652 172.948 134.504 173.257 134.271 173.507C134.038 173.757 133.73 173.937 133.386 174.025C132.929 174.142 132.44 174.086 132.027 173.868C131.615 173.651 131.311 173.289 131.185 172.864Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M126.956 172.726C126.86 172.407 126.868 172.069 126.979 171.755C127.09 171.44 127.298 171.163 127.578 170.959C127.858 170.755 128.196 170.633 128.551 170.608C128.905 170.583 129.26 170.657 129.569 170.82C129.879 170.982 130.13 171.227 130.29 171.522C130.45 171.818 130.512 172.151 130.469 172.479C130.425 172.808 130.278 173.117 130.046 173.367C129.814 173.618 129.507 173.798 129.164 173.886C128.937 173.945 128.7 173.962 128.466 173.936C128.232 173.909 128.006 173.84 127.801 173.733C127.596 173.625 127.416 173.48 127.271 173.307C127.126 173.135 127.019 172.937 126.956 172.726Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.201 185.082C123.105 184.764 123.113 184.425 123.224 184.111C123.334 183.796 123.543 183.519 123.823 183.315C124.103 183.11 124.442 182.988 124.797 182.964C125.152 182.939 125.507 183.013 125.816 183.176C126.126 183.339 126.376 183.585 126.536 183.881C126.696 184.176 126.757 184.51 126.713 184.838C126.669 185.167 126.521 185.476 126.287 185.726C126.054 185.976 125.746 186.156 125.403 186.243C124.946 186.359 124.458 186.302 124.045 186.085C123.633 185.867 123.33 185.507 123.201 185.082Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.056 184.791C114.96 184.473 114.967 184.134 115.078 183.82C115.189 183.505 115.397 183.228 115.677 183.024C115.958 182.819 116.297 182.697 116.651 182.673C117.006 182.648 117.361 182.722 117.671 182.885C117.98 183.048 118.231 183.294 118.39 183.59C118.55 183.885 118.612 184.219 118.568 184.547C118.523 184.876 118.375 185.185 118.142 185.435C117.909 185.685 117.601 185.865 117.257 185.952C116.8 186.068 116.312 186.011 115.9 185.794C115.487 185.576 115.184 185.216 115.056 184.791Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.291 179.145C122.195 178.827 122.203 178.489 122.314 178.175C122.424 177.86 122.633 177.583 122.913 177.379C123.192 177.175 123.531 177.053 123.886 177.028C124.24 177.003 124.595 177.077 124.904 177.24C125.214 177.402 125.464 177.647 125.624 177.942C125.785 178.238 125.847 178.571 125.803 178.899C125.76 179.227 125.613 179.536 125.381 179.787C125.148 180.037 124.841 180.218 124.499 180.306C124.041 180.422 123.552 180.366 123.138 180.148C122.725 179.931 122.42 179.571 122.291 179.145Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M128.447 183.938C128.351 183.619 128.358 183.281 128.469 182.966C128.58 182.651 128.788 182.374 129.068 182.17C129.348 181.966 129.687 181.844 130.042 181.819C130.397 181.794 130.752 181.868 131.062 182.032C131.371 182.195 131.622 182.44 131.781 182.736C131.941 183.032 132.003 183.365 131.958 183.694C131.914 184.023 131.766 184.331 131.533 184.582C131.3 184.832 130.992 185.012 130.648 185.099C130.191 185.215 129.703 185.158 129.291 184.94C128.878 184.723 128.575 184.363 128.447 183.938Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M124.143 182.239C124.047 181.92 124.055 181.582 124.165 181.267C124.276 180.952 124.485 180.675 124.765 180.471C125.045 180.267 125.384 180.145 125.739 180.12C126.094 180.095 126.448 180.169 126.758 180.332C127.068 180.496 127.318 180.741 127.478 181.037C127.638 181.333 127.699 181.666 127.655 181.995C127.611 182.323 127.462 182.632 127.229 182.882C126.996 183.132 126.688 183.312 126.345 183.4C125.887 183.517 125.399 183.461 124.986 183.243C124.573 183.026 124.27 182.664 124.143 182.239Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.172 184.647C119.076 184.329 119.084 183.991 119.195 183.677C119.306 183.362 119.514 183.085 119.794 182.881C120.074 182.677 120.413 182.555 120.767 182.53C121.122 182.505 121.476 182.579 121.786 182.742C122.095 182.904 122.346 183.149 122.506 183.444C122.666 183.74 122.728 184.073 122.685 184.401C122.641 184.729 122.494 185.038 122.262 185.289C122.03 185.539 121.723 185.72 121.38 185.808C120.922 185.926 120.432 185.87 120.018 185.652C119.605 185.435 119.3 185.073 119.172 184.647Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.646 181.577C119.55 181.258 119.558 180.921 119.669 180.606C119.78 180.292 119.988 180.015 120.268 179.811C120.548 179.607 120.887 179.485 121.241 179.46C121.596 179.435 121.95 179.509 122.26 179.671C122.569 179.834 122.82 180.079 122.98 180.374C123.14 180.669 123.202 181.002 123.159 181.331C123.115 181.659 122.968 181.968 122.736 182.219C122.504 182.469 122.197 182.65 121.854 182.738C121.627 182.797 121.39 182.814 121.156 182.787C120.922 182.761 120.696 182.692 120.491 182.584C120.286 182.476 120.106 182.332 119.961 182.159C119.816 181.986 119.709 181.788 119.646 181.577Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M132.014 181.715C131.918 181.396 131.926 181.058 132.036 180.744C132.147 180.429 132.356 180.152 132.636 179.947C132.916 179.743 133.255 179.621 133.61 179.596C133.965 179.572 134.32 179.646 134.629 179.809C134.939 179.972 135.189 180.217 135.349 180.513C135.509 180.809 135.57 181.143 135.526 181.471C135.482 181.8 135.334 182.109 135.1 182.359C134.867 182.609 134.559 182.789 134.216 182.876C133.759 182.994 133.27 182.937 132.857 182.72C132.444 182.502 132.141 182.141 132.014 181.715Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.786 181.577C127.69 181.258 127.698 180.921 127.808 180.606C127.919 180.292 128.128 180.015 128.407 179.811C128.687 179.607 129.026 179.485 129.38 179.46C129.735 179.435 130.089 179.509 130.399 179.671C130.709 179.834 130.959 180.079 131.119 180.374C131.279 180.669 131.342 181.002 131.298 181.331C131.255 181.659 131.108 181.968 130.875 182.219C130.643 182.469 130.336 182.65 129.994 182.738C129.535 182.856 129.046 182.8 128.632 182.582C128.218 182.364 127.914 182.003 127.786 181.577Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.773 188.86C118.624 188.56 118.575 188.225 118.63 187.898C118.686 187.572 118.844 187.268 119.085 187.025C119.327 186.782 119.64 186.612 119.985 186.534C120.331 186.457 120.693 186.477 121.026 186.592C121.36 186.706 121.649 186.91 121.858 187.178C122.067 187.445 122.185 187.764 122.199 188.095C122.213 188.425 122.121 188.752 121.936 189.034C121.75 189.316 121.478 189.54 121.156 189.678C120.723 189.864 120.229 189.881 119.782 189.728C119.336 189.575 118.973 189.262 118.773 188.86Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.69 189.812C110.542 189.511 110.494 189.177 110.55 188.851C110.607 188.524 110.766 188.221 111.008 187.979C111.25 187.737 111.563 187.567 111.909 187.49C112.254 187.414 112.616 187.435 112.949 187.55C113.282 187.665 113.571 187.87 113.779 188.137C113.987 188.405 114.105 188.725 114.119 189.055C114.132 189.385 114.039 189.712 113.853 189.993C113.667 190.275 113.395 190.498 113.072 190.636C112.858 190.729 112.626 190.781 112.39 190.789C112.154 190.798 111.918 190.763 111.697 190.686C111.475 190.609 111.272 190.493 111.099 190.343C110.927 190.193 110.787 190.012 110.69 189.812Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M116.84 183.143C116.697 182.842 116.652 182.509 116.712 182.185C116.771 181.86 116.932 181.56 117.175 181.32C117.417 181.081 117.73 180.913 118.074 180.839C118.418 180.764 118.779 180.785 119.11 180.9C119.441 181.015 119.729 181.219 119.936 181.485C120.144 181.751 120.262 182.069 120.277 182.397C120.292 182.726 120.202 183.051 120.018 183.333C119.835 183.614 119.567 183.839 119.247 183.979C119.031 184.074 118.796 184.127 118.557 184.137C118.317 184.146 118.079 184.11 117.854 184.033C117.63 183.955 117.425 183.836 117.25 183.683C117.076 183.53 116.937 183.347 116.84 183.143Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.75 186.933C123.599 186.632 123.547 186.297 123.602 185.969C123.657 185.64 123.815 185.335 124.057 185.091C124.299 184.847 124.613 184.676 124.96 184.598C125.307 184.521 125.671 184.542 126.005 184.658C126.339 184.774 126.629 184.98 126.837 185.25C127.045 185.52 127.161 185.841 127.173 186.173C127.184 186.504 127.088 186.832 126.899 187.113C126.709 187.394 126.434 187.616 126.108 187.751C125.678 187.929 125.19 187.943 124.749 187.79C124.308 187.637 123.95 187.33 123.75 186.933Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.203 185.912C119.056 185.611 119.007 185.276 119.064 184.95C119.12 184.624 119.28 184.321 119.521 184.078C119.763 183.836 120.077 183.666 120.422 183.59C120.768 183.514 121.13 183.534 121.463 183.65C121.796 183.765 122.085 183.969 122.293 184.237C122.501 184.505 122.619 184.824 122.632 185.154C122.645 185.485 122.553 185.811 122.367 186.093C122.18 186.374 121.909 186.598 121.586 186.736C121.372 186.828 121.14 186.88 120.903 186.889C120.667 186.897 120.432 186.862 120.21 186.786C119.989 186.709 119.786 186.592 119.613 186.442C119.44 186.292 119.301 186.112 119.203 185.912Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.731 189.046C114.582 188.745 114.532 188.41 114.587 188.083C114.643 187.756 114.801 187.452 115.042 187.209C115.284 186.966 115.597 186.795 115.943 186.718C116.289 186.641 116.652 186.661 116.985 186.776C117.319 186.891 117.608 187.095 117.817 187.363C118.025 187.631 118.143 187.951 118.157 188.282C118.17 188.612 118.077 188.939 117.89 189.221C117.704 189.503 117.431 189.726 117.108 189.864C116.676 190.047 116.183 190.064 115.738 189.911C115.293 189.758 114.931 189.447 114.731 189.046Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.657 185.94C114.507 185.64 114.457 185.305 114.512 184.978C114.568 184.651 114.726 184.347 114.967 184.104C115.209 183.861 115.522 183.69 115.868 183.612C116.214 183.535 116.577 183.555 116.91 183.67C117.244 183.785 117.533 183.99 117.742 184.258C117.95 184.526 118.069 184.845 118.082 185.176C118.095 185.507 118.002 185.834 117.816 186.116C117.629 186.397 117.356 186.621 117.033 186.758C116.601 186.942 116.109 186.959 115.663 186.806C115.218 186.652 114.856 186.341 114.657 185.94Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M126.881 184.2C126.732 183.9 126.682 183.565 126.737 183.238C126.792 182.911 126.951 182.606 127.192 182.363C127.433 182.12 127.747 181.949 128.093 181.872C128.439 181.795 128.801 181.815 129.135 181.93C129.468 182.045 129.758 182.249 129.966 182.517C130.175 182.786 130.293 183.105 130.306 183.436C130.32 183.767 130.227 184.094 130.04 184.375C129.853 184.657 129.581 184.881 129.258 185.018C128.826 185.202 128.333 185.219 127.888 185.065C127.443 184.912 127.081 184.601 126.881 184.2Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.684 184.699C122.536 184.398 122.487 184.064 122.544 183.737C122.601 183.411 122.76 183.108 123.002 182.866C123.243 182.623 123.557 182.454 123.903 182.377C124.248 182.301 124.61 182.322 124.943 182.437C125.276 182.552 125.565 182.756 125.773 183.024C125.981 183.292 126.099 183.611 126.112 183.942C126.125 184.272 126.033 184.598 125.847 184.88C125.661 185.161 125.389 185.385 125.066 185.523C124.852 185.615 124.62 185.667 124.384 185.676C124.148 185.684 123.912 185.649 123.691 185.573C123.469 185.496 123.266 185.379 123.093 185.229C122.92 185.079 122.781 184.899 122.684 184.699Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M116.902 168.233C116.806 167.914 116.813 167.576 116.924 167.261C117.035 166.946 117.243 166.669 117.524 166.465C117.804 166.261 118.143 166.139 118.498 166.114C118.852 166.089 119.207 166.163 119.517 166.327C119.826 166.49 120.077 166.735 120.237 167.031C120.396 167.327 120.458 167.66 120.414 167.989C120.369 168.317 120.221 168.626 119.988 168.876C119.755 169.127 119.447 169.307 119.104 169.394C118.646 169.51 118.158 169.453 117.746 169.235C117.333 169.018 117.03 168.658 116.902 168.233Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.756 167.942C108.66 167.623 108.668 167.285 108.779 166.97C108.889 166.655 109.098 166.378 109.378 166.174C109.658 165.97 109.997 165.848 110.352 165.823C110.707 165.798 111.062 165.872 111.371 166.036C111.681 166.199 111.931 166.444 112.091 166.74C112.251 167.036 112.312 167.369 112.268 167.698C112.224 168.026 112.076 168.335 111.842 168.585C111.609 168.836 111.301 169.016 110.958 169.103C110.501 169.219 110.013 169.162 109.6 168.944C109.188 168.727 108.885 168.367 108.756 167.942Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.991 162.296C115.896 161.977 115.904 161.639 116.014 161.325C116.125 161.011 116.333 160.734 116.613 160.53C116.893 160.326 117.232 160.203 117.586 160.179C117.941 160.154 118.295 160.227 118.605 160.39C118.914 160.553 119.165 160.797 119.325 161.093C119.485 161.388 119.547 161.721 119.504 162.05C119.461 162.378 119.313 162.687 119.081 162.937C118.849 163.188 118.542 163.369 118.199 163.457C117.741 163.573 117.252 163.516 116.839 163.299C116.425 163.081 116.121 162.721 115.991 162.296Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.147 167.089C122.052 166.77 122.059 166.432 122.17 166.118C122.281 165.804 122.489 165.527 122.769 165.323C123.049 165.119 123.388 164.996 123.742 164.972C124.097 164.947 124.451 165.02 124.761 165.183C125.07 165.346 125.321 165.59 125.481 165.886C125.641 166.181 125.703 166.514 125.66 166.842C125.617 167.171 125.469 167.48 125.237 167.73C125.005 167.981 124.698 168.162 124.355 168.25C123.897 168.367 123.407 168.311 122.994 168.094C122.58 167.876 122.275 167.515 122.147 167.089Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M117.844 165.407C117.748 165.088 117.755 164.75 117.866 164.435C117.977 164.12 118.185 163.843 118.465 163.639C118.746 163.435 119.085 163.313 119.439 163.288C119.794 163.263 120.149 163.337 120.459 163.5C120.768 163.664 121.019 163.909 121.178 164.205C121.338 164.501 121.4 164.834 121.355 165.163C121.311 165.491 121.163 165.8 120.93 166.05C120.697 166.3 120.389 166.48 120.045 166.567C119.588 166.685 119.099 166.629 118.686 166.411C118.274 166.194 117.97 165.832 117.844 165.407Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M112.879 167.797C112.783 167.479 112.791 167.14 112.901 166.826C113.012 166.511 113.221 166.234 113.501 166.03C113.781 165.825 114.12 165.703 114.475 165.678C114.83 165.654 115.184 165.728 115.494 165.891C115.804 166.054 116.054 166.299 116.214 166.595C116.373 166.891 116.435 167.225 116.391 167.553C116.347 167.882 116.198 168.191 115.965 168.441C115.732 168.691 115.424 168.871 115.081 168.958C114.623 169.076 114.135 169.019 113.722 168.802C113.309 168.584 113.006 168.223 112.879 167.797Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M113.347 164.727C113.251 164.409 113.259 164.071 113.37 163.757C113.48 163.442 113.689 163.165 113.969 162.961C114.249 162.757 114.587 162.635 114.942 162.61C115.296 162.585 115.651 162.659 115.96 162.822C116.27 162.984 116.52 163.229 116.681 163.524C116.841 163.82 116.903 164.153 116.86 164.481C116.816 164.81 116.669 165.119 116.437 165.369C116.204 165.62 115.897 165.8 115.555 165.888C115.097 166.004 114.608 165.948 114.194 165.73C113.781 165.513 113.476 165.153 113.347 164.727Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.715 164.866C125.619 164.547 125.626 164.209 125.737 163.894C125.848 163.579 126.056 163.302 126.337 163.098C126.617 162.894 126.956 162.771 127.311 162.747C127.665 162.722 128.02 162.796 128.33 162.959C128.639 163.123 128.89 163.368 129.05 163.664C129.209 163.96 129.271 164.293 129.227 164.622C129.182 164.95 129.034 165.259 128.801 165.509C128.568 165.759 128.26 165.939 127.917 166.026C127.459 166.142 126.971 166.086 126.559 165.868C126.146 165.651 125.843 165.29 125.715 164.866Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.486 164.722C121.39 164.403 121.398 164.065 121.509 163.751C121.62 163.436 121.828 163.16 122.108 162.955C122.388 162.751 122.726 162.629 123.081 162.604C123.436 162.579 123.79 162.653 124.1 162.816C124.409 162.979 124.66 163.223 124.82 163.519C124.98 163.814 125.042 164.147 124.999 164.475C124.955 164.804 124.808 165.113 124.576 165.363C124.344 165.614 124.037 165.794 123.694 165.882C123.236 165.999 122.747 165.942 122.334 165.724C121.92 165.507 121.616 165.147 121.486 164.722Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M110.721 171.048C110.625 170.729 110.633 170.391 110.744 170.077C110.855 169.762 111.063 169.486 111.343 169.282C111.623 169.078 111.961 168.955 112.316 168.931C112.67 168.906 113.025 168.979 113.334 169.142C113.644 169.305 113.895 169.549 114.055 169.845C114.215 170.14 114.277 170.473 114.234 170.801C114.19 171.13 114.043 171.439 113.811 171.689C113.579 171.94 113.272 172.121 112.929 172.209C112.471 172.326 111.981 172.27 111.567 172.053C111.153 171.835 110.849 171.474 110.721 171.048Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.582 170.764C102.486 170.445 102.493 170.107 102.604 169.792C102.715 169.478 102.923 169.201 103.203 168.996C103.483 168.792 103.822 168.67 104.177 168.645C104.532 168.621 104.887 168.695 105.197 168.858C105.506 169.021 105.757 169.266 105.916 169.562C106.076 169.858 106.138 170.192 106.093 170.52C106.049 170.849 105.901 171.158 105.668 171.408C105.435 171.658 105.127 171.838 104.783 171.925C104.326 172.041 103.838 171.984 103.426 171.767C103.013 171.549 102.71 171.189 102.582 170.764Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M109.817 165.116C109.721 164.797 109.728 164.459 109.839 164.144C109.95 163.829 110.158 163.552 110.438 163.348C110.718 163.144 111.057 163.021 111.412 162.997C111.767 162.972 112.122 163.046 112.432 163.209C112.741 163.373 112.992 163.618 113.151 163.914C113.311 164.21 113.373 164.543 113.328 164.872C113.284 165.2 113.136 165.509 112.903 165.759C112.67 166.009 112.362 166.189 112.018 166.276C111.561 166.392 111.073 166.336 110.661 166.118C110.248 165.901 109.945 165.54 109.817 165.116Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.973 169.905C115.876 169.586 115.884 169.248 115.995 168.933C116.106 168.618 116.314 168.341 116.594 168.137C116.874 167.933 117.213 167.811 117.568 167.786C117.923 167.761 118.278 167.835 118.588 167.998C118.897 168.162 119.148 168.407 119.307 168.703C119.467 168.999 119.529 169.332 119.484 169.661C119.44 169.989 119.292 170.298 119.059 170.548C118.825 170.798 118.518 170.978 118.174 171.066C117.717 171.183 117.228 171.127 116.815 170.909C116.402 170.692 116.099 170.33 115.973 169.905Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.663 168.21C111.567 167.891 111.575 167.553 111.686 167.239C111.796 166.925 112.005 166.648 112.285 166.444C112.565 166.24 112.903 166.117 113.258 166.093C113.612 166.068 113.967 166.141 114.276 166.304C114.586 166.467 114.837 166.711 114.997 167.007C115.157 167.302 115.219 167.635 115.176 167.964C115.132 168.292 114.985 168.601 114.753 168.851C114.52 169.102 114.214 169.283 113.871 169.371C113.413 169.488 112.923 169.432 112.509 169.215C112.095 168.997 111.791 168.636 111.663 168.21Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M106.698 170.629C106.602 170.311 106.61 169.972 106.72 169.658C106.831 169.343 107.04 169.066 107.32 168.862C107.6 168.657 107.939 168.535 108.294 168.511C108.649 168.486 109.003 168.56 109.313 168.723C109.623 168.886 109.873 169.131 110.033 169.427C110.193 169.723 110.254 170.057 110.21 170.385C110.166 170.714 110.017 171.023 109.784 171.273C109.551 171.523 109.243 171.703 108.9 171.79C108.443 171.906 107.955 171.849 107.542 171.632C107.13 171.414 106.826 171.054 106.698 170.629Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.172 167.541C107.076 167.223 107.084 166.884 107.194 166.57C107.305 166.255 107.514 165.978 107.794 165.774C108.074 165.569 108.413 165.447 108.768 165.423C109.123 165.398 109.477 165.472 109.787 165.635C110.097 165.798 110.347 166.044 110.507 166.34C110.667 166.635 110.728 166.969 110.684 167.297C110.64 167.626 110.491 167.935 110.258 168.185C110.025 168.435 109.717 168.615 109.374 168.702C108.917 168.818 108.429 168.761 108.016 168.544C107.604 168.326 107.3 167.966 107.172 167.541Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.534 167.681C119.438 167.362 119.446 167.024 119.557 166.71C119.668 166.395 119.876 166.119 120.156 165.914C120.436 165.71 120.774 165.588 121.129 165.563C121.483 165.538 121.838 165.612 122.147 165.775C122.457 165.938 122.708 166.182 122.868 166.478C123.028 166.773 123.09 167.106 123.047 167.434C123.003 167.763 122.856 168.072 122.624 168.322C122.392 168.573 122.085 168.753 121.742 168.841C121.284 168.959 120.794 168.903 120.38 168.685C119.966 168.468 119.662 168.107 119.534 167.681Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.312 167.541C115.215 167.223 115.223 166.884 115.334 166.57C115.444 166.255 115.653 165.978 115.933 165.774C116.213 165.569 116.552 165.447 116.907 165.423C117.262 165.398 117.617 165.472 117.926 165.635C118.236 165.798 118.487 166.044 118.646 166.34C118.806 166.635 118.867 166.969 118.823 167.297C118.779 167.626 118.631 167.935 118.398 168.185C118.164 168.435 117.857 168.615 117.513 168.702C117.056 168.82 116.567 168.764 116.154 168.546C115.741 168.328 115.438 167.967 115.312 167.541Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.2138 169.266C99.1176 168.947 99.1253 168.609 99.236 168.294C99.3466 167.98 99.5552 167.703 99.8354 167.498C100.116 167.294 100.455 167.172 100.809 167.147C101.164 167.123 101.519 167.197 101.829 167.36C102.138 167.523 102.389 167.768 102.548 168.064C102.708 168.36 102.77 168.693 102.725 169.022C102.681 169.351 102.533 169.66 102.3 169.91C102.067 170.16 101.759 170.34 101.415 170.427C100.958 170.544 100.469 170.488 100.056 170.271C99.6436 170.053 99.3405 169.692 99.2138 169.266Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M91.0682 168.981C90.972 168.662 90.9797 168.324 91.0904 168.009C91.201 167.694 91.4097 167.417 91.6898 167.213C91.9699 167.009 92.3089 166.887 92.6638 166.862C93.0187 166.837 93.3734 166.911 93.683 167.075C93.9927 167.238 94.2432 167.483 94.4029 167.779C94.5625 168.075 94.6242 168.408 94.5799 168.737C94.5356 169.065 94.3875 169.374 94.1543 169.625C93.921 169.875 93.6132 170.055 93.2699 170.142C92.8126 170.258 92.3245 170.201 91.9121 169.983C91.4997 169.766 91.1964 169.406 91.0682 168.981Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.3032 163.335C98.2074 163.016 98.2153 162.678 98.326 162.364C98.4367 162.05 98.6452 161.773 98.925 161.569C99.2049 161.365 99.5435 161.242 99.898 161.218C100.253 161.193 100.607 161.266 100.917 161.429C101.226 161.592 101.477 161.836 101.637 162.132C101.797 162.427 101.859 162.76 101.816 163.089C101.772 163.417 101.625 163.726 101.393 163.976C101.161 164.227 100.854 164.408 100.511 164.496C100.053 164.612 99.5641 164.555 99.1507 164.338C98.7373 164.12 98.4327 163.76 98.3032 163.335Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.459 168.123C104.363 167.805 104.371 167.466 104.481 167.152C104.592 166.837 104.801 166.56 105.081 166.356C105.361 166.151 105.7 166.029 106.055 166.005C106.41 165.98 106.764 166.054 107.074 166.217C107.384 166.38 107.634 166.626 107.794 166.922C107.953 167.217 108.015 167.551 107.971 167.879C107.927 168.208 107.778 168.517 107.545 168.767C107.312 169.017 107.004 169.197 106.661 169.284C106.203 169.4 105.715 169.343 105.303 169.126C104.891 168.908 104.587 168.548 104.459 168.123Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M100.156 166.428C100.059 166.109 100.067 165.771 100.178 165.456C100.288 165.142 100.497 164.865 100.777 164.66C101.057 164.456 101.396 164.334 101.751 164.309C102.106 164.285 102.461 164.359 102.77 164.522C103.08 164.685 103.331 164.93 103.49 165.226C103.65 165.522 103.712 165.856 103.667 166.184C103.623 166.513 103.475 166.822 103.242 167.072C103.008 167.322 102.701 167.502 102.357 167.589C101.9 167.705 101.412 167.648 100.999 167.431C100.587 167.213 100.284 166.853 100.156 166.428Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M95.1846 168.837C95.0888 168.518 95.0968 168.18 95.2075 167.866C95.3182 167.552 95.5267 167.275 95.8065 167.071C96.0863 166.867 96.425 166.744 96.7795 166.72C97.134 166.695 97.4885 166.768 97.798 166.931C98.1076 167.094 98.3583 167.338 98.5183 167.634C98.6784 167.929 98.7407 168.262 98.6973 168.591C98.6539 168.919 98.5067 169.228 98.2744 169.478C98.0422 169.729 97.7353 169.91 97.3926 169.998C96.9344 170.114 96.4456 170.057 96.0321 169.84C95.6187 169.622 95.3142 169.262 95.1846 168.837Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M95.6587 165.761C95.5629 165.442 95.5708 165.104 95.6815 164.79C95.7922 164.475 96.0007 164.199 96.2805 163.995C96.5604 163.79 96.899 163.668 97.2535 163.643C97.608 163.619 97.9625 163.692 98.2721 163.855C98.5816 164.018 98.8323 164.262 98.9924 164.558C99.1525 164.853 99.2147 165.186 99.1713 165.514C99.1279 165.843 98.9807 166.152 98.7485 166.402C98.5162 166.653 98.2093 166.833 97.8666 166.921C97.4084 167.039 96.9188 166.983 96.5049 166.766C96.0911 166.548 95.7868 166.187 95.6587 165.761Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.02 165.899C107.924 165.58 107.932 165.242 108.043 164.927C108.153 164.612 108.362 164.335 108.642 164.131C108.922 163.927 109.261 163.805 109.616 163.78C109.971 163.755 110.326 163.829 110.635 163.993C110.945 164.156 111.195 164.401 111.355 164.697C111.515 164.993 111.576 165.326 111.532 165.655C111.488 165.983 111.34 166.292 111.107 166.542C110.873 166.793 110.565 166.973 110.222 167.06C109.765 167.177 109.276 167.121 108.863 166.903C108.45 166.686 108.147 166.324 108.02 165.899Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.798 165.761C103.702 165.442 103.71 165.104 103.821 164.79C103.932 164.475 104.14 164.199 104.42 163.995C104.7 163.79 105.038 163.668 105.393 163.643C105.747 163.619 106.102 163.692 106.411 163.855C106.721 164.018 106.972 164.262 107.132 164.558C107.292 164.853 107.354 165.186 107.311 165.514C107.267 165.843 107.12 166.152 106.888 166.402C106.656 166.653 106.349 166.833 106.006 166.921C105.779 166.981 105.542 166.997 105.308 166.971C105.074 166.945 104.848 166.875 104.643 166.768C104.438 166.66 104.258 166.515 104.113 166.343C103.968 166.17 103.861 165.972 103.798 165.761Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.7772 180.288C98.681 179.969 98.6887 179.63 98.7994 179.316C98.91 179.001 99.1187 178.724 99.3988 178.52C99.6789 178.316 100.018 178.193 100.373 178.169C100.728 178.144 101.082 178.218 101.392 178.381C101.702 178.544 101.952 178.79 102.112 179.086C102.272 179.382 102.333 179.715 102.289 180.044C102.245 180.372 102.096 180.681 101.863 180.931C101.63 181.181 101.322 181.361 100.979 181.448C100.521 181.566 100.033 181.51 99.6198 181.292C99.207 181.074 98.9039 180.713 98.7772 180.288Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M90.6316 180.004C90.5354 179.686 90.5431 179.347 90.6538 179.033C90.7645 178.718 90.9731 178.441 91.2532 178.237C91.5334 178.032 91.8724 177.91 92.2272 177.886C92.5821 177.861 92.9368 177.935 93.2465 178.098C93.5561 178.261 93.8066 178.506 93.9663 178.802C94.126 179.098 94.1876 179.432 94.1433 179.76C94.0991 180.089 93.9509 180.398 93.7177 180.648C93.4844 180.898 93.1766 181.078 92.8333 181.165C92.376 181.281 91.8879 181.224 91.4755 181.007C91.0631 180.789 90.7598 180.429 90.6316 180.004Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.8666 174.356C97.7708 174.038 97.7787 173.7 97.8894 173.385C98.0001 173.071 98.2086 172.794 98.4884 172.59C98.7682 172.386 99.1069 172.264 99.4614 172.239C99.8159 172.214 100.17 172.288 100.48 172.451C100.789 172.613 101.04 172.858 101.2 173.153C101.36 173.449 101.423 173.782 101.379 174.11C101.336 174.438 101.189 174.747 100.956 174.998C100.724 175.248 100.417 175.429 100.074 175.517C99.6163 175.635 99.1266 175.579 98.7128 175.361C98.299 175.144 97.9947 174.782 97.8666 174.356Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.023 179.145C103.926 178.826 103.934 178.488 104.045 178.173C104.155 177.858 104.364 177.581 104.644 177.377C104.924 177.173 105.263 177.051 105.618 177.026C105.973 177.001 106.328 177.075 106.637 177.239C106.947 177.402 107.198 177.647 107.357 177.943C107.517 178.239 107.578 178.572 107.534 178.901C107.49 179.23 107.342 179.538 107.109 179.789C106.875 180.039 106.568 180.219 106.224 180.306C105.767 180.422 105.279 180.365 104.866 180.147C104.454 179.93 104.151 179.57 104.023 179.145Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.719 177.45C99.6228 177.131 99.6305 176.793 99.7411 176.478C99.8518 176.163 100.06 175.886 100.341 175.682C100.621 175.478 100.96 175.355 101.315 175.331C101.669 175.306 102.024 175.38 102.334 175.543C102.643 175.707 102.894 175.952 103.054 176.248C103.213 176.544 103.275 176.877 103.231 177.206C103.186 177.534 103.038 177.843 102.805 178.093C102.572 178.343 102.264 178.523 101.921 178.61C101.463 178.728 100.974 178.672 100.562 178.454C100.149 178.236 99.8457 177.875 99.719 177.45Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.7481 179.858C94.6523 179.54 94.6602 179.202 94.7709 178.887C94.8816 178.573 95.0901 178.296 95.3699 178.092C95.6497 177.888 95.9884 177.766 96.3429 177.741C96.6974 177.716 97.0519 177.79 97.3614 177.953C97.671 178.115 97.9217 178.36 98.0818 178.655C98.2418 178.951 98.3041 179.284 98.2607 179.612C98.2173 179.94 98.0701 180.249 97.8379 180.5C97.6056 180.75 97.2987 180.931 96.956 181.019C96.4978 181.137 96.0081 181.081 95.5943 180.863C95.1805 180.646 94.8762 180.284 94.7481 179.858Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M95.2221 176.782C95.1263 176.464 95.1342 176.126 95.2449 175.811C95.3556 175.497 95.5641 175.22 95.8439 175.016C96.1238 174.812 96.4624 174.69 96.8169 174.665C97.1714 174.64 97.5259 174.714 97.8355 174.876C98.145 175.039 98.3957 175.284 98.5558 175.579C98.7159 175.875 98.7781 176.207 98.7347 176.536C98.6913 176.864 98.5441 177.173 98.3119 177.424C98.0796 177.674 97.7727 177.855 97.43 177.943C96.9718 178.061 96.4822 178.005 96.0683 177.787C95.6545 177.569 95.3502 177.208 95.2221 176.782Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M107.59 176.922C107.494 176.604 107.502 176.265 107.612 175.951C107.723 175.636 107.932 175.359 108.212 175.155C108.492 174.95 108.831 174.828 109.186 174.803C109.541 174.779 109.895 174.853 110.205 175.016C110.515 175.179 110.765 175.424 110.925 175.72C111.084 176.016 111.146 176.35 111.102 176.678C111.058 177.007 110.909 177.316 110.676 177.566C110.443 177.816 110.135 177.996 109.792 178.083C109.334 178.201 108.846 178.144 108.433 177.927C108.02 177.709 107.717 177.348 107.59 176.922Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.361 176.782C103.266 176.464 103.274 176.126 103.384 175.811C103.495 175.497 103.703 175.22 103.983 175.016C104.263 174.812 104.602 174.69 104.956 174.665C105.311 174.64 105.665 174.714 105.975 174.876C106.284 175.039 106.535 175.284 106.695 175.579C106.855 175.875 106.917 176.207 106.874 176.536C106.831 176.864 106.683 177.173 106.451 177.424C106.219 177.674 105.912 177.855 105.569 177.943C105.111 178.061 104.621 178.005 104.208 177.787C103.794 177.569 103.489 177.208 103.361 176.782Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.326 171.251C99.6083 171.048 99.9491 170.928 100.305 170.906C100.661 170.884 101.015 170.961 101.324 171.127C101.633 171.294 101.881 171.542 102.038 171.84C102.194 172.138 102.252 172.473 102.203 172.802C102.155 173.13 102.002 173.438 101.765 173.686C101.528 173.934 101.216 174.11 100.871 174.193C100.526 174.275 100.162 174.261 99.8255 174.15C99.4893 174.04 99.196 173.838 98.983 173.572C98.7001 173.221 98.5774 172.781 98.6416 172.346C98.7059 171.911 98.9518 171.518 99.326 171.251Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M96.5568 164.118C96.8391 163.915 97.1798 163.795 97.5356 163.773C97.8914 163.751 98.2462 163.828 98.5548 163.995C98.8633 164.161 99.1117 164.409 99.2683 164.707C99.4248 165.005 99.4825 165.34 99.4339 165.669C99.3853 165.998 99.2327 166.305 98.9955 166.553C98.7583 166.801 98.4472 166.977 98.1018 167.06C97.7565 167.143 97.3925 167.128 97.0563 167.017C96.72 166.907 96.4268 166.706 96.2138 166.439C95.9324 166.088 95.8107 165.648 95.8749 165.213C95.9391 164.779 96.1839 164.386 96.5568 164.118Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.902 168.245C105.184 168.042 105.525 167.922 105.881 167.9C106.237 167.878 106.591 167.955 106.9 168.122C107.208 168.288 107.457 168.536 107.613 168.834C107.77 169.132 107.828 169.467 107.779 169.796C107.73 170.124 107.578 170.432 107.341 170.68C107.103 170.928 106.792 171.104 106.447 171.187C106.102 171.27 105.738 171.255 105.401 171.144C105.065 171.034 104.772 170.833 104.559 170.566C104.276 170.215 104.153 169.775 104.218 169.34C104.282 168.905 104.528 168.512 104.902 168.245Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.407 175.348C102.689 175.146 103.03 175.026 103.386 175.004C103.742 174.982 104.097 175.059 104.405 175.225C104.714 175.391 104.962 175.639 105.119 175.938C105.275 176.236 105.333 176.571 105.284 176.899C105.236 177.228 105.083 177.536 104.846 177.784C104.609 178.031 104.298 178.208 103.952 178.29C103.607 178.373 103.243 178.358 102.907 178.248C102.57 178.137 102.277 177.936 102.064 177.67C101.781 177.319 101.658 176.878 101.723 176.444C101.787 176.009 102.033 175.615 102.407 175.348Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.507 171.001C102.789 170.798 103.13 170.678 103.486 170.656C103.842 170.634 104.196 170.711 104.505 170.877C104.813 171.044 105.062 171.292 105.218 171.59C105.375 171.888 105.433 172.223 105.384 172.552C105.335 172.88 105.183 173.188 104.946 173.436C104.708 173.684 104.397 173.86 104.052 173.943C103.707 174.025 103.343 174.011 103.006 173.9C102.67 173.79 102.377 173.588 102.164 173.322C101.881 172.971 101.758 172.531 101.823 172.096C101.887 171.661 102.133 171.268 102.507 171.001Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.2408 167.617C98.5225 167.413 98.8629 167.292 99.2187 167.27C99.5745 167.247 99.9296 167.323 100.239 167.489C100.548 167.654 100.797 167.902 100.954 168.199C101.112 168.497 101.17 168.832 101.122 169.161C101.075 169.49 100.923 169.798 100.686 170.046C100.45 170.295 100.139 170.472 99.7938 170.555C99.4486 170.639 99.0845 170.624 98.748 170.515C98.4114 170.405 98.1176 170.204 97.904 169.938C97.622 169.588 97.4992 169.148 97.5622 168.714C97.6252 168.279 97.8689 167.886 98.2408 167.617Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M101.484 166.876C101.766 166.673 102.107 166.553 102.463 166.531C102.819 166.509 103.173 166.586 103.482 166.752C103.791 166.919 104.039 167.167 104.196 167.465C104.352 167.763 104.41 168.098 104.361 168.427C104.313 168.755 104.16 169.063 103.923 169.311C103.686 169.559 103.374 169.735 103.029 169.818C102.684 169.9 102.32 169.886 101.984 169.775C101.647 169.665 101.354 169.463 101.141 169.197C100.858 168.846 100.735 168.406 100.8 167.971C100.864 167.536 101.11 167.143 101.484 166.876Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M105.981 177.595C106.263 177.392 106.603 177.271 106.959 177.248C107.315 177.225 107.67 177.302 107.979 177.467C108.288 177.633 108.537 177.88 108.694 178.178C108.852 178.476 108.91 178.81 108.863 179.139C108.815 179.468 108.663 179.776 108.426 180.025C108.19 180.273 107.879 180.45 107.534 180.534C107.189 180.617 106.825 180.603 106.488 180.493C106.152 180.383 105.858 180.183 105.644 179.917C105.362 179.566 105.239 179.126 105.302 178.692C105.365 178.258 105.609 177.864 105.981 177.595Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.54 173.891C104.822 173.689 105.163 173.569 105.519 173.547C105.875 173.525 106.23 173.602 106.538 173.768C106.847 173.934 107.095 174.182 107.252 174.481C107.408 174.779 107.466 175.113 107.417 175.442C107.369 175.771 107.216 176.079 106.979 176.327C106.742 176.574 106.431 176.751 106.085 176.833C105.74 176.916 105.376 176.901 105.04 176.791C104.703 176.68 104.41 176.479 104.197 176.213C103.916 175.861 103.794 175.421 103.858 174.987C103.922 174.553 104.167 174.159 104.54 173.891Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M121.062 168.663C121.344 168.46 121.685 168.34 122.041 168.318C122.397 168.296 122.751 168.373 123.06 168.54C123.369 168.706 123.617 168.954 123.774 169.252C123.93 169.55 123.988 169.885 123.939 170.214C123.891 170.542 123.738 170.85 123.501 171.098C123.264 171.346 122.952 171.522 122.607 171.605C122.262 171.688 121.898 171.673 121.562 171.562C121.225 171.452 120.932 171.251 120.719 170.984C120.436 170.633 120.313 170.193 120.378 169.758C120.442 169.323 120.688 168.93 121.062 168.663Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.293 161.53C118.575 161.327 118.916 161.207 119.272 161.185C119.627 161.163 119.982 161.24 120.291 161.407C120.599 161.573 120.848 161.821 121.004 162.119C121.161 162.417 121.219 162.752 121.17 163.081C121.121 163.41 120.969 163.717 120.732 163.965C120.494 164.213 120.183 164.389 119.838 164.472C119.493 164.555 119.129 164.54 118.792 164.429C118.456 164.319 118.163 164.118 117.95 163.852C117.668 163.5 117.547 163.06 117.611 162.626C117.675 162.191 117.92 161.798 118.293 161.53Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M126.632 165.655C126.914 165.452 127.255 165.332 127.611 165.31C127.966 165.288 128.321 165.365 128.63 165.532C128.938 165.698 129.187 165.946 129.343 166.244C129.5 166.542 129.557 166.877 129.509 167.206C129.46 167.535 129.308 167.842 129.07 168.09C128.833 168.338 128.522 168.514 128.177 168.597C127.831 168.68 127.467 168.665 127.131 168.554C126.795 168.444 126.502 168.243 126.289 167.977C126.006 167.626 125.883 167.185 125.947 166.75C126.012 166.315 126.257 165.922 126.632 165.655Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M111.083 166.242C111.365 166.038 111.705 165.917 112.061 165.895C112.417 165.872 112.772 165.948 113.081 166.114C113.39 166.279 113.639 166.527 113.796 166.824C113.954 167.122 114.012 167.457 113.965 167.786C113.917 168.115 113.765 168.423 113.528 168.671C113.292 168.92 112.981 169.097 112.636 169.18C112.291 169.263 111.927 169.249 111.59 169.14C111.253 169.03 110.96 168.829 110.746 168.563C110.464 168.213 110.341 167.773 110.404 167.339C110.467 166.904 110.711 166.511 111.083 166.242Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M129.601 169.561C129.883 169.358 130.224 169.238 130.579 169.217C130.935 169.195 131.29 169.272 131.599 169.438C131.907 169.604 132.155 169.852 132.312 170.151C132.469 170.449 132.526 170.783 132.478 171.112C132.429 171.441 132.276 171.749 132.039 171.996C131.802 172.244 131.491 172.421 131.146 172.503C130.8 172.586 130.436 172.571 130.1 172.461C129.764 172.35 129.471 172.149 129.258 171.883C128.976 171.531 128.854 171.091 128.919 170.657C128.983 170.223 129.228 169.829 129.601 169.561Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.983 165.03C120.265 164.827 120.606 164.707 120.962 164.685C121.318 164.663 121.672 164.74 121.981 164.907C122.29 165.073 122.538 165.321 122.695 165.619C122.851 165.917 122.909 166.252 122.86 166.581C122.812 166.91 122.659 167.217 122.422 167.465C122.185 167.713 121.873 167.889 121.528 167.972C121.183 168.055 120.819 168.04 120.483 167.929C120.146 167.819 119.853 167.618 119.64 167.352C119.357 167.001 119.234 166.56 119.299 166.125C119.363 165.69 119.609 165.297 119.983 165.03Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.22 164.286C123.502 164.083 123.843 163.963 124.199 163.941C124.555 163.919 124.909 163.996 125.218 164.163C125.527 164.329 125.775 164.577 125.932 164.875C126.088 165.173 126.146 165.508 126.097 165.837C126.049 166.165 125.896 166.473 125.659 166.721C125.422 166.969 125.11 167.145 124.765 167.228C124.42 167.311 124.056 167.296 123.72 167.185C123.383 167.075 123.09 166.874 122.877 166.607C122.594 166.256 122.471 165.816 122.536 165.381C122.6 164.946 122.846 164.553 123.22 164.286Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.638 168.483C114.92 168.28 115.261 168.16 115.617 168.138C115.973 168.116 116.327 168.194 116.636 168.36C116.944 168.526 117.193 168.774 117.349 169.072C117.506 169.371 117.564 169.705 117.515 170.034C117.466 170.363 117.314 170.671 117.077 170.918C116.839 171.166 116.528 171.343 116.183 171.425C115.838 171.508 115.474 171.493 115.137 171.382C114.801 171.272 114.508 171.071 114.295 170.805C114.014 170.453 113.892 170.013 113.956 169.579C114.02 169.144 114.265 168.751 114.638 168.483Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M113.191 164.786C113.473 164.583 113.814 164.463 114.17 164.441C114.526 164.419 114.88 164.496 115.189 164.663C115.497 164.829 115.746 165.077 115.902 165.375C116.059 165.673 116.117 166.008 116.068 166.337C116.019 166.666 115.867 166.973 115.63 167.221C115.392 167.469 115.081 167.645 114.736 167.728C114.391 167.811 114.027 167.796 113.69 167.685C113.354 167.575 113.061 167.374 112.848 167.107C112.565 166.756 112.442 166.316 112.507 165.881C112.571 165.446 112.817 165.053 113.191 164.786Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.694 183.631C123.942 183.394 124.259 183.23 124.606 183.16C124.954 183.091 125.316 183.119 125.646 183.241C125.976 183.363 126.26 183.573 126.462 183.845C126.664 184.118 126.774 184.439 126.78 184.77C126.785 185.101 126.685 185.425 126.492 185.703C126.298 185.981 126.021 186.199 125.695 186.33C125.369 186.46 125.008 186.498 124.658 186.439C124.309 186.379 123.986 186.224 123.732 185.993C123.39 185.684 123.195 185.262 123.188 184.819C123.181 184.376 123.363 183.949 123.694 183.631Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.104 177.014C122.351 176.776 122.669 176.613 123.016 176.543C123.363 176.474 123.725 176.502 124.056 176.624C124.386 176.746 124.67 176.956 124.872 177.228C125.073 177.5 125.184 177.822 125.189 178.153C125.194 178.483 125.094 178.808 124.901 179.086C124.708 179.363 124.431 179.581 124.104 179.712C123.778 179.843 123.417 179.881 123.068 179.821C122.718 179.761 122.396 179.606 122.141 179.376C121.8 179.067 121.604 178.645 121.597 178.202C121.59 177.759 121.772 177.332 122.104 177.014Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.736 182.645C115.983 182.407 116.301 182.243 116.648 182.174C116.995 182.105 117.357 182.133 117.687 182.255C118.018 182.376 118.302 182.587 118.504 182.859C118.705 183.131 118.816 183.453 118.821 183.784C118.826 184.114 118.726 184.439 118.533 184.717C118.34 184.994 118.063 185.212 117.736 185.343C117.41 185.474 117.049 185.512 116.7 185.452C116.35 185.392 116.028 185.237 115.773 185.007C115.433 184.697 115.239 184.275 115.232 183.833C115.225 183.39 115.406 182.963 115.736 182.645Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.427 181.384C127.431 180.461 126.629 179.71 125.637 179.706C124.645 179.703 123.838 180.448 123.834 181.371C123.83 182.295 124.632 183.046 125.624 183.049C126.616 183.053 127.423 182.307 127.427 181.384Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.418 179.022C127.664 178.785 127.981 178.62 128.329 178.55C128.676 178.48 129.038 178.507 129.368 178.628C129.699 178.749 129.984 178.959 130.186 179.231C130.389 179.502 130.5 179.824 130.506 180.155C130.512 180.485 130.413 180.81 130.22 181.088C130.028 181.366 129.751 181.585 129.426 181.717C129.1 181.848 128.739 181.887 128.389 181.828C128.04 181.769 127.717 181.615 127.461 181.385C127.121 181.076 126.925 180.654 126.917 180.212C126.909 179.77 127.089 179.342 127.418 179.022Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.609 184.367C119.856 184.13 120.174 183.966 120.521 183.897C120.869 183.827 121.23 183.855 121.561 183.977C121.891 184.099 122.175 184.309 122.377 184.582C122.579 184.854 122.689 185.176 122.694 185.506C122.7 185.837 122.599 186.162 122.406 186.439C122.213 186.717 121.936 186.935 121.61 187.066C121.283 187.197 120.923 187.235 120.573 187.175C120.224 187.115 119.901 186.96 119.646 186.729C119.305 186.421 119.109 185.998 119.102 185.556C119.095 185.113 119.277 184.685 119.609 184.367Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M117.582 180.915C117.829 180.677 118.146 180.513 118.493 180.442C118.84 180.372 119.202 180.399 119.533 180.521C119.863 180.642 120.148 180.852 120.35 181.123C120.553 181.395 120.664 181.716 120.67 182.047C120.676 182.378 120.577 182.703 120.385 182.981C120.192 183.259 119.916 183.477 119.59 183.609C119.264 183.741 118.903 183.78 118.553 183.721C118.204 183.661 117.881 183.507 117.625 183.277C117.283 182.969 117.087 182.548 117.078 182.105C117.07 181.662 117.251 181.234 117.582 180.915Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.457 171.907C99.7038 171.669 100.021 171.505 100.368 171.435C100.715 171.364 101.077 171.392 101.408 171.513C101.738 171.634 102.023 171.844 102.226 172.115C102.428 172.387 102.539 172.709 102.546 173.039C102.552 173.37 102.452 173.695 102.26 173.973C102.068 174.251 101.791 174.47 101.465 174.601C101.139 174.733 100.778 174.772 100.429 174.713C100.079 174.654 99.7561 174.499 99.5007 174.269C99.16 173.961 98.9643 173.539 98.9561 173.097C98.9479 172.654 99.128 172.227 99.457 171.907Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.8666 165.262C98.1136 165.026 98.43 164.864 98.776 164.796C99.1219 164.728 99.4817 164.756 99.8102 164.878C100.139 165 100.421 165.21 100.622 165.481C100.822 165.752 100.932 166.072 100.937 166.401C100.942 166.73 100.843 167.053 100.651 167.329C100.459 167.606 100.183 167.823 99.8589 167.954C99.5345 168.085 99.1757 168.123 98.8277 168.065C98.4798 168.006 98.1584 167.852 97.904 167.624C97.5642 167.314 97.3696 166.892 97.3626 166.45C97.3556 166.008 97.5367 165.58 97.8666 165.262Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M91.4986 170.921C91.7453 170.683 92.0624 170.518 92.4097 170.448C92.7569 170.378 93.1187 170.405 93.4494 170.526C93.78 170.648 94.0646 170.857 94.2671 171.129C94.4696 171.401 94.581 171.722 94.5871 172.053C94.5932 172.384 94.4938 172.709 94.3014 172.987C94.1091 173.265 93.8324 173.483 93.5065 173.615C93.1805 173.747 92.8199 173.785 92.4703 173.726C92.1206 173.667 91.7976 173.513 91.5422 173.283C91.2001 172.975 91.0033 172.553 90.9951 172.11C90.9869 171.668 91.168 171.24 91.4986 170.921Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M100.099 168.483C100.346 168.245 100.663 168.081 101.011 168.011C101.358 167.941 101.72 167.968 102.05 168.089C102.381 168.21 102.665 168.42 102.868 168.692C103.07 168.963 103.182 169.285 103.188 169.615C103.194 169.946 103.095 170.271 102.902 170.549C102.71 170.827 102.433 171.046 102.107 171.178C101.781 171.309 101.421 171.348 101.071 171.289C100.721 171.23 100.398 171.076 100.143 170.846C99.8009 170.538 99.6041 170.116 99.5959 169.673C99.5878 169.23 99.7688 168.802 100.099 168.483Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.187 167.293C103.434 167.056 103.752 166.892 104.099 166.822C104.447 166.753 104.808 166.781 105.139 166.903C105.469 167.025 105.753 167.235 105.955 167.507C106.157 167.78 106.267 168.101 106.272 168.432C106.278 168.763 106.177 169.087 105.984 169.365C105.791 169.643 105.514 169.861 105.188 169.992C104.861 170.123 104.501 170.161 104.151 170.101C103.802 170.041 103.479 169.886 103.224 169.655C102.883 169.346 102.687 168.924 102.68 168.481C102.673 168.038 102.855 167.611 103.187 167.293Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M95.3718 172.644C95.6185 172.406 95.9356 172.241 96.2829 172.171C96.6301 172.101 96.9919 172.128 97.3226 172.249C97.6532 172.37 97.9378 172.58 98.1403 172.852C98.3428 173.123 98.4542 173.445 98.4603 173.776C98.4664 174.106 98.367 174.431 98.1746 174.709C97.9823 174.987 97.7056 175.206 97.3797 175.338C97.0537 175.469 96.6931 175.508 96.3434 175.449C95.9938 175.39 95.6708 175.236 95.4154 175.006C95.0733 174.698 94.8765 174.276 94.8683 173.833C94.8601 173.39 95.0412 172.962 95.3718 172.644Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M93.351 169.191C93.598 168.956 93.9145 168.794 94.2604 168.726C94.6063 168.657 94.9661 168.686 95.2946 168.808C95.623 168.93 95.9054 169.139 96.1059 169.41C96.3065 169.681 96.4163 170.002 96.4215 170.331C96.4267 170.66 96.3271 170.983 96.1352 171.259C95.9433 171.536 95.6678 171.753 95.3433 171.884C95.0189 172.014 94.6601 172.053 94.3121 171.994C93.9642 171.935 93.6428 171.782 93.3884 171.554C93.0486 171.244 92.854 170.822 92.847 170.38C92.84 169.937 93.0211 169.51 93.351 169.191Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M45.6502 95.6133C45.6502 95.6133 38.7894 95.6423 33.0826 107.157C27.3757 118.673 26.727 120.315 26.2218 128.952C25.7166 137.588 27.7624 140.043 26.8019 142.301C25.8414 144.558 23.9079 145.092 25.199 147.414C26.101 148.935 27.5396 150.12 29.2717 150.769C29.7769 150.989 31.8851 152.127 31.8851 152.127C31.8851 152.127 37.2052 146.213 43.1117 144.796C49.0182 143.38 54.1201 143.92 54.9621 143.52C55.8041 143.119 58.8602 141.982 58.9662 138.557C59.0723 135.133 57.8061 129.95 58.1991 128.777C58.592 127.605 61.224 124.924 61.3613 123.444C61.4985 121.963 60.301 120.275 59.933 118.568C59.5372 116.963 59.48 115.301 59.7646 113.675C60.0577 111.493 59.671 104.197 57.5691 99.0202" fill="#38BCA7"/>
<path d="M45.6502 95.6133C45.6502 95.6133 38.7894 95.6423 33.0826 107.157C27.3757 118.673 26.727 120.315 26.2218 128.952C25.7166 137.588 27.7624 140.043 26.8019 142.301C25.8414 144.558 23.9079 145.092 25.199 147.414C26.101 148.935 27.5396 150.12 29.2717 150.769C29.7769 150.989 31.8851 152.127 31.8851 152.127C31.8851 152.127 37.2052 146.213 43.1117 144.796C49.0182 143.38 54.1201 143.92 54.9621 143.52C55.8041 143.119 58.8602 141.982 58.9662 138.557C59.0723 135.133 57.8061 129.95 58.1991 128.777C58.592 127.605 61.224 124.924 61.3613 123.444C61.4985 121.963 60.301 120.275 59.933 118.568C59.5372 116.963 59.48 115.301 59.7646 113.675C60.0577 111.493 59.671 104.197 57.5691 99.0202" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M34.1179 141.192C38.8456 137.756 46.2739 133.148 51.3758 133.856" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M29.621 144.726C29.621 144.726 30.4755 143.978 31.8913 142.875" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M55.4049 128.592C55.4049 128.592 45.0327 128.882 41.3154 129.683" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M57.5005 144.269C56.7763 143.234 55.7941 142.378 54.6377 141.773C52.9475 141.024 46.2801 140.391 39.6689 144.147C33.0576 147.902 30.7063 150.531 29.964 153.033C29.2218 155.534 35.1345 161.234 35.989 162.122C36.8435 163.01 40.1678 164.188 42.4568 163.985C43.2973 163.913 44.1108 163.671 44.841 163.277C45.5712 162.884 46.2006 162.347 46.6855 161.704C47.1595 161.001 41.989 160.218 43.7479 155.319C45.5067 150.421 51.856 146.132 53.7271 145.731C55.0449 145.39 56.3124 144.899 57.5005 144.269Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M134.621 160.467C134.493 162.013 134.51 163.567 134.671 165.11C134.958 166.683 134.74 168.877 134.085 169.434C133.43 169.991 131.11 169.719 130.018 170.253C128.927 170.787 128.21 169.87 127.979 169.428C127.748 168.987 128.253 164.286 129.226 163.108C130.199 161.93 133.374 158.592 133.854 158.894C134.334 159.196 134.621 160.467 134.621 160.467Z" fill="white" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M130.542 166.91C130.742 167.615 131.136 168.259 131.684 168.779" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M132.557 162.94C132.501 163.77 132.344 164.592 132.089 165.389C131.777 165.969 129.058 167.583 129.058 167.583C128.726 168.562 128.248 169.492 127.636 170.346C126.819 171.315 124.143 171.889 123.956 172.255C123.769 172.621 122.646 174.182 120.838 174.269C119.029 174.356 115.181 172.928 113.553 171.466C111.925 170.003 114.083 169.191 114.083 169.191L112.386 166.956L104.734 161.773C104.734 161.773 101.222 162.127 91.0121 162.887C80.8021 163.648 46.7853 163.706 44.278 163.033C41.7707 162.359 35.6959 159.359 39.2884 153.578C42.8809 147.797 50.3279 144.466 52.6855 143.891C55.0431 143.316 58.9662 144.332 58.9662 144.332L107.652 152.73C107.652 152.73 114.008 154.663 117.158 154.669C120.307 154.675 128.852 156.567 131.547 156.88C134.241 157.194 134.478 159.515 134.621 160.467C134.699 161.074 134.554 161.688 134.21 162.208" fill="white"/>
<path d="M132.557 162.94C132.501 163.77 132.344 164.592 132.089 165.389C131.777 165.969 129.058 167.583 129.058 167.583C128.726 168.562 128.248 169.492 127.636 170.346C126.819 171.315 124.143 171.889 123.956 172.255C123.769 172.621 122.646 174.182 120.838 174.269C119.029 174.356 115.181 172.928 113.553 171.466C111.925 170.003 114.083 169.191 114.083 169.191L112.386 166.956L104.734 161.773C104.734 161.773 101.222 162.127 91.0121 162.887C80.8021 163.648 46.7853 163.706 44.278 163.033C41.7707 162.359 35.6959 159.359 39.2884 153.578C42.8809 147.797 50.3279 144.466 52.6855 143.891C55.0431 143.316 58.9662 144.332 58.9662 144.332L107.652 152.73C107.652 152.73 114.008 154.663 117.158 154.669C120.307 154.675 128.852 156.567 131.547 156.88C134.241 157.194 134.478 159.515 134.621 160.467C134.699 161.074 134.554 161.688 134.21 162.208" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M114.102 169.191C114.102 169.191 115.929 170.497 116.677 171.171C117.426 171.844 118.143 172.761 119.571 172.633" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M147.707 122.375C147.707 122.375 173.996 130.013 203.042 147.855" stroke="#263238"strokeWidth="3" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M151.056 121.522C150.878 121.809 150.589 122.022 150.248 122.117C149.907 122.213 149.539 122.183 149.221 122.035C148.902 121.887 148.656 121.631 148.534 121.32C148.411 121.009 148.421 120.666 148.561 120.361C148.636 120.198 148.746 120.051 148.884 119.929C149.022 119.807 149.186 119.713 149.365 119.652C149.543 119.591 149.734 119.564 149.924 119.574C150.114 119.584 150.3 119.63 150.47 119.71C150.641 119.789 150.792 119.9 150.914 120.035C151.037 120.171 151.129 120.328 151.183 120.498C151.238 120.668 151.255 120.846 151.233 121.022C151.211 121.198 151.151 121.368 151.056 121.522Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M153.551 126.055C153.428 126.287 153.233 126.48 152.992 126.609C152.751 126.739 152.474 126.798 152.197 126.781C151.919 126.763 151.653 126.669 151.433 126.511C151.213 126.352 151.048 126.136 150.96 125.891C150.872 125.645 150.865 125.381 150.939 125.131C151.013 124.881 151.165 124.658 151.376 124.489C151.586 124.32 151.846 124.213 152.123 124.181C152.399 124.15 152.679 124.196 152.927 124.313C153.256 124.469 153.506 124.739 153.623 125.065C153.74 125.391 153.714 125.746 153.551 126.055Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.152 131.744C168.029 131.976 167.834 132.169 167.593 132.299C167.352 132.428 167.075 132.488 166.797 132.47C166.52 132.453 166.254 132.359 166.034 132.2C165.814 132.042 165.649 131.826 165.561 131.58C165.473 131.334 165.466 131.07 165.54 130.82C165.614 130.571 165.766 130.347 165.976 130.178C166.187 130.009 166.447 129.902 166.724 129.871C167 129.84 167.28 129.886 167.528 130.003C167.857 130.158 168.107 130.428 168.224 130.754C168.341 131.08 168.315 131.436 168.152 131.744Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.906 129.625C162.783 129.857 162.589 130.05 162.348 130.18C162.107 130.309 161.83 130.369 161.552 130.351C161.274 130.333 161.009 130.239 160.789 130.081C160.568 129.923 160.404 129.707 160.316 129.461C160.228 129.215 160.22 128.951 160.294 128.701C160.368 128.452 160.52 128.228 160.731 128.059C160.942 127.89 161.202 127.783 161.478 127.752C161.754 127.72 162.034 127.766 162.283 127.884C162.612 128.039 162.862 128.309 162.979 128.635C163.095 128.961 163.069 129.317 162.906 129.625Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.43 137.391C181.307 137.622 181.113 137.813 180.873 137.94C180.633 138.068 180.358 138.127 180.082 138.109C179.806 138.091 179.542 137.997 179.324 137.839C179.105 137.682 178.942 137.467 178.854 137.223C178.767 136.979 178.759 136.716 178.832 136.468C178.905 136.22 179.056 135.997 179.265 135.829C179.474 135.66 179.732 135.554 180.007 135.522C180.281 135.49 180.559 135.535 180.807 135.65C180.971 135.726 181.117 135.831 181.237 135.96C181.358 136.089 181.449 136.238 181.507 136.4C181.565 136.562 181.588 136.733 181.575 136.903C181.562 137.073 181.513 137.239 181.43 137.391Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M147.089 122.69C146.966 122.92 146.772 123.112 146.532 123.239C146.292 123.367 146.017 123.426 145.741 123.408C145.465 123.39 145.201 123.296 144.982 123.138C144.764 122.98 144.601 122.766 144.513 122.522C144.426 122.278 144.418 122.015 144.491 121.767C144.564 121.519 144.715 121.296 144.924 121.128C145.133 120.959 145.391 120.852 145.666 120.821C145.94 120.789 146.218 120.833 146.465 120.949C146.63 121.025 146.776 121.13 146.896 121.259C147.017 121.387 147.108 121.537 147.166 121.699C147.224 121.861 147.247 122.031 147.234 122.202C147.221 122.372 147.172 122.538 147.089 122.69Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M159.245 125.266C159.177 125.439 159.071 125.596 158.933 125.727C158.795 125.859 158.629 125.962 158.445 126.03C158.262 126.097 158.065 126.128 157.868 126.12C157.67 126.112 157.477 126.066 157.3 125.984C157.124 125.902 156.968 125.786 156.843 125.644C156.718 125.501 156.626 125.336 156.574 125.159C156.522 124.982 156.511 124.796 156.541 124.615C156.572 124.433 156.643 124.26 156.75 124.105C156.938 123.836 157.226 123.64 157.559 123.556C157.892 123.471 158.247 123.504 158.556 123.648C158.864 123.791 159.105 124.036 159.233 124.334C159.36 124.633 159.364 124.965 159.245 125.266Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M163.218 126.456C163.095 126.686 162.901 126.877 162.661 127.005C162.421 127.133 162.145 127.191 161.87 127.173C161.594 127.155 161.33 127.061 161.111 126.904C160.893 126.746 160.73 126.532 160.642 126.287C160.555 126.043 160.547 125.78 160.62 125.532C160.693 125.284 160.844 125.062 161.053 124.893C161.262 124.725 161.52 124.618 161.795 124.586C162.069 124.554 162.347 124.599 162.594 124.714C162.759 124.79 162.905 124.896 163.025 125.024C163.146 125.153 163.237 125.303 163.295 125.464C163.353 125.626 163.376 125.797 163.363 125.967C163.35 126.137 163.301 126.303 163.218 126.456Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M158.54 128.047C158.417 128.279 158.223 128.472 157.982 128.601C157.741 128.731 157.464 128.79 157.186 128.773C156.909 128.755 156.643 128.661 156.423 128.503C156.203 128.344 156.038 128.129 155.95 127.883C155.862 127.637 155.855 127.373 155.928 127.123C156.002 126.873 156.154 126.65 156.365 126.481C156.576 126.312 156.836 126.205 157.112 126.174C157.389 126.142 157.669 126.188 157.917 126.306C158.247 126.46 158.498 126.73 158.615 127.056C158.732 127.383 158.705 127.739 158.54 128.047Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.865 130.902C173.742 131.135 173.547 131.328 173.306 131.457C173.065 131.586 172.788 131.646 172.511 131.628C172.233 131.611 171.967 131.517 171.747 131.358C171.527 131.2 171.362 130.984 171.274 130.738C171.186 130.493 171.179 130.228 171.253 129.979C171.327 129.729 171.479 129.505 171.69 129.336C171.9 129.167 172.16 129.06 172.437 129.029C172.713 128.998 172.993 129.044 173.241 129.161C173.57 129.317 173.82 129.587 173.937 129.912C174.054 130.238 174.028 130.594 173.865 130.902Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M165.582 130.803C165.394 131.073 165.106 131.268 164.773 131.353C164.44 131.437 164.085 131.405 163.777 131.261C163.468 131.117 163.227 130.873 163.1 130.574C162.973 130.276 162.968 129.944 163.087 129.642C163.155 129.47 163.262 129.313 163.4 129.181C163.537 129.05 163.704 128.947 163.887 128.879C164.071 128.811 164.268 128.78 164.465 128.788C164.662 128.796 164.855 128.843 165.032 128.925C165.208 129.007 165.364 129.123 165.49 129.265C165.615 129.407 165.706 129.572 165.758 129.75C165.81 129.927 165.821 130.112 165.791 130.294C165.761 130.475 165.689 130.649 165.582 130.803Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.994 132.51C177.926 132.683 177.819 132.84 177.681 132.972C177.543 133.103 177.377 133.206 177.194 133.274C177.01 133.342 176.813 133.372 176.616 133.364C176.419 133.357 176.226 133.31 176.049 133.228C175.872 133.146 175.716 133.03 175.591 132.888C175.466 132.746 175.375 132.58 175.323 132.403C175.271 132.226 175.26 132.04 175.29 131.859C175.32 131.677 175.391 131.504 175.499 131.35C175.687 131.08 175.974 130.884 176.308 130.8C176.641 130.715 176.995 130.748 177.304 130.892C177.613 131.035 177.854 131.28 177.981 131.579C178.108 131.877 178.113 132.209 177.994 132.51Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M169.673 128.842C169.551 129.074 169.356 129.267 169.115 129.396C168.874 129.526 168.597 129.585 168.319 129.568C168.042 129.55 167.776 129.456 167.556 129.298C167.336 129.139 167.171 128.924 167.083 128.678C166.995 128.432 166.988 128.168 167.062 127.918C167.135 127.668 167.287 127.445 167.498 127.276C167.709 127.107 167.969 127 168.245 126.968C168.522 126.937 168.802 126.983 169.05 127.1C169.379 127.256 169.629 127.526 169.746 127.852C169.863 128.178 169.837 128.533 169.673 128.842Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M157.087 123.727C157.019 123.9 156.913 124.057 156.775 124.188C156.637 124.32 156.471 124.423 156.287 124.491C156.104 124.558 155.907 124.589 155.71 124.581C155.512 124.573 155.319 124.527 155.142 124.445C154.966 124.362 154.81 124.247 154.685 124.104C154.56 123.962 154.468 123.797 154.416 123.62C154.364 123.443 154.353 123.257 154.383 123.076C154.414 122.894 154.485 122.721 154.592 122.566C154.78 122.297 155.068 122.101 155.401 122.017C155.734 121.932 156.089 121.965 156.398 122.109C156.706 122.252 156.947 122.497 157.075 122.795C157.202 123.094 157.206 123.426 157.087 123.727Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M160.555 128.975C160.432 129.207 160.238 129.4 159.997 129.529C159.755 129.659 159.478 129.718 159.201 129.701C158.923 129.683 158.657 129.589 158.437 129.431C158.217 129.272 158.053 129.056 157.965 128.811C157.877 128.565 157.869 128.301 157.943 128.051C158.017 127.801 158.169 127.578 158.38 127.409C158.591 127.239 158.851 127.133 159.127 127.101C159.403 127.07 159.683 127.116 159.931 127.233C160.262 127.388 160.513 127.658 160.63 127.984C160.747 128.31 160.72 128.667 160.555 128.975Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M155.996 127.28C155.872 127.51 155.679 127.701 155.438 127.829C155.198 127.957 154.923 128.015 154.647 127.997C154.371 127.979 154.107 127.886 153.889 127.728C153.67 127.57 153.507 127.356 153.42 127.112C153.332 126.867 153.325 126.605 153.398 126.357C153.471 126.108 153.621 125.886 153.831 125.718C154.04 125.549 154.298 125.442 154.572 125.41C154.846 125.379 155.125 125.423 155.372 125.539C155.536 125.615 155.683 125.72 155.803 125.849C155.923 125.977 156.015 126.127 156.073 126.289C156.131 126.45 156.154 126.621 156.141 126.791C156.127 126.962 156.078 127.128 155.996 127.28Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M154.723 123.137C154.6 123.369 154.406 123.562 154.165 123.691C153.924 123.821 153.647 123.88 153.369 123.863C153.091 123.845 152.826 123.751 152.606 123.593C152.385 123.434 152.221 123.218 152.133 122.973C152.045 122.727 152.037 122.463 152.111 122.213C152.185 121.963 152.337 121.74 152.548 121.571C152.759 121.402 153.019 121.295 153.295 121.263C153.571 121.232 153.851 121.278 154.1 121.395C154.429 121.551 154.679 121.821 154.796 122.147C154.912 122.473 154.886 122.828 154.723 123.137Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M171.769 133.606C171.646 133.836 171.452 134.028 171.212 134.155C170.972 134.283 170.696 134.342 170.421 134.324C170.145 134.306 169.881 134.212 169.662 134.054C169.444 133.896 169.28 133.682 169.193 133.438C169.106 133.194 169.098 132.931 169.171 132.683C169.244 132.435 169.395 132.212 169.604 132.044C169.813 131.875 170.071 131.768 170.346 131.737C170.62 131.705 170.898 131.749 171.145 131.865C171.475 132.02 171.725 132.29 171.841 132.616C171.958 132.942 171.932 133.298 171.769 133.606Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.971 137.35C179.848 137.582 179.654 137.775 179.412 137.904C179.171 138.034 178.894 138.093 178.617 138.076C178.339 138.058 178.073 137.964 177.853 137.806C177.633 137.647 177.468 137.431 177.38 137.186C177.292 136.94 177.285 136.676 177.359 136.426C177.433 136.176 177.585 135.953 177.796 135.784C178.006 135.614 178.266 135.508 178.543 135.476C178.819 135.445 179.099 135.491 179.347 135.608C179.676 135.764 179.926 136.034 180.043 136.36C180.16 136.686 180.134 137.041 179.971 137.35Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M148.686 124.454C148.563 124.684 148.369 124.875 148.129 125.003C147.889 125.131 147.613 125.189 147.337 125.171C147.062 125.153 146.798 125.059 146.579 124.902C146.361 124.744 146.197 124.53 146.11 124.285C146.022 124.041 146.015 123.779 146.088 123.53C146.161 123.282 146.312 123.06 146.521 122.891C146.73 122.723 146.988 122.616 147.262 122.584C147.537 122.552 147.815 122.597 148.062 122.712C148.391 122.868 148.641 123.138 148.758 123.464C148.875 123.79 148.849 124.145 148.686 124.454Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M183.944 138.539C183.821 138.771 183.627 138.964 183.385 139.094C183.144 139.223 182.867 139.283 182.59 139.265C182.312 139.247 182.046 139.153 181.826 138.995C181.606 138.837 181.441 138.621 181.353 138.375C181.265 138.129 181.258 137.865 181.332 137.615C181.406 137.366 181.558 137.142 181.769 136.973C181.979 136.804 182.239 136.697 182.516 136.666C182.792 136.635 183.072 136.68 183.32 136.798C183.649 136.953 183.899 137.223 184.016 137.549C184.133 137.875 184.107 138.231 183.944 138.539Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.813 135.807C177.69 136.039 177.496 136.232 177.254 136.361C177.013 136.491 176.736 136.55 176.459 136.533C176.181 136.515 175.915 136.421 175.695 136.263C175.475 136.104 175.31 135.888 175.222 135.643C175.134 135.397 175.127 135.133 175.201 134.883C175.275 134.633 175.427 134.41 175.638 134.241C175.848 134.071 176.108 133.965 176.385 133.933C176.661 133.902 176.941 133.948 177.189 134.065C177.518 134.221 177.768 134.491 177.885 134.817C178.002 135.143 177.976 135.498 177.813 135.807Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.449 135.225C175.261 135.495 174.973 135.69 174.64 135.775C174.307 135.859 173.952 135.827 173.644 135.683C173.335 135.539 173.094 135.295 172.967 134.996C172.84 134.698 172.835 134.366 172.954 134.064C173.022 133.892 173.129 133.735 173.267 133.603C173.404 133.472 173.571 133.369 173.754 133.301C173.938 133.233 174.135 133.202 174.332 133.21C174.529 133.218 174.722 133.265 174.899 133.347C175.075 133.429 175.231 133.545 175.357 133.687C175.482 133.829 175.573 133.994 175.625 134.172C175.677 134.349 175.688 134.534 175.658 134.716C175.628 134.897 175.556 135.071 175.449 135.225Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M166.836 128.127C166.713 128.359 166.518 128.552 166.277 128.682C166.036 128.811 165.759 128.87 165.481 128.853C165.204 128.835 164.938 128.741 164.718 128.583C164.498 128.424 164.333 128.209 164.245 127.963C164.157 127.717 164.15 127.453 164.224 127.203C164.298 126.954 164.45 126.73 164.66 126.561C164.871 126.392 165.131 126.285 165.408 126.254C165.684 126.222 165.964 126.268 166.212 126.386C166.541 126.541 166.791 126.811 166.908 127.137C167.025 127.463 166.999 127.819 166.836 128.127Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M187.443 139.793C187.32 140.025 187.125 140.218 186.884 140.348C186.643 140.477 186.366 140.537 186.089 140.519C185.811 140.501 185.545 140.407 185.325 140.249C185.105 140.09 184.94 139.875 184.852 139.629C184.764 139.383 184.757 139.119 184.831 138.869C184.905 138.62 185.057 138.396 185.268 138.227C185.478 138.058 185.738 137.951 186.015 137.92C186.291 137.888 186.571 137.934 186.819 138.052C187.148 138.207 187.398 138.477 187.515 138.803C187.632 139.129 187.606 139.485 187.443 139.793Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.767 144.129C193.644 144.361 193.45 144.554 193.209 144.684C192.968 144.813 192.691 144.872 192.413 144.855C192.135 144.837 191.87 144.743 191.649 144.585C191.429 144.426 191.265 144.211 191.177 143.965C191.089 143.719 191.081 143.455 191.155 143.205C191.229 142.955 191.381 142.732 191.592 142.563C191.803 142.394 192.063 142.287 192.339 142.256C192.615 142.224 192.895 142.27 193.143 142.388C193.471 142.544 193.72 142.814 193.837 143.14C193.954 143.465 193.929 143.82 193.767 144.129Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.74 145.319C197.617 145.549 197.423 145.74 197.183 145.868C196.943 145.996 196.667 146.054 196.392 146.036C196.116 146.018 195.852 145.925 195.633 145.767C195.415 145.609 195.251 145.395 195.164 145.151C195.077 144.907 195.069 144.644 195.142 144.396C195.215 144.147 195.366 143.925 195.575 143.757C195.784 143.588 196.042 143.481 196.317 143.449C196.591 143.418 196.869 143.462 197.116 143.578C197.446 143.733 197.696 144.003 197.812 144.329C197.929 144.655 197.903 145.011 197.74 145.319Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.597 142.59C191.528 142.763 191.422 142.92 191.284 143.052C191.146 143.183 190.98 143.286 190.797 143.354C190.613 143.422 190.416 143.452 190.219 143.445C190.022 143.437 189.828 143.39 189.652 143.308C189.475 143.226 189.319 143.11 189.194 142.968C189.069 142.826 188.977 142.661 188.926 142.483C188.874 142.306 188.862 142.121 188.893 141.939C188.923 141.757 188.994 141.584 189.102 141.43C189.29 141.16 189.577 140.964 189.91 140.88C190.244 140.795 190.598 140.828 190.907 140.972C191.216 141.116 191.457 141.36 191.584 141.659C191.711 141.957 191.716 142.289 191.597 142.59Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.245 141.999C189.058 142.268 188.77 142.464 188.437 142.548C188.104 142.633 187.749 142.6 187.44 142.456C187.131 142.313 186.89 142.068 186.763 141.77C186.636 141.471 186.631 141.139 186.75 140.838C186.819 140.665 186.925 140.508 187.063 140.377C187.201 140.245 187.367 140.142 187.55 140.074C187.734 140.007 187.931 139.976 188.128 139.984C188.325 139.992 188.519 140.038 188.695 140.12C188.872 140.202 189.028 140.318 189.153 140.46C189.278 140.603 189.37 140.768 189.422 140.945C189.473 141.122 189.485 141.308 189.454 141.489C189.424 141.671 189.353 141.844 189.245 141.999Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.229 133.142C179.069 133.351 178.847 133.513 178.59 133.605C178.332 133.697 178.051 133.717 177.782 133.661C177.513 133.604 177.267 133.475 177.077 133.29C176.886 133.104 176.759 132.87 176.711 132.617C176.663 132.364 176.697 132.104 176.808 131.869C176.919 131.633 177.103 131.434 177.335 131.296C177.568 131.158 177.839 131.087 178.115 131.092C178.391 131.097 178.659 131.178 178.886 131.325C179.037 131.423 179.166 131.548 179.265 131.692C179.364 131.837 179.431 131.998 179.463 132.167C179.495 132.336 179.491 132.509 179.451 132.676C179.41 132.843 179.335 133.002 179.229 133.142Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M186.713 138.005C186.555 138.216 186.332 138.378 186.075 138.471C185.817 138.564 185.536 138.584 185.266 138.528C184.996 138.473 184.75 138.344 184.559 138.158C184.368 137.972 184.241 137.738 184.193 137.485C184.145 137.232 184.179 136.971 184.29 136.735C184.401 136.5 184.585 136.301 184.818 136.163C185.051 136.024 185.323 135.954 185.599 135.959C185.875 135.965 186.144 136.047 186.37 136.194C186.52 136.292 186.648 136.417 186.747 136.561C186.846 136.705 186.913 136.865 186.945 137.033C186.976 137.202 186.972 137.374 186.933 137.541C186.893 137.707 186.818 137.865 186.713 138.005Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.443 139.753C190.284 139.963 190.061 140.124 189.804 140.216C189.546 140.309 189.265 140.328 188.996 140.272C188.727 140.216 188.481 140.087 188.291 139.901C188.1 139.715 187.973 139.481 187.925 139.228C187.878 138.975 187.911 138.715 188.023 138.48C188.134 138.245 188.317 138.046 188.55 137.907C188.782 137.769 189.053 137.698 189.329 137.703C189.605 137.709 189.873 137.79 190.1 137.936C190.251 138.034 190.38 138.159 190.479 138.304C190.578 138.448 190.646 138.609 190.677 138.778C190.709 138.947 190.705 139.12 190.665 139.287C190.624 139.455 190.549 139.613 190.443 139.753Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M198.058 142.852C197.9 143.063 197.679 143.225 197.422 143.319C197.164 143.413 196.883 143.434 196.613 143.379C196.343 143.324 196.097 143.196 195.905 143.01C195.714 142.825 195.585 142.591 195.537 142.338C195.488 142.085 195.521 141.825 195.631 141.589C195.742 141.353 195.925 141.153 196.157 141.015C196.39 140.876 196.661 140.804 196.937 140.809C197.214 140.814 197.482 140.894 197.709 141.041C198.012 141.239 198.219 141.54 198.284 141.879C198.35 142.219 198.268 142.568 198.058 142.852Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M201.089 144.233C200.931 144.443 200.709 144.605 200.452 144.697C200.195 144.79 199.914 144.81 199.644 144.755C199.375 144.7 199.129 144.571 198.938 144.386C198.747 144.201 198.619 143.967 198.571 143.715C198.522 143.462 198.555 143.201 198.665 142.966C198.775 142.731 198.958 142.531 199.19 142.392C199.422 142.253 199.693 142.181 199.969 142.186C200.245 142.19 200.513 142.27 200.74 142.416C200.892 142.514 201.021 142.638 201.121 142.782C201.221 142.927 201.289 143.088 201.321 143.257C201.354 143.426 201.35 143.599 201.31 143.766C201.27 143.934 201.195 144.092 201.089 144.233Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.285 140.995C194.126 141.206 193.904 141.368 193.647 141.461C193.389 141.554 193.108 141.574 192.838 141.519C192.568 141.463 192.322 141.334 192.131 141.148C191.94 140.963 191.813 140.728 191.765 140.475C191.717 140.222 191.75 139.961 191.862 139.726C191.973 139.49 192.157 139.291 192.39 139.153C192.623 139.015 192.895 138.944 193.171 138.95C193.447 138.955 193.715 139.037 193.942 139.184C194.093 139.282 194.221 139.406 194.32 139.55C194.42 139.694 194.487 139.855 194.519 140.023C194.551 140.192 194.546 140.364 194.506 140.531C194.466 140.698 194.391 140.856 194.285 140.995Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M184.836 136.178C184.678 136.389 184.456 136.552 184.199 136.645C183.942 136.739 183.661 136.76 183.391 136.705C183.121 136.65 182.875 136.522 182.683 136.337C182.491 136.151 182.363 135.918 182.314 135.665C182.265 135.412 182.298 135.151 182.409 134.915C182.519 134.679 182.702 134.48 182.935 134.341C183.167 134.202 183.439 134.13 183.715 134.135C183.991 134.14 184.26 134.22 184.486 134.367C184.79 134.565 184.996 134.866 185.062 135.206C185.127 135.545 185.046 135.894 184.836 136.178Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M182.597 135.255C182.438 135.466 182.216 135.628 181.959 135.721C181.701 135.814 181.42 135.834 181.15 135.778C180.88 135.723 180.634 135.594 180.443 135.408C180.252 135.222 180.124 134.988 180.076 134.735C180.028 134.482 180.062 134.221 180.174 133.985C180.285 133.75 180.469 133.551 180.702 133.413C180.935 133.274 181.206 133.204 181.483 133.209C181.759 133.215 182.027 133.297 182.254 133.444C182.404 133.542 182.532 133.667 182.631 133.811C182.729 133.955 182.796 134.115 182.828 134.283C182.86 134.452 182.856 134.624 182.816 134.791C182.777 134.957 182.702 135.115 182.597 135.255Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.048 159.184C199.736 156.328 204.919 157.095 206.035 157.304L208.942 153.496C208.973 153.455 209.016 153.422 209.067 153.403C209.116 153.403 214.374 151.209 217.742 155.011C221.484 159.23 224.659 163.839 222.62 168.941C220.58 174.042 218.821 184.31 218.809 184.414C218.799 184.465 218.775 184.513 218.739 184.553C218.702 184.593 218.656 184.623 218.603 184.64C218.552 184.658 218.496 184.662 218.442 184.653C218.389 184.644 218.339 184.622 218.297 184.588C216.033 182.782 213.608 181.157 211.05 179.73L210.489 179.446C206.946 177.658 199.58 173.938 197.559 170.032C195.476 165.986 194.004 162.411 197.048 159.184Z" fill="#263238"/>
<path d="M240.757 92.9551C240.757 92.9551 227.329 116.752 202.087 141.297" stroke="#263238"strokeWidth="3" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M241.069 96.3393C240.837 96.178 240.662 95.9565 240.565 95.7027C240.468 95.4489 240.454 95.1741 240.525 94.913C240.596 94.6519 240.748 94.4161 240.963 94.2354C241.177 94.0547 241.445 93.9372 241.731 93.8976C242.017 93.8581 242.31 93.8982 242.571 94.0131C242.833 94.128 243.053 94.3124 243.202 94.5431C243.351 94.7738 243.424 95.0405 243.41 95.3095C243.397 95.5786 243.298 95.8379 243.127 96.0549C242.894 96.3464 242.547 96.5402 242.161 96.5935C241.775 96.6468 241.382 96.5554 241.069 96.3393Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.574 97.8775C235.339 97.7167 235.161 97.4947 235.062 97.2395C234.963 96.9844 234.947 96.7076 235.017 96.4442C235.088 96.1809 235.24 95.9429 235.456 95.7604C235.672 95.578 235.941 95.4592 236.229 95.4192C236.518 95.3793 236.812 95.4199 237.076 95.536C237.339 95.652 237.56 95.8383 237.709 96.0711C237.859 96.304 237.931 96.573 237.916 96.8439C237.901 97.1149 237.8 97.3756 237.626 97.5931C237.511 97.7372 237.367 97.859 237.202 97.9513C237.037 98.0437 236.854 98.1048 236.663 98.1312C236.473 98.1576 236.278 98.1488 236.091 98.1053C235.905 98.0617 235.729 97.9843 235.574 97.8775Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M226.524 110.848C226.289 110.687 226.111 110.465 226.012 110.21C225.913 109.955 225.897 109.678 225.968 109.415C226.038 109.152 226.19 108.914 226.406 108.731C226.622 108.549 226.891 108.43 227.179 108.39C227.468 108.35 227.762 108.391 228.026 108.507C228.289 108.623 228.51 108.809 228.659 109.042C228.809 109.275 228.881 109.544 228.866 109.815C228.851 110.086 228.75 110.346 228.576 110.564C228.461 110.708 228.317 110.83 228.152 110.922C227.987 111.014 227.804 111.076 227.613 111.102C227.423 111.128 227.228 111.12 227.042 111.076C226.855 111.032 226.679 110.955 226.524 110.848Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M217.742 122.509C217.51 122.348 217.335 122.126 217.238 121.873C217.142 121.619 217.128 121.344 217.199 121.083C217.269 120.822 217.422 120.586 217.636 120.405C217.851 120.225 218.118 120.107 218.404 120.068C218.691 120.028 218.983 120.068 219.245 120.183C219.507 120.298 219.726 120.482 219.875 120.713C220.025 120.944 220.097 121.21 220.084 121.479C220.071 121.748 219.972 122.008 219.8 122.225C219.568 122.516 219.221 122.71 218.835 122.763C218.449 122.817 218.056 122.725 217.742 122.509Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M240.526 92.2757C240.293 92.115 240.117 91.8937 240.019 91.6398C239.922 91.3859 239.907 91.1107 239.978 90.8491C240.048 90.5876 240.2 90.3512 240.415 90.1701C240.629 89.9889 240.897 89.871 241.184 89.8313C241.47 89.7916 241.763 89.8318 242.025 89.9469C242.287 90.062 242.507 90.2468 242.656 90.478C242.805 90.7091 242.877 90.9762 242.864 91.2456C242.85 91.5149 242.75 91.7744 242.578 91.9913C242.464 92.1354 242.32 92.2572 242.154 92.3495C241.989 92.4419 241.806 92.503 241.615 92.5294C241.425 92.5558 241.231 92.547 241.044 92.5035C240.857 92.4599 240.681 92.3825 240.526 92.2757Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.374 103.49C235.14 103.329 234.962 103.108 234.863 102.853C234.764 102.598 234.749 102.322 234.819 102.059C234.888 101.796 235.041 101.558 235.256 101.375C235.471 101.193 235.739 101.074 236.027 101.033C236.315 100.992 236.609 101.032 236.873 101.147C237.136 101.262 237.357 101.448 237.508 101.679C237.658 101.911 237.731 102.18 237.718 102.45C237.705 102.721 237.605 102.982 237.433 103.2C237.2 103.491 236.853 103.685 236.468 103.739C236.082 103.794 235.689 103.704 235.374 103.49Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.304 107.082C233.069 106.921 232.891 106.699 232.792 106.445C232.694 106.19 232.678 105.913 232.748 105.65C232.818 105.387 232.97 105.15 233.185 104.967C233.4 104.785 233.668 104.665 233.956 104.625C234.244 104.584 234.538 104.624 234.802 104.739C235.066 104.854 235.287 105.039 235.437 105.271C235.587 105.503 235.661 105.771 235.647 106.042C235.634 106.313 235.535 106.574 235.362 106.792C235.129 107.083 234.782 107.277 234.397 107.331C234.011 107.385 233.618 107.296 233.304 107.082Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.362 100.983C235.129 100.822 234.953 100.601 234.855 100.347C234.758 100.093 234.743 99.8178 234.813 99.5562C234.884 99.2946 235.036 99.0583 235.251 98.8771C235.465 98.6959 235.733 98.578 236.019 98.5383C236.306 98.4986 236.599 98.5388 236.861 98.6539C237.123 98.769 237.342 98.9538 237.492 99.185C237.641 99.4161 237.713 99.6833 237.699 99.9526C237.685 100.222 237.586 100.481 237.414 100.698C237.299 100.842 237.155 100.964 236.99 101.057C236.825 101.149 236.642 101.21 236.451 101.236C236.261 101.263 236.066 101.254 235.879 101.21C235.692 101.167 235.517 101.09 235.362 100.983Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M232.43 102.27C232.195 102.11 232.016 101.888 231.917 101.634C231.817 101.379 231.801 101.102 231.87 100.839C231.939 100.575 232.091 100.337 232.306 100.154C232.522 99.9707 232.79 99.8512 233.078 99.8105C233.367 99.7697 233.661 99.8095 233.925 99.9248C234.189 100.04 234.41 100.226 234.561 100.458C234.711 100.69 234.784 100.959 234.77 101.23C234.756 101.501 234.656 101.762 234.482 101.98C234.368 102.125 234.225 102.247 234.06 102.34C233.895 102.433 233.712 102.494 233.521 102.521C233.331 102.548 233.136 102.54 232.949 102.497C232.762 102.454 232.586 102.377 232.43 102.27Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M237.164 103.444C236.929 103.283 236.751 103.061 236.652 102.806C236.553 102.551 236.538 102.274 236.608 102.011C236.678 101.747 236.831 101.509 237.046 101.327C237.262 101.144 237.531 101.026 237.82 100.986C238.108 100.946 238.403 100.986 238.666 101.102C238.93 101.218 239.15 101.405 239.3 101.638C239.449 101.87 239.521 102.139 239.507 102.41C239.492 102.681 239.391 102.942 239.216 103.159C239.102 103.304 238.958 103.425 238.793 103.518C238.627 103.61 238.444 103.671 238.254 103.698C238.063 103.724 237.869 103.715 237.682 103.672C237.495 103.628 237.319 103.551 237.164 103.444Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M225.495 117.57C225.261 117.409 225.083 117.188 224.984 116.933C224.885 116.678 224.869 116.402 224.939 116.139C225.009 115.876 225.161 115.638 225.376 115.455C225.591 115.273 225.86 115.154 226.148 115.113C226.435 115.073 226.73 115.112 226.993 115.227C227.257 115.342 227.478 115.528 227.628 115.76C227.779 115.991 227.852 116.26 227.838 116.53C227.825 116.801 227.726 117.062 227.553 117.28C227.321 117.571 226.974 117.765 226.588 117.819C226.203 117.874 225.81 117.784 225.495 117.57Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.04 108.527C227.806 108.366 227.628 108.144 227.53 107.888C227.432 107.633 227.417 107.357 227.488 107.094C227.558 106.831 227.711 106.594 227.927 106.412C228.143 106.23 228.412 106.111 228.7 106.071C228.988 106.032 229.282 106.072 229.545 106.188C229.808 106.304 230.029 106.49 230.178 106.722C230.328 106.955 230.4 107.223 230.386 107.494C230.372 107.764 230.271 108.025 230.098 108.243C229.865 108.534 229.518 108.728 229.132 108.781C228.746 108.835 228.353 108.743 228.04 108.527Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M223.811 120.135C223.576 119.975 223.398 119.752 223.299 119.497C223.2 119.242 223.184 118.965 223.254 118.702C223.325 118.439 223.477 118.201 223.693 118.018C223.909 117.836 224.178 117.717 224.466 117.677C224.755 117.637 225.049 117.678 225.313 117.794C225.576 117.91 225.797 118.096 225.946 118.329C226.096 118.562 226.168 118.831 226.153 119.102C226.138 119.373 226.037 119.633 225.863 119.851C225.748 119.995 225.604 120.117 225.439 120.209C225.274 120.301 225.091 120.363 224.9 120.389C224.71 120.415 224.515 120.407 224.328 120.363C224.141 120.32 223.966 120.242 223.811 120.135Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M229.43 112.828C229.199 112.666 229.023 112.445 228.927 112.191C228.83 111.937 228.816 111.662 228.887 111.401C228.958 111.14 229.11 110.904 229.325 110.724C229.539 110.543 229.806 110.425 230.093 110.386C230.379 110.346 230.671 110.387 230.933 110.501C231.195 110.616 231.414 110.801 231.564 111.031C231.713 111.262 231.785 111.529 231.772 111.798C231.759 112.067 231.66 112.326 231.489 112.543C231.256 112.835 230.909 113.028 230.523 113.082C230.137 113.135 229.744 113.044 229.43 112.828Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M237.489 101.701C237.256 101.541 237.08 101.319 236.982 101.066C236.884 100.812 236.87 100.537 236.94 100.275C237.011 100.013 237.163 99.777 237.377 99.5959C237.592 99.4147 237.86 99.2968 238.146 99.2571C238.433 99.2173 238.726 99.2576 238.988 99.3727C239.25 99.4878 239.469 99.6726 239.619 99.9037C239.768 100.135 239.84 100.402 239.826 100.671C239.812 100.941 239.713 101.2 239.541 101.417C239.426 101.561 239.282 101.683 239.117 101.775C238.952 101.868 238.769 101.929 238.578 101.955C238.387 101.982 238.193 101.973 238.006 101.929C237.819 101.886 237.643 101.808 237.489 101.701Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.753 99.9889C233.517 99.8287 233.339 99.6071 233.239 99.3523C233.139 99.0974 233.123 98.8207 233.192 98.5573C233.262 98.2938 233.414 98.0555 233.629 97.8725C233.844 97.6894 234.112 97.57 234.401 97.5292C234.689 97.4885 234.984 97.5282 235.247 97.6435C235.511 97.7588 235.732 97.9444 235.883 98.1768C236.033 98.4091 236.106 98.6778 236.092 98.9488C236.078 99.2197 235.978 99.4807 235.805 99.6987C235.691 99.8433 235.547 99.9656 235.382 100.058C235.217 100.151 235.034 100.213 234.843 100.24C234.653 100.267 234.458 100.259 234.271 100.216C234.084 100.172 233.908 100.095 233.753 99.9889Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M238.593 99.5493C238.358 99.3886 238.179 99.1665 238.08 98.9114C237.981 98.6562 237.966 98.3794 238.036 98.1161C238.106 97.8528 238.259 97.6148 238.475 97.4323C238.69 97.2498 238.96 97.1311 239.248 97.0911C239.536 97.0512 239.831 97.0918 240.094 97.2078C240.358 97.3239 240.579 97.5102 240.728 97.743C240.878 97.9759 240.95 98.2448 240.935 98.5158C240.92 98.7867 240.819 99.0475 240.645 99.2649C240.531 99.4097 240.387 99.5321 240.222 99.6249C240.056 99.7177 239.873 99.779 239.682 99.8055C239.492 99.8319 239.297 99.8229 239.11 99.779C238.923 99.735 238.747 99.6569 238.593 99.5493Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M237.801 96.9596C237.565 96.7994 237.386 96.5779 237.287 96.323C237.187 96.0681 237.171 95.7914 237.24 95.528C237.309 95.2645 237.461 95.0262 237.677 94.8432C237.892 94.6601 238.16 94.5407 238.449 94.4999C238.737 94.4592 239.031 94.499 239.295 94.6142C239.559 94.7295 239.78 94.9151 239.931 95.1475C240.081 95.3798 240.154 95.6485 240.14 95.9195C240.126 96.1904 240.026 96.4514 239.853 96.6694C239.739 96.8146 239.596 96.9375 239.431 97.0308C239.266 97.1242 239.082 97.1861 238.892 97.2131C238.701 97.2401 238.506 97.2316 238.319 97.1881C238.132 97.1445 237.955 97.0669 237.801 96.9596Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M223.761 113.949C223.527 113.788 223.349 113.567 223.25 113.312C223.151 113.057 223.135 112.781 223.205 112.518C223.275 112.255 223.427 112.017 223.642 111.834C223.857 111.652 224.126 111.533 224.414 111.492C224.702 111.451 224.996 111.491 225.259 111.606C225.523 111.721 225.744 111.907 225.894 112.138C226.045 112.37 226.118 112.639 226.105 112.909C226.091 113.18 225.992 113.441 225.819 113.659C225.705 113.804 225.56 113.926 225.395 114.019C225.229 114.112 225.046 114.174 224.855 114.201C224.664 114.228 224.469 114.219 224.281 114.176C224.093 114.133 223.917 114.056 223.761 113.949Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.067 121.117C217.833 120.956 217.655 120.733 217.557 120.478C217.459 120.223 217.444 119.947 217.515 119.684C217.585 119.421 217.738 119.184 217.954 119.002C218.17 118.82 218.439 118.701 218.727 118.661C219.015 118.622 219.309 118.662 219.572 118.778C219.835 118.894 220.056 119.08 220.205 119.312C220.355 119.545 220.427 119.813 220.413 120.084C220.399 120.354 220.298 120.615 220.125 120.832C219.892 121.124 219.545 121.318 219.159 121.371C218.773 121.424 218.38 121.333 218.067 121.117Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.129 118.249C223.894 118.088 223.715 117.867 223.615 117.612C223.515 117.357 223.499 117.08 223.569 116.817C223.638 116.554 223.79 116.315 224.005 116.132C224.22 115.949 224.489 115.83 224.777 115.789C225.065 115.748 225.36 115.788 225.624 115.903C225.888 116.019 226.109 116.204 226.259 116.437C226.409 116.669 226.482 116.938 226.468 117.209C226.454 117.479 226.354 117.74 226.181 117.958C226.067 118.103 225.923 118.225 225.758 118.318C225.593 118.411 225.41 118.473 225.22 118.5C225.029 118.527 224.835 118.518 224.647 118.475C224.46 118.432 224.284 118.355 224.129 118.249Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M238.268 93.5006C238.033 93.3404 237.854 93.1189 237.754 92.864C237.655 92.6091 237.638 92.3324 237.708 92.069C237.777 91.8055 237.929 91.5672 238.144 91.3842C238.359 91.2012 238.628 91.0817 238.916 91.0409C239.204 91.0002 239.499 91.04 239.763 91.1552C240.027 91.2705 240.248 91.4561 240.398 91.6885C240.549 91.9208 240.622 92.1895 240.608 92.4605C240.594 92.7314 240.494 92.9924 240.32 93.2104C240.089 93.5012 239.743 93.695 239.359 93.7493C238.974 93.8037 238.582 93.7143 238.268 93.5006Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M215.996 124.709C215.762 124.547 215.585 124.325 215.486 124.07C215.388 123.815 215.373 123.539 215.444 123.276C215.515 123.013 215.668 122.775 215.883 122.593C216.099 122.411 216.368 122.293 216.656 122.253C216.944 122.213 217.238 122.254 217.501 122.37C217.765 122.486 217.985 122.671 218.135 122.904C218.284 123.136 218.357 123.405 218.342 123.675C218.328 123.946 218.228 124.207 218.054 124.424C217.822 124.716 217.474 124.91 217.088 124.963C216.702 125.016 216.309 124.925 215.996 124.709Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.181 119.335C219.946 119.174 219.767 118.953 219.667 118.698C219.567 118.443 219.551 118.166 219.62 117.903C219.69 117.64 219.842 117.401 220.057 117.218C220.272 117.035 220.541 116.916 220.829 116.875C221.117 116.834 221.412 116.874 221.676 116.989C221.939 117.105 222.161 117.29 222.311 117.522C222.461 117.755 222.534 118.024 222.52 118.294C222.506 118.565 222.406 118.826 222.233 119.044C222.001 119.335 221.656 119.529 221.271 119.583C220.887 119.638 220.495 119.548 220.181 119.335Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.285 117.176C221.052 117.015 220.876 116.794 220.778 116.54C220.681 116.286 220.666 116.011 220.736 115.75C220.807 115.488 220.959 115.252 221.174 115.07C221.388 114.889 221.656 114.771 221.942 114.732C222.229 114.692 222.522 114.732 222.784 114.847C223.046 114.962 223.266 115.147 223.415 115.378C223.564 115.609 223.636 115.877 223.622 116.146C223.609 116.415 223.509 116.675 223.337 116.892C223.223 117.036 223.079 117.159 222.914 117.252C222.749 117.344 222.565 117.406 222.375 117.432C222.184 117.459 221.989 117.45 221.802 117.406C221.615 117.362 221.439 117.284 221.285 117.176Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M230.778 110.268C230.545 110.108 230.368 109.887 230.269 109.633C230.171 109.38 230.156 109.105 230.225 108.843C230.295 108.581 230.446 108.345 230.66 108.163C230.874 107.981 231.141 107.863 231.428 107.822C231.714 107.782 232.007 107.821 232.27 107.935C232.532 108.05 232.752 108.234 232.902 108.464C233.052 108.695 233.125 108.962 233.112 109.231C233.099 109.501 233.001 109.76 232.83 109.978C232.598 110.269 232.252 110.462 231.868 110.517C231.483 110.571 231.091 110.482 230.778 110.268Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.95 127.831C213.715 127.67 213.537 127.448 213.438 127.193C213.339 126.937 213.323 126.661 213.394 126.397C213.464 126.134 213.617 125.896 213.832 125.714C214.048 125.531 214.317 125.412 214.605 125.372C214.894 125.332 215.188 125.373 215.452 125.489C215.716 125.605 215.936 125.791 216.086 126.024C216.235 126.257 216.307 126.526 216.292 126.797C216.277 127.068 216.176 127.329 216.002 127.546C215.77 127.836 215.424 128.029 215.039 128.082C214.655 128.135 214.263 128.045 213.95 127.831Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.95 133.09C207.715 132.93 207.537 132.708 207.438 132.452C207.339 132.197 207.323 131.92 207.394 131.657C207.464 131.394 207.616 131.156 207.832 130.973C208.048 130.791 208.317 130.672 208.605 130.632C208.894 130.592 209.188 130.633 209.452 130.749C209.715 130.865 209.936 131.051 210.086 131.284C210.235 131.517 210.307 131.786 210.292 132.057C210.277 132.328 210.176 132.588 210.002 132.806C209.888 132.95 209.744 133.072 209.578 133.164C209.413 133.257 209.23 133.318 209.039 133.344C208.849 133.371 208.655 133.362 208.468 133.318C208.281 133.275 208.105 133.197 207.95 133.09Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M205.879 136.682C205.644 136.522 205.465 136.3 205.366 136.046C205.266 135.791 205.25 135.514 205.319 135.251C205.388 134.987 205.54 134.749 205.755 134.566C205.971 134.383 206.239 134.263 206.527 134.223C206.816 134.182 207.11 134.222 207.374 134.337C207.638 134.452 207.859 134.638 208.01 134.87C208.16 135.102 208.233 135.371 208.219 135.642C208.205 135.913 208.105 136.174 207.931 136.392C207.817 136.537 207.674 136.659 207.509 136.752C207.344 136.845 207.161 136.906 206.97 136.933C206.78 136.96 206.585 136.952 206.398 136.909C206.211 136.866 206.035 136.789 205.879 136.682Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M210.064 131.309C209.831 131.149 209.655 130.928 209.556 130.674C209.458 130.421 209.443 130.146 209.512 129.884C209.582 129.622 209.733 129.386 209.947 129.204C210.161 129.022 210.428 128.904 210.715 128.863C211.001 128.823 211.294 128.862 211.556 128.976C211.819 129.091 212.039 129.275 212.189 129.505C212.339 129.736 212.412 130.003 212.399 130.272C212.386 130.542 212.288 130.801 212.116 131.019C211.885 131.31 211.539 131.503 211.155 131.558C210.77 131.612 210.378 131.523 210.064 131.309Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M211.162 129.15C210.93 128.989 210.755 128.767 210.658 128.513C210.562 128.259 210.548 127.985 210.619 127.724C210.689 127.462 210.842 127.227 211.056 127.046C211.271 126.865 211.538 126.748 211.824 126.708C212.111 126.669 212.403 126.709 212.665 126.824C212.927 126.939 213.146 127.123 213.295 127.354C213.445 127.584 213.517 127.851 213.504 128.12C213.49 128.389 213.392 128.648 213.22 128.865C212.988 129.157 212.641 129.351 212.255 129.404C211.869 129.457 211.476 129.366 211.162 129.15Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.064 138.569C206.832 138.408 206.655 138.187 206.558 137.933C206.46 137.679 206.446 137.404 206.516 137.142C206.586 136.881 206.739 136.644 206.953 136.463C207.168 136.282 207.435 136.164 207.722 136.124C208.009 136.085 208.301 136.125 208.564 136.24C208.826 136.355 209.045 136.54 209.194 136.771C209.344 137.002 209.416 137.269 209.402 137.539C209.388 137.808 209.289 138.067 209.116 138.284C209.002 138.428 208.858 138.55 208.693 138.643C208.528 138.735 208.344 138.796 208.154 138.822C207.963 138.849 207.769 138.84 207.582 138.796C207.395 138.753 207.219 138.675 207.064 138.569Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M222.875 121.198C222.671 121.007 222.532 120.764 222.476 120.499C222.42 120.235 222.449 119.961 222.56 119.712C222.671 119.463 222.859 119.25 223.1 119.101C223.341 118.951 223.624 118.871 223.914 118.871C224.203 118.871 224.487 118.951 224.727 119.101C224.968 119.25 225.156 119.463 225.267 119.712C225.378 119.961 225.408 120.235 225.352 120.499C225.296 120.764 225.157 121.007 224.952 121.198C224.816 121.325 224.654 121.426 224.476 121.495C224.298 121.564 224.107 121.599 223.914 121.599C223.721 121.599 223.53 121.564 223.352 121.495C223.173 121.426 223.011 121.325 222.875 121.198Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.071 127.472C215.865 127.281 215.726 127.038 215.669 126.774C215.612 126.509 215.641 126.235 215.752 125.985C215.863 125.736 216.05 125.523 216.291 125.373C216.533 125.223 216.816 125.143 217.106 125.143C217.396 125.143 217.68 125.223 217.921 125.373C218.162 125.523 218.35 125.736 218.46 125.985C218.571 126.235 218.6 126.509 218.543 126.774C218.487 127.038 218.347 127.281 218.141 127.472C217.867 127.727 217.494 127.87 217.106 127.87C216.718 127.87 216.346 127.727 216.071 127.472Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.191 120.008C220.987 119.817 220.848 119.574 220.792 119.31C220.736 119.045 220.765 118.771 220.876 118.522C220.987 118.273 221.175 118.061 221.416 117.911C221.657 117.761 221.94 117.682 222.23 117.682C222.519 117.682 222.803 117.761 223.043 117.911C223.284 118.061 223.472 118.273 223.583 118.522C223.694 118.771 223.724 119.045 223.668 119.31C223.612 119.574 223.473 119.817 223.268 120.008C223.132 120.135 222.97 120.236 222.792 120.305C222.614 120.374 222.423 120.41 222.23 120.41C222.037 120.41 221.846 120.374 221.668 120.305C221.489 120.236 221.328 120.135 221.191 120.008Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.426 130.721C213.222 130.53 213.083 130.287 213.027 130.023C212.971 129.758 213 129.484 213.111 129.235C213.222 128.986 213.41 128.774 213.651 128.624C213.892 128.474 214.175 128.395 214.465 128.395C214.754 128.395 215.037 128.474 215.278 128.624C215.519 128.774 215.707 128.986 215.818 129.235C215.929 129.484 215.959 129.758 215.903 130.023C215.847 130.287 215.708 130.53 215.503 130.721C215.227 130.977 214.854 131.12 214.465 131.12C214.075 131.12 213.702 130.977 213.426 130.721Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M208.555 137.45C208.351 137.259 208.212 137.016 208.156 136.751C208.1 136.487 208.129 136.213 208.24 135.964C208.351 135.715 208.539 135.502 208.78 135.353C209.021 135.203 209.304 135.123 209.594 135.123C209.883 135.123 210.166 135.203 210.407 135.353C210.648 135.502 210.836 135.715 210.947 135.964C211.058 136.213 211.088 136.487 211.032 136.751C210.975 137.016 210.836 137.259 210.632 137.45C210.496 137.577 210.334 137.678 210.156 137.747C209.978 137.815 209.787 137.851 209.594 137.851C209.401 137.851 209.21 137.815 209.031 137.747C208.853 137.678 208.691 137.577 208.555 137.45Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.453 140.102C206.249 139.911 206.11 139.668 206.054 139.403C205.998 139.139 206.027 138.865 206.138 138.616C206.249 138.367 206.437 138.155 206.678 138.005C206.919 137.855 207.202 137.775 207.492 137.775C207.781 137.775 208.064 137.855 208.305 138.005C208.546 138.155 208.734 138.367 208.845 138.616C208.956 138.865 208.986 139.139 208.93 139.403C208.874 139.668 208.735 139.911 208.53 140.102C208.394 140.229 208.232 140.33 208.054 140.399C207.876 140.468 207.685 140.503 207.492 140.503C207.299 140.503 207.108 140.468 206.929 140.399C206.751 140.33 206.589 140.229 206.453 140.102Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.788 138.012C204.581 137.821 204.441 137.577 204.383 137.311C204.326 137.045 204.355 136.77 204.466 136.519C204.577 136.268 204.766 136.054 205.008 135.903C205.25 135.752 205.535 135.672 205.826 135.672C206.118 135.672 206.403 135.752 206.645 135.903C206.887 136.054 207.076 136.268 207.187 136.519C207.298 136.77 207.327 137.045 207.27 137.311C207.212 137.577 207.071 137.821 206.865 138.012C206.589 138.268 206.216 138.411 205.826 138.411C205.437 138.411 205.064 138.268 204.788 138.012Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M211.324 134.18C211.118 133.989 210.977 133.745 210.92 133.479C210.862 133.213 210.891 132.937 211.002 132.687C211.113 132.436 211.302 132.222 211.544 132.071C211.787 131.92 212.071 131.84 212.363 131.84C212.654 131.84 212.939 131.92 213.181 132.071C213.423 132.222 213.612 132.436 213.723 132.687C213.835 132.937 213.863 133.213 213.806 133.479C213.749 133.745 213.608 133.989 213.401 134.18C213.125 134.436 212.752 134.579 212.363 134.579C211.974 134.579 211.6 134.436 211.324 134.18Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.447 126.008C218.243 125.817 218.104 125.574 218.048 125.31C217.991 125.045 218.021 124.771 218.132 124.522C218.243 124.273 218.431 124.061 218.672 123.911C218.913 123.761 219.196 123.682 219.485 123.682C219.775 123.682 220.058 123.761 220.299 123.911C220.54 124.061 220.728 124.273 220.839 124.522C220.95 124.771 220.98 125.045 220.923 125.31C220.867 125.574 220.728 125.817 220.524 126.008C220.248 126.264 219.875 126.407 219.485 126.407C219.096 126.407 218.723 126.264 218.447 126.008Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.636 122.358C220.43 122.167 220.289 121.923 220.232 121.657C220.174 121.391 220.203 121.115 220.314 120.865C220.425 120.614 220.614 120.4 220.856 120.249C221.098 120.098 221.383 120.018 221.675 120.018C221.966 120.018 222.251 120.098 222.493 120.249C222.735 120.4 222.924 120.614 223.035 120.865C223.146 121.115 223.175 121.391 223.118 121.657C223.061 121.923 222.92 122.167 222.713 122.358C222.437 122.614 222.064 122.757 221.675 122.757C221.285 122.757 220.912 122.614 220.636 122.358Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M219.894 124.035C219.69 123.844 219.551 123.601 219.495 123.337C219.438 123.073 219.468 122.799 219.579 122.55C219.69 122.301 219.878 122.088 220.119 121.938C220.36 121.789 220.643 121.709 220.933 121.709C221.222 121.709 221.505 121.789 221.746 121.938C221.987 122.088 222.175 122.301 222.286 122.55C222.397 122.799 222.427 123.073 222.37 123.337C222.314 123.601 222.175 123.844 221.971 124.035C221.835 124.163 221.674 124.265 221.495 124.334C221.317 124.404 221.126 124.439 220.933 124.439C220.739 124.439 220.548 124.404 220.37 124.334C220.191 124.265 220.03 124.163 219.894 124.035Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.437 126.113C199.437 126.113 207.732 158.511 205.2 187.688" stroke="#263238"strokeWidth="5" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.584 155.627C125.584 155.627 160.511 150.606 204.794 155.888" stroke="#263238"strokeWidth="3" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M128.914 152.892C128.914 153.223 128.809 153.546 128.612 153.821C128.414 154.096 128.134 154.31 127.806 154.437C127.477 154.563 127.116 154.596 126.768 154.532C126.419 154.467 126.099 154.308 125.848 154.074C125.597 153.84 125.426 153.543 125.356 153.218C125.287 152.894 125.323 152.558 125.459 152.253C125.595 151.947 125.825 151.686 126.12 151.502C126.416 151.319 126.763 151.221 127.118 151.221C127.595 151.221 128.052 151.397 128.388 151.71C128.725 152.024 128.914 152.449 128.914 152.892Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M134.559 156.793C134.559 157.123 134.454 157.446 134.256 157.721C134.059 157.996 133.778 158.21 133.45 158.337C133.122 158.463 132.761 158.497 132.412 158.432C132.064 158.368 131.744 158.208 131.493 157.975C131.241 157.741 131.07 157.443 131.001 157.119C130.932 156.795 130.967 156.458 131.103 156.153C131.239 155.848 131.469 155.586 131.765 155.403C132.06 155.219 132.407 155.121 132.763 155.121C133.239 155.121 133.696 155.297 134.033 155.611C134.37 155.924 134.559 156.349 134.559 156.793Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M154.798 155.679C154.798 156.01 154.693 156.333 154.495 156.608C154.298 156.883 154.018 157.097 153.689 157.224C153.361 157.35 153 157.383 152.651 157.319C152.303 157.254 151.983 157.095 151.732 156.861C151.481 156.628 151.309 156.33 151.24 156.005C151.171 155.681 151.206 155.345 151.342 155.04C151.478 154.734 151.709 154.473 152.004 154.29C152.299 154.106 152.647 154.008 153.002 154.008C153.478 154.008 153.935 154.184 154.272 154.497C154.609 154.811 154.798 155.236 154.798 155.679Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.509 155.203C173.509 155.534 173.404 155.857 173.206 156.132C173.008 156.407 172.728 156.621 172.399 156.748C172.07 156.874 171.709 156.907 171.36 156.842C171.012 156.777 170.692 156.617 170.441 156.383C170.19 156.148 170.019 155.85 169.95 155.525C169.882 155.201 169.918 154.864 170.055 154.559C170.192 154.254 170.423 153.993 170.72 153.81C171.016 153.627 171.364 153.53 171.719 153.531C171.955 153.531 172.188 153.575 172.406 153.659C172.623 153.743 172.821 153.866 172.987 154.021C173.153 154.176 173.285 154.361 173.375 154.564C173.464 154.766 173.51 154.984 173.509 155.203Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.276 157.99C124.268 157.99 125.072 157.241 125.072 156.318C125.072 155.395 124.268 154.646 123.276 154.646C122.284 154.646 121.48 155.395 121.48 156.318C121.48 157.241 122.284 157.99 123.276 157.99Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M140.628 152.892C140.628 153.223 140.522 153.546 140.325 153.821C140.128 154.096 139.847 154.31 139.519 154.437C139.191 154.563 138.829 154.596 138.481 154.532C138.132 154.467 137.812 154.308 137.561 154.074C137.31 153.84 137.139 153.543 137.07 153.218C137 152.894 137.036 152.558 137.172 152.253C137.308 151.947 137.538 151.686 137.833 151.502C138.129 151.319 138.476 151.221 138.831 151.221C139.308 151.221 139.765 151.397 140.102 151.71C140.438 152.024 140.628 152.449 140.628 152.892Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M144.133 153.851C145.125 153.851 145.929 153.103 145.929 152.179C145.929 151.256 145.125 150.508 144.133 150.508C143.141 150.508 142.337 151.256 142.337 152.179C142.337 153.103 143.141 153.851 144.133 153.851Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.168 156.421C137.16 156.421 137.964 155.673 137.964 154.75C137.964 153.827 137.16 153.078 136.168 153.078C135.176 153.078 134.372 153.827 134.372 154.75C134.372 155.673 135.176 156.421 136.168 156.421Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M139.686 158.146C140.678 158.146 141.482 157.397 141.482 156.474C141.482 155.551 140.678 154.803 139.686 154.803C138.694 154.803 137.89 155.551 137.89 156.474C137.89 157.397 138.694 158.146 139.686 158.146Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M137.484 152.968C138.476 152.968 139.28 152.22 139.28 151.297C139.28 150.373 138.476 149.625 137.484 149.625C136.492 149.625 135.688 150.373 135.688 151.297C135.688 152.22 136.492 152.968 137.484 152.968Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.682 151.703C162.682 152.033 162.576 152.357 162.379 152.631C162.182 152.906 161.901 153.121 161.573 153.247C161.245 153.374 160.884 153.407 160.535 153.342C160.187 153.278 159.867 153.119 159.615 152.885C159.364 152.651 159.193 152.353 159.124 152.029C159.054 151.705 159.09 151.369 159.226 151.063C159.362 150.758 159.592 150.497 159.888 150.313C160.183 150.129 160.53 150.031 160.886 150.031C161.362 150.031 161.819 150.207 162.156 150.521C162.493 150.834 162.682 151.259 162.682 151.703Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M151.287 155.941C151.287 156.272 151.181 156.595 150.984 156.87C150.787 157.145 150.506 157.359 150.178 157.485C149.85 157.612 149.488 157.645 149.14 157.581C148.792 157.516 148.472 157.357 148.22 157.123C147.969 156.889 147.798 156.591 147.729 156.267C147.659 155.943 147.695 155.607 147.831 155.301C147.967 154.996 148.197 154.735 148.492 154.551C148.788 154.368 149.135 154.27 149.49 154.27C149.967 154.27 150.424 154.446 150.761 154.759C151.097 155.073 151.287 155.498 151.287 155.941Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M166.611 151.384C166.611 151.715 166.506 152.038 166.308 152.313C166.111 152.588 165.83 152.802 165.502 152.929C165.174 153.055 164.813 153.088 164.464 153.024C164.116 152.959 163.796 152.8 163.545 152.566C163.293 152.333 163.122 152.035 163.053 151.711C162.984 151.386 163.019 151.05 163.155 150.745C163.291 150.439 163.522 150.178 163.817 149.995C164.112 149.811 164.46 149.713 164.815 149.713C165.291 149.713 165.748 149.889 166.085 150.202C166.422 150.516 166.611 150.941 166.611 151.384Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M154.798 151.541C154.798 151.871 154.693 152.194 154.495 152.469C154.298 152.744 154.018 152.958 153.689 153.085C153.361 153.212 153 153.245 152.651 153.18C152.303 153.116 151.983 152.956 151.732 152.723C151.481 152.489 151.309 152.191 151.24 151.867C151.171 151.543 151.206 151.206 151.342 150.901C151.478 150.596 151.709 150.335 152.004 150.151C152.299 149.967 152.647 149.869 153.002 149.869C153.478 149.869 153.935 150.045 154.272 150.359C154.609 150.672 154.798 151.097 154.798 151.541Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.413 153.933C136.406 153.933 137.21 153.185 137.21 152.261C137.21 151.338 136.406 150.59 135.413 150.59C134.421 150.59 133.617 151.338 133.617 152.261C133.617 153.185 134.421 153.933 135.413 153.933Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.318 158.593C137.31 158.593 138.114 157.845 138.114 156.922C138.114 155.998 137.31 155.25 136.318 155.25C135.326 155.25 134.522 155.998 134.522 156.922C134.522 157.845 135.326 158.593 136.318 158.593Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M132.332 154.49C133.324 154.49 134.129 153.741 134.129 152.818C134.129 151.895 133.324 151.146 132.332 151.146C131.34 151.146 130.536 151.895 130.536 152.818C130.536 153.741 131.34 154.49 132.332 154.49Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M131.971 155.423C131.971 155.754 131.865 156.077 131.668 156.352C131.471 156.627 131.19 156.841 130.862 156.968C130.534 157.094 130.172 157.127 129.824 157.063C129.476 156.998 129.155 156.839 128.904 156.605C128.653 156.372 128.482 156.074 128.413 155.75C128.343 155.425 128.379 155.089 128.515 154.784C128.651 154.478 128.881 154.217 129.176 154.034C129.472 153.85 129.819 153.752 130.174 153.752C130.651 153.752 131.108 153.928 131.445 154.242C131.781 154.555 131.971 154.98 131.971 155.423Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M158.322 157.589C159.314 157.589 160.118 156.841 160.118 155.918C160.118 154.994 159.314 154.246 158.322 154.246C157.33 154.246 156.526 154.994 156.526 155.918C156.526 156.841 157.33 157.589 158.322 157.589Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M170.029 157.589C171.021 157.589 171.825 156.841 171.825 155.918C171.825 154.994 171.021 154.246 170.029 154.246C169.037 154.246 168.233 154.994 168.233 155.918C168.233 156.841 169.037 157.589 170.029 157.589Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.601 154.13C163.593 154.13 164.397 153.382 164.397 152.459C164.397 151.535 163.593 150.787 162.601 150.787C161.609 150.787 160.804 151.535 160.804 152.459C160.804 153.382 161.609 154.13 162.601 154.13Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M126.183 159.179C127.175 159.179 127.979 158.431 127.979 157.507C127.979 156.584 127.175 155.836 126.183 155.836C125.191 155.836 124.386 156.584 124.386 157.507C124.386 158.431 125.191 159.179 126.183 159.179Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.33 156.874C176.323 156.874 177.127 156.126 177.127 155.203C177.127 154.28 176.323 153.531 175.33 153.531C174.338 153.531 173.534 154.28 173.534 155.203C173.534 156.126 174.338 156.874 175.33 156.874Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.407 155.279C168.409 155.61 168.304 155.934 168.108 156.209C167.911 156.485 167.631 156.7 167.303 156.827C166.975 156.955 166.613 156.989 166.265 156.925C165.916 156.861 165.595 156.702 165.343 156.469C165.091 156.235 164.92 155.937 164.85 155.613C164.78 155.289 164.815 154.952 164.951 154.647C165.087 154.341 165.317 154.079 165.613 153.895C165.908 153.711 166.256 153.613 166.611 153.613C167.086 153.613 167.542 153.789 167.879 154.101C168.216 154.413 168.406 154.837 168.407 155.279Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M165.333 155.836C165.334 156.167 165.229 156.49 165.033 156.766C164.836 157.042 164.556 157.257 164.228 157.384C163.9 157.512 163.538 157.545 163.19 157.482C162.841 157.418 162.52 157.259 162.268 157.025C162.016 156.792 161.845 156.494 161.775 156.17C161.705 155.845 161.74 155.509 161.876 155.203C162.012 154.897 162.242 154.636 162.538 154.452C162.833 154.268 163.181 154.17 163.536 154.17C164.012 154.17 164.468 154.345 164.804 154.657C165.141 154.97 165.331 155.393 165.333 155.836Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M151.106 152.197C151.107 152.528 151.003 152.852 150.806 153.127C150.61 153.403 150.329 153.618 150.001 153.745C149.673 153.873 149.312 153.907 148.963 153.843C148.614 153.779 148.293 153.62 148.042 153.387C147.79 153.153 147.618 152.855 147.548 152.531C147.478 152.207 147.514 151.87 147.649 151.564C147.785 151.259 148.015 150.997 148.311 150.813C148.607 150.629 148.954 150.531 149.31 150.531C149.785 150.531 150.241 150.707 150.578 151.019C150.914 151.331 151.104 151.755 151.106 152.197Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.917 154.802C181.917 155.133 181.811 155.456 181.614 155.731C181.417 156.006 181.136 156.22 180.808 156.347C180.48 156.473 180.119 156.506 179.77 156.442C179.422 156.377 179.102 156.218 178.85 155.984C178.599 155.751 178.428 155.453 178.359 155.129C178.289 154.804 178.325 154.468 178.461 154.163C178.597 153.857 178.827 153.596 179.123 153.413C179.418 153.229 179.765 153.131 180.121 153.131C180.597 153.131 181.054 153.307 181.391 153.62C181.728 153.934 181.917 154.359 181.917 154.802Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.037 158.146C191.029 158.146 191.834 157.397 191.834 156.474C191.834 155.551 191.029 154.803 190.037 154.803C189.045 154.803 188.241 155.551 188.241 156.474C188.241 157.397 189.045 158.146 190.037 158.146Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.129 155.759C197.129 156.09 197.023 156.414 196.826 156.689C196.628 156.964 196.347 157.178 196.019 157.304C195.69 157.431 195.328 157.463 194.98 157.398C194.631 157.333 194.311 157.174 194.06 156.939C193.809 156.705 193.639 156.407 193.57 156.082C193.501 155.757 193.538 155.421 193.675 155.116C193.812 154.81 194.043 154.55 194.339 154.367C194.635 154.184 194.983 154.087 195.339 154.088C195.574 154.088 195.808 154.131 196.025 154.215C196.243 154.299 196.44 154.423 196.607 154.578C196.773 154.733 196.905 154.917 196.994 155.12C197.084 155.323 197.13 155.54 197.129 155.759Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M186.613 157.507C187.605 157.507 188.41 156.759 188.41 155.836C188.41 154.912 187.605 154.164 186.613 154.164C185.621 154.164 184.817 154.912 184.817 155.836C184.817 156.759 185.621 157.507 186.613 157.507Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.36 156.392C185.361 156.723 185.257 157.047 185.06 157.323C184.863 157.598 184.583 157.813 184.255 157.941C183.927 158.068 183.566 158.102 183.217 158.038C182.868 157.974 182.547 157.816 182.295 157.582C182.044 157.349 181.872 157.051 181.802 156.726C181.732 156.402 181.767 156.066 181.903 155.76C182.039 155.454 182.269 155.193 182.565 155.009C182.86 154.825 183.208 154.727 183.563 154.727C184.039 154.727 184.495 154.902 184.831 155.214C185.168 155.526 185.358 155.95 185.36 156.392Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M196.468 154.97C197.46 154.97 198.264 154.222 198.264 153.299C198.264 152.375 197.46 151.627 196.468 151.627C195.476 151.627 194.671 152.375 194.671 153.299C194.671 154.222 195.476 154.97 196.468 154.97Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.42 151.477C168.366 151.804 168.209 152.109 167.969 152.353C167.729 152.597 167.417 152.769 167.071 152.848C166.726 152.927 166.363 152.908 166.029 152.795C165.695 152.681 165.405 152.478 165.195 152.211C164.985 151.944 164.864 151.625 164.849 151.295C164.835 150.964 164.925 150.637 165.11 150.354C165.296 150.072 165.567 149.847 165.889 149.708C166.212 149.568 166.572 149.522 166.923 149.573C167.156 149.606 167.379 149.682 167.58 149.796C167.782 149.909 167.957 150.059 168.096 150.236C168.235 150.413 168.335 150.613 168.391 150.826C168.446 151.039 168.456 151.26 168.42 151.477Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.996 153.132C179.941 153.458 179.784 153.762 179.543 154.006C179.303 154.249 178.991 154.421 178.646 154.499C178.301 154.577 177.939 154.558 177.605 154.445C177.272 154.332 176.982 154.129 176.772 153.862C176.562 153.596 176.442 153.277 176.426 152.947C176.411 152.617 176.501 152.29 176.685 152.008C176.869 151.725 177.139 151.5 177.461 151.36C177.783 151.22 178.142 151.172 178.493 151.222C178.726 151.256 178.95 151.331 179.152 151.445C179.354 151.56 179.53 151.71 179.67 151.887C179.81 152.064 179.91 152.266 179.966 152.479C180.022 152.693 180.032 152.915 179.996 153.132Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.389 153.868C168.334 154.195 168.177 154.499 167.936 154.742C167.696 154.985 167.384 155.157 167.039 155.235C166.694 155.313 166.332 155.295 165.998 155.181C165.665 155.068 165.375 154.865 165.165 154.599C164.955 154.332 164.835 154.014 164.819 153.684C164.804 153.354 164.894 153.027 165.078 152.744C165.262 152.462 165.532 152.236 165.854 152.096C166.176 151.957 166.535 151.909 166.886 151.959C167.356 152.027 167.779 152.267 168.061 152.625C168.342 152.983 168.46 153.43 168.389 153.868Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.36 153.178C185.306 153.505 185.149 153.81 184.909 154.054C184.669 154.298 184.356 154.47 184.011 154.549C183.666 154.628 183.303 154.609 182.969 154.496C182.635 154.383 182.344 154.18 182.134 153.913C181.924 153.645 181.804 153.326 181.789 152.996C181.774 152.665 181.865 152.338 182.05 152.055C182.235 151.773 182.506 151.548 182.829 151.409C183.152 151.27 183.511 151.223 183.863 151.274C184.332 151.343 184.753 151.582 185.034 151.938C185.314 152.295 185.431 152.741 185.36 153.178Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M196 152.753C195.945 153.079 195.788 153.384 195.548 153.627C195.307 153.87 194.995 154.042 194.65 154.12C194.305 154.198 193.943 154.179 193.609 154.066C193.276 153.953 192.986 153.75 192.776 153.484C192.566 153.217 192.446 152.899 192.431 152.568C192.415 152.238 192.505 151.911 192.689 151.629C192.873 151.346 193.143 151.121 193.465 150.981C193.787 150.841 194.146 150.793 194.497 150.843C194.73 150.877 194.955 150.953 195.157 151.067C195.359 151.181 195.535 151.331 195.674 151.508C195.814 151.685 195.914 151.887 195.97 152.1C196.026 152.314 196.036 152.536 196 152.753Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.328 152.749C200.274 153.076 200.116 153.38 199.876 153.623C199.636 153.866 199.324 154.038 198.979 154.116C198.634 154.194 198.271 154.175 197.938 154.062C197.604 153.949 197.314 153.746 197.105 153.48C196.895 153.213 196.774 152.895 196.759 152.565C196.744 152.234 196.834 151.908 197.018 151.625C197.202 151.342 197.472 151.117 197.794 150.977C198.115 150.838 198.474 150.79 198.825 150.84C199.059 150.873 199.283 150.949 199.485 151.063C199.687 151.177 199.863 151.327 200.003 151.504C200.142 151.681 200.243 151.883 200.299 152.096C200.355 152.31 200.365 152.532 200.328 152.749Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.324 155.788C199.271 156.115 199.114 156.42 198.875 156.664C198.635 156.908 198.323 157.081 197.978 157.16C197.633 157.239 197.27 157.221 196.936 157.108C196.602 156.995 196.311 156.793 196.101 156.527C195.891 156.26 195.77 155.941 195.754 155.611C195.738 155.281 195.828 154.953 196.012 154.671C196.196 154.388 196.467 154.162 196.789 154.022C197.111 153.882 197.47 153.834 197.821 153.885C198.292 153.952 198.714 154.19 198.996 154.547C199.278 154.904 199.396 155.35 199.324 155.788Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.524 152.591C190.469 152.916 190.312 153.219 190.073 153.462C189.833 153.704 189.522 153.875 189.178 153.952C188.834 154.03 188.473 154.011 188.141 153.898C187.809 153.785 187.52 153.583 187.311 153.317C187.102 153.052 186.982 152.734 186.967 152.405C186.952 152.076 187.042 151.751 187.225 151.469C187.409 151.188 187.678 150.963 187.999 150.824C188.319 150.685 188.677 150.637 189.027 150.687C189.26 150.721 189.483 150.796 189.684 150.91C189.886 151.024 190.061 151.173 190.2 151.35C190.339 151.527 190.439 151.728 190.495 151.94C190.55 152.153 190.56 152.374 190.524 152.591Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.721 152.017C176.667 152.343 176.509 152.647 176.269 152.891C176.029 153.134 175.716 153.305 175.371 153.384C175.026 153.462 174.664 153.443 174.331 153.33C173.997 153.216 173.707 153.014 173.497 152.747C173.288 152.481 173.167 152.162 173.152 151.832C173.136 151.502 173.226 151.175 173.411 150.893C173.595 150.61 173.865 150.385 174.186 150.245C174.508 150.105 174.867 150.057 175.218 150.107C175.689 150.176 176.112 150.415 176.393 150.773C176.675 151.131 176.793 151.578 176.721 152.017Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M171.27 152.666C171.215 152.993 171.058 153.297 170.817 153.54C170.576 153.783 170.264 153.955 169.918 154.032C169.573 154.11 169.211 154.091 168.877 153.977C168.543 153.863 168.254 153.66 168.044 153.393C167.835 153.126 167.715 152.807 167.701 152.477C167.686 152.146 167.777 151.819 167.962 151.537C168.147 151.255 168.418 151.03 168.74 150.891C169.063 150.752 169.422 150.705 169.773 150.756C170.244 150.825 170.666 151.065 170.947 151.423C171.227 151.781 171.344 152.228 171.27 152.666Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.584 152.128C173.528 152.453 173.37 152.755 173.13 152.996C172.89 153.238 172.579 153.408 172.235 153.485C171.891 153.561 171.531 153.542 171.199 153.428C170.867 153.315 170.579 153.112 170.37 152.847C170.162 152.581 170.043 152.264 170.028 151.935C170.013 151.606 170.103 151.281 170.287 151C170.471 150.719 170.74 150.494 171.06 150.355C171.38 150.216 171.738 150.169 172.087 150.218C172.321 150.252 172.545 150.327 172.746 150.442C172.948 150.556 173.124 150.706 173.263 150.883C173.402 151.061 173.502 151.262 173.557 151.476C173.612 151.689 173.621 151.911 173.584 152.128Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M128.503 127.1C128.503 127.1 160.411 141.215 204.445 147.994" stroke="#263238"strokeWidth="3" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M130.848 130.583C130.941 130.266 130.932 129.93 130.821 129.618C130.71 129.305 130.503 129.03 130.224 128.827C129.946 128.624 129.61 128.502 129.257 128.476C128.905 128.45 128.552 128.521 128.243 128.681C127.934 128.841 127.682 129.082 127.52 129.374C127.358 129.666 127.291 129.997 127.33 130.324C127.368 130.651 127.509 130.96 127.736 131.213C127.962 131.465 128.264 131.65 128.603 131.744C128.832 131.808 129.073 131.828 129.31 131.804C129.548 131.78 129.779 131.712 129.988 131.603C130.197 131.495 130.381 131.349 130.528 131.174C130.676 130.999 130.785 130.798 130.848 130.583Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M137.453 128.372C137.555 128.055 137.555 127.717 137.451 127.401C137.347 127.085 137.144 126.804 136.869 126.595C136.593 126.386 136.257 126.258 135.903 126.226C135.549 126.195 135.193 126.262 134.881 126.419C134.568 126.576 134.312 126.816 134.145 127.108C133.979 127.4 133.91 127.732 133.946 128.061C133.982 128.39 134.123 128.702 134.35 128.957C134.577 129.211 134.879 129.398 135.22 129.492C135.676 129.619 136.167 129.572 136.585 129.362C137.004 129.152 137.316 128.796 137.453 128.372Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M156.52 134.843C156.622 134.526 156.621 134.188 156.517 133.872C156.413 133.555 156.211 133.275 155.935 133.066C155.66 132.857 155.324 132.728 154.97 132.697C154.616 132.666 154.26 132.733 153.947 132.89C153.634 133.047 153.378 133.286 153.212 133.579C153.046 133.871 152.976 134.203 153.013 134.532C153.049 134.861 153.19 135.173 153.416 135.427C153.643 135.682 153.946 135.869 154.287 135.963C154.512 136.026 154.749 136.048 154.984 136.026C155.218 136.003 155.446 135.939 155.653 135.834C155.861 135.73 156.044 135.589 156.193 135.419C156.341 135.249 156.452 135.053 156.52 134.843Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.308 140.292C174.41 139.975 174.409 139.637 174.305 139.321C174.201 139.005 173.999 138.724 173.723 138.515C173.448 138.306 173.112 138.178 172.758 138.146C172.404 138.115 172.048 138.182 171.735 138.339C171.422 138.496 171.166 138.736 171 139.028C170.834 139.32 170.764 139.652 170.801 139.981C170.837 140.31 170.978 140.622 171.204 140.877C171.431 141.131 171.734 141.318 172.075 141.412C172.3 141.476 172.537 141.497 172.772 141.475C173.006 141.453 173.234 141.388 173.441 141.284C173.649 141.18 173.832 141.038 173.981 140.868C174.129 140.698 174.24 140.502 174.308 140.292Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M128.216 126.294C128.318 125.977 128.318 125.639 128.214 125.323C128.109 125.007 127.907 124.726 127.631 124.517C127.356 124.308 127.02 124.18 126.666 124.148C126.312 124.117 125.956 124.184 125.644 124.341C125.331 124.498 125.075 124.738 124.908 125.03C124.742 125.322 124.673 125.654 124.709 125.983C124.745 126.312 124.886 126.624 125.113 126.879C125.339 127.133 125.642 127.32 125.983 127.414C126.439 127.541 126.93 127.494 127.348 127.284C127.767 127.074 128.079 126.718 128.216 126.294Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M142.068 133.722C142.171 133.405 142.17 133.067 142.066 132.751C141.962 132.434 141.759 132.154 141.484 131.945C141.208 131.736 140.873 131.607 140.519 131.576C140.165 131.545 139.809 131.612 139.496 131.769C139.183 131.926 138.927 132.165 138.761 132.458C138.595 132.75 138.525 133.082 138.562 133.411C138.598 133.74 138.738 134.052 138.965 134.306C139.192 134.561 139.495 134.747 139.836 134.842C140.061 134.905 140.298 134.927 140.533 134.904C140.767 134.882 140.995 134.817 141.202 134.713C141.409 134.609 141.593 134.468 141.741 134.298C141.89 134.128 142.001 133.932 142.068 133.722Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.927 135.806C147.029 135.49 147.028 135.152 146.924 134.836C146.82 134.52 146.617 134.24 146.342 134.031C146.067 133.822 145.731 133.693 145.378 133.662C145.024 133.63 144.669 133.697 144.356 133.854C144.043 134.01 143.787 134.249 143.62 134.541C143.454 134.833 143.384 135.164 143.419 135.493C143.455 135.822 143.594 136.134 143.82 136.389C144.046 136.644 144.348 136.831 144.688 136.926C144.914 136.99 145.151 137.011 145.386 136.989C145.621 136.967 145.849 136.902 146.057 136.798C146.265 136.694 146.449 136.553 146.598 136.383C146.747 136.212 146.859 136.017 146.927 135.806Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M140.091 131.232C140.193 130.915 140.192 130.578 140.088 130.262C139.984 129.946 139.782 129.666 139.506 129.457C139.231 129.248 138.896 129.119 138.542 129.088C138.189 129.056 137.833 129.123 137.52 129.279C137.207 129.436 136.951 129.675 136.785 129.967C136.618 130.259 136.548 130.59 136.583 130.919C136.619 131.248 136.758 131.559 136.984 131.814C137.21 132.07 137.512 132.257 137.852 132.352C138.078 132.415 138.315 132.437 138.55 132.415C138.785 132.393 139.013 132.328 139.221 132.224C139.429 132.12 139.613 131.979 139.762 131.808C139.912 131.638 140.023 131.442 140.091 131.232Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M143.989 130.519C144.091 130.202 144.089 129.864 143.984 129.549C143.879 129.233 143.675 128.953 143.399 128.745C143.123 128.536 142.787 128.409 142.434 128.378C142.08 128.348 141.724 128.415 141.412 128.573C141.099 128.731 140.844 128.971 140.679 129.263C140.513 129.556 140.444 129.887 140.481 130.216C140.518 130.545 140.659 130.856 140.886 131.111C141.113 131.365 141.416 131.551 141.757 131.645C142.213 131.771 142.705 131.723 143.123 131.512C143.542 131.301 143.854 130.944 143.989 130.519Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M140.285 134.894C140.386 134.577 140.384 134.239 140.279 133.924C140.174 133.608 139.971 133.328 139.695 133.12C139.419 132.911 139.083 132.784 138.729 132.753C138.375 132.723 138.019 132.79 137.707 132.948C137.395 133.106 137.139 133.346 136.974 133.638C136.808 133.931 136.739 134.262 136.776 134.591C136.813 134.92 136.954 135.231 137.181 135.486C137.408 135.74 137.711 135.926 138.052 136.02C138.278 136.083 138.515 136.104 138.75 136.082C138.985 136.059 139.212 135.994 139.42 135.889C139.627 135.785 139.811 135.643 139.959 135.472C140.107 135.301 140.218 135.105 140.285 134.894Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.831 140.751C162.933 140.435 162.932 140.097 162.828 139.781C162.724 139.465 162.522 139.185 162.247 138.976C161.971 138.767 161.636 138.639 161.282 138.607C160.929 138.576 160.573 138.642 160.26 138.799C159.948 138.955 159.692 139.195 159.525 139.486C159.358 139.778 159.288 140.11 159.324 140.438C159.359 140.767 159.499 141.079 159.724 141.334C159.95 141.589 160.252 141.776 160.592 141.872C160.818 141.935 161.056 141.956 161.291 141.934C161.526 141.912 161.754 141.847 161.961 141.743C162.169 141.639 162.353 141.498 162.503 141.328C162.652 141.158 162.764 140.962 162.831 140.751Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M153.22 133.653C153.323 133.337 153.322 132.999 153.218 132.682C153.114 132.366 152.911 132.086 152.636 131.876C152.36 131.667 152.024 131.539 151.67 131.508C151.317 131.476 150.961 131.543 150.648 131.7C150.335 131.857 150.079 132.097 149.913 132.389C149.746 132.682 149.677 133.013 149.713 133.343C149.75 133.672 149.89 133.983 150.117 134.238C150.344 134.493 150.647 134.679 150.987 134.774C151.443 134.9 151.934 134.853 152.353 134.643C152.771 134.433 153.083 134.077 153.22 133.653Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M166.499 142.103C166.601 141.786 166.6 141.448 166.496 141.132C166.392 140.815 166.19 140.535 165.914 140.326C165.639 140.116 165.303 139.988 164.949 139.957C164.595 139.925 164.239 139.992 163.926 140.149C163.614 140.306 163.358 140.546 163.191 140.839C163.025 141.131 162.956 141.463 162.992 141.792C163.028 142.121 163.169 142.432 163.396 142.687C163.622 142.942 163.925 143.128 164.266 143.223C164.722 143.349 165.213 143.302 165.631 143.092C166.05 142.882 166.362 142.526 166.499 142.103Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M155.247 138.808C155.35 138.491 155.349 138.153 155.245 137.837C155.141 137.52 154.938 137.24 154.663 137.031C154.387 136.822 154.051 136.693 153.698 136.662C153.344 136.63 152.988 136.698 152.675 136.854C152.362 137.011 152.106 137.251 151.94 137.544C151.773 137.836 151.704 138.168 151.74 138.497C151.777 138.826 151.917 139.138 152.144 139.392C152.371 139.647 152.674 139.833 153.014 139.928C153.47 140.053 153.96 140.005 154.378 139.795C154.796 139.585 155.109 139.231 155.247 138.808Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M138.582 133.421C138.684 133.104 138.684 132.766 138.579 132.45C138.475 132.134 138.273 131.853 137.997 131.644C137.722 131.435 137.386 131.306 137.032 131.275C136.678 131.244 136.322 131.311 136.009 131.468C135.697 131.625 135.441 131.865 135.274 132.157C135.108 132.449 135.039 132.781 135.075 133.11C135.111 133.439 135.252 133.751 135.479 134.006C135.705 134.26 136.008 134.447 136.349 134.541C136.575 134.604 136.812 134.626 137.046 134.604C137.281 134.582 137.508 134.517 137.715 134.413C137.923 134.309 138.106 134.167 138.255 133.997C138.404 133.827 138.515 133.631 138.582 133.421Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M140.902 129.189C141.003 128.873 141.001 128.535 140.896 128.22C140.791 127.904 140.587 127.625 140.312 127.417C140.036 127.208 139.7 127.081 139.347 127.05C138.993 127.019 138.638 127.087 138.326 127.244C138.014 127.401 137.758 127.641 137.592 127.933C137.426 128.225 137.357 128.556 137.393 128.884C137.429 129.213 137.569 129.524 137.795 129.779C138.021 130.034 138.323 130.22 138.663 130.315C138.889 130.379 139.127 130.4 139.362 130.377C139.598 130.355 139.826 130.29 140.034 130.185C140.242 130.08 140.426 129.939 140.575 129.768C140.724 129.597 140.835 129.4 140.902 129.189Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.819 132.067C135.921 131.751 135.921 131.413 135.816 131.096C135.712 130.78 135.51 130.5 135.234 130.29C134.959 130.081 134.623 129.953 134.269 129.922C133.915 129.89 133.559 129.957 133.246 130.114C132.934 130.271 132.678 130.511 132.511 130.803C132.345 131.096 132.276 131.428 132.312 131.757C132.348 132.086 132.489 132.397 132.716 132.652C132.942 132.907 133.245 133.093 133.586 133.188C134.042 133.314 134.533 133.267 134.951 133.057C135.37 132.847 135.682 132.491 135.819 132.067Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M134.553 128.991C134.655 128.674 134.654 128.337 134.55 128.02C134.446 127.704 134.244 127.423 133.968 127.214C133.693 127.005 133.357 126.877 133.003 126.845C132.649 126.814 132.293 126.881 131.98 127.038C131.667 127.195 131.412 127.435 131.245 127.727C131.079 128.02 131.01 128.351 131.046 128.68C131.082 129.009 131.223 129.321 131.45 129.576C131.676 129.831 131.979 130.017 132.32 130.111C132.546 130.175 132.782 130.196 133.017 130.174C133.251 130.152 133.479 130.087 133.686 129.983C133.894 129.879 134.077 129.738 134.226 129.567C134.375 129.397 134.486 129.201 134.553 128.991Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M161.659 136.026C161.761 135.71 161.761 135.372 161.656 135.055C161.552 134.739 161.35 134.459 161.074 134.249C160.799 134.04 160.463 133.912 160.109 133.881C159.755 133.849 159.399 133.916 159.086 134.073C158.774 134.23 158.518 134.47 158.351 134.762C158.185 135.055 158.116 135.386 158.152 135.716C158.188 136.045 158.329 136.356 158.556 136.611C158.782 136.866 159.085 137.052 159.426 137.147C159.652 137.21 159.889 137.231 160.123 137.209C160.358 137.187 160.585 137.122 160.792 137.018C161 136.914 161.183 136.773 161.332 136.603C161.481 136.432 161.592 136.237 161.659 136.026Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M172.886 139.15C172.987 138.833 172.985 138.495 172.88 138.179C172.775 137.864 172.571 137.584 172.296 137.375C172.02 137.167 171.684 137.04 171.33 137.009C170.976 136.979 170.62 137.046 170.308 137.204C169.996 137.361 169.74 137.602 169.575 137.894C169.409 138.187 169.34 138.518 169.377 138.847C169.414 139.176 169.555 139.487 169.782 139.742C170.009 139.996 170.312 140.182 170.653 140.276C171.109 140.402 171.601 140.354 172.02 140.143C172.438 139.932 172.75 139.575 172.886 139.15Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M164.703 140.484C164.804 140.167 164.802 139.829 164.697 139.513C164.592 139.198 164.388 138.918 164.113 138.709C163.837 138.501 163.501 138.374 163.147 138.343C162.793 138.313 162.437 138.38 162.125 138.538C161.813 138.695 161.557 138.936 161.392 139.228C161.226 139.521 161.157 139.852 161.194 140.181C161.231 140.51 161.372 140.821 161.599 141.076C161.826 141.33 162.129 141.516 162.47 141.61C162.926 141.736 163.418 141.688 163.837 141.477C164.255 141.266 164.567 140.909 164.703 140.484Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M131.372 125.939C131.474 125.622 131.473 125.285 131.369 124.969C131.265 124.653 131.062 124.373 130.787 124.164C130.512 123.955 130.176 123.826 129.823 123.795C129.469 123.763 129.114 123.83 128.801 123.986C128.488 124.143 128.232 124.382 128.065 124.674C127.899 124.966 127.829 125.297 127.864 125.626C127.9 125.955 128.039 126.266 128.265 126.521C128.491 126.777 128.793 126.964 129.133 127.059C129.359 127.122 129.596 127.144 129.831 127.122C130.066 127.1 130.294 127.035 130.502 126.931C130.71 126.827 130.894 126.686 131.043 126.516C131.192 126.345 131.304 126.149 131.372 125.939Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.744 141.251C177.847 140.934 177.846 140.596 177.742 140.28C177.638 139.964 177.435 139.683 177.16 139.474C176.884 139.265 176.548 139.137 176.194 139.105C175.841 139.074 175.485 139.141 175.172 139.298C174.859 139.455 174.603 139.695 174.437 139.987C174.27 140.279 174.201 140.611 174.237 140.94C174.274 141.269 174.414 141.581 174.641 141.836C174.868 142.09 175.171 142.277 175.511 142.371C175.737 142.435 175.974 142.456 176.208 142.434C176.443 142.412 176.67 142.347 176.878 142.243C177.085 142.139 177.269 141.997 177.417 141.827C177.566 141.657 177.677 141.461 177.744 141.251Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M169.418 138.847C169.52 138.53 169.519 138.192 169.415 137.876C169.311 137.559 169.109 137.279 168.833 137.07C168.558 136.861 168.222 136.732 167.868 136.701C167.514 136.67 167.158 136.737 166.845 136.894C166.532 137.051 166.277 137.29 166.11 137.583C165.944 137.875 165.875 138.207 165.911 138.536C165.947 138.865 166.088 139.177 166.315 139.431C166.541 139.686 166.844 139.872 167.185 139.967C167.641 140.093 168.132 140.046 168.55 139.836C168.969 139.626 169.281 139.27 169.418 138.847Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M166.649 137.495C166.751 137.178 166.75 136.84 166.646 136.524C166.542 136.208 166.34 135.927 166.064 135.718C165.789 135.509 165.453 135.381 165.099 135.349C164.745 135.318 164.389 135.385 164.076 135.542C163.763 135.699 163.507 135.939 163.341 136.231C163.175 136.524 163.105 136.855 163.142 137.184C163.178 137.513 163.318 137.825 163.545 138.08C163.772 138.335 164.075 138.521 164.416 138.615C164.641 138.679 164.878 138.7 165.113 138.678C165.347 138.656 165.575 138.591 165.782 138.487C165.99 138.383 166.173 138.242 166.322 138.071C166.47 137.901 166.581 137.705 166.649 137.495Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M151.892 137.181C151.993 136.864 151.991 136.527 151.886 136.211C151.781 135.895 151.578 135.615 151.302 135.407C151.026 135.198 150.69 135.071 150.336 135.04C149.982 135.01 149.627 135.078 149.314 135.235C149.002 135.393 148.747 135.633 148.581 135.925C148.415 136.218 148.347 136.549 148.383 136.878C148.42 137.207 148.561 137.518 148.788 137.773C149.015 138.027 149.318 138.213 149.659 138.307C150.115 138.433 150.607 138.386 151.026 138.174C151.444 137.963 151.756 137.606 151.892 137.181Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M182.241 142.911C182.344 142.594 182.343 142.256 182.239 141.94C182.135 141.624 181.932 141.343 181.657 141.134C181.381 140.925 181.045 140.797 180.691 140.765C180.338 140.734 179.982 140.801 179.669 140.958C179.356 141.115 179.1 141.355 178.934 141.647C178.767 141.94 178.698 142.271 178.734 142.6C178.771 142.929 178.911 143.241 179.138 143.496C179.365 143.751 179.668 143.937 180.008 144.031C180.234 144.095 180.471 144.116 180.705 144.094C180.94 144.072 181.167 144.007 181.375 143.903C181.582 143.799 181.765 143.658 181.914 143.487C182.063 143.317 182.174 143.121 182.241 142.911Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M192.22 143.931C192.322 143.615 192.321 143.277 192.217 142.961C192.113 142.645 191.911 142.365 191.635 142.156C191.36 141.947 191.025 141.818 190.671 141.787C190.318 141.755 189.962 141.822 189.649 141.979C189.336 142.135 189.08 142.374 188.914 142.666C188.747 142.958 188.677 143.289 188.713 143.618C188.748 143.947 188.888 144.259 189.113 144.514C189.339 144.769 189.641 144.956 189.981 145.051C190.207 145.115 190.445 145.136 190.679 145.114C190.914 145.092 191.142 145.027 191.35 144.923C191.558 144.819 191.742 144.678 191.891 144.508C192.041 144.337 192.153 144.142 192.22 143.931Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.079 146.056C197.181 145.739 197.181 145.401 197.077 145.085C196.973 144.768 196.77 144.488 196.495 144.279C196.219 144.07 195.883 143.941 195.529 143.91C195.175 143.879 194.819 143.946 194.507 144.103C194.194 144.26 193.938 144.499 193.771 144.792C193.605 145.084 193.536 145.416 193.572 145.745C193.608 146.074 193.749 146.386 193.976 146.64C194.203 146.895 194.505 147.081 194.846 147.176C195.072 147.239 195.309 147.26 195.543 147.238C195.778 147.216 196.005 147.151 196.213 147.047C196.42 146.943 196.603 146.802 196.752 146.632C196.901 146.462 197.012 146.266 197.079 146.056Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.753 143.653C188.855 143.337 188.854 142.999 188.75 142.682C188.646 142.366 188.444 142.086 188.168 141.876C187.893 141.667 187.557 141.539 187.203 141.508C186.849 141.476 186.493 141.543 186.18 141.7C185.867 141.857 185.611 142.097 185.445 142.389C185.279 142.682 185.209 143.013 185.246 143.343C185.282 143.672 185.423 143.983 185.649 144.238C185.876 144.493 186.179 144.679 186.52 144.774C186.745 144.837 186.982 144.858 187.217 144.836C187.451 144.814 187.679 144.749 187.886 144.645C188.094 144.541 188.277 144.4 188.426 144.23C188.574 144.059 188.685 143.864 188.753 143.653Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.983 142.295C186.085 141.978 186.083 141.64 185.978 141.324C185.873 141.008 185.669 140.728 185.393 140.52C185.117 140.312 184.781 140.184 184.428 140.154C184.074 140.123 183.718 140.191 183.406 140.348C183.093 140.506 182.838 140.746 182.673 141.039C182.507 141.331 182.438 141.663 182.475 141.992C182.512 142.32 182.653 142.632 182.88 142.886C183.107 143.14 183.41 143.326 183.75 143.42C184.207 143.547 184.699 143.499 185.117 143.288C185.536 143.077 185.848 142.719 185.983 142.295Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.41 148.709C197.511 148.392 197.509 148.054 197.404 147.738C197.299 147.422 197.095 147.142 196.82 146.934C196.544 146.726 196.208 146.598 195.854 146.568C195.5 146.537 195.144 146.605 194.832 146.762C194.52 146.92 194.264 147.16 194.099 147.453C193.933 147.745 193.864 148.077 193.901 148.406C193.938 148.735 194.079 149.046 194.306 149.3C194.533 149.555 194.836 149.74 195.177 149.835C195.633 149.961 196.125 149.913 196.544 149.702C196.962 149.491 197.274 149.133 197.41 148.709Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.251 142.515C168.301 142.188 168.245 141.853 168.091 141.555C167.937 141.257 167.691 141.008 167.384 140.84C167.078 140.673 166.724 140.593 166.369 140.612C166.014 140.631 165.673 140.748 165.39 140.948C165.106 141.147 164.892 141.421 164.776 141.734C164.659 142.046 164.645 142.384 164.735 142.704C164.825 143.025 165.016 143.313 165.282 143.532C165.548 143.752 165.878 143.893 166.231 143.937C166.701 143.997 167.178 143.881 167.556 143.614C167.935 143.348 168.185 142.953 168.251 142.515Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.852 144.002C179.901 143.674 179.844 143.34 179.689 143.043C179.533 142.745 179.287 142.497 178.98 142.33C178.673 142.163 178.32 142.085 177.965 142.105C177.61 142.124 177.27 142.242 176.987 142.442C176.704 142.642 176.491 142.916 176.375 143.228C176.259 143.541 176.245 143.879 176.336 144.198C176.426 144.518 176.617 144.806 176.883 145.025C177.149 145.244 177.479 145.385 177.832 145.429C178.303 145.489 178.78 145.372 179.159 145.104C179.538 144.837 179.787 144.44 179.852 144.002Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.962 140.199C169.011 139.871 168.954 139.538 168.799 139.24C168.644 138.943 168.397 138.695 168.09 138.528C167.783 138.361 167.43 138.282 167.075 138.302C166.72 138.322 166.38 138.439 166.097 138.639C165.814 138.839 165.601 139.113 165.485 139.425C165.369 139.738 165.355 140.076 165.446 140.396C165.536 140.716 165.727 141.003 165.993 141.222C166.259 141.442 166.589 141.582 166.942 141.627C167.413 141.686 167.89 141.569 168.269 141.302C168.648 141.034 168.897 140.637 168.962 140.199Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M184.998 145.383C185.047 145.055 184.99 144.721 184.834 144.424C184.679 144.126 184.432 143.878 184.125 143.711C183.819 143.544 183.466 143.466 183.111 143.485C182.756 143.505 182.415 143.623 182.132 143.823C181.849 144.023 181.636 144.296 181.52 144.609C181.404 144.922 181.391 145.259 181.481 145.579C181.572 145.899 181.762 146.187 182.028 146.406C182.295 146.625 182.625 146.766 182.977 146.81C183.448 146.87 183.926 146.753 184.305 146.485C184.684 146.218 184.933 145.821 184.998 145.383Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.071 148.633C195.119 148.305 195.062 147.971 194.907 147.674C194.752 147.376 194.505 147.128 194.198 146.961C193.891 146.794 193.538 146.716 193.183 146.735C192.829 146.755 192.488 146.873 192.205 147.073C191.922 147.273 191.709 147.546 191.593 147.859C191.477 148.172 191.464 148.509 191.554 148.829C191.645 149.149 191.835 149.437 192.101 149.656C192.367 149.875 192.698 150.016 193.05 150.06C193.521 150.12 193.999 150.003 194.377 149.735C194.756 149.468 195.006 149.071 195.071 148.633Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.218 149.794C199.267 149.467 199.21 149.133 199.055 148.836C198.899 148.539 198.653 148.291 198.347 148.124C198.04 147.957 197.687 147.878 197.333 147.897C196.978 147.917 196.638 148.034 196.355 148.233C196.072 148.433 195.858 148.706 195.742 149.018C195.625 149.33 195.611 149.668 195.701 149.987C195.79 150.307 195.979 150.595 196.245 150.815C196.51 151.035 196.839 151.176 197.191 151.222C197.663 151.281 198.141 151.165 198.521 150.897C198.901 150.629 199.152 150.233 199.218 149.794Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.193 146.608C199.242 146.281 199.185 145.948 199.03 145.65C198.874 145.353 198.628 145.105 198.322 144.938C198.015 144.771 197.662 144.692 197.308 144.712C196.953 144.731 196.613 144.848 196.33 145.048C196.047 145.247 195.833 145.52 195.717 145.833C195.6 146.145 195.586 146.482 195.676 146.802C195.765 147.122 195.954 147.41 196.22 147.629C196.485 147.849 196.815 147.991 197.166 148.036C197.638 148.096 198.117 147.979 198.496 147.711C198.876 147.444 199.127 147.047 199.193 146.608Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.782 147.327C189.831 146.999 189.775 146.666 189.621 146.368C189.467 146.07 189.221 145.821 188.915 145.653C188.609 145.485 188.256 145.406 187.901 145.425C187.546 145.443 187.206 145.56 186.922 145.759C186.638 145.958 186.424 146.231 186.307 146.543C186.19 146.855 186.175 147.193 186.264 147.513C186.353 147.833 186.543 148.121 186.808 148.341C187.073 148.561 187.403 148.703 187.755 148.749C188.226 148.809 188.703 148.693 189.083 148.426C189.462 148.16 189.714 147.765 189.782 147.327Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.372 144.199C176.422 143.871 176.366 143.537 176.212 143.239C176.057 142.941 175.811 142.692 175.505 142.524C175.198 142.356 174.845 142.277 174.49 142.296C174.135 142.315 173.794 142.432 173.51 142.631C173.226 142.831 173.013 143.104 172.896 143.417C172.78 143.73 172.766 144.068 172.856 144.388C172.946 144.708 173.136 144.996 173.402 145.216C173.669 145.435 173.999 145.576 174.351 145.621C174.822 145.68 175.298 145.564 175.677 145.298C176.056 145.031 176.306 144.636 176.372 144.199Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M171.357 142.114C171.406 141.787 171.349 141.453 171.194 141.156C171.039 140.859 170.792 140.611 170.486 140.444C170.179 140.277 169.826 140.198 169.472 140.218C169.117 140.237 168.777 140.354 168.494 140.554C168.211 140.753 167.997 141.026 167.881 141.338C167.765 141.651 167.75 141.988 167.84 142.308C167.929 142.628 168.119 142.916 168.384 143.135C168.649 143.355 168.979 143.496 169.33 143.542C169.802 143.602 170.281 143.485 170.66 143.217C171.04 142.95 171.291 142.553 171.357 142.114Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.409 143.253C173.458 142.925 173.401 142.592 173.246 142.295C173.091 141.997 172.844 141.75 172.538 141.583C172.231 141.416 171.878 141.337 171.524 141.356C171.169 141.376 170.829 141.493 170.546 141.692C170.263 141.892 170.049 142.165 169.933 142.477C169.817 142.789 169.802 143.127 169.892 143.446C169.981 143.766 170.171 144.054 170.436 144.274C170.701 144.494 171.031 144.635 171.382 144.681C171.616 144.711 171.854 144.698 172.083 144.643C172.311 144.587 172.526 144.49 172.714 144.358C172.902 144.225 173.061 144.059 173.18 143.87C173.299 143.68 173.377 143.471 173.409 143.253Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M269.279 127.85C269.279 127.85 241.1 147.676 199.424 162.569" stroke="#263238"strokeWidth="3" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M267.738 131.744C267.57 131.453 267.498 131.122 267.531 130.792C267.565 130.463 267.703 130.151 267.927 129.895C268.152 129.638 268.453 129.45 268.793 129.353C269.132 129.256 269.495 129.255 269.836 129.35C270.176 129.445 270.478 129.632 270.705 129.887C270.931 130.142 271.071 130.453 271.107 130.782C271.143 131.111 271.073 131.443 270.906 131.735C270.739 132.027 270.484 132.266 270.171 132.423C269.751 132.633 269.26 132.679 268.803 132.552C268.347 132.425 267.964 132.134 267.738 131.744Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M260.784 130.781C260.615 130.49 260.543 130.159 260.577 129.83C260.611 129.5 260.749 129.188 260.973 128.932C261.198 128.675 261.499 128.487 261.838 128.39C262.178 128.293 262.541 128.292 262.881 128.387C263.222 128.482 263.524 128.669 263.75 128.924C263.977 129.179 264.117 129.49 264.152 129.819C264.188 130.148 264.118 130.48 263.952 130.772C263.785 131.064 263.529 131.303 263.216 131.46C262.797 131.67 262.305 131.716 261.849 131.589C261.393 131.462 261.01 131.171 260.784 130.781Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M243.52 140.699C243.351 140.408 243.279 140.077 243.313 139.748C243.347 139.418 243.484 139.106 243.709 138.85C243.934 138.593 244.235 138.405 244.574 138.308C244.914 138.211 245.277 138.21 245.617 138.305C245.958 138.4 246.26 138.587 246.486 138.842C246.713 139.097 246.852 139.408 246.888 139.737C246.924 140.066 246.854 140.398 246.688 140.69C246.521 140.982 246.265 141.221 245.952 141.378C245.745 141.483 245.517 141.55 245.282 141.573C245.047 141.596 244.81 141.575 244.584 141.512C244.357 141.448 244.147 141.344 243.964 141.205C243.781 141.065 243.631 140.893 243.52 140.699Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M227.285 149.381C227.118 149.091 227.046 148.761 227.081 148.433C227.115 148.105 227.252 147.794 227.476 147.539C227.7 147.284 228.001 147.097 228.339 147.001C228.677 146.904 229.039 146.903 229.378 146.998C229.717 147.093 230.018 147.279 230.243 147.533C230.468 147.787 230.608 148.098 230.643 148.426C230.679 148.753 230.609 149.083 230.443 149.374C230.278 149.665 230.023 149.904 229.711 150.06C229.504 150.165 229.277 150.23 229.043 150.253C228.808 150.275 228.572 150.254 228.346 150.191C228.12 150.128 227.91 150.024 227.728 149.885C227.546 149.746 227.396 149.575 227.285 149.381Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M269.385 127.013C269.218 126.723 269.148 126.394 269.182 126.066C269.217 125.739 269.354 125.428 269.578 125.173C269.802 124.919 270.102 124.732 270.44 124.635C270.778 124.539 271.139 124.538 271.478 124.633C271.817 124.727 272.118 124.913 272.343 125.167C272.568 125.42 272.708 125.73 272.744 126.057C272.78 126.385 272.712 126.714 272.547 127.005C272.382 127.296 272.128 127.535 271.817 127.692C271.61 127.797 271.382 127.863 271.147 127.885C270.913 127.908 270.675 127.887 270.449 127.824C270.223 127.761 270.013 127.657 269.83 127.517C269.647 127.378 269.496 127.207 269.385 127.013Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M257.428 136.892C257.26 136.601 257.188 136.27 257.222 135.941C257.255 135.612 257.393 135.299 257.618 135.043C257.842 134.787 258.143 134.598 258.483 134.501C258.823 134.404 259.186 134.403 259.526 134.498C259.866 134.593 260.169 134.78 260.395 135.035C260.621 135.29 260.761 135.602 260.797 135.931C260.833 136.26 260.763 136.591 260.596 136.883C260.43 137.175 260.174 137.415 259.861 137.571C259.442 137.781 258.95 137.828 258.494 137.7C258.037 137.573 257.654 137.282 257.428 136.892Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M253.131 139.869C252.965 139.579 252.894 139.249 252.928 138.922C252.963 138.594 253.101 138.283 253.325 138.029C253.548 137.774 253.848 137.587 254.186 137.491C254.524 137.395 254.886 137.394 255.224 137.488C255.563 137.583 255.864 137.769 256.089 138.022C256.315 138.276 256.454 138.585 256.49 138.913C256.527 139.24 256.458 139.57 256.293 139.861C256.128 140.152 255.874 140.391 255.564 140.548C255.144 140.758 254.652 140.804 254.196 140.677C253.74 140.55 253.357 140.259 253.131 139.869Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M258.826 134.064C258.657 133.773 258.585 133.442 258.619 133.113C258.652 132.784 258.79 132.471 259.015 132.215C259.239 131.959 259.54 131.77 259.88 131.673C260.22 131.576 260.583 131.575 260.923 131.67C261.263 131.765 261.566 131.952 261.792 132.207C262.018 132.462 262.158 132.773 262.194 133.102C262.23 133.431 262.16 133.763 261.993 134.055C261.827 134.347 261.571 134.586 261.258 134.743C260.839 134.953 260.347 135 259.891 134.872C259.435 134.745 259.051 134.454 258.826 134.064Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M254.853 134.117C254.684 133.826 254.612 133.495 254.646 133.166C254.679 132.836 254.817 132.524 255.042 132.268C255.266 132.011 255.567 131.823 255.907 131.726C256.247 131.629 256.61 131.628 256.95 131.723C257.29 131.818 257.593 132.005 257.819 132.26C258.045 132.515 258.185 132.826 258.221 133.155C258.257 133.484 258.187 133.816 258.02 134.108C257.854 134.4 257.598 134.639 257.285 134.796C256.866 135.006 256.374 135.052 255.918 134.925C255.462 134.798 255.078 134.507 254.853 134.117Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M259.431 137.705C259.262 137.414 259.19 137.083 259.224 136.753C259.257 136.424 259.395 136.112 259.62 135.856C259.844 135.599 260.145 135.411 260.485 135.314C260.825 135.217 261.188 135.216 261.528 135.311C261.868 135.406 262.171 135.593 262.397 135.848C262.623 136.102 262.763 136.414 262.799 136.743C262.835 137.072 262.765 137.404 262.598 137.696C262.432 137.988 262.176 138.227 261.863 138.384C261.444 138.594 260.952 138.64 260.496 138.513C260.04 138.386 259.656 138.095 259.431 137.705Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M238.624 147.675C238.455 147.384 238.383 147.053 238.417 146.724C238.451 146.395 238.588 146.082 238.813 145.826C239.037 145.57 239.339 145.381 239.678 145.284C240.018 145.187 240.381 145.186 240.721 145.281C241.062 145.376 241.364 145.563 241.59 145.818C241.816 146.073 241.956 146.385 241.992 146.714C242.028 147.043 241.958 147.374 241.792 147.666C241.625 147.958 241.369 148.198 241.056 148.354C240.849 148.459 240.621 148.525 240.386 148.547C240.152 148.57 239.914 148.549 239.688 148.486C239.462 148.423 239.252 148.319 239.069 148.18C238.886 148.04 238.735 147.869 238.624 147.675Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M246.482 138.918C246.314 138.627 246.242 138.295 246.276 137.966C246.309 137.637 246.447 137.325 246.672 137.068C246.896 136.812 247.197 136.624 247.537 136.527C247.877 136.43 248.24 136.429 248.58 136.524C248.92 136.619 249.223 136.805 249.449 137.06C249.675 137.315 249.815 137.627 249.851 137.956C249.887 138.285 249.817 138.616 249.65 138.908C249.484 139.201 249.228 139.44 248.915 139.597C248.707 139.701 248.48 139.767 248.245 139.79C248.01 139.812 247.773 139.791 247.547 139.728C247.321 139.665 247.11 139.561 246.928 139.422C246.745 139.283 246.594 139.111 246.482 138.918Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.324 149.689C235.156 149.398 235.084 149.067 235.118 148.738C235.151 148.409 235.289 148.096 235.514 147.84C235.738 147.584 236.039 147.395 236.379 147.298C236.719 147.201 237.082 147.2 237.422 147.295C237.762 147.39 238.065 147.577 238.291 147.832C238.517 148.087 238.657 148.399 238.693 148.727C238.729 149.056 238.659 149.388 238.492 149.68C238.326 149.972 238.07 150.211 237.757 150.368C237.337 150.578 236.846 150.625 236.39 150.497C235.933 150.37 235.55 150.079 235.324 149.689Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M245.628 144.343C245.459 144.052 245.387 143.721 245.421 143.392C245.455 143.063 245.593 142.75 245.817 142.494C246.042 142.238 246.343 142.049 246.682 141.952C247.022 141.855 247.385 141.854 247.725 141.949C248.066 142.044 248.368 142.231 248.594 142.486C248.821 142.741 248.961 143.053 248.996 143.382C249.032 143.711 248.962 144.042 248.796 144.334C248.629 144.626 248.373 144.866 248.06 145.022C247.641 145.232 247.149 145.279 246.693 145.152C246.237 145.024 245.854 144.733 245.628 144.343Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M260.765 135.939C260.597 135.648 260.525 135.317 260.558 134.988C260.592 134.659 260.73 134.346 260.954 134.09C261.179 133.834 261.48 133.645 261.82 133.548C262.159 133.451 262.522 133.45 262.863 133.545C263.203 133.64 263.505 133.827 263.732 134.082C263.958 134.337 264.098 134.649 264.134 134.977C264.17 135.306 264.1 135.638 263.933 135.93C263.767 136.222 263.511 136.461 263.198 136.618C262.778 136.828 262.287 136.875 261.83 136.747C261.374 136.62 260.991 136.329 260.765 135.939Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M257.591 132.23C257.422 131.939 257.35 131.608 257.384 131.279C257.417 130.95 257.555 130.637 257.78 130.381C258.004 130.125 258.305 129.936 258.645 129.839C258.985 129.742 259.348 129.741 259.688 129.836C260.028 129.931 260.331 130.118 260.557 130.373C260.783 130.628 260.923 130.94 260.959 131.268C260.995 131.597 260.925 131.929 260.759 132.221C260.592 132.513 260.336 132.753 260.023 132.909C259.604 133.119 259.112 133.166 258.656 133.038C258.2 132.911 257.817 132.62 257.591 132.23Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M263.191 134.093C263.023 133.802 262.951 133.471 262.985 133.142C263.018 132.813 263.156 132.5 263.381 132.244C263.605 131.988 263.906 131.799 264.246 131.702C264.586 131.605 264.949 131.604 265.289 131.699C265.629 131.794 265.932 131.981 266.158 132.236C266.384 132.491 266.524 132.803 266.56 133.132C266.596 133.461 266.526 133.792 266.359 134.084C266.193 134.376 265.937 134.616 265.624 134.772C265.416 134.877 265.189 134.943 264.954 134.965C264.719 134.988 264.482 134.967 264.256 134.904C264.03 134.841 263.819 134.737 263.637 134.598C263.454 134.458 263.303 134.287 263.191 134.093Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M263.765 130.843C263.599 130.553 263.528 130.224 263.563 129.896C263.597 129.569 263.735 129.258 263.959 129.003C264.183 128.749 264.482 128.562 264.821 128.465C265.159 128.369 265.52 128.368 265.858 128.463C266.197 128.557 266.498 128.743 266.723 128.997C266.949 129.25 267.088 129.56 267.125 129.887C267.161 130.215 267.092 130.545 266.927 130.836C266.762 131.127 266.508 131.366 266.198 131.522C265.99 131.627 265.763 131.693 265.528 131.715C265.293 131.738 265.056 131.717 264.83 131.654C264.604 131.591 264.393 131.487 264.21 131.348C264.028 131.208 263.877 131.037 263.765 130.843Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M238.73 142.83C238.561 142.539 238.489 142.208 238.523 141.878C238.557 141.549 238.694 141.237 238.919 140.981C239.143 140.724 239.445 140.536 239.784 140.439C240.124 140.342 240.487 140.341 240.827 140.436C241.168 140.531 241.47 140.718 241.696 140.972C241.923 141.227 242.062 141.539 242.098 141.868C242.134 142.197 242.064 142.529 241.898 142.821C241.731 143.113 241.475 143.352 241.162 143.509C240.743 143.719 240.251 143.765 239.795 143.638C239.339 143.51 238.956 143.22 238.73 142.83Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.395 147.994C228.227 147.703 228.155 147.372 228.188 147.042C228.222 146.713 228.36 146.401 228.584 146.145C228.809 145.888 229.11 145.7 229.45 145.603C229.789 145.506 230.152 145.505 230.493 145.6C230.833 145.695 231.135 145.882 231.361 146.137C231.588 146.392 231.728 146.703 231.763 147.032C231.799 147.361 231.73 147.693 231.563 147.985C231.396 148.277 231.14 148.516 230.828 148.673C230.408 148.883 229.916 148.929 229.46 148.802C229.004 148.675 228.621 148.384 228.395 147.994Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M236.728 147.763C236.559 147.472 236.487 147.141 236.521 146.812C236.555 146.483 236.692 146.17 236.917 145.914C237.141 145.658 237.443 145.469 237.782 145.372C238.122 145.275 238.485 145.274 238.825 145.369C239.166 145.464 239.468 145.651 239.694 145.906C239.92 146.161 240.06 146.473 240.096 146.802C240.132 147.131 240.062 147.462 239.896 147.754C239.729 148.046 239.473 148.286 239.16 148.442C238.741 148.652 238.249 148.699 237.793 148.571C237.337 148.444 236.954 148.153 236.728 147.763Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M266.216 127.246C266.048 126.955 265.976 126.624 266.01 126.294C266.043 125.965 266.181 125.653 266.406 125.397C266.63 125.14 266.931 124.952 267.271 124.855C267.611 124.758 267.974 124.757 268.314 124.852C268.654 124.947 268.957 125.134 269.183 125.389C269.409 125.643 269.549 125.955 269.585 126.284C269.621 126.613 269.551 126.945 269.384 127.237C269.218 127.529 268.962 127.768 268.649 127.925C268.23 128.135 267.738 128.181 267.282 128.054C266.825 127.927 266.442 127.636 266.216 127.246Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.123 150.966C223.954 150.675 223.882 150.344 223.916 150.015C223.949 149.686 224.087 149.374 224.312 149.117C224.536 148.861 224.837 148.672 225.177 148.575C225.517 148.478 225.88 148.477 226.22 148.572C226.56 148.667 226.863 148.854 227.089 149.109C227.315 149.364 227.455 149.676 227.491 150.005C227.527 150.334 227.457 150.665 227.291 150.957C227.124 151.249 226.868 151.489 226.555 151.646C226.348 151.751 226.12 151.817 225.885 151.84C225.65 151.863 225.413 151.842 225.186 151.779C224.96 151.716 224.749 151.612 224.567 151.472C224.384 151.333 224.233 151.161 224.123 150.966Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M231.757 147.048C231.588 146.757 231.516 146.426 231.55 146.097C231.584 145.768 231.721 145.456 231.946 145.199C232.171 144.943 232.472 144.754 232.811 144.657C233.151 144.561 233.514 144.559 233.854 144.654C234.195 144.749 234.497 144.936 234.723 145.191C234.95 145.446 235.089 145.758 235.125 146.087C235.161 146.416 235.091 146.747 234.925 147.039C234.758 147.331 234.502 147.571 234.189 147.728C233.77 147.938 233.278 147.984 232.822 147.857C232.366 147.729 231.983 147.439 231.757 147.048Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M234.183 145.203C234.014 144.912 233.942 144.581 233.976 144.251C234.01 143.922 234.148 143.61 234.372 143.354C234.597 143.097 234.898 142.909 235.238 142.812C235.577 142.715 235.94 142.714 236.281 142.809C236.621 142.904 236.923 143.091 237.149 143.346C237.376 143.601 237.516 143.912 237.551 144.241C237.587 144.57 237.518 144.902 237.351 145.194C237.184 145.486 236.928 145.725 236.615 145.882C236.408 145.987 236.18 146.052 235.946 146.075C235.711 146.097 235.474 146.076 235.247 146.013C235.021 145.95 234.811 145.846 234.628 145.707C234.446 145.568 234.294 145.396 234.183 145.203Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M248.559 142.127C248.391 141.836 248.319 141.504 248.353 141.175C248.386 140.846 248.524 140.534 248.749 140.277C248.973 140.021 249.274 139.833 249.614 139.736C249.954 139.639 250.317 139.638 250.657 139.733C250.997 139.828 251.3 140.014 251.526 140.269C251.752 140.524 251.892 140.836 251.928 141.165C251.964 141.494 251.894 141.825 251.727 142.117C251.561 142.409 251.305 142.649 250.992 142.806C250.572 143.016 250.081 143.062 249.625 142.935C249.168 142.807 248.785 142.517 248.559 142.127Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.112 153.434C219.945 153.143 219.874 152.814 219.908 152.486C219.942 152.158 220.08 151.847 220.304 151.592C220.528 151.337 220.828 151.15 221.166 151.053C221.505 150.957 221.866 150.956 222.205 151.051C222.544 151.146 222.845 151.332 223.07 151.586C223.296 151.84 223.435 152.151 223.471 152.478C223.506 152.806 223.437 153.136 223.271 153.427C223.105 153.718 222.85 153.957 222.539 154.113C222.12 154.321 221.629 154.366 221.175 154.239C220.72 154.112 220.338 153.822 220.112 153.434Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M210.526 156.34C210.356 156.049 210.283 155.718 210.316 155.389C210.349 155.06 210.486 154.747 210.709 154.49C210.933 154.233 211.234 154.044 211.574 153.946C211.913 153.848 212.276 153.846 212.617 153.94C212.957 154.035 213.26 154.221 213.487 154.476C213.714 154.73 213.855 155.042 213.891 155.371C213.928 155.7 213.859 156.031 213.693 156.324C213.527 156.616 213.271 156.856 212.958 157.013C212.54 157.224 212.049 157.271 211.593 157.145C211.137 157.019 210.753 156.729 210.526 156.34Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.229 159.306C206.06 159.015 205.988 158.684 206.022 158.355C206.055 158.026 206.193 157.713 206.418 157.457C206.642 157.201 206.943 157.012 207.283 156.915C207.623 156.818 207.986 156.817 208.326 156.912C208.666 157.007 208.969 157.194 209.195 157.449C209.421 157.704 209.561 158.016 209.597 158.345C209.633 158.674 209.563 159.005 209.397 159.297C209.23 159.589 208.974 159.829 208.661 159.985C208.242 160.195 207.75 160.242 207.294 160.114C206.838 159.987 206.455 159.696 206.229 159.306Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.863 155.388C213.694 155.097 213.622 154.766 213.656 154.437C213.69 154.108 213.827 153.795 214.052 153.539C214.276 153.283 214.578 153.094 214.917 152.997C215.257 152.9 215.62 152.899 215.96 152.994C216.301 153.089 216.603 153.276 216.829 153.531C217.055 153.786 217.195 154.098 217.231 154.427C217.267 154.756 217.197 155.087 217.031 155.379C216.864 155.671 216.608 155.911 216.295 156.067C215.876 156.277 215.384 156.324 214.928 156.196C214.472 156.069 214.089 155.778 213.863 155.388Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.289 153.543C216.119 153.253 216.046 152.922 216.079 152.592C216.112 152.263 216.249 151.95 216.472 151.693C216.696 151.436 216.997 151.247 217.337 151.149C217.676 151.051 218.039 151.049 218.38 151.144C218.72 151.238 219.023 151.424 219.25 151.679C219.477 151.933 219.618 152.245 219.654 152.574C219.691 152.903 219.622 153.234 219.456 153.527C219.29 153.819 219.034 154.059 218.721 154.216C218.303 154.427 217.812 154.474 217.356 154.348C216.9 154.222 216.516 153.932 216.289 153.543Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.478 161.97C206.31 161.679 206.238 161.348 206.271 161.019C206.305 160.69 206.443 160.377 206.667 160.121C206.892 159.865 207.193 159.676 207.533 159.579C207.872 159.482 208.235 159.481 208.576 159.576C208.916 159.671 209.218 159.858 209.445 160.113C209.671 160.368 209.811 160.68 209.847 161.009C209.882 161.338 209.813 161.669 209.646 161.961C209.479 162.253 209.223 162.493 208.911 162.649C208.491 162.859 207.999 162.906 207.543 162.778C207.087 162.651 206.704 162.36 206.478 161.97Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.69 150.42C233.572 150.109 233.555 149.771 233.642 149.451C233.729 149.13 233.916 148.841 234.18 148.62C234.444 148.398 234.772 148.255 235.124 148.207C235.475 148.159 235.834 148.209 236.155 148.351C236.475 148.493 236.744 148.721 236.926 149.004C237.108 149.288 237.195 149.616 237.177 149.946C237.159 150.276 237.036 150.594 236.824 150.859C236.612 151.124 236.32 151.325 235.986 151.436C235.763 151.509 235.527 151.542 235.292 151.53C235.056 151.519 234.825 151.465 234.612 151.371C234.399 151.276 234.208 151.144 234.049 150.981C233.891 150.818 233.769 150.627 233.69 150.42Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M222.651 154.061C222.532 153.749 222.515 153.412 222.602 153.091C222.689 152.771 222.877 152.482 223.14 152.26C223.404 152.039 223.733 151.895 224.084 151.848C224.435 151.8 224.794 151.85 225.115 151.992C225.436 152.134 225.704 152.361 225.886 152.645C226.068 152.929 226.156 153.257 226.138 153.587C226.119 153.917 225.997 154.235 225.785 154.5C225.572 154.765 225.281 154.966 224.946 155.076C224.497 155.224 224.003 155.199 223.573 155.009C223.143 154.819 222.812 154.478 222.651 154.061Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M232.499 148.284C232.381 147.972 232.365 147.635 232.453 147.314C232.541 146.994 232.729 146.705 232.994 146.484C233.258 146.263 233.587 146.12 233.939 146.073C234.291 146.027 234.649 146.078 234.97 146.221C235.29 146.364 235.558 146.592 235.739 146.877C235.92 147.161 236.006 147.49 235.987 147.82C235.967 148.15 235.843 148.467 235.63 148.732C235.416 148.996 235.123 149.196 234.788 149.305C234.566 149.38 234.33 149.412 234.094 149.401C233.858 149.39 233.627 149.335 233.414 149.24C233.201 149.145 233.011 149.011 232.854 148.847C232.697 148.683 232.576 148.492 232.499 148.284Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M217.917 156.376C217.799 156.064 217.783 155.726 217.871 155.406C217.959 155.086 218.147 154.797 218.411 154.576C218.676 154.355 219.005 154.212 219.357 154.165C219.708 154.118 220.067 154.17 220.388 154.313C220.708 154.456 220.976 154.684 221.157 154.969C221.338 155.253 221.424 155.581 221.405 155.912C221.385 156.242 221.261 156.559 221.047 156.824C220.834 157.088 220.541 157.288 220.206 157.397C219.984 157.471 219.748 157.502 219.512 157.491C219.277 157.479 219.046 157.424 218.833 157.329C218.62 157.234 218.43 157.101 218.273 156.938C218.115 156.774 217.995 156.583 217.917 156.376Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M208.755 161.453C208.636 161.142 208.619 160.804 208.706 160.484C208.793 160.164 208.981 159.874 209.244 159.653C209.508 159.432 209.836 159.288 210.188 159.24C210.539 159.192 210.898 159.243 211.219 159.385C211.54 159.527 211.808 159.754 211.99 160.038C212.172 160.322 212.26 160.649 212.241 160.979C212.223 161.309 212.101 161.627 211.888 161.892C211.676 162.157 211.385 162.358 211.05 162.469C210.828 162.543 210.592 162.575 210.356 162.564C210.12 162.552 209.889 162.498 209.676 162.404C209.463 162.309 209.272 162.177 209.114 162.014C208.956 161.851 208.834 161.66 208.755 161.453Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.95 163.369C204.831 163.057 204.814 162.72 204.901 162.399C204.988 162.078 205.175 161.789 205.439 161.567C205.703 161.346 206.032 161.202 206.383 161.154C206.735 161.106 207.094 161.157 207.415 161.299C207.736 161.442 208.005 161.67 208.186 161.954C208.368 162.238 208.455 162.567 208.436 162.897C208.417 163.227 208.293 163.545 208.08 163.81C207.867 164.075 207.574 164.275 207.239 164.385C207.017 164.458 206.782 164.49 206.546 164.479C206.311 164.468 206.081 164.414 205.868 164.319C205.655 164.225 205.465 164.092 205.307 163.929C205.15 163.766 205.028 163.576 204.95 163.369Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.283 160.246C204.163 159.934 204.146 159.597 204.233 159.276C204.32 158.955 204.508 158.666 204.772 158.444C205.036 158.222 205.364 158.079 205.716 158.031C206.068 157.983 206.427 158.034 206.748 158.176C207.069 158.319 207.337 158.547 207.519 158.831C207.701 159.115 207.788 159.444 207.769 159.774C207.75 160.104 207.626 160.422 207.413 160.687C207.2 160.952 206.907 161.152 206.572 161.262C206.35 161.335 206.114 161.367 205.879 161.356C205.644 161.345 205.413 161.291 205.201 161.196C204.988 161.102 204.797 160.969 204.64 160.806C204.482 160.643 204.361 160.453 204.283 160.246Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.657 159.18C213.533 158.868 213.513 158.529 213.597 158.206C213.682 157.883 213.868 157.591 214.132 157.366C214.395 157.142 214.725 156.997 215.078 156.948C215.432 156.899 215.793 156.949 216.115 157.092C216.438 157.235 216.707 157.465 216.889 157.751C217.07 158.037 217.156 158.367 217.136 158.699C217.115 159.031 216.988 159.349 216.772 159.614C216.555 159.878 216.259 160.077 215.921 160.184C215.478 160.326 214.992 160.3 214.568 160.112C214.145 159.924 213.818 159.59 213.657 159.18Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M226.106 153.596C225.987 153.284 225.97 152.946 226.057 152.626C226.144 152.305 226.331 152.015 226.595 151.794C226.859 151.572 227.188 151.428 227.54 151.381C227.891 151.333 228.25 151.384 228.571 151.526C228.892 151.668 229.161 151.896 229.342 152.181C229.524 152.465 229.611 152.793 229.592 153.124C229.573 153.454 229.449 153.772 229.236 154.037C229.023 154.301 228.73 154.501 228.395 154.611C228.173 154.685 227.938 154.717 227.703 154.706C227.467 154.695 227.237 154.64 227.024 154.546C226.811 154.451 226.621 154.319 226.463 154.156C226.306 153.993 226.184 153.802 226.106 153.596Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M230.572 150.617C230.453 150.306 230.435 149.968 230.522 149.647C230.609 149.326 230.797 149.037 231.061 148.815C231.325 148.594 231.653 148.45 232.005 148.402C232.357 148.355 232.716 148.405 233.037 148.548C233.358 148.69 233.626 148.918 233.808 149.202C233.99 149.487 234.077 149.815 234.058 150.145C234.039 150.475 233.915 150.793 233.702 151.058C233.489 151.323 233.196 151.523 232.861 151.633C232.413 151.78 231.92 151.756 231.491 151.565C231.062 151.375 230.731 151.034 230.572 150.617Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.807 152.115C228.687 151.804 228.67 151.466 228.757 151.145C228.844 150.824 229.032 150.535 229.296 150.313C229.56 150.092 229.888 149.948 230.24 149.9C230.592 149.853 230.951 149.903 231.272 150.046C231.593 150.188 231.861 150.416 232.043 150.7C232.225 150.985 232.311 151.313 232.293 151.643C232.274 151.973 232.15 152.291 231.937 152.556C231.724 152.821 231.431 153.021 231.096 153.131C230.647 153.276 230.155 153.251 229.727 153.061C229.298 152.871 228.968 152.531 228.807 152.115Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.549 186.301C136.549 186.301 168.981 173.312 213.264 168.105" stroke="#263238"strokeWidth="3" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M139.037 182.9C139.127 183.22 139.113 183.557 138.996 183.87C138.88 184.182 138.666 184.455 138.383 184.655C138.1 184.854 137.759 184.971 137.404 184.991C137.049 185.01 136.697 184.931 136.39 184.764C136.084 184.596 135.837 184.348 135.682 184.05C135.528 183.753 135.471 183.419 135.52 183.091C135.569 182.764 135.721 182.457 135.958 182.21C136.194 181.963 136.504 181.787 136.848 181.704C137.309 181.593 137.798 181.656 138.208 181.88C138.619 182.105 138.917 182.471 139.037 182.9Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M145.549 185.36C145.637 185.68 145.622 186.018 145.504 186.33C145.387 186.641 145.173 186.914 144.889 187.113C144.605 187.311 144.264 187.427 143.91 187.446C143.555 187.464 143.203 187.385 142.897 187.217C142.591 187.049 142.345 186.8 142.191 186.503C142.037 186.205 141.981 185.871 142.03 185.544C142.08 185.217 142.233 184.91 142.469 184.664C142.706 184.417 143.016 184.241 143.359 184.159C143.588 184.104 143.826 184.091 144.06 184.122C144.293 184.152 144.518 184.226 144.722 184.337C144.925 184.449 145.103 184.597 145.245 184.772C145.386 184.948 145.49 185.147 145.549 185.36Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M164.865 179.563C164.953 179.883 164.938 180.22 164.821 180.532C164.703 180.844 164.489 181.116 164.205 181.315C163.921 181.514 163.581 181.63 163.226 181.648C162.871 181.667 162.519 181.587 162.213 181.419C161.907 181.251 161.661 181.003 161.507 180.705C161.353 180.407 161.297 180.074 161.347 179.746C161.396 179.419 161.549 179.113 161.785 178.866C162.022 178.62 162.332 178.444 162.676 178.361C162.904 178.305 163.142 178.292 163.376 178.323C163.61 178.353 163.836 178.426 164.039 178.538C164.243 178.649 164.42 178.797 164.562 178.973C164.704 179.149 164.807 179.349 164.865 179.563Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M157.829 181.583C157.918 181.903 157.903 182.24 157.785 182.552C157.668 182.864 157.454 183.137 157.17 183.335C156.886 183.534 156.545 183.65 156.191 183.669C155.836 183.687 155.483 183.607 155.177 183.44C154.871 183.272 154.626 183.023 154.472 182.725C154.317 182.428 154.262 182.094 154.311 181.767C154.361 181.439 154.513 181.133 154.75 180.886C154.986 180.64 155.296 180.464 155.64 180.381C155.869 180.326 156.107 180.314 156.34 180.344C156.574 180.375 156.799 180.448 157.002 180.56C157.206 180.671 157.383 180.819 157.525 180.995C157.667 181.17 157.77 181.37 157.829 181.583Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.237 187.107C136.327 187.427 136.312 187.765 136.196 188.077C136.079 188.39 135.866 188.663 135.583 188.863C135.299 189.062 134.959 189.179 134.604 189.198C134.249 189.218 133.896 189.139 133.59 188.971C133.283 188.804 133.037 188.556 132.882 188.258C132.727 187.96 132.671 187.627 132.72 187.299C132.769 186.972 132.921 186.665 133.157 186.418C133.394 186.171 133.704 185.995 134.048 185.912C134.508 185.802 134.997 185.866 135.407 186.09C135.817 186.314 136.115 186.68 136.237 187.107Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M150.376 180.173C150.465 180.493 150.449 180.83 150.332 181.142C150.215 181.454 150 181.727 149.717 181.925C149.433 182.124 149.092 182.24 148.737 182.258C148.383 182.277 148.03 182.197 147.724 182.029C147.418 181.861 147.173 181.613 147.018 181.315C146.864 181.017 146.808 180.684 146.858 180.356C146.907 180.029 147.06 179.723 147.297 179.476C147.533 179.23 147.843 179.054 148.187 178.971C148.415 178.916 148.653 178.904 148.887 178.934C149.121 178.965 149.346 179.038 149.549 179.15C149.752 179.261 149.93 179.409 150.072 179.585C150.214 179.76 150.317 179.96 150.376 180.173Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M155.316 178.244C155.404 178.564 155.389 178.902 155.272 179.214C155.154 179.526 154.94 179.798 154.656 179.997C154.372 180.196 154.032 180.312 153.677 180.33C153.322 180.349 152.97 180.269 152.664 180.101C152.358 179.933 152.112 179.684 151.958 179.387C151.804 179.089 151.748 178.755 151.798 178.428C151.847 178.101 152 177.794 152.236 177.548C152.473 177.301 152.783 177.126 153.127 177.043C153.355 176.987 153.593 176.974 153.827 177.004C154.061 177.034 154.287 177.108 154.49 177.219C154.694 177.331 154.871 177.479 155.013 177.655C155.155 177.831 155.258 178.031 155.316 178.244Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M152.166 183.44C152.255 183.76 152.239 184.097 152.122 184.409C152.005 184.721 151.79 184.993 151.507 185.192C151.223 185.391 150.882 185.507 150.527 185.525C150.173 185.544 149.82 185.464 149.514 185.296C149.208 185.128 148.963 184.88 148.808 184.582C148.654 184.284 148.598 183.951 148.648 183.623C148.697 183.296 148.85 182.99 149.087 182.743C149.323 182.497 149.633 182.321 149.977 182.238C150.205 182.182 150.444 182.169 150.678 182.199C150.912 182.23 151.137 182.303 151.34 182.415C151.544 182.526 151.722 182.674 151.863 182.85C152.005 183.026 152.108 183.226 152.166 183.44Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M169.667 174.298C169.756 174.618 169.74 174.955 169.623 175.267C169.506 175.579 169.291 175.852 169.008 176.05C168.724 176.249 168.383 176.365 168.028 176.383C167.674 176.402 167.321 176.322 167.015 176.154C166.709 175.986 166.464 175.738 166.31 175.44C166.155 175.142 166.099 174.809 166.149 174.481C166.199 174.154 166.351 173.848 166.588 173.601C166.824 173.355 167.134 173.179 167.478 173.096C167.707 173.041 167.944 173.029 168.178 173.059C168.412 173.09 168.637 173.163 168.84 173.275C169.044 173.386 169.221 173.534 169.363 173.71C169.505 173.885 169.608 174.085 169.667 174.298Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M161.515 180.63C161.605 180.95 161.591 181.288 161.475 181.6C161.358 181.913 161.145 182.186 160.861 182.385C160.578 182.585 160.237 182.702 159.883 182.721C159.528 182.74 159.175 182.661 158.868 182.494C158.562 182.327 158.316 182.078 158.161 181.781C158.006 181.483 157.949 181.149 157.998 180.822C158.047 180.494 158.2 180.188 158.436 179.941C158.672 179.694 158.982 179.518 159.326 179.434C159.787 179.323 160.276 179.387 160.687 179.611C161.097 179.835 161.395 180.202 161.515 180.63Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.125 172.65C175.215 172.97 175.201 173.308 175.084 173.621C174.968 173.934 174.754 174.207 174.471 174.407C174.187 174.607 173.846 174.724 173.491 174.743C173.136 174.762 172.782 174.682 172.476 174.514C172.169 174.347 171.923 174.098 171.769 173.8C171.614 173.501 171.559 173.167 171.609 172.839C171.658 172.512 171.812 172.205 172.049 171.959C172.286 171.712 172.597 171.537 172.942 171.455C173.401 171.345 173.889 171.409 174.298 171.633C174.707 171.857 175.004 172.223 175.125 172.65Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M149.023 184.659C149.111 184.979 149.096 185.316 148.978 185.628C148.861 185.94 148.647 186.213 148.363 186.412C148.079 186.61 147.738 186.726 147.384 186.745C147.029 186.763 146.677 186.684 146.371 186.516C146.065 186.348 145.819 186.099 145.665 185.801C145.511 185.504 145.455 185.17 145.504 184.843C145.554 184.515 145.707 184.209 145.943 183.963C146.18 183.716 146.49 183.54 146.833 183.458C147.062 183.403 147.3 183.39 147.534 183.421C147.767 183.451 147.992 183.524 148.196 183.636C148.399 183.748 148.577 183.895 148.719 184.071C148.86 184.246 148.964 184.446 149.023 184.659Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M170.06 178.559C170.149 178.879 170.133 179.216 170.016 179.528C169.899 179.84 169.684 180.113 169.401 180.311C169.117 180.51 168.776 180.626 168.421 180.644C168.067 180.663 167.714 180.583 167.408 180.415C167.102 180.247 166.857 179.999 166.702 179.701C166.548 179.403 166.492 179.07 166.542 178.742C166.591 178.415 166.744 178.109 166.981 177.862C167.217 177.616 167.527 177.44 167.871 177.357C168.1 177.301 168.338 177.288 168.572 177.319C168.806 177.349 169.031 177.422 169.234 177.534C169.438 177.645 169.616 177.793 169.757 177.969C169.899 178.145 170.002 178.346 170.06 178.559Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.393 175.853C181.483 176.173 181.469 176.511 181.353 176.824C181.236 177.137 181.023 177.41 180.739 177.61C180.455 177.81 180.114 177.927 179.759 177.946C179.404 177.965 179.051 177.885 178.744 177.718C178.438 177.55 178.192 177.301 178.037 177.003C177.883 176.704 177.827 176.37 177.877 176.043C177.927 175.715 178.08 175.408 178.317 175.162C178.555 174.915 178.865 174.74 179.21 174.658C179.67 174.548 180.157 174.612 180.566 174.836C180.975 175.06 181.273 175.426 181.393 175.853Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M139.368 187.589C139.456 187.909 139.441 188.246 139.324 188.558C139.206 188.87 138.992 189.143 138.708 189.341C138.424 189.54 138.084 189.656 137.729 189.674C137.374 189.693 137.022 189.613 136.716 189.445C136.41 189.277 136.164 189.029 136.01 188.731C135.856 188.433 135.8 188.1 135.85 187.772C135.899 187.445 136.052 187.139 136.288 186.892C136.525 186.646 136.835 186.47 137.179 186.387C137.407 186.332 137.645 186.32 137.879 186.35C138.112 186.381 138.337 186.454 138.541 186.566C138.744 186.677 138.922 186.825 139.064 187.001C139.206 187.176 139.309 187.376 139.368 187.589Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M186.333 173.898C186.422 174.218 186.408 174.555 186.292 174.868C186.175 175.18 185.962 175.453 185.678 175.653C185.395 175.853 185.054 175.969 184.7 175.989C184.345 176.008 183.992 175.929 183.685 175.762C183.379 175.594 183.133 175.346 182.978 175.048C182.823 174.751 182.766 174.417 182.816 174.089C182.865 173.762 183.017 173.455 183.253 173.208C183.49 172.961 183.799 172.785 184.143 172.702C184.604 172.591 185.093 172.654 185.504 172.878C185.914 173.103 186.212 173.469 186.333 173.898Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.913 176.011C178.001 176.331 177.986 176.668 177.868 176.98C177.751 177.292 177.537 177.564 177.253 177.763C176.969 177.962 176.628 178.078 176.274 178.096C175.919 178.115 175.567 178.035 175.261 177.867C174.955 177.699 174.709 177.451 174.555 177.153C174.401 176.855 174.345 176.522 174.394 176.194C174.444 175.867 174.597 175.561 174.833 175.314C175.07 175.068 175.38 174.892 175.723 174.809C175.952 174.754 176.19 174.742 176.424 174.772C176.657 174.803 176.882 174.876 177.086 174.988C177.289 175.099 177.467 175.247 177.608 175.422C177.75 175.598 177.854 175.798 177.913 176.011Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.081 177.265C175.17 177.585 175.155 177.923 175.037 178.235C174.92 178.548 174.706 178.82 174.422 179.019C174.137 179.218 173.796 179.334 173.441 179.352C173.086 179.371 172.733 179.29 172.427 179.122C172.121 178.954 171.876 178.705 171.722 178.406C171.569 178.108 171.513 177.774 171.564 177.446C171.614 177.119 171.768 176.813 172.005 176.567C172.243 176.32 172.554 176.145 172.898 176.064C173.359 175.954 173.847 176.019 174.256 176.245C174.665 176.47 174.962 176.837 175.081 177.265Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.861 172.4C190.949 172.721 190.934 173.058 190.816 173.37C190.699 173.682 190.485 173.954 190.201 174.153C189.917 174.352 189.576 174.468 189.222 174.486C188.867 174.505 188.515 174.425 188.209 174.257C187.903 174.089 187.657 173.841 187.503 173.543C187.349 173.245 187.293 172.912 187.342 172.584C187.392 172.257 187.545 171.951 187.781 171.704C188.018 171.458 188.328 171.282 188.671 171.199C188.9 171.143 189.138 171.13 189.372 171.16C189.606 171.191 189.831 171.264 190.035 171.375C190.238 171.487 190.416 171.635 190.558 171.811C190.7 171.987 190.803 172.187 190.861 172.4Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.909 171.704C200.998 172.024 200.984 172.362 200.868 172.674C200.751 172.987 200.538 173.26 200.254 173.46C199.971 173.659 199.63 173.776 199.276 173.795C198.921 173.815 198.568 173.736 198.261 173.568C197.955 173.401 197.709 173.153 197.554 172.855C197.399 172.557 197.342 172.224 197.391 171.896C197.44 171.568 197.593 171.262 197.829 171.015C198.066 170.768 198.375 170.592 198.719 170.509C199.18 170.397 199.669 170.461 200.08 170.685C200.49 170.909 200.788 171.276 200.909 171.704Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M205.848 169.776C205.937 170.096 205.921 170.434 205.804 170.746C205.687 171.057 205.473 171.33 205.189 171.529C204.905 171.727 204.564 171.843 204.209 171.862C203.855 171.88 203.502 171.801 203.196 171.633C202.89 171.465 202.645 171.216 202.491 170.919C202.336 170.621 202.28 170.287 202.33 169.96C202.38 169.633 202.532 169.326 202.769 169.08C203.005 168.833 203.315 168.657 203.659 168.575C203.888 168.52 204.125 168.507 204.359 168.538C204.593 168.568 204.818 168.642 205.021 168.753C205.225 168.865 205.402 169.013 205.544 169.188C205.686 169.364 205.789 169.563 205.848 169.776Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.428 171.884C197.518 172.204 197.504 172.542 197.387 172.854C197.271 173.166 197.058 173.44 196.774 173.639C196.491 173.839 196.15 173.956 195.795 173.975C195.441 173.994 195.088 173.915 194.781 173.748C194.475 173.581 194.228 173.332 194.073 173.035C193.919 172.737 193.862 172.403 193.911 172.076C193.96 171.748 194.113 171.442 194.349 171.195C194.585 170.948 194.895 170.771 195.239 170.688C195.7 170.577 196.189 170.641 196.599 170.865C197.01 171.089 197.308 171.455 197.428 171.884Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.896 172.307C176.931 172.636 176.861 172.967 176.694 173.259C176.527 173.551 176.27 173.79 175.957 173.946C175.644 174.102 175.288 174.169 174.934 174.137C174.581 174.104 174.245 173.976 173.97 173.766C173.696 173.557 173.494 173.276 173.39 172.96C173.287 172.643 173.286 172.305 173.389 171.989C173.492 171.672 173.694 171.392 173.968 171.182C174.242 170.972 174.578 170.842 174.931 170.809C175.166 170.787 175.403 170.808 175.629 170.871C175.855 170.934 176.066 171.039 176.248 171.178C176.431 171.317 176.582 171.488 176.693 171.682C176.804 171.876 176.873 172.088 176.896 172.307Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.553 171.21C188.588 171.539 188.518 171.87 188.351 172.162C188.184 172.454 187.927 172.693 187.614 172.849C187.301 173.006 186.945 173.072 186.591 173.04C186.238 173.008 185.902 172.879 185.627 172.669C185.353 172.46 185.151 172.179 185.047 171.863C184.944 171.546 184.943 171.209 185.046 170.892C185.149 170.576 185.35 170.295 185.625 170.085C185.899 169.875 186.235 169.745 186.588 169.713C187.062 169.671 187.534 169.804 187.902 170.085C188.27 170.365 188.504 170.77 188.553 171.21Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.748 170.051C193.784 170.38 193.713 170.711 193.546 171.003C193.379 171.294 193.123 171.534 192.81 171.69C192.496 171.846 192.14 171.912 191.787 171.88C191.433 171.848 191.098 171.719 190.823 171.51C190.548 171.3 190.346 171.019 190.243 170.703C190.139 170.387 190.139 170.049 190.242 169.733C190.345 169.416 190.546 169.135 190.82 168.925C191.095 168.715 191.43 168.586 191.784 168.553C192.258 168.509 192.731 168.643 193.099 168.923C193.468 169.204 193.701 169.61 193.748 170.051Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M203.94 167.148C203.974 167.476 203.903 167.806 203.735 168.096C203.568 168.386 203.312 168.623 203 168.778C202.688 168.933 202.333 168.998 201.981 168.966C201.629 168.933 201.295 168.804 201.022 168.595C200.748 168.386 200.548 168.107 200.445 167.791C200.342 167.476 200.342 167.14 200.445 166.825C200.548 166.51 200.748 166.23 201.022 166.021C201.295 165.812 201.629 165.683 201.981 165.651C202.216 165.629 202.452 165.651 202.678 165.714C202.903 165.778 203.112 165.882 203.294 166.021C203.476 166.16 203.627 166.332 203.738 166.525C203.848 166.718 203.917 166.93 203.94 167.148Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M198.601 168.25C198.635 168.577 198.564 168.907 198.396 169.197C198.229 169.488 197.973 169.725 197.661 169.88C197.349 170.035 196.994 170.1 196.642 170.067C196.29 170.035 195.956 169.906 195.683 169.697C195.409 169.488 195.209 169.208 195.106 168.893C195.003 168.578 195.003 168.242 195.106 167.926C195.209 167.611 195.41 167.332 195.683 167.123C195.956 166.914 196.29 166.785 196.642 166.752C196.877 166.731 197.113 166.752 197.339 166.816C197.564 166.879 197.774 166.984 197.955 167.123C198.137 167.262 198.288 167.433 198.399 167.626C198.51 167.82 198.578 168.032 198.601 168.25Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.582 200.632C174.582 200.632 163.175 200.243 159.12 202.246C155.066 204.248 149.515 206.279 149.515 206.279C149.515 206.279 155.459 199.193 156.513 197.713C157.567 196.233 161.123 197.417 164.547 196.256C167.971 195.095 167.135 194.056 171.407 193.934C175.68 193.812 175.455 193.551 177.9 193.882C180.345 194.213 185.703 196.569 185.977 197.997C186.252 199.425 187.293 200.725 186.944 201.375C186.595 202.025 178.661 200.028 174.582 200.632Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.148 200.092L175.461 196.58" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M171.333 200.655L167.752 198.084" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.651 200.406L159.863 199.1" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M250.424 191.484C249.769 186.005 247.15 182.047 241.917 181.136C238.661 180.628 235.316 181.164 232.43 182.656C227.198 181.739 225.888 184.787 222.944 187.526C220 190.266 222.289 192.094 223.268 193.91C224.247 195.727 234.395 201.525 237.994 203.957C241.593 206.389 249.439 218.572 249.439 218.572C249.439 218.572 251.079 208.833 251.403 206.697C251.728 204.561 251.079 196.963 250.424 191.484Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M261.676 169.876C258.794 165.058 254.734 162.33 249.526 163.398C246.315 164.106 243.457 165.807 241.418 168.221C236.21 169.266 236.272 172.545 234.695 176.132C233.117 179.719 235.992 180.578 237.657 181.907C239.322 183.236 251.079 184.914 255.414 185.866C259.749 186.817 272.054 195.21 272.054 195.21C272.054 195.21 269.503 185.639 268.936 183.556C268.368 181.472 264.57 174.693 261.676 169.876Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M243.426 158.267C240.545 153.455 236.485 150.751 231.277 151.795C228.065 152.503 225.207 154.204 223.168 156.618C217.967 157.663 218.029 160.942 216.451 164.529C214.873 168.116 217.748 168.975 219.414 170.304C221.079 171.633 232.836 173.311 237.171 174.257C241.505 175.203 253.805 183.601 253.805 183.601C253.805 183.601 251.26 174.03 250.686 171.947C250.112 169.863 246.314 163.084 243.426 158.267Z" fill="#263238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.335 202.697C224.335 202.697 235.736 202.308 239.79 204.31C243.844 206.313 249.395 208.373 249.395 208.373C249.395 208.373 243.458 201.286 242.397 199.806C241.337 198.326 237.788 199.51 234.364 198.35C230.94 197.189 231.776 196.15 227.503 196.028C223.231 195.906 223.455 195.645 221.017 195.976C218.578 196.307 213.208 198.663 212.909 200.091C212.609 201.519 211.593 202.819 211.948 203.469C212.304 204.119 220.25 202.093 224.335 202.697Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M219.763 202.158L223.449 198.641" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M227.578 202.722L231.164 200.15" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M236.26 202.472L239.048 201.166" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.927 197.468C218.927 197.468 229.387 193.219 233.946 193.701C238.505 194.183 244.468 194.177 244.468 194.177C244.468 194.177 236.148 189.609 234.588 188.594C233.029 187.578 230.179 189.888 226.536 189.975C222.894 190.062 223.262 188.814 219.258 190.155C215.254 191.496 215.341 191.182 213.202 192.32C211.062 193.457 206.996 197.479 207.301 198.901C207.607 200.323 207.152 201.89 207.732 202.384C208.312 202.877 214.892 198.298 218.927 197.468Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M214.462 198.525L216.507 193.998" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.952 196.389L224.279 192.768" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M229.936 193.19L232.013 191.025" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.339 191.403C195.339 191.403 189.844 192.146 189.245 196.876C188.647 201.606 188.353 207.451 193.137 210.806C197.921 214.16 205.393 221.932 205.393 221.932C206.244 219.252 207.344 216.646 208.68 214.143C210.476 211.084 214.917 203.812 214.343 199.36C213.769 194.909 212.865 191.327 208.68 190.219C204.495 189.11 200.191 193.225 200.191 193.225L195.339 191.403Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.547 194.652C200.547 194.652 200.353 202.778 202.293 208.907C203.24 211.69 204.017 214.521 204.62 217.387" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M201.707 207.104C201.707 207.104 200.353 211.613 200.16 212.327" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.36 208.183C205.2 206.378 200.933 202.594 200.933 202.594C200.933 202.594 196.474 207.283 195.894 208.729" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.033 208.361L206.753 211.252" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.568 203.314C195.137 201.313 196.959 199.495 198.994 197.898" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M202.1 197.908C202.1 197.908 208.493 200.23 210.432 203.683" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M202.873 194.107C202.873 194.107 207.139 195.013 208.886 196.638" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.441 194.107C197.441 194.107 193.761 194.833 192.595 196.458" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.249 202.611C196.364 201.118 193.02 200.58 189.763 201.085C184.53 202.002 181.911 205.96 181.256 211.439C180.601 216.918 179.946 224.527 180.276 226.657C180.607 228.787 182.241 238.538 182.241 238.538C182.241 238.538 190.087 226.349 193.686 223.923C197.285 221.497 207.407 215.705 208.412 213.877C209.416 212.048 211.68 210.226 208.736 207.492C205.792 204.759 204.482 201.694 199.249 202.611Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M198.9 204.201C198.9 204.201 192.089 210.162 187.674 217.614C183.258 225.067 183.27 233.285 183.27 233.285" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.289 210.098C204.818 210.849 205.165 211.699 205.306 212.588" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.898 205.686C201.001 206.495 202.02 207.398 202.942 208.384" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.896 209.412C194.896 209.412 197.896 212.767 198.096 215.936" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.491 208.485C191.491 208.485 187.287 208.299 184.686 209.065" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M186.688 205.128C186.688 205.128 191.091 202.708 194.896 204.757" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.293 215.936C190.293 215.936 193.493 218.356 194.291 220.579" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.085 214.629C186.366 214.573 184.657 214.893 183.096 215.564" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.48 222.269L183.881 220.963" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M187.486 221.896C187.486 221.896 189.289 223.202 189.289 224.131" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.911 202.645C223.798 201.156 227.141 200.62 230.397 201.124C235.63 202.036 238.25 205.994 238.904 211.473C239.559 216.952 240.208 224.567 239.884 226.697C239.559 228.827 237.919 238.566 237.919 238.566C237.919 238.566 230.067 226.378 226.474 223.957C222.882 221.537 212.753 215.739 211.748 213.911C210.744 212.082 208.48 210.254 211.424 207.526C214.368 204.798 215.69 201.734 220.911 202.645Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.272 204.236C221.272 204.236 228.077 210.197 232.499 217.649C236.921 225.102 236.902 233.32 236.902 233.32" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M215.884 210.127C215.355 210.879 215.009 211.728 214.867 212.617" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.287 205.727C219.184 206.536 218.165 207.439 217.243 208.425" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M225.277 209.453C225.277 209.453 222.277 212.808 222.077 215.971" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.682 208.52C228.682 208.52 232.886 208.334 235.487 209.1" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.485 205.17C233.485 205.17 229.081 202.744 225.277 204.793" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M229.88 215.971C229.88 215.971 226.68 218.397 225.875 220.614" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M232.081 214.671C233.8 214.611 235.509 214.93 237.071 215.6" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M238.692 222.31L236.285 221.004" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M232.686 221.932C232.686 221.932 230.884 223.238 230.884 224.172" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.796 196.748C176.796 196.748 171.744 194.606 168.488 198.28C165.233 201.954 161.584 206.719 163.661 211.931C165.738 217.143 167.491 227.468 167.491 227.468C169.767 225.644 172.208 224.007 174.788 222.575C178.075 220.904 186.064 217.044 188.154 212.999C190.243 208.953 191.584 205.506 188.709 202.459C185.834 199.412 179.821 200.717 179.821 200.717L176.796 196.748Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.31 202.123C179.31 202.123 174.42 208.856 172.48 214.991C171.657 217.808 170.661 220.579 169.499 223.29" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.041 213.18C173.041 213.18 169.299 216.285 168.676 216.796" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.322 216.435C176.397 214.328 175.012 208.994 175.012 208.994C175.012 208.994 168.52 210.677 167.204 211.612" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.264 215.414L174.863 219.21" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.401 205.889C170.884 204.999 173.472 204.39 176.11 204.072" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M178.723 205.635C178.723 205.635 182.734 210.858 182.372 214.695" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.58 202.842C181.58 202.842 184.642 205.744 185.16 207.996" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.014 200.109C177.014 200.109 173.497 198.855 171.576 199.638" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.154 190.172C191.154 190.172 186.064 187.328 183.008 187.804C181.665 187.975 180.388 188.453 179.293 189.197C178.198 189.94 177.319 190.926 176.734 192.064C175.543 194.275 172.661 205.169 172.661 205.169C172.661 205.169 185.316 198.263 190.973 197.908C196.63 197.554 201.869 195.366 200.852 192.685C199.836 190.003 194.21 188.274 191.154 190.172Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.8 191.438C189.8 191.438 182.503 192.064 177.239 198.983" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.8 192.697L189.626 196.487" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.896 193.805L184.879 196.962" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.11 193.017L183.52 192.541" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M183.351 190.328L187.256 191.274" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M192.844 191.908C192.844 191.908 195.563 191.908 197.091 193.33" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.52 183.696C204.52 183.696 209.609 180.858 212.665 181.328C214.009 181.501 215.285 181.981 216.38 182.726C217.475 183.47 218.354 184.456 218.94 185.594C220.131 187.805 223.013 198.699 223.013 198.699C223.013 198.699 210.358 191.793 204.726 191.456C199.094 191.119 193.829 188.908 194.846 186.232C195.863 183.557 201.464 181.804 204.52 183.696Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M205.873 184.961C205.873 184.961 213.171 185.594 218.435 192.541" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M205.873 186.225L206.048 190.015" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M209.778 187.328L210.794 190.486" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.563 186.538L212.154 186.068" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M212.322 183.857L208.424 184.803" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M202.823 185.436C202.823 185.436 200.104 185.436 198.582 186.858" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M223.2 177.321C223.2 177.321 234.607 176.938 238.661 178.935C242.715 180.931 248.266 182.969 248.266 182.969C248.266 182.969 242.322 175.882 241.268 174.402C240.214 172.922 236.659 174.106 233.235 172.945C229.811 171.784 230.647 170.745 226.374 170.623C222.102 170.502 222.326 170.246 219.882 170.577C217.437 170.908 212.079 173.258 211.805 174.686C211.53 176.114 210.489 177.414 210.838 178.064C211.187 178.714 219.121 176.718 223.2 177.321Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.634 176.783L222.32 173.271" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M226.449 177.345L230.029 174.773" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.131 177.095L237.919 175.789" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.447 179.388C173.447 179.388 162.046 178.999 157.992 181.002C153.937 183.004 148.386 185.036 148.386 185.036C148.386 185.036 154.324 177.949 155.384 176.469C156.445 174.989 159.994 176.173 163.418 175.012C166.842 173.851 166.006 172.812 170.278 172.69C174.551 172.569 174.326 172.307 176.765 172.644C179.204 172.981 184.567 175.325 184.873 176.753C185.179 178.181 186.189 179.481 185.834 180.131C185.478 180.781 177.532 178.785 173.447 179.388Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M178.019 178.849L174.333 175.338" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M170.204 179.411L166.617 176.84" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M161.522 179.161L158.734 177.855" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M178.854 174.159C178.854 174.159 168.395 169.911 163.836 170.392C159.276 170.874 153.314 170.868 153.314 170.868C153.314 170.868 161.634 166.301 163.193 165.285C164.752 164.269 167.603 166.579 171.245 166.672C174.888 166.765 174.52 165.511 178.524 166.846C182.528 168.181 182.441 167.873 184.58 169.011C186.719 170.149 190.786 174.171 190.48 175.593C190.175 177.015 190.63 178.582 190.05 179.075C189.47 179.569 182.865 174.989 178.854 174.159Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M183.32 175.215L181.274 170.688" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.829 173.079L173.503 169.463" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.846 169.882L165.769 167.717" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M202.468 168.094C202.468 168.094 207.969 168.837 208.568 173.567C209.166 178.297 209.459 184.142 204.676 187.497C199.892 190.851 192.42 198.623 192.42 198.623C191.565 195.944 190.466 193.338 189.133 190.834C187.337 187.775 182.896 180.503 183.47 176.051C184.044 171.6 184.948 168.024 189.133 166.91C193.318 165.796 197.622 169.916 197.622 169.916L202.468 168.094Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.21 171.344C197.21 171.344 197.403 179.469 195.464 185.598C194.517 188.382 193.74 191.213 193.137 194.078" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M196.075 183.793C196.075 183.793 197.428 188.303 197.622 189.017" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.416 184.872C192.582 183.073 196.848 179.283 196.848 179.283C196.848 179.283 201.308 183.973 201.888 185.418" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.748 185.059L191.029 187.943" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.214 180.003C202.643 178.004 200.821 176.186 198.788 174.588" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.682 174.588C195.682 174.588 189.283 176.939 187.349 180.363" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.908 170.805C194.908 170.805 190.642 171.704 188.896 173.329" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.328 170.805C200.328 170.805 204.008 171.524 205.175 173.126" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.986 173.452C220.986 173.452 226.038 171.304 229.293 174.978C232.549 178.652 236.198 183.4 234.121 188.624C232.044 193.847 230.266 204.167 230.266 204.167C227.99 202.343 225.548 200.706 222.969 199.274C219.682 197.602 211.692 193.743 209.603 189.697C207.514 185.652 206.173 182.204 209.048 179.157C211.923 176.11 217.936 177.416 217.936 177.416L220.986 173.452Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.472 178.814C218.472 178.814 223.362 185.547 225.333 191.682C226.157 194.498 227.15 197.269 228.308 199.982" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.74 189.871C224.74 189.871 228.482 192.982 229.106 193.487" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.46 193.126C221.385 191.019 222.769 185.686 222.769 185.686C222.769 185.686 229.25 187.386 230.578 188.303" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M223.518 192.105L222.919 195.901" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M229.381 182.58C226.896 181.691 224.306 181.081 221.665 180.764" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M219.04 182.332C219.04 182.332 215.029 187.527 215.391 191.386" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.202 179.533C216.202 179.533 213.139 182.435 212.622 184.687" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.767 176.8C220.767 176.8 224.285 175.546 226.206 176.33" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.628 166.863C206.628 166.863 211.717 164.019 214.736 164.495C216.08 164.667 217.358 165.147 218.454 165.891C219.55 166.636 220.431 167.622 221.017 168.761C222.208 170.967 225.089 181.867 225.089 181.867C225.089 181.867 212.435 174.954 206.803 174.617C201.17 174.281 195.906 172.075 196.923 169.394C197.94 166.712 203.572 164.971 206.628 166.863Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.981 168.129C207.981 168.129 215.279 168.762 220.536 175.709" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.981 169.389L208.15 173.179" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M211.886 170.496L212.902 173.653" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.672 169.706L214.262 169.23" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M214.43 167.02L210.526 167.971" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.931 168.6C204.931 168.6 202.212 168.6 200.684 170.022" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.262 160.392C193.262 160.392 188.173 157.548 185.116 158.024C183.773 158.195 182.497 158.674 181.401 159.418C180.306 160.161 179.427 161.146 178.842 162.285C177.651 164.496 174.769 175.39 174.769 175.39C174.769 175.39 187.424 168.483 193.056 168.147C198.688 167.81 203.952 165.599 202.936 162.923C201.919 160.247 196.318 158.495 193.262 160.392Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.908 161.652C191.908 161.652 184.611 162.285 179.347 169.232" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.908 162.916L191.734 166.706" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.004 164.02L186.987 167.177" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.218 163.23L185.628 162.76" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.459 160.549L189.358 161.495" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.958 162.127C194.958 162.127 197.678 162.127 199.2 163.549" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.807 152.011C216.807 152.011 205.399 151.622 201.351 153.624C197.304 155.627 191.746 157.658 191.746 157.658C191.746 157.658 197.684 150.572 198.744 149.092C199.805 147.611 203.353 148.795 206.778 147.635C210.202 146.474 209.36 145.435 213.638 145.313C217.917 145.191 217.686 144.93 220.125 145.261C222.563 145.592 227.927 147.948 228.233 149.376C228.539 150.804 229.555 152.104 229.2 152.754C228.844 153.404 220.911 151.407 216.807 152.011Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.378 151.47L217.692 147.953" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.563 152.034L209.977 149.463" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.875 151.784L202.094 150.479" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M266.56 154.077C266.56 154.077 277.967 153.688 282.021 155.691C286.075 157.693 291.626 159.725 291.626 159.725C291.626 159.725 285.682 152.638 284.628 151.158C283.574 149.678 280.013 150.862 276.595 149.701C273.177 148.54 274.007 147.501 269.734 147.379C265.462 147.258 265.686 146.996 263.241 147.327C260.796 147.658 255.458 150.02 255.214 151.477C254.971 152.934 253.898 154.205 254.248 154.855C254.597 155.505 262.48 153.474 266.56 154.077Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M261.988 153.537L265.68 150.02" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M269.803 154.1L273.389 151.529" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M278.491 153.851L281.279 152.545" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M261.152 148.849C261.152 148.849 271.611 144.6 276.171 145.082C280.73 145.564 286.699 145.558 286.699 145.558C286.699 145.558 278.379 140.99 276.813 139.974C275.248 138.959 272.404 141.269 268.767 141.356C265.131 141.443 265.493 140.195 261.483 141.536C257.472 142.876 257.572 142.563 255.433 143.701C253.293 144.838 249.196 148.86 249.532 150.282C249.869 151.704 249.383 153.271 249.963 153.765C250.543 154.258 257.117 149.679 261.152 148.849Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M256.686 149.904L258.732 145.377" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M264.183 147.768L266.503 144.146" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M272.167 144.571L274.243 142.406" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M237.539 142.77C237.539 142.77 232.044 143.513 231.445 148.243C230.846 152.974 230.547 158.812 235.331 162.173C240.114 165.533 247.586 173.299 247.586 173.299C248.441 170.619 249.543 168.013 250.88 165.51C252.67 162.451 257.117 155.179 256.543 150.727C255.969 146.276 255.065 142.695 250.88 141.586C246.695 140.477 242.385 144.593 242.385 144.593L237.539 142.77Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M242.771 146.033C242.771 146.033 242.578 154.159 244.518 160.288C245.465 163.071 246.241 165.902 246.844 168.768" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M243.938 158.482C243.938 158.482 242.578 162.992 242.385 163.706" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M248.591 159.562C247.424 157.757 243.158 153.973 243.158 153.973C243.158 153.973 238.699 158.662 238.119 160.107" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M246.264 159.742L248.977 162.633" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.792 154.692C237.363 152.691 239.187 150.873 241.225 149.277" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M244.324 149.277C244.324 149.277 250.724 151.599 252.663 155.081" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M245.098 145.486C245.098 145.486 249.364 146.392 251.11 148.017" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M239.672 145.486C239.672 145.486 235.986 146.212 234.825 147.837" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M219.04 148.134C219.04 148.134 213.988 145.993 210.732 149.667C207.476 153.34 203.827 158.106 205.911 163.318C207.994 168.53 209.74 178.849 209.74 178.849C212.016 177.027 214.458 175.391 217.037 173.962C220.324 172.291 228.308 168.431 230.397 164.385C232.487 160.34 233.828 156.887 230.959 153.845C228.089 150.804 222.065 152.104 222.065 152.104L219.04 148.134Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.534 153.502C221.534 153.502 216.645 160.235 214.705 166.369C213.881 169.186 212.887 171.957 211.73 174.669" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M215.297 164.559C215.297 164.559 211.555 167.664 210.931 168.174" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.553 167.816C218.622 165.709 217.243 160.375 217.243 160.375C217.243 160.375 210.763 162.07 209.435 162.993" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.489 166.793L217.094 170.589" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M210.626 157.281C213.111 156.392 215.701 155.783 218.341 155.465" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.948 157.014C220.948 157.014 224.965 162.237 224.597 166.074" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M223.805 154.223C223.805 154.223 226.867 157.125 227.391 159.377" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M219.239 151.476C219.239 151.476 215.721 150.223 213.8 151" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.385 141.553C233.385 141.553 228.295 138.709 225.239 139.185C223.895 139.355 222.617 139.834 221.521 140.577C220.425 141.321 219.544 142.306 218.958 143.445C217.773 145.656 214.886 156.55 214.886 156.55C214.886 156.55 227.541 149.643 233.179 149.307C238.817 148.97 244.075 146.765 243.058 144.083C242.042 141.402 236.441 139.655 233.385 141.553Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M232.025 142.818C232.025 142.818 224.728 143.445 219.47 150.393" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M232.025 144.076L231.857 147.866" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.121 145.186L227.104 148.343" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.335 144.396L225.751 143.92" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M225.576 141.709L229.48 142.655" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M235.081 143.287C235.081 143.287 237.794 143.287 239.322 144.709" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M246.744 135.075C246.744 135.075 251.834 132.237 254.89 132.707C256.233 132.879 257.51 133.359 258.606 134.102C259.702 134.845 260.583 135.83 261.171 136.967C262.356 139.178 265.243 150.072 265.243 150.072C265.243 150.072 252.588 143.166 246.95 142.829C241.312 142.492 236.054 140.281 237.077 137.605C238.1 134.93 243.695 133.183 246.744 135.075Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M248.104 136.34C248.104 136.34 255.401 136.967 260.659 143.931" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M248.104 137.605L248.273 141.395" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M252.008 138.709L253.025 141.866" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M258.794 137.919L254.385 137.449" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M254.553 135.236L250.649 136.182" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M245.048 136.816C245.048 136.816 242.335 136.816 240.807 138.238" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.507 218.89C138.393 217.398 141.737 216.86 144.994 217.364C150.226 218.281 152.846 222.233 153.501 227.718C154.156 233.203 154.811 240.806 154.48 242.936C154.15 245.066 152.522 254.788 152.522 254.788C152.522 254.788 144.669 242.6 141.07 240.174C137.472 237.748 127.349 231.955 126.351 230.127C125.353 228.299 123.077 226.476 126.021 223.743C128.964 221.009 130.274 217.973 135.507 218.89Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.863 220.475C135.863 220.475 142.667 226.441 147.089 233.888C151.511 241.334 151.493 249.558 151.493 249.558" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M130.474 226.35C129.947 227.104 129.6 227.955 129.457 228.845" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M134.84 221.967C133.738 222.775 132.721 223.679 131.802 224.666" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M139.86 225.693C139.86 225.693 136.86 229.048 136.661 232.217" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M143.266 224.764C143.266 224.764 147.47 224.578 150.07 225.344" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M148.068 221.41C148.068 221.41 143.665 218.989 139.86 221.038" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M144.47 232.217C144.47 232.217 141.264 234.637 140.465 236.86" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.69 230.911C148.408 230.854 150.117 231.172 151.68 231.839" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M153.276 238.548L150.875 237.242" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M147.27 238.178C147.27 238.178 145.468 239.478 145.468 240.412" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.605 207.086C133.717 205.593 130.371 205.055 127.112 205.559C121.879 206.477 119.247 210.429 118.623 215.902C118 221.375 117.314 228.99 117.638 231.12C117.962 233.25 119.603 242.995 119.603 242.995C119.603 242.995 127.455 230.807 131.054 228.381C134.653 225.955 144.775 220.162 145.773 218.334C146.771 216.506 149.048 214.683 146.104 211.95C143.16 209.216 141.838 206.169 136.605 207.086Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.249 208.67C136.249 208.67 129.445 214.636 125.023 222.083C120.601 229.53 120.619 237.754 120.619 237.754" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M141.644 214.561C142.169 215.316 142.514 216.167 142.655 217.056" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M137.253 210.162C138.355 210.971 139.372 211.874 140.291 212.861" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M132.245 213.887C132.245 213.887 135.251 217.241 135.451 220.41" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M128.846 212.959C128.846 212.959 124.642 212.774 122.035 213.54" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M124.037 209.605C124.037 209.605 128.44 207.185 132.245 209.234" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.642 220.41C127.642 220.41 130.848 222.83 131.646 225.053" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.44 219.106C123.722 219.049 122.013 219.367 120.451 220.035" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.835 226.743L121.237 225.438" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M124.86 226.35C124.86 226.35 126.663 227.65 126.663 228.584" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M76.5609 221.473C76.5609 221.473 67.5359 222.053 64.5234 223.951C61.5109 225.849 57.3134 227.88 57.3134 227.88C57.3134 227.88 61.3612 221.827 62.0598 220.585C62.7583 219.343 65.6648 220.004 68.2594 218.803C70.854 217.602 70.1305 216.87 73.4424 216.441C76.7542 216.011 76.5983 215.825 78.5505 215.895C80.5027 215.965 84.9435 217.404 85.2927 218.507C85.642 219.61 86.5776 220.556 86.3593 221.096C86.141 221.635 79.7231 220.672 76.5609 221.473Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M80.116 220.689L76.8914 218.205" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M74.0037 221.747L70.9475 219.994" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M67.1367 222.223L64.8228 221.416" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M92.0661 212.576C92.0661 212.576 87.8 213.592 87.7563 217.364C87.7127 221.137 88.0058 225.768 92.0786 228.026C96.1514 230.284 102.744 235.838 102.744 235.838C103.168 233.664 103.794 231.528 104.615 229.454C105.75 226.9 108.613 220.823 107.734 217.358C106.854 213.893 105.862 211.148 102.445 210.597C99.0267 210.045 96.0266 213.632 96.0266 213.632L92.0661 212.576Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M96.4882 214.74C96.4882 214.74 97.0682 221.125 99.1514 225.837C100.146 227.958 101.012 230.128 101.746 232.338" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.5277 224.451C98.5277 224.451 97.8666 228.114 97.7793 228.694" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M102.295 224.939C101.216 223.609 97.5111 220.957 97.5111 220.957C97.5111 220.957 94.3925 225.02 94.0869 226.181" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M100.474 225.264L102.875 227.324" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M91.7668 222.1C92.821 220.402 94.0899 218.827 95.5464 217.41" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.0038 217.166C98.0038 217.166 103.255 218.513 105.095 221.066" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.272 214.119C98.272 214.119 101.715 214.496 103.262 215.64" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M93.9934 214.543C93.9934 214.543 91.1555 215.402 90.3821 216.772" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M77.958 218.222C77.958 218.222 73.7792 216.928 71.5463 220.079C69.3135 223.231 66.8685 227.271 68.9767 231.217C71.0848 235.164 73.3987 243.179 73.3987 243.179C75.0283 241.564 76.8041 240.083 78.7064 238.751C81.1451 237.178 87.0952 233.527 88.3738 230.161C89.6524 226.795 90.4008 223.986 87.8624 221.809C85.3239 219.633 80.696 221.124 80.696 221.124L77.958 218.222Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M80.4216 222.285C80.4216 222.285 77.1783 227.967 76.1991 232.953C75.8054 235.239 75.2724 237.502 74.6024 239.732" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M76.4798 231.479C76.4798 231.479 73.7916 234.224 73.3613 234.671" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M79.3613 233.789C79.2303 232.123 77.6586 228.025 77.6586 228.025C77.6586 228.025 72.7064 229.871 71.7397 230.701" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M77.6461 233.145L78.4632 236.087" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M72.1638 226.1C74.0395 225.202 76.0247 224.518 78.0765 224.062" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M80.2782 225.09C80.2782 225.09 83.9081 228.874 83.9705 231.944" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M82.274 222.664C82.274 222.664 84.9497 224.719 85.5671 226.448" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M78.432 220.865C78.432 220.865 75.5442 220.157 74.1035 220.923" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M88.6857 211.932C88.6857 211.932 84.4133 210.098 82.0495 210.678C81.0056 210.917 80.042 211.394 79.2451 212.066C78.4483 212.738 77.8431 213.583 77.484 214.526C76.748 216.366 75.4569 225.176 75.4569 225.176C75.4569 225.176 84.8125 218.74 89.2221 218.037C93.6316 217.335 97.5797 215.176 96.5318 213.145C95.484 211.113 90.9185 210.196 88.6857 211.932Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M87.7252 213.035C87.7252 213.035 82.0308 214.103 78.5131 220" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M87.8374 214.027L88.0495 217.028" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M84.8624 215.205L84.3447 217.77" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M79.4424 215.112L82.879 214.393" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M82.5422 212.662L85.7044 213.103" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M90.1763 213.168C90.1763 213.168 92.3156 212.953 93.6503 213.957" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M60.9745 229.251C60.9745 229.251 56.7022 227.405 54.3383 228.02C53.2954 228.26 52.3328 228.737 51.537 229.409C50.7412 230.08 50.1371 230.926 49.7791 231.868C49.0369 233.708 47.752 242.518 47.752 242.518C47.752 242.518 57.1076 236.082 61.5109 235.38C65.9143 234.677 69.8685 232.524 68.827 230.487C67.7854 228.45 63.2074 227.509 60.9745 229.251Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M59.9953 230.354C59.9953 230.354 54.3009 231.421 50.777 237.318" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M60.1325 231.346L60.3384 234.346" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M57.1575 232.523L56.6398 235.095" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M51.7313 232.431L55.1679 231.711" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M54.8373 229.982L57.9995 230.429" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M62.4901 230.486C62.4901 230.486 64.6357 230.271 65.9642 231.275" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M98.6275 205.786C98.6275 205.786 102.37 203.145 104.833 203.278C105.908 203.308 106.959 203.586 107.89 204.088C108.821 204.589 109.603 205.299 110.166 206.151C111.301 207.8 114.563 216.163 114.563 216.163C114.563 216.163 103.96 211.706 99.4882 211.88C95.0163 212.054 90.6753 210.719 91.2366 208.531C91.7979 206.343 96.0516 204.526 98.6275 205.786Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.8125 206.674C99.8125 206.674 105.619 206.598 110.415 211.665" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M99.9248 207.666L100.405 210.643" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M103.106 208.234L104.191 210.643" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.382 207.08L104.858 207.051" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.796 205.297L101.802 206.347" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.4487 207.283C97.4487 207.283 95.3094 207.498 94.2304 208.734" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M66.6253 210.393C66.6253 210.393 70.3675 207.752 72.8623 207.886C73.9375 207.916 74.9878 208.194 75.9187 208.695C76.8495 209.197 77.6318 209.906 78.195 210.759C79.3301 212.407 82.5609 220.771 82.5609 220.771C82.5609 220.771 71.9579 216.313 67.4922 216.487C63.0265 216.661 58.6731 215.326 59.2344 213.138C59.7957 210.95 64.0432 209.134 66.6253 210.393Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M67.8103 211.258C67.8103 211.258 73.617 211.182 78.4133 216.249" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M67.9226 212.273L68.3966 215.251" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M71.0972 212.844L72.1825 215.252" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M76.3738 211.687L72.8561 211.658" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M72.7875 209.906L69.7999 210.957" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M65.4403 211.891C65.4403 211.891 63.301 212.105 62.2282 213.347" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.187 225.733C133.309 224.25 129.977 223.714 126.732 224.212C121.499 225.124 118.879 229.082 118.224 234.561C117.569 240.04 116.921 247.655 117.245 249.785C117.569 251.915 119.21 261.654 119.21 261.654C119.21 261.654 127.062 249.466 130.661 247.045C134.26 244.625 144.382 238.827 145.38 236.999C146.378 235.17 148.648 233.342 145.705 230.614C142.761 227.886 141.42 224.822 136.187 225.733Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.838 227.324C135.838 227.324 129.033 233.285 124.611 240.737C120.189 248.19 120.208 256.408 120.208 256.408" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M141.233 233.215C141.757 233.968 142.101 234.817 142.243 235.705" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M136.836 228.814C137.939 229.624 138.958 230.527 139.879 231.513" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M131.833 232.541C131.833 232.541 134.833 235.896 135.039 239.059" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M128.428 231.608C128.428 231.608 124.224 231.422 121.623 232.188" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M123.613 228.258C123.613 228.258 128.016 225.832 131.821 227.881" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M127.23 239.059C127.23 239.059 130.43 241.479 131.235 243.702" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M125.029 237.759C123.31 237.7 121.601 238.018 120.039 238.688" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M118.418 245.398L120.825 244.092" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M124.424 245.02C124.424 245.02 126.226 246.325 126.226 247.254" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.095 213.929C137.984 212.444 141.327 211.912 144.582 212.42C149.815 213.331 152.434 217.289 153.089 222.768C153.744 228.247 154.393 235.862 154.068 237.992C153.744 240.122 152.104 249.862 152.104 249.862C152.104 249.862 144.258 237.673 140.659 235.253C137.06 232.833 126.937 227.034 125.933 225.206C124.929 223.378 122.665 221.55 125.609 218.822C128.553 216.094 129.85 213 135.095 213.929Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M135.463 215.518C135.463 215.518 142.274 221.478 146.69 228.931C151.106 236.383 151.093 244.601 151.093 244.601" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M130.056 221.41C129.528 222.162 129.181 223.011 129.039 223.9" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M134.447 217.01C133.345 217.818 132.326 218.719 131.403 219.703" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M139.449 220.736C139.449 220.736 136.449 224.091 136.249 227.254" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M142.854 219.801C142.854 219.801 147.058 219.615 149.659 220.381" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M147.657 216.483C147.657 216.483 143.253 214.056 139.449 216.105" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M144.052 227.254C144.052 227.254 140.852 229.674 140.048 231.897" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.253 225.954C147.972 225.895 149.681 226.213 151.243 226.883" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M152.865 233.593L150.463 232.287" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.858 233.215C146.858 233.215 145.056 234.521 145.056 235.449" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.42 234.416C195.42 234.416 206.803 235.159 210.607 237.544C214.412 239.929 219.694 242.501 219.694 242.501C219.694 242.501 214.599 234.868 213.713 233.295C212.827 231.723 209.166 232.547 205.892 231.055C202.617 229.563 203.572 228.612 199.355 228.072C195.139 227.532 195.37 227.3 192.906 227.387C190.443 227.474 184.842 229.285 184.405 230.678C183.969 232.071 182.784 233.266 183.058 233.946C183.333 234.625 191.428 233.412 195.42 234.416Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.936 233.429L195.002 230.301" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M198.644 234.758L202.499 232.553" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.308 235.367L210.227 234.346" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.854 223.196C175.854 223.196 181.237 224.479 181.293 229.238C181.349 233.998 180.981 239.842 175.842 242.686C170.703 245.53 162.389 252.553 162.389 252.553C161.844 249.805 161.047 247.106 160.006 244.485C158.572 241.264 154.954 233.597 156.058 229.227C157.162 224.856 158.441 221.386 162.732 220.695C167.023 220.004 170.84 224.525 170.84 224.525L175.854 223.196Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M170.278 225.908C170.278 225.908 169.549 234.005 166.923 239.913C165.666 242.588 164.571 245.326 163.642 248.114" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.703 238.178C167.703 238.178 168.545 242.792 168.651 243.529" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.956 238.792C164.316 237.115 168.994 233.766 168.994 233.766C168.994 233.766 172.892 238.867 173.303 240.365" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M165.245 239.199L162.22 241.805" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.235 235.211C174.9 233.067 173.294 231.079 171.451 229.291" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.37 228.984C168.37 228.984 161.74 230.691 159.42 233.906" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M168.027 225.141C168.027 225.141 163.661 225.617 161.79 227.062" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.428 225.676C173.428 225.676 177.008 226.761 177.981 228.491" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.655 230.347C193.655 230.347 198.925 228.71 201.763 232.669C204.601 236.627 207.669 241.74 205.006 246.72C202.343 251.7 199.43 261.811 199.43 261.811C197.374 259.774 195.133 257.906 192.732 256.227C189.651 254.242 182.129 249.616 180.532 245.391C178.935 241.166 177.975 237.596 181.156 234.851C184.337 232.106 190.193 233.992 190.193 233.992L193.655 230.347Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.549 235.438C190.549 235.438 194.64 242.605 195.869 248.897C196.37 251.78 197.045 254.634 197.89 257.446" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.52 247.045C195.52 247.045 198.913 250.527 199.43 251.073" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.884 249.96C192.052 247.859 194.035 242.693 194.035 242.693C194.035 242.693 200.272 245.015 201.52 246.065" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.054 249.146L193.019 252.861" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.952 240.278C198.578 239.145 196.065 238.283 193.468 237.713" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.73 238.984C190.73 238.984 186.145 243.755 186.071 247.632" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.21 235.926C188.21 235.926 184.836 238.514 184.056 240.697" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.056 233.657C193.056 233.657 196.699 232.763 198.52 233.726" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M180.127 222.379C180.127 222.379 185.516 220.057 188.497 220.829C189.813 221.133 191.028 221.736 192.033 222.583C193.037 223.431 193.8 224.498 194.254 225.687C195.183 228.009 196.811 239.123 196.811 239.123C196.811 239.123 185.017 230.998 179.447 230.116C173.877 229.233 168.906 226.511 170.222 223.94C171.538 221.369 177.308 220.196 180.127 222.379Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.33 223.771C181.33 223.771 188.516 225.118 192.956 232.547" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.187 225.025L180.925 228.815" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M184.942 226.512L185.597 229.75" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.784 226.396L187.449 225.49" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M187.873 223.307L183.881 223.864" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M178.243 223.94C178.243 223.94 175.543 223.673 173.877 224.938" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M93.0516 230.15C93.0516 230.15 98.4404 227.829 101.422 228.601C102.738 228.904 103.953 229.507 104.958 230.355C105.962 231.203 106.725 232.269 107.178 233.459C108.108 235.78 109.736 246.889 109.736 246.889C109.736 246.889 97.9414 238.763 92.3717 237.881C86.8021 236.999 81.8312 234.277 83.1472 231.706C84.4632 229.135 90.2324 227.962 93.0516 230.15Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.2616 231.537C94.2616 231.537 101.44 232.889 105.881 240.313" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M94.1119 232.797L93.8499 236.581" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M97.8666 234.283L98.5215 237.522" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M104.709 234.167L100.374 233.256" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M100.798 231.078L96.8063 231.635" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M91.1805 231.711C91.1805 231.711 88.4798 231.444 86.8145 232.704" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.578 214.624C167.578 214.624 162.838 211.299 159.75 211.467C158.394 211.505 157.069 211.855 155.894 212.487C154.72 213.12 153.732 214.014 153.021 215.089C151.586 217.167 147.476 227.718 147.476 227.718C147.476 227.718 160.848 222.094 166.493 222.321C172.137 222.547 177.613 220.864 176.908 218.089C176.204 215.315 170.834 213.046 167.578 214.624Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M166.081 215.75C166.081 215.75 158.759 215.657 152.734 222.047" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M165.937 217.004L165.339 220.753" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M161.933 217.719L160.561 220.76" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M155.272 216.261L159.713 216.227" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M159.8 214.016L163.574 215.339" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M169.068 216.521C169.068 216.521 171.769 216.788 173.123 218.35" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M120.987 213.806C120.987 213.806 116.247 210.48 113.154 210.649C111.798 210.687 110.474 211.038 109.3 211.67C108.127 212.302 107.14 213.196 106.43 214.27C104.995 216.348 100.885 226.9 100.885 226.9C100.885 226.9 114.257 221.276 119.896 221.502C125.534 221.729 131.023 220.045 130.318 217.271C129.613 214.497 124.237 212.222 120.987 213.806Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.49 214.932C119.49 214.932 112.162 214.839 106.143 221.229" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M119.347 216.186L118.748 219.935" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M115.343 216.9L113.971 219.942" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M108.682 215.443L113.122 215.408" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M113.203 213.197L116.977 214.521" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M122.472 215.705C122.472 215.705 125.172 215.972 126.532 217.533" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M226.106 178.134C226.106 178.134 214.699 177.745 210.651 179.748C206.603 181.75 201.046 183.811 201.046 183.811C201.046 183.811 206.983 176.724 208.044 175.244C209.104 173.764 212.653 174.948 216.071 173.787C219.489 172.626 218.659 171.587 222.931 171.465C227.204 171.344 226.979 171.082 229.418 171.413C231.857 171.744 237.22 174.1 237.495 175.528C237.769 176.956 238.811 178.256 238.462 178.906C238.112 179.556 230.185 177.531 226.106 178.134Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M230.678 177.595L226.992 174.078" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M222.863 178.157L219.277 175.586" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M214.175 177.907L211.393 176.602" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M246.838 168.889C246.838 168.889 241.343 169.632 240.744 174.362C240.146 179.092 239.846 184.931 244.63 188.291C249.414 191.652 256.886 199.418 256.886 199.418C257.75 196.738 258.86 194.132 260.204 191.629C262 188.564 266.441 181.298 265.867 176.846C265.293 172.394 264.358 168.813 260.204 167.728C256.05 166.643 251.715 170.74 251.715 170.74L246.838 168.889Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M252.096 172.156C252.096 172.156 251.902 180.282 253.842 186.411C254.789 189.194 255.566 192.025 256.169 194.891" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M253.237 184.605C253.237 184.605 251.877 189.115 251.684 189.829" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M257.89 185.685C256.724 183.88 252.458 180.09 252.458 180.09C252.458 180.09 247.998 184.785 247.418 186.225" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M255.564 185.865L258.277 188.756" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M245.092 180.816C246.661 178.815 248.483 176.997 250.518 175.4" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M253.624 175.4C253.624 175.4 260.023 177.722 261.963 181.175" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M254.397 171.611C254.397 171.611 258.663 172.517 260.41 174.142" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M248.977 171.611C248.977 171.611 245.291 172.337 244.131 173.933" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.32 174.257C228.32 174.257 223.268 172.116 220.012 175.79C216.757 179.463 213.108 184.223 215.185 189.441C217.262 194.658 219.04 204.972 219.04 204.972C221.317 203.151 223.758 201.516 226.337 200.085C229.63 198.414 237.613 194.554 239.703 190.509C241.792 186.463 243.133 183.01 240.258 179.968C237.383 176.927 231.37 178.227 231.37 178.227L228.32 174.257Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M230.834 179.627C230.834 179.627 225.944 186.36 223.973 192.489C223.159 195.312 222.176 198.09 221.029 200.812" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.566 190.684C224.566 190.684 220.823 193.789 220.2 194.299" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M227.846 193.933C227.921 191.832 226.536 186.498 226.536 186.498C226.536 186.498 220.062 188.193 218.734 189.116" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M225.788 192.918L226.393 196.708" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M219.925 183.399C222.409 182.507 225 181.897 227.64 181.582" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M230.266 183.139C230.266 183.139 234.283 188.362 233.915 192.199" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.104 180.346C233.104 180.346 236.166 183.248 236.69 185.5" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.539 177.594C228.539 177.594 225.021 176.347 223.1 177.124" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M242.684 167.676C242.684 167.676 237.588 164.832 234.539 165.308C233.194 165.478 231.917 165.957 230.821 166.7C229.724 167.444 228.844 168.429 228.258 169.568C227.073 171.779 224.185 182.673 224.185 182.673C224.185 182.673 236.84 175.766 242.478 175.43C248.117 175.093 253.374 172.888 252.352 170.206C251.329 167.525 245.734 165.778 242.684 167.676Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M241.324 168.936C241.324 168.936 234.027 169.568 228.769 176.516" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M241.324 170.201L241.156 173.991" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M237.42 171.303L236.403 174.466" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M230.634 170.515L235.044 170.045" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M234.875 167.832L238.78 168.778" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M244.381 169.412C244.381 169.412 247.094 169.412 248.622 170.834" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M256.044 161.198C256.044 161.198 261.133 158.36 264.189 158.83C265.532 159.002 266.808 159.48 267.904 160.222C269 160.965 269.882 161.948 270.47 163.084C271.655 165.296 274.543 176.19 274.543 176.19C274.543 176.19 261.888 169.277 256.25 168.946C250.611 168.615 245.354 166.398 246.37 163.723C247.387 161.047 252.988 159.306 256.044 161.198Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M257.404 162.465C257.404 162.465 264.701 163.086 269.959 170.051" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M257.404 163.729L257.572 167.519" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M261.302 164.832L262.325 167.989" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M268.094 164.042L263.678 163.566" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M263.853 161.361L259.948 162.307" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M254.347 162.939C254.347 162.939 251.634 162.939 250.106 164.356" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M144.563 222.112C144.563 222.112 133.156 221.723 129.108 223.726C125.06 225.728 119.503 227.754 119.503 227.754C119.503 227.754 125.44 220.667 126.501 219.193C127.561 217.719 131.11 218.891 134.528 217.736C137.946 216.581 137.116 215.536 141.389 215.414C145.661 215.293 145.436 215.037 147.875 215.368C150.314 215.699 155.678 218.05 155.952 219.477C156.226 220.905 157.274 222.211 156.919 222.861C156.563 223.511 148.648 221.503 144.563 222.112Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M149.135 221.572L145.449 218.055" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M141.32 222.136L137.734 219.559" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M132.632 221.886L129.85 220.58" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.316 224.179C194.316 224.179 205.724 223.79 209.778 225.792C213.832 227.795 219.376 229.832 219.376 229.832C219.376 229.832 213.439 222.745 212.385 221.271C211.331 219.797 207.769 220.969 204.351 219.814C200.933 218.659 201.763 217.614 197.491 217.493C193.218 217.371 193.449 217.115 191.004 217.446C188.559 217.777 183.202 220.128 182.927 221.555C182.653 222.983 181.611 224.289 181.96 224.933C182.31 225.578 190.237 223.569 194.316 224.179Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.744 223.638L193.43 220.121" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.559 224.202L201.146 221.625" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.247 223.952L209.035 222.646" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.908 218.948C188.908 218.948 199.368 214.7 203.927 215.182C208.486 215.663 214.449 215.657 214.449 215.657C214.449 215.657 206.135 211.09 204.57 210.074C203.004 209.058 200.16 211.368 196.524 211.455C192.888 211.542 193.249 210.295 189.239 211.629C185.229 212.964 185.328 212.657 183.183 213.8C181.037 214.944 176.977 218.954 177.289 220.382C177.601 221.81 177.139 223.371 177.719 223.864C178.299 224.358 184.873 219.778 188.908 218.948Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M184.443 220.006L186.488 215.479" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.94 217.864L194.26 214.248" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M199.923 214.671L202 212.506" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M165.295 212.883C165.295 212.883 159.8 213.626 159.201 218.356C158.603 223.086 158.303 228.925 163.087 232.286C167.871 235.646 175.343 243.418 175.343 243.418C176.198 240.741 177.297 238.137 178.63 235.635C180.426 232.57 184.867 225.303 184.299 220.852C183.732 216.4 182.821 212.819 178.63 211.705C174.439 210.59 170.141 214.717 170.141 214.717L165.295 212.883Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M170.528 216.127C170.528 216.127 170.335 224.253 172.274 230.382C173.221 233.165 173.998 235.996 174.601 238.861" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M171.694 228.578C171.694 228.578 170.335 233.094 170.141 233.802" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.347 229.663C175.181 227.858 170.915 224.068 170.915 224.068C170.915 224.068 166.455 228.758 165.875 230.203" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.021 229.842L176.734 232.726" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M163.53 224.794C165.1 222.792 166.924 220.974 168.962 219.379" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M172.081 219.385C172.081 219.385 178.48 221.706 180.42 225.16" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M172.886 215.588C172.886 215.588 177.152 216.488 178.898 218.113" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.428 215.588C167.428 215.588 163.742 216.308 162.582 217.909" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.777 218.222C146.777 218.222 141.725 216.08 138.47 219.754C135.214 223.428 131.565 228.188 133.642 233.405C135.719 238.623 137.478 248.937 137.478 248.937C139.755 247.114 142.196 245.477 144.775 244.044C148.062 242.39 156.046 238.536 158.135 234.473C160.224 230.411 161.565 226.975 158.696 223.933C155.827 220.892 149.802 222.192 149.802 222.192L146.777 218.222Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M149.291 223.604C149.291 223.604 144.401 230.33 142.461 236.465C141.636 239.283 140.643 242.056 139.486 244.771" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M143.023 234.654C143.023 234.654 139.28 237.765 138.657 238.276" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.31 237.91C146.378 235.809 145 230.475 145 230.475C145 230.475 138.52 232.169 137.191 233.092" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M144.245 236.889L144.85 240.684" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M138.382 227.369C140.866 226.477 143.457 225.867 146.098 225.553" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M148.705 227.115C148.705 227.115 152.721 232.31 152.353 236.169" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M151.561 224.322C151.561 224.322 154.624 227.224 155.147 229.47" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M146.996 221.583C146.996 221.583 143.478 220.335 141.557 221.113" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M161.141 211.647C161.141 211.647 156.052 208.809 152.996 209.279C151.647 209.449 150.365 209.927 149.264 210.672C148.164 211.416 147.279 212.404 146.69 213.545C145.505 215.756 142.617 226.651 142.617 226.651C142.617 226.651 155.272 219.744 160.91 219.407C166.549 219.071 171.807 216.859 170.784 214.184C169.761 211.508 164.191 209.755 161.141 211.647Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M159.788 212.912C159.788 212.912 152.49 213.545 147.233 220.492" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M159.782 214.178L159.613 217.968" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M155.877 215.281L154.861 218.439" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M149.091 214.492L153.501 214.021" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M153.332 211.811L157.237 212.757" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.838 213.389C162.838 213.389 165.551 213.389 167.079 214.811" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.501 205.176C174.501 205.176 179.59 202.332 182.646 202.808C183.991 202.981 185.268 203.461 186.364 204.205C187.46 204.95 188.34 205.935 188.927 207.074C190.112 209.279 193 220.179 193 220.179C193 220.179 180.37 213.266 174.707 212.93C169.044 212.593 163.811 210.388 164.827 207.706C165.844 205.025 171.445 203.284 174.501 205.176Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.861 206.441C175.861 206.441 183.158 207.074 188.416 214.021" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.861 207.701L176.029 211.491" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.765 208.809L180.782 211.966" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M186.551 208.019L182.135 207.543" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M182.31 205.332L178.405 206.278" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M172.804 206.91C172.804 206.91 170.091 206.91 168.563 208.332" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.326 116.206C216.201 115.09 215.965 113.986 215.622 112.91C217.225 109.358 217.954 106.281 215.31 103.623C212.353 100.646 206.372 102.085 206.372 102.085L205.25 100.686C205.258 99.1711 204.629 97.713 203.497 96.6236C200.547 93.6461 194.565 95.0855 194.565 95.0855L192.844 92.9322C194.06 89.8735 194.372 87.1862 192.027 84.8066C189.071 81.8291 183.089 83.2685 183.089 83.2685L179.971 79.3799C179.971 79.3799 174.863 77.3543 171.707 81.1211C168.551 84.8879 165.033 89.7168 167.254 94.8824C169.474 100.048 171.507 110.321 171.507 110.321C173.634 108.535 175.909 106.909 178.312 105.457C178.419 105.87 178.556 106.275 178.723 106.67C180.938 111.836 182.971 122.109 182.971 122.109C184.79 120.589 186.708 119.176 188.715 117.878C188.884 118.133 189.046 118.389 189.183 118.644C189.538 119.306 191.098 120.49 193.112 121.842C194.11 125.719 194.784 129.12 194.784 129.12C194.784 129.12 196.861 127.379 199.237 125.736C201.108 126.897 202.811 127.948 203.902 128.691C207.501 131.128 215.353 143.305 215.353 143.305C215.353 143.305 216.988 133.56 217.318 131.43C217.649 129.3 216.981 121.685 216.326 116.206Z" fill="#263238"/>
<path d="M193.299 106.066C190.605 103.216 185.422 103.982 184.306 104.186L181.399 100.401C181.368 100.358 181.325 100.324 181.274 100.303C181.224 100.303 175.967 98.1088 172.599 101.916C168.856 106.136 165.682 110.744 167.721 115.846C169.761 120.948 171.52 131.221 171.532 131.325C171.543 131.376 171.567 131.424 171.603 131.463C171.639 131.503 171.686 131.533 171.738 131.552C171.773 131.558 171.809 131.558 171.844 131.552C171.919 131.551 171.992 131.527 172.05 131.482C174.307 129.67 176.73 128.044 179.291 126.624L179.859 126.34C183.395 124.552 190.767 120.832 192.782 116.92C194.865 112.892 196.343 109.293 193.299 106.066Z" fill="#263238"/>
<path d="M179.746 89.2283C177.052 86.3785 171.869 87.1446 170.759 87.3478L167.834 83.5694C167.801 83.5274 167.758 83.4936 167.709 83.4707C167.653 83.4707 162.395 81.2768 159.027 85.0842C155.285 89.3037 152.116 93.9121 154.15 99.0139C156.183 104.116 157.948 114.389 157.967 114.493C157.975 114.544 157.997 114.592 158.033 114.632C158.068 114.672 158.114 114.702 158.166 114.719C158.201 114.725 158.237 114.725 158.272 114.719C158.348 114.72 158.421 114.695 158.478 114.65C160.736 112.839 163.159 111.214 165.719 109.792L166.287 109.508C169.829 107.72 177.195 104 179.216 100.088C181.293 96.0538 182.771 92.4553 179.746 89.2283Z" fill="#263238"/>
<path d="M213.121 91.6965C215.815 88.8467 220.998 89.6128 222.114 89.816L225.021 86.0143C225.053 85.9726 225.096 85.9387 225.146 85.9157C225.196 85.9157 230.453 83.7276 233.821 87.5292C237.564 91.7487 240.738 96.3571 238.699 101.459C236.659 106.561 234.9 116.834 234.882 116.938C234.874 116.989 234.852 117.038 234.816 117.078C234.781 117.118 234.735 117.148 234.682 117.164C234.647 117.17 234.611 117.17 234.576 117.164C234.501 117.164 234.428 117.14 234.37 117.095C232.116 115.295 229.7 113.68 227.148 112.266L226.58 111.981C223.044 110.194 215.672 106.473 213.657 102.562C211.555 98.5336 210.077 94.9293 213.121 91.6965Z" fill="#263238"/>
<path d="M167.597 104.394C167.597 104.394 158.06 110.227 155.996 114.041C153.931 117.854 150.675 122.503 150.675 122.503C150.675 122.503 151.112 113.524 151.05 111.748C150.987 109.972 154.624 109.02 156.694 106.234C158.765 103.448 157.436 103.042 160.836 100.657C164.235 98.2712 163.898 98.1667 166.093 97.122C168.289 96.0773 174.127 95.1022 175.237 96.1237C176.347 97.1452 178.012 97.6386 178.131 98.3583C178.249 99.078 170.553 101.701 167.597 104.394Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M170.989 101.494L165.794 100.617" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M164.965 106.164L160.443 105.996" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M157.73 110.641L154.648 111.076" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M183.975 99.8684C183.975 99.8684 174.432 105.672 172.374 109.509C170.316 113.345 167.054 117.971 167.054 117.971C167.054 117.971 167.484 108.992 167.428 107.222C167.372 105.452 171.002 104.494 173.073 101.702C175.143 98.9107 173.815 98.516 177.208 96.1248C180.601 93.7335 180.276 93.6349 182.472 92.5901C184.667 91.5454 190.505 90.5762 191.615 91.5919C192.726 92.6076 194.391 93.1067 194.503 93.8264C194.615 94.5461 186.925 97.1753 183.975 99.8684Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M187.368 96.9662L182.172 96.084" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.343 101.631L176.821 101.463" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.108 106.107L171.021 106.543" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.594 73.7906C162.594 73.7906 151.193 73.4018 147.139 75.4042C143.085 77.4065 137.534 79.4379 137.534 79.4379C137.534 79.4379 143.478 72.3513 144.532 70.8712C145.586 69.3912 149.141 70.5752 152.565 69.4144C155.989 68.2536 155.154 67.2147 159.426 67.0928C163.698 66.9709 163.474 66.7098 165.913 67.0464C168.351 67.383 173.721 69.7278 174.021 71.1556C174.32 72.5834 175.337 73.8835 174.987 74.5336C174.638 75.1836 166.68 73.187 162.594 73.7906Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.166 73.2497L163.48 69.7383" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M159.351 73.8134L155.765 71.2422" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M150.669 73.5637L147.881 72.2578" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M212.353 75.8566C212.353 75.8566 223.755 75.4678 227.809 77.4701C231.863 79.4725 237.414 81.5039 237.414 81.5039C237.414 81.5039 231.47 74.4172 230.416 72.9372C229.362 71.4572 225.807 72.6412 222.383 71.4804C218.958 70.3196 219.794 69.2807 215.522 69.1588C211.25 69.0369 211.474 68.7757 209.035 69.1066C206.597 69.4374 201.227 71.7938 200.927 73.2216C200.628 74.6494 199.611 75.9495 199.961 76.5995C200.31 77.2496 208.268 75.253 212.353 75.8566Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.782 75.3161L211.468 71.7988" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M215.597 75.8798L219.183 73.3086" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.279 75.6301L227.067 74.3242" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.946 70.628C206.946 70.628 217.405 66.3795 221.965 66.8612C226.524 67.3429 232.487 67.3371 232.487 67.3371C232.487 67.3371 224.166 62.7694 222.601 61.7537C221.035 60.738 218.198 63.048 214.555 63.135C210.913 63.2221 211.281 61.9742 207.277 63.315C203.272 64.6557 203.36 64.3423 201.22 65.4798C199.081 66.6174 195.015 70.6396 195.32 72.0616C195.626 73.4836 195.17 75.0507 195.75 75.544C196.331 76.0373 202.904 71.458 206.946 70.628Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M202.48 71.6834L204.526 67.1562" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M209.971 69.5475L212.291 65.9258" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M217.954 66.3504L220.031 64.1855" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M183.326 64.5611C183.326 64.5611 177.832 65.304 177.233 70.0343C176.634 74.7645 176.335 80.6092 181.118 83.9639C185.902 87.3186 193.38 95.0902 193.38 95.0902C194.232 92.4102 195.332 89.8041 196.667 87.3012C198.457 84.2425 202.904 76.9701 202.331 72.5184C201.757 68.0667 200.852 64.4856 196.667 63.3771C192.482 62.2685 188.172 66.3836 188.172 66.3836L183.326 64.5611Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.565 67.8125C188.565 67.8125 188.372 75.9381 190.306 82.0671C191.252 84.8468 192.029 87.674 192.632 90.5352" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.726 80.2617C189.726 80.2617 188.372 84.7714 188.173 85.4853" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.378 81.3412C193.218 79.5362 188.952 75.752 188.952 75.752C188.952 75.752 184.493 80.4416 183.906 81.8868" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M192.052 81.5215L194.765 84.4119" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.58 76.4718C183.15 74.4701 184.974 72.652 187.012 71.0566" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.112 71.0566C190.112 71.0566 196.511 73.3782 198.451 76.8316" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.892 67.2676C190.892 67.2676 195.158 68.173 196.898 69.7981" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.459 67.2676C185.459 67.2676 181.78 67.9931 180.613 69.6182" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M187.268 75.7698C184.382 74.2773 181.038 73.7412 177.782 74.2491C172.549 75.1603 169.929 79.1187 169.274 84.5977C168.619 90.0766 167.965 97.6857 168.295 99.8158C168.626 101.946 170.254 111.691 170.254 111.691C170.254 111.691 178.106 99.5024 181.705 97.0763C185.303 94.6502 195.426 88.8578 196.424 87.0295C197.422 85.2013 199.699 83.3788 196.755 80.6451C193.811 77.9114 192.501 74.8643 187.268 75.7698Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M186.913 77.3594C186.913 77.3594 180.108 83.3201 175.686 90.7724C171.264 98.2248 171.283 106.443 171.283 106.443" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M192.308 83.25C192.834 84.0026 193.181 84.8518 193.324 85.7399" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M187.917 78.8457C189.019 79.6541 190.036 80.5578 190.954 81.5446" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M182.915 82.5723C182.915 82.5723 185.915 85.927 186.114 89.096" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.509 81.6428C179.509 81.6428 175.305 81.4571 172.705 82.2233" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M174.707 78.2886C174.707 78.2886 179.11 75.8683 182.915 77.9171" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M178.306 89.0957C178.306 89.0957 181.511 91.516 182.31 93.7389" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M176.104 87.7896C174.385 87.733 172.676 88.0531 171.114 88.724" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M169.499 95.427L171.9 94.1211" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M175.505 95.0566C175.505 95.0566 177.308 96.3625 177.308 97.2912" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M208.942 75.8036C211.825 74.3169 215.164 73.781 218.416 74.283C223.649 75.1942 226.268 79.1525 226.917 84.6315C227.566 90.1105 228.227 97.7253 227.902 99.8554C227.578 101.985 225.938 111.725 225.938 111.725C225.938 111.725 218.085 99.5362 214.487 97.1159C210.888 94.6957 200.765 88.8974 199.767 87.0692C198.769 85.2409 196.499 83.4185 199.443 80.6848C202.387 77.9511 203.703 74.8924 208.942 75.8036Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M209.291 77.3945C209.291 77.3945 216.096 83.3552 220.518 90.8076C224.94 98.2599 224.927 106.478 224.927 106.478" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M203.896 83.2852C203.371 84.0402 203.027 84.8913 202.886 85.7809" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M208.287 78.8867C207.187 79.6969 206.17 80.6004 205.25 81.5856" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.295 82.6133C213.295 82.6133 210.289 85.968 210.089 89.1312" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M216.701 81.678C216.701 81.678 220.904 81.4923 223.505 82.2584" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M221.534 78.3458C221.534 78.3458 217.131 75.9198 213.326 77.9686" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M217.898 89.1309C217.898 89.1309 214.699 91.5569 213.894 93.7741" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.1 87.8305C221.818 87.7747 223.527 88.0926 225.089 88.7591" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M226.705 95.468L224.304 94.1621" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M220.705 95.0918C220.705 95.0918 218.902 96.3977 218.902 97.3321" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M229.599 80.9301C232.486 79.4407 235.829 78.9048 239.085 79.4094C244.318 80.3207 246.938 84.279 247.593 89.758C248.248 95.237 248.896 102.852 248.572 104.982C248.248 107.112 246.607 116.851 246.607 116.851C246.607 116.851 238.761 104.663 235.162 102.242C231.564 99.8221 221.441 94.0239 220.437 92.1957C219.432 90.3674 217.168 88.5391 220.112 85.8113C223.056 83.0834 224.366 80.0189 229.599 80.9301Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M229.948 82.5195C229.948 82.5195 236.759 88.4802 241.175 95.9326C245.591 103.385 245.578 111.603 245.578 111.603" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M224.559 88.4102C224.031 89.162 223.684 90.0115 223.543 90.9001" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M228.95 84.0117C227.847 84.8208 226.828 85.7244 225.906 86.7106" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M233.952 87.7383C233.952 87.7383 230.952 91.093 230.753 94.2562" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M237.358 86.803C237.358 86.803 241.561 86.6173 244.162 87.3834" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M242.16 83.4533C242.16 83.4533 237.757 81.0272 233.952 83.076" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M238.555 94.2559C238.555 94.2559 235.356 96.6761 234.551 98.8991" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M240.757 92.9544C242.475 92.8946 244.185 93.2128 245.747 93.883" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M247.368 100.593L244.961 99.2871" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M241.362 100.217C241.362 100.217 239.559 101.523 239.559 102.457" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.191 101.272C209.077 99.7793 212.422 99.2431 215.678 99.7511C220.911 100.662 223.53 104.621 224.185 110.1C224.84 115.579 225.495 123.188 225.164 125.324C224.834 127.459 223.2 137.193 223.2 137.193C223.2 137.193 215.353 125.004 211.755 122.584C208.156 120.164 198.033 114.36 197.029 112.537C196.025 110.715 193.761 108.881 196.705 106.153C199.649 103.425 200.952 100.36 206.191 101.272Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M206.565 102.863C206.565 102.863 213.376 108.824 217.792 116.276C222.208 123.729 222.195 131.947 222.195 131.947" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M201.152 108.754C200.622 109.505 200.276 110.355 200.135 111.244" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M205.543 104.354C204.441 105.162 203.422 106.063 202.499 107.047" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M210.545 108.08C210.545 108.08 207.545 111.429 207.345 114.598" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M213.95 107.147C213.95 107.147 218.154 106.961 220.755 107.727" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M218.753 103.791C218.753 103.791 214.349 101.37 210.545 103.419" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M215.148 114.598C215.148 114.598 211.948 117.018 211.15 119.241" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M217.356 113.292C219.074 113.235 220.784 113.555 222.345 114.226" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M223.961 120.931L221.559 119.631" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M217.954 120.559C217.954 120.559 216.152 121.864 216.152 122.793" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M183.145 96.5354C183.145 96.5354 177.651 97.2783 177.052 102.009C176.453 106.739 176.154 112.583 180.938 115.938C185.721 119.293 193.193 127.064 193.193 127.064C194.048 124.385 195.147 121.78 196.48 119.275C198.276 116.217 202.717 108.944 202.143 104.493C201.57 100.041 200.665 96.4657 196.48 95.3513C192.295 94.237 187.992 98.3578 187.992 98.3578L183.145 96.5354Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M188.378 99.7871C188.378 99.7871 188.185 107.913 190.125 114.042C191.072 116.825 191.848 119.656 192.451 122.521" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.545 112.236C189.545 112.236 188.185 116.746 187.992 117.46" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M194.197 113.322C193.031 111.517 188.765 107.727 188.765 107.727C188.765 107.727 184.306 112.416 183.726 113.861" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M191.871 113.502L194.584 116.387" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M181.399 108.446C182.97 106.447 184.792 104.629 186.825 103.031" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M189.931 103.031C189.931 103.031 196.331 105.382 198.27 108.806" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.705 99.2402C190.705 99.2402 194.971 100.14 196.717 101.765" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M185.279 99.2402C185.279 99.2402 181.592 99.9599 180.426 101.562" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M192.083 82.8389C192.083 82.8389 187.362 85.5609 188.834 90.1229C190.306 94.6848 192.538 100.152 198.351 101.441C204.164 102.729 214.356 107.105 214.356 107.105C213.994 104.331 213.89 101.533 214.044 98.7418C214.38 95.2594 215.366 86.9423 212.909 83.071C210.451 79.1997 208.119 76.2513 203.802 76.7853C199.486 77.3192 197.304 82.6879 197.304 82.6879L192.083 82.8389Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M198.276 83.8828C198.276 83.8828 201.57 91.428 205.973 96.3092C208.035 98.5106 209.959 100.821 211.736 103.228" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.694 94.8711C204.694 94.8711 205.38 99.5143 205.511 100.246" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M209.403 94.1382C207.563 92.9136 202.031 91.0215 202.031 91.0215C202.031 91.0215 199.948 96.9764 200.029 98.5145" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M207.345 95.166L211.069 96.8085" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.576 94.4121C196.163 91.9995 197.059 89.6612 198.245 87.4473" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M201.089 86.2865C201.089 86.2865 207.95 86.0659 211.206 88.492" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.179 82.5198C200.179 82.5198 204.476 81.771 206.771 82.6126" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M195.195 84.5332C195.195 84.5332 192.127 86.5588 191.753 88.4799" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M164.815 69.9135C164.815 69.9135 159.757 67.7719 156.507 71.4458C153.258 75.1197 149.596 79.8848 151.68 85.1026C153.763 90.3204 155.509 100.634 155.509 100.634C157.785 98.8104 160.227 97.1734 162.807 95.7414C166.093 94.0698 174.083 90.2102 176.166 86.1648C178.249 82.1194 179.597 78.6718 176.728 75.6247C173.859 72.5776 167.84 73.8835 167.84 73.8835L164.815 69.9135Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.322 75.2812C167.322 75.2812 162.439 82.0139 160.499 88.1487C159.675 90.9658 158.68 93.7369 157.518 96.4485" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M161.035 86.3379C161.035 86.3379 157.293 89.443 156.669 89.9538" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M164.341 89.595C164.409 87.4882 163.031 82.1543 163.031 82.1543C163.031 82.1543 156.551 83.8491 155.222 84.7719" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M162.276 88.5723L162.881 92.3681" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M156.414 79.0491C158.906 78.1583 161.505 77.5484 164.154 77.2324" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M166.736 78.7988C166.736 78.7988 170.752 83.9934 170.391 87.8531" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M169.592 76.0254C169.592 76.0254 172.655 78.9274 173.179 81.1794" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M165.033 73.2688C165.033 73.2688 161.515 72.0151 159.588 72.7986" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M179.172 63.3319C179.172 63.3319 174.083 60.4879 171.027 60.9639C169.688 61.1393 168.417 61.6201 167.327 62.3634C166.237 63.1067 165.361 64.0894 164.777 65.224C163.586 67.4353 160.705 78.3353 160.705 78.3353C160.705 78.3353 173.36 71.4227 178.992 71.0861C184.624 70.7494 189.888 68.5439 188.871 65.8624C187.854 63.181 182.241 61.434 179.172 63.3319Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.813 64.5977C177.813 64.5977 170.522 65.2245 165.258 72.1719" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M177.813 65.8555L177.644 69.6455" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M173.915 66.9648L172.892 70.1222" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M167.123 66.1751L171.538 65.6992" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M171.37 63.4883L175.268 64.4343" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M180.869 65.0664C180.869 65.0664 183.582 65.0664 185.11 66.4884" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M192.538 56.8716C192.538 56.8716 197.628 54.0277 200.678 54.4978C202.022 54.6698 203.3 55.1496 204.396 55.8941C205.492 56.6386 206.373 57.6246 206.958 58.7637C208.143 60.975 211.031 71.8692 211.031 71.8692C211.031 71.8692 198.376 64.9624 192.738 64.6258C187.1 64.2891 181.848 62.0778 182.865 59.4022C183.881 56.7265 189.482 54.9621 192.538 56.8716Z" fill="#38BCA7" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.892 58.1191C193.892 58.1191 201.189 58.7518 206.447 65.6992" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M193.892 59.3848L194.06 63.1748" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M197.796 60.4883L198.813 63.6457" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M204.582 59.6986L200.173 59.2285" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M200.341 57.0176L196.437 57.9636" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M190.836 58.6133C190.836 58.6133 188.123 58.6133 186.595 60.0353" stroke="#263238" strokeLinecap="round"strokeLinejoin="round"/>
</svg>

  )
}
