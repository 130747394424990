import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../assets/logoWithName.svg";
import { Exit, Hamburger, Minimize } from "../assets";
import navs from "../config/navs";
import Hexagon from "../assets/Hexagon";
import Modal from "./Modal";
import Button from "./Button";

export default function SideBar({ onHide }) {
  const [visible, setVisible] = useState("");
  const [activeLink, setActiveLink] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const history = useHistory();

  const toggleVisibility = (name) => {
    setActiveLink("/" + name.toLowerCase());

    if (!visible) return setVisible(name);

    if (visible === name) return setVisible("");
    if (visible !== activeLink && visible === activeLink) return setVisible("");

    return setVisible(name);
  };

  const handleLogout = () => {
    setOpenModal(false);
    try {
      history.push("/");
    } catch (error) {}
  };

  useEffect(() => {
    if (history.location.pathname.split("/").length >= 2)
      toggleVisibility(
        history.location.pathname.split("/")[1].charAt(0).toUpperCase() + history.location.pathname.split("/")[1].substring(1)
      );
  }, []);


  return (
    <>
      <nav className="lg:flex justify-between flex-col w-60 min-h-screen select-none lg:relative hidden absolute top-10 right-0 lg:top-0 lg:right-0">
        <div className="relative h-10 w-10/12 mx-auto mt-10">
          <img src={logo} layout="fill" alt="logo" />
        </div>

        <div className="w-full mx-auto text-neutral-200 text-lg font-medium gap-3 flex flex-col justify-center   transition-all duration-200 ease-in-out">
          {Object.values(navs()).map((nav, i) => (
            <Link to={nav.url} key={i}
                className={`p-2 py-3 hover:bg-primary-500 hover:text-primary-100 duration-200 transition-all ease-out text-lg relative ${
                  activeLink === nav.url && "bg-primary-500 text-primary-100 font-semibold"
                }`}
              >
                <div className="flex gap-4 justify-start pl-6 items-center ">
                  {nav.component && (
                    <nav.component className="stroke-current stroke-[1.5px] h-7 w-7" />
                  )}
                  <p className="flex-1 text-lg">{nav.name}</p>
                  {activeLink === nav.url && (
                  <div className="w-1 bg-primary-100 h-full absolute top-0 right-0"></div>
                )}
                </div>
            </Link>
          ))}
        </div>


        <div className="p-5 py-4">
          <div className="flex justify-between">
            <div className="flex">
              <div className="flex text-lg font-medium text-neutral-200 hover:text-primary-100" onClick={() => setOpenModal(true)} title="Logout">
                <Exit className="cursor-pointer stroke-neutral-200 hover:stroke-primary-100 transition-all duration-200 ease-out stroke-2 mr-2 h-8 w-8" />
                Log Out
              </div>
            </div>

            <div className="" title="Hide menu" onClick={() => onHide(true)}>
              <Minimize className="cursor-pointer stroke-neutral-200 hover:stroke-primary-100 transition-all duration-200 ease-out stroke-2 h-8 w-8" />
            </div>
          </div>
        </div>
      </nav>

      <Modal isOpen={openModal}>
        <div className="flex flex-col">
          <div className="flex flex-col justify-start gap-5 mb-5">
            <div className="relative mx-auto h-16 w-16">
             <Hexagon className=' fill-primary-100 stroke-primary-100'/>
            </div>

            <h3 className="font-bold text-center text-2xl text-primary-200">Log out</h3>
          </div>

          <div className="flex flex-col gap-5">
            <p className="text-center text-neutral-200 text-sm">You're about to be logged out</p>

            <div className="flex items-center justify-between gap-5">
              <Button
                name="Cancel"
                type="SECONDARY"
                className="w-full py-1.5 !text-primary-100 hover:!text-shade-light outline-none"
                onClick={() => setOpenModal(false)}
              />
              <Button name="Confirm" className="w-full py-1.5" onClick={handleLogout} />
            </div>
          </div>
        </div>
      </Modal>

      {/* mobile navbar */}
      <div className="sticky w-full bg-shade-light shadow-md top-0 right-1 z-50 lg:hidden">
        <div className="relative flex flex-row justify-between items-center gap-3 py-1 px-2 ">
          {/* class dropdown */}
          <div className="relative h-8 w-1/2 pl-3">
            <img src={logo} layout="fixed" />
          </div>

          <div
            className="h-10 w-10 relative cursor-pointer group"
            onClick={() => {
              setOpenDropdown((prev) => !prev);
            }}
          >
            <Hamburger className="group-hover:stroke-primary-100 stroke-[#717A84]" />
          </div>

          {openDropdown && (
            <div className="flex flex-col absolute top-11 right-1 rounded-lg shadow-lg bg-light text-center overflow-hidden w-80">
              {Object.values(navs()).map((nav, i) => (
                <Link to={nav.url} key={i}
                    className={`p-2.5 hover:bg-primary-500 hover:text-primary-100 duration-200 transition-all ease-out text-lg relative ${
                      activeLink === nav.url && "bg-primary-500 text-primary-100 font-semibold"
                    }`}
                    onClick={() => setOpenDropdown(false)}
                  >
                    <div className="flex gap-4 justify-start px-3 py-0.5 items-center ">
                      <p className="flex-1 text-lg">{nav.name}</p>
                    </div>
                </Link>
              ))}
              <div
                className={`p-2.5 hover:bg-primary-900 hover:text-primary-100 duration-200 transition-all ease-out text-lg relative cursor-pointer `}
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenModal(true);
                }}
              >
                <div className="flex gap-4 justify-start px-3 py-0.5 items-center ">
                  <p className="flex-1 text-lg">Log out</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
