import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FiCalendar } from "react-icons/fi";
import { FiX } from "react-icons/fi";



const CalendarComponent = ({onFromChange, fromValue, showCalender, onClick, onClickDay, onToChange, calendarToValue}) => {
  return showCalender ?  (
    <div className='flex justify-end sticky top-0'>
        <Calendar
            onChange={onFromChange}
            value={fromValue}
            className={'sticky top-0 shadow text-neutral-500'}
            onClickDay={(val, e) => onClickDay(val, 'from')}
        />
        <Calendar
            onChange={onToChange}
            value={calendarToValue}
            className={'sticky top-0 shadow text-neutral-500'}
            onClickDay={(val, e) => onClickDay(val, 'to')}
        />
        <FiX className='cursor-pointer text-neutral-500' onClick={onClick}/>
    </div>
  ) : (
        <div className='my-3 cusor'>
            <FiCalendar onClick={onClick} className='cursor-pointer text-neutral-500'/>
        </div>
    )
}

export default CalendarComponent