import React from 'react'
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import logo from '../assets/logo-white.svg';
import Header from './Header';
import Input from './Input';
import Button from "./Button";
import Modal from "./Modal";
import LoginPolygon from '../assets/LoginPolygon';
import LoginPolygonResponsive from '../assets/LoginPolygonResponsive';
import { useForm } from 'react-hook-form';
import { useLoginUser } from '../pages/data/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export default function LogIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableLoginButton, setDisableLoginButton] = useState(false);

  const [resetPhoneNumber, setResetPhoneNumber] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const history = useHistory()

  const handleLogin = async () => {
    setDisableLoginButton(true);

  };

  const sendResetCodeToPhone = async () => {
    setSubmitting(true);

  };

  const handleResetPassword = async () => {
    setSubmitting(true);
  };

  const handleOpenPasswordResetModal = () => {
    //Opens modal from the input of phone number modal with no input
    setChangePasswordModal(false);
    setResetPhoneNumber("");
    setResetCode("");
    setNewPassword("");
    setConfirmNewPassword("");

    setOpenModal(true);
  };

  //redirects to dashboard if user is logged in
  useEffect(() => {
    //validToken() ? router.push("/dashboard") : setLoading(false);
  }, []);

  const { handleSubmit, register, formState: { errors } } = useForm({ mode: 'onChange' })

  const { mutate, isPending } = useLoginUser()
  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: () => history.push('/dashboard')
    })
  }

  return loading ? null : (
    <>
      <div className="App">
        <div className="flex flex-col lg:grid lg:grid-cols-12 h-screen bg-white relative">
          <div className="md:col-span-6 md:flex items-center text-white">
            <LoginPolygonResponsive className='relative h-72 md:h-full w-full md:w-11/12 lg:hidden' />
            <LoginPolygon className='relative hidden lg:block lg:w-full lg:h-full xl:w-10/12' />
            <div className='flex flex-col absolute max-md:top-0  items-start max-md:w-full p-5 md:p-14'>
              <div className="h-16 w-16 md:h-20 md:w-20 mb-5 z-10">
                <img src={logo} alt="" className='w-full h-full' />
              </div>
              <div className="flex flex-col lg:mx-auto gap-5 lg:justify-center">
                <h2 className="font-bold text-3xl md:text-4xl xl:text-5xl">Welcome back!</h2>
                <div className="w-3/5">
                  <p className='font-semibiold'>Tutor Sign In</p>
                </div>
              </div>
            </div>
          </div>


          <div className=" lg:col-span-6 p-5 lg:p-20 xl:p-10 flex items-center relative flex-col justify-between bg-[#FBFBFB] rounded-t-3xl gap-1 md:my-auto">
            <div className="mb-5 flex flex-col gap-1.5">
              <Header text="Sign in" className="text-5xl font-bold" />
            </div>

            <div className="mx-auto w-full md:max-w-md flex flex-col justify-start flex-1">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-1 md:gap-4">
                  <Input
                    label="Email/username"
                    name="login"
                    type="email"
                    register={register}
                    rules={{ required: 'Username filed is required' }}
                    errors={errors.login}
                    placeholder="example@mail.com"
                  />
                  <Input
                    label="Password"
                    name="password"
                    rules={{ required: 'Password filed is required' }}
                    register={register}
                    type="password"
                    errors={errors.password}
                    placeholder="Password"

                    onKeyUp={(e) => e.key === "Enter" && handleLogin()}
                  />

                  <p
                    className="text-sm text-primary-100 mt-1 md:-mt-3 cursor-pointer hover:underline md:font-medium max-w-fit"
                    onClick={handleOpenPasswordResetModal}
                  >
                    Forgot Password?
                  </p>

                  <Button
                    name="Log In"
                    className="mt-3 text-light flex justify-center items-center"
                    type='submit'
                    // onClick={handleLogin}
                    loading={isPending}
                    disabled={isPending}
                  />
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

      <Modal
        isOpen={openModal}
        onClose={setOpenModal}
        title={!changePasswordModal ? "Forgot Your Password?" : "Let's change your password"}
        subtitle={
          !changePasswordModal && (
            <>
              <p>Kindly enter your phone number below.</p>
              <p>We will send you a reset link</p>
            </>
          )
        }
      >
        {!changePasswordModal ? (
          <div className="pt-5">
            <Input
              label="Phone number"
              placeholder="0700 xxxxxx"
              type="tel"
              value={resetPhoneNumber}
              onChange={(e) => setResetPhoneNumber(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && sendResetCodeToPhone()}
            />

            <div className="flex flex-row justify-between items-center gap-5 pt-8">
              <Button
                className="text-primary-c9 flex-1"
                name="Cancel"
                onClick={() => setOpenModal(false)}
                type="SECONDARY"
              />
              <Button
                name="Confirm"
                className="flex-1"
                disabled={!resetPhoneNumber || submitting}
                loading={submitting}
                onClick={() => sendResetCodeToPhone()}
              />
            </div>
          </div>
        ) : (
          <div className="py-10 pt-6">
            <div className="flex flex-col gap-2 pb-6">
              <Input
                label="Reset code"
                type="password"
                maxLength={5}
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                placeholder="•••••"
              />

              <Input
                label="Enter new password"
                placeholder="••••••••"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <Input
                label="Confirm new password"
                placeholder="••••••••"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              {/* handle password mismatch error */}
              {newPassword.length > 0 &&
                confirmNewPassword.length > 0 &&
                newPassword !== confirmNewPassword && (
                  <p className="text-alerts-danger text-sm -mt-3 md:-mt-4 pl-1">
                    password does not match
                  </p>
                )}
            </div>

            <Button
              name="Confirm"
              className="flex-1 w-full"
              disabled={
                !resetCode ||
                !newPassword ||
                !confirmNewPassword ||
                newPassword !== confirmNewPassword ||
                submitting
              }
              loading={submitting}
              onClick={() => handleResetPassword()}
            />
          </div>
        )}
      </Modal>
    </>
  );
}
