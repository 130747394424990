import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getModule } from '../api/modules';
import Module from '../components/Module';
import Content from '../components/Content';
import Layout from '../components/Layout'

export default function Modules() {
  const [viewContent, setViewContent] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null)


  const { data, error, isLoading } = useQuery({
    queryKey: ['modules'],
    queryFn: getModule,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading modules: {error.message}</div>;


  const handleViewContent = (module) => {
    setSelectedModule(module);
    setViewContent(true)
  }
  return (
    <Layout>
      {viewContent ?
        <Content modules={data} selectedModule={selectedModule} />
        :
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10">
          {data.data?.map((module) => (
            <Module
              module={module}
              key={module.id}
              moduleTitle={module.name}
              moduleDescription={module.description}
              handleViewContent={() => handleViewContent(module)}
            />
          ))}
        </div>
      }

    </Layout>
  )
}
