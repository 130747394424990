import api from "./axios";

export const getModule = () => {
    return api.get('/modules')
        .then(res => res.data)
        .catch(error => {
            console.error('Error fetching modules:', error);
            throw error;
        });
};
