export default function Minimize({ className }) {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path d="M14.0814 16H27.4148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.0814 16L19.4148 21.3333" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.0814 16L19.4148 10.6667" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.08142 5.33331V26.6666" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
  