import React from 'react'
import { Copy } from '../assets';
import Button from './Button';
import AppointmentIcon from '../assets/AppointmentIcon';
export default function Appointment({
    isConfirmed
}) {

    const action = isConfirmed ? 'Scheduled appointment' : 'New appointment booking' ;
  return (
    <div className='flex flex-col md:flex-row justify-between m-8 md:max-h-[160px] bg-light rounded-3xl shadow'>
        <div className='flex justify-center h-20 md:h-[160px] md:w-44 rounded-t-3xl md:rounded-tr-none md:rounded-tl-3xl md:rounded-bl-3xl bg-[#DBF0E3] items-center'>
            <AppointmentIcon />
        </div>
        <div className='flex flex-col p-5 md:p-0 justify-center items-center'>
            <h3 className='p-4 text-center text-black font-bold text-xl'>{action}</h3>
            <div className='flex flex-col md:flex-row'>
                <p className='p-2'><span className=' text-neutral-200'>Student: </span>Jane Doe</p>
                <p className='p-2'><span className=' text-neutral-200'>Time: </span> 11AM - 12AM</p>
            </div>
            <div className='flex flex-col md:flex-row'>
                <p className='p-2'><span className=' text-neutral-200'>Date: </span> March 20, 2023</p>
                <p className='flex p-2'><span className=' text-neutral-200 mr-1'>Email:</span>jane@doe.com<Copy className=' h-4 w-4 ml-1 cursor-pointer stroke-primary-100' /></p>
            </div>
        </div>
        <div className='flex flex-col justify-center py-5 px-10'>
            {!isConfirmed && 
                <Button className='w-full py-2 px-8 mb-4' name='Accept' />
            }
            <Button className={`w-full py-2 px-8 ${isConfirmed ? '' : 'bg-white border-2 border-neutral-200 text-neutral-200 hover:text-light hover:border-primary-100' }`} name='Reschedule' />
        </div>
    </div>
  )
}
