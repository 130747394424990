import Spinner from "./Spinner";

export default function Button({
  className,
  Component,
  disabled,
  name,
  onClick,
  type = "PRIMARY",
  loading = false,
}) {
  let _className = "";

  switch (type) {
    case "PRIMARY":
      _className = `bg-primary-100 rounded-lg md:py-3 py-2 text-light text-sm md:text-lg cursor-pointer transition-all ease-in-out border border-primary-100/80 active:shadow-lg outline-none focus:ring-2 ring-primary-100/80 ring-offset-2 ${className} flex gap-2 items-center justify-center ${disabled ? " cursor-not-allowed opacity-50" : " hover:bg-primary-100/90 hover:shadow"
        } ${Component ? " flex justify-between items-center " : ""}`;
      break;
    case "submit":
      _className = `bg-primary-100 rounded-lg md:py-3 py-2 text-light text-sm md:text-lg cursor-pointer transition-all ease-in-out border border-primary-100/80 active:shadow-lg outline-none focus:ring-2 ring-primary-100/80 ring-offset-2 ${className} flex gap-2 items-center justify-center ${disabled ? " cursor-not-allowed opacity-50" : " hover:bg-primary-100/90 hover:shadow"
        } ${Component ? " flex justify-between items-center " : ""}`;
      break;

    case "SECONDARY":
      _className = `bg-[#FBFBFB] rounded-lg py-2 md:py-3 text-dark text-sm md:text-lg cursor-pointer border border-primary-100/80 hover:border-primary-100 transition-all ease-in-out outline-none focus:ring-2 ring-primary-100 ring-offset-2 focus:border-none ${className} flex gap-2 items-center justify-center ${disabled
          ? " cursor-not-allowed opacity-50"
          : " hover:bg-primary-100/80 hover:shadow hover:text-light"
        }  ${Component ? " flex justify-between items-center " : ""}`;
      break;

    default:
      break;
  }

  return (
    <button className={_className} disabled={disabled} onClick={onClick}>
      {loading && <Spinner />}
      {name}
      {Component && <Component />}
    </button>
  );
}
