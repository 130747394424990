import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from "chart.js";
  import { useEffect, useState, useRef } from "react";
  import { Line } from "react-chartjs-2";
  
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);
  
  export function Chart({
    chartData = {
      datasets: [
        {
          borderColor: "#CC377B",
          borderWidth: 2,
          lineTension: 0.4,
          pointRadius: 0.1,
          fill: "start",
          opacityFill: 0.3,
          pointColor: "#fff",
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
        },
      ],
    },
    data,
    labels,
  }) {
    const [_chartData, setChartData] = useState(chartData);
    const chartRef = useRef(null);
    const createGradient = (ctx, area) => {
      const colorEnd = "#cc377a1d";
      const colorStart = "rgba(38, 189, 164, 0.1)";
  
      const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  
      gradient.addColorStop(0, colorStart);
      gradient.addColorStop(1, colorEnd);
  
      return gradient;
    };
  
    useEffect(() => {
      const chart = chartRef.current;
  
      if (!chart) {
        return;
      }
  
      const chartData = {
        ..._chartData,
        datasets: _chartData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
        })),
      };
  
      setChartData(chartData);
    }, []);
  
    return (
      <>
        <Line
          ref={chartRef}
          data={{
            ..._chartData,
            labels,
            datasets: [
              {
                ..._chartData.datasets[0],
                data,
                pointHoverRadius: 5,
                // pointHoverBackgroundColor: "#0099CC",
                pointHoverBorderColor: "#0099CC",
                pointHoverBorderWidth: 2,
                pointHoverRadius: 3,
                hoverBorderWidth: 10,
                hoverBorderJoinStyle: "round",
                hoverBorderColor: "#fff",
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              filler: { propagate: false },
            },
            interaction: { intersect: false },
            scales: {
              x: {
                grid: { drawOnChartArea: false },
                title: {
                  color: "#050D10",
                  display: true,
                  font: { size: 16, weight: 600 },
                  text: "Hours",
                  padding: 10,
                },
              },
              y: {
                grid: { drawOnChartArea: true, borderDash: [15, 20], drawBorder: false },
                min: 0,
                title: {
                  color: "#050D10",
                  display: true,
                  font: { size: 16, weight: 600 },
                  text: "Total Users",
                  padding: 10,
                },
                ticks: {
                  precision: 0,
                },
              },
            },
          }}
        />
      </>
    );
  }
  