import React, { useState } from 'react'
import BarChart from '../BarChart';
import DropdownButton from '../DropdownButton';

export default function QuizStatsComponent() {
  const [labels, setLabels] = useState(['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']);
  const [dataToDisplay, setDataToDisplay] = useState([20, 30, 40, 50, 40, 70, 60, 90, 80, 70, 66,82, 90]);
  return (
    <div className='flex flex-col p-5 mt-5 min-h-[500px] bg-light rounded-lg'>
      <div className='flex p-5 justify-between'>
          <h3 className='text-black text-2xl font-semibold'>IoT architecture quiz</h3>
          <DropdownButton />
      </div>
      <div className='flex-1'>
      <BarChart labels={labels} dataToDisplay={dataToDisplay} titleXLabel={'Month'} titleYLabel={'Avg Performance'} />
      </div>
    </div>
  )
}
