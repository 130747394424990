import React, { useState } from 'react';
import Button from './Button';

const UserForm = ({ addUser }) => {
    const [newUser, setNewUser] = useState({ name: '', email: '', phoneNumber: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        addUser(newUser);
        setNewUser({ name: '', email: '', phoneNumber: '' });
    };
    return (
        <form className="mb-5" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4 mb-4">
                <input
                    className="border border-gray-300 p-2"
                    type="text"
                    name="name"
                    value={newUser.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                />
                <input
                    className="border border-gray-300 p-2"
                    type="email"
                    name="email"
                    value={newUser.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                />
                <input
                    className="border border-gray-300 p-2"
                    type="text"
                    name="phoneNumber"
                    value={newUser.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                />
            </div>
            <div className="flex justify-center">
                <Button
                    className="px-8 py-2 font-normal"
                    name="Add Use"
                />
            </div>

        </form>
    );
};

export default UserForm;
