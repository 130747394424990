import dayjs from "dayjs";
import CalendarComponent from './Calendar';
import MiniTotal from './MiniTotal';
import { Chart } from './Chart';
import { useState } from "react";
import BarChart from "./BarChart";

export default function UserAnalytics() {
    //const { profile } = useSelector((state) => state.profile);
    const [labels, setLabels] = useState([1,2,3,4]);
    const [dataToDisplay, setDataToDisplay] = useState([20, 30, 40, 50]
    );
    const [startPeriod, setStartPeriod] = useState(null)
    const [endPeriod, setEndPeriod] = useState(null)
    const [calendarFromValue, setCalendarFromValue] = useState(new Date());
    const [calendarToValue, setCalendarToValue] = useState(new Date());
    const [showCalender, setShowCalendar] = useState(false);

    const handleCalendarToggle = () => {
      setShowCalendar(!showCalender);
    };

    const handleSelectCalendar = (val, type) => {
      const period = new Date(val).toLocaleDateString('en-CA');
      if(type === 'from') {
        setStartPeriod(period)
      } else {
        setEndPeriod(period)
      }
    }
  
  return (
    <div className='flex flex-row'>
        {/* active users */}
        <div className="grid gap-10 grid-cols-9 flex-1 rounded-2xl overflow-hidden mb-10 bg-white">
            <div className="bg-light rounded-md p-10 py-5 col-span-9 shadow flex flex-col h-full flex-1 overflow-scroll">
              <div className="flex flex-row justify-between sticky top-0">
                <div className="flex flex-col pb-10">
                  <p className="font-semibold text-2xl">Total active users</p>
                  <p className="text-neutral-500">
                    From {dayjs(calendarFromValue).format("MMM DD, YYYY")} to{" "}
                    {dayjs(calendarToValue).format("MMM DD, YYYY")}
                  </p>
                </div>
                <CalendarComponent
                  onFromChange={setCalendarFromValue} 
                  fromValue={calendarFromValue}
                  onToChange={setCalendarToValue} 
                  toValue={calendarToValue}
                  showCalender={showCalender}
                  onClick={handleCalendarToggle}
                  onClickDay={handleSelectCalendar}
                />
              </div>
              <div className="flex-1">
                <BarChart  titleXLabel={'Week'} titleYLabel={'Total Users'} dataToDisplay={dataToDisplay} labels={labels} />
              </div>
            </div>
          </div>
          <div>
            <MiniTotal number={300} />
            <MiniTotal users='active users' number={300} />
          </div>
      </div>
  )
}
