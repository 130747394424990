import React from 'react'

export default function Upload({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M9.5 17V11L7.5 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.5 11L11.5 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.5 10V15C22.5 20 20.5 22 15.5 22H9.5C4.5 22 2.5 20 2.5 15V9C2.5 4 4.5 2 9.5 2H14.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.5 10H18.5C15.5 10 14.5 9 14.5 6V2L22.5 10Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
