import React from 'react'
import { Trash } from '../../assets'

export default function Resource({name,fileType,onDelete}) {
  return (
    <div className='flex justify-between items-center w-full shadow-lg bg-light rounded-xl px-10 py-4'>
        <div className='flex justify-start items-center gap-8 pr-20'>
            <p>{name}</p>
            <p className='text-neutral-200 font-medium uppercase'>{fileType}</p>
        </div>
        <div className='h-12 w-12 bg-[#F2DFD9] rounded-full items-center'>
            <Trash className='h-5 w-5 m-[14px] stroke-alert cursor-pointer' onClick={onDelete}/>
        </div>
    </div>
  )
}
