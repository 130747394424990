import React from 'react'

export default function Appointments({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M8.5 2V5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.5 2V5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 9.08984H21" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.5 8.5V17C21.5 20 20 22 16.5 22H8.5C5 22 3.5 20 3.5 17V8.5C3.5 5.5 5 3.5 8.5 3.5H16.5C20 3.5 21.5 5.5 21.5 8.5Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.1947 13.7002H16.2037" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.1947 16.7002H16.2037" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.4955 13.7002H12.5045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.4955 16.7002H12.5045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.79431 13.7002H8.80329" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.79431 16.7002H8.80329" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
