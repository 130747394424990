import React from 'react';
import { QueryClient, useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { deleteModule } from '../api/deleteModule';
import Warning from '../assets/Warning';
import Button from './Button';

const DeleteModule = ({ handleModalToggle, selectedModule, handleDeleteModule, type = 'module' }) => {
  const queryClient = useQueryClient()
  const { mutate, isPending } = useMutation({
    mutationFn: deleteModule,
    onSuccess: () => {
      toast.success(`Module ${selectedModule.id} was deleted successfully!`);
      queryClient.invalidateQueries('modules')
      handleModalToggle();
    },
    onError: (error) => {
      toast.error(`Error deleting module: ${error.message}`);
    },
  });

  const handleDelete = () => {
    if (selectedModule?.id) {
      mutate(selectedModule.id);
    }
  };

  return (
    <div className='flex flex-col justify-center'>
      <div className='py-3 flex flex-col justify-center'>
        <Warning className='mx-auto' />
        <p className='mx-auto mt-5 text-2xl text-alerts-danger-500'>Delete {selectedModule?.title}?</p>
      </div>
      <div className='py-3 flex justify-center'>
        <p className='text-base mx-auto'>You’re about to delete a {type} and all its content. Note that this action cannot be undone.</p>
      </div>
      <div className='py-3 flex flex-col md:flex-row'>
        <Button
          name={'Cancel'}
          onClick={handleModalToggle}
          className='mr-3 w-44 text-neutral-300 hover:bg-[#FBFBFB] hover:text-neutral-600'
          type='SECONDARY'
        />
        <Button
          name={'Delete'}
          onClick={handleDelete}
          loading={isPending}
          isPending={isPending}
          className='w-44 bg-alert hover:bg-alert/80 border-alert/80'
        />
      </div>
    </div>
  );
};

export default DeleteModule;

