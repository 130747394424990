import React from 'react'
import Video from '../../assets/Video'

export default function VideoComponent() {
  return (
    <div className='p-5 flex justify-center items-center min-h-[500px]'>
      <Video className='m-auto h-44 w-44 stroke-login ' />
    </div>
  )
}
