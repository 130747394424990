import React from 'react'

export default function CloudUpload({className}) {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_1355_11901)">
    <path d="M21 53.9996C17.2568 53.9996 13.667 52.5773 11.0201 50.0456C8.37332 47.5138 6.88635 44.08 6.88635 40.4996C6.88635 36.9192 8.37332 33.4854 11.0201 30.9537C13.667 28.4219 17.2568 26.9996 21 26.9996C21.884 23.0611 24.4703 19.6001 28.1898 17.3778C30.0314 16.2774 32.0959 15.5143 34.2653 15.1319C36.4347 14.7496 38.6665 14.7556 40.8333 15.1496C43.0001 15.5436 45.0595 16.3178 46.8939 17.428C48.7282 18.5383 50.3016 19.9629 51.5243 21.6204C52.7469 23.2779 53.5948 25.1359 54.0196 27.0884C54.4444 29.0408 54.4377 31.0495 54 32.9996H57C59.7848 32.9996 62.4555 34.1059 64.4246 36.075C66.3937 38.0441 67.5 40.7148 67.5 43.4996C67.5 46.2844 66.3937 48.9551 64.4246 50.9242C62.4555 52.8934 59.7848 53.9996 57 53.9996H54" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27 45L36 36L45 45" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M36 36V63" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1355_11901">
    <rect width="72" height="72" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}
