import React from 'react'

export default function TopicMarker({className}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M2.5 8.33301H17.5" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M2.5 12.5H17.5" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>


  )
}
