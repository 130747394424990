import React from 'react'

export default function MiniTotal({
  users="users",
  number=0
}) {
  return (
    <div className='shadow rounded-2xl bg-white m-10 p-10 text-center'>
        <h1 className=' font-extrabold text-6xl'>{number}</h1>
        <p className='pt-2'>Total {users}</p>
    </div>
  )
}
