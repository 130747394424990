import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createCourse, createLesson, createQuiz, geModules, getLessonsByTopic, getQuizByQuizId, getQuizByTopic, getTopicsByModuleId, loginUser, uploadVideo } from "./requests";
import { toast } from 'react-toastify';

export const useGetModules = () => {
  return useQuery({
    queryKey: ['modules'],
    queryFn: geModules,
    onSuccess: (data) => {
      console.log('Data fetched successfully:', data);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}


export const useGetTopicsByModuleId = (module_id) => {
  return useQuery({
    queryKey: ['topics',module_id],
    queryFn: getTopicsByModuleId,
  });
}

export const useGetLessonsByTopic = (lesson_id) => {
  return useQuery({
    queryKey: ['lessons', lesson_id],
    queryFn: getLessonsByTopic,
  });
}

export const useGetQuizzesByTopic = (lesson_id) => {
  return useQuery({
    queryKey: ['quizzes', lesson_id],
    queryFn: getQuizByTopic,
  });
}

export const useGetQuizzesById = (quiz_id) => {
  return useQuery({
    queryKey: ['quizzes', quiz_id],
    queryFn: getQuizByQuizId,
  });
}

export const useCreateCourse = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createCourse,
    onSuccess: (data) => {
      toast.success('Module updated successfully!');
      queryClient.invalidateQueries('modules')
    },
    onError: (data) => {
      toast.error('something went wrong');
      queryClient.invalidateQueries('modules')
    },
  });
}

export const useLoginUser = () => {
  return useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      toast.success('Logged in successfully!');
    },
    onError: (data) => {
      toast.error('something went wrong');
    },
  });
}

export const useCreateLesson = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createLesson,
    onSuccess: (data) => {
      toast.success('Lesson created successfully!');
      queryClient.invalidateQueries('modules')
    },
    onError: (data) => {
      toast.error('something went wrong');
      queryClient.invalidateQueries('modules')
    },
  });
}

export const useCreateQuiz = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createQuiz,
    onSuccess: (data) => {
      toast.success('Lesson created successfully!');
      queryClient.invalidateQueries('modules')
    },
    onError: (data) => {
      toast.error('something went wrong');
      queryClient.invalidateQueries('modules')
    },
  });
}

export const useUploadVideo = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: uploadVideo,
    onSuccess: (data) => {
      toast.success('Lesson created successfully!');
      queryClient.invalidateQueries('modules')
    },
    onError: (data) => {
      toast.error('something went wrong');
      queryClient.invalidateQueries('modules')
    },
  });
}