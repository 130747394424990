import React from 'react';
import { avatar } from '../assets';
import Button from './Button';
import { FaPlus } from 'react-icons/fa';

const UserDatabaseNavbar = ({ onAddUser }) => {
    return (
        <div className=" top-0 mt-0 ">
            <nav className="p-2 flex items-center justify-between max-w-screen-xl mx-auto">
                <div className="flex items-center space-x-2">
                    <button className="bg-primary-500 px-4 py-2 rounded hover:bg-gray-100">
                        Student
                    </button>
                    <button className="bg-gray-100 px-4 py-2 rounded hover:bg-gray-100">
                        Tutor
                    </button>
                </div>
                <div className="hidden lg:flex justify-center flex-grow mx-4 ">
                    <div className="relative w-full max-w-md">
                        <svg
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                            width="20"
                            height="20"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M10,20C4.477,20,0,15.523,0,10S4.477,0,10,0s10,4.477,10,10S15.523,20,10,20z M10,2C5.589,2,2,5.589,2,10s3.589,8,8,8s8-3.589,8-8S14.411,2,10,2z" />
                            <path d="M21.707,20.293l-5.387-5.386C17.142,13.563,18,11.854,18,10c0-4.418-3.582-8-8-8S2,5.582,2,10s3.582,8,8,8c1.854,0,3.563-0.858,4.906-2.32l5.387,5.387L21.707,20.293z" />
                        </svg>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="p-2 pl-20  rounded bg-white text-gray-100"
                        />
                    </div>
                </div>
                <div className="flex items-center">
                    <Button
                        className="flex items-center px-8 py-2 font-normal"
                        name={
                            <>
                                <FaPlus className="flex items-center justify-center w-5 h-5 rounded-full bg-violet-950 text-white " />
                                Add New User
                            </>
                        }
                        onClick={onAddUser}
                    />
                    <img src={avatar} className="rounded-full w-10 h-10" alt="avatar" />
                </div>
            </nav>
        </div>
    );
};

export default UserDatabaseNavbar;
