import React from 'react'

export default function NotesComponent({title,notes}) {
  return (
    <div className='flex flex-col'>
      <h3 className='text-2xl font-bold p-2'>{title}</h3>
      <div className='p-5 min-h-[500px] rounded-lg bg-light'>
        <p className='pb-4'>{notes}</p>
      </div>
    </div>
  )
}
