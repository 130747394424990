import React, { useState } from 'react';
import Layout from '../components/Layout';
import Button from '../components/Button';
import UserAnalytics from '../components/UserAnalytics';
import UserDatabase from '../components/UserDatabase';

export default function Dashboard() {
  const [showAnalytics, setShowAnalytics] = useState(true);

  const toggleComponents = () => {
    setShowAnalytics((prevShowAnalytics) => !prevShowAnalytics);
  };

  const buttonName = showAnalytics ? 'User Database' : 'User Analytics';

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between py-1 pb-6">
        <div className='pb-20'>
          <h2 className="text-3xl font-semibold pb-2">Hello User,</h2>
          <p className="text-neutral-400 text-lg">Find some actionable insights in your dashboard</p>
        </div>
        <div className='lg:w-[200px]'>
          <Button className='w-full' name={buttonName} onClick={toggleComponents} />
        </div>
      </div>

      {showAnalytics ? <UserAnalytics /> : <UserDatabase />}
    </Layout>
  );
}
